import React from 'react'
import { useQuery } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'
import { GridItem, Text, Box, Stack, Flex, Spinner } from '@chakra-ui/react'
import { reverse } from 'ramda'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import Card from '../../components/core/Card'
import Table from '../core/Table'

const PaymentHeaders = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'date',
    key: 'date',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().AMOUNT,
    type: 'cash',
  },
  {
    id: 'payment_method',
    key: 'payment_method',
    name: dictionary().PAYMENT_METHOD,
    type: 'text',
  },
  {
    id: 'collector_name',
    key: 'collector_name',
    name: dictionary().NAME,
    type: 'text',
  },
  {
    id: 'ref',
    key: 'ref',
    name: dictionary().REF_NUMBER,
    type: 'text',
  },
  {
    id: 'note',
    key: 'note',
    name: dictionary().COMMENT,
    type: 'text',
  },
  {
    id: 'ConfirmationImage',
    key: 'ConfirmationImage',
    name: dictionary().RELATED_IMAGES,
    type: 'modal',
  },
]

const PaymentsTable: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { pathname } = useLocation()
  const { isLoading, data } = useQuery(
    [`payment-data-${id}`],
    () => fetch('GET', `${pathname.includes('corporates') ? '/corporates/' : '/station-providers/'}${id}`),
    {},
  )

  return (
    <>
      <Card h='100%' p={4}>
        <Text m={3} fontSize='20px'>
          {dictionary().PAYMENT_TABLE}
        </Text>
        {isLoading ? (
          <Flex w='100%' h='100%' justify='center'>
            <Spinner />
          </Flex>
        ) : data?.corporate_payment?.length > 0 || data?.station_provider_payment?.length > 0 ? (
          <GridItem colSpan={6}>
            <Box w='100%'>
              <Stack>
                <GridItem>
                  <Card h='100%'>
                    <Table
                      raised
                      enableIdRedirect={false}
                      height={350}
                      headers={PaymentHeaders}
                      data={reverse(pathname.includes('corporates') ? data?.corporate_payment : data?.station_provider_payment)}
                    />
                  </Card>
                </GridItem>
              </Stack>
            </Box>
          </GridItem>
        ) : (
          <Flex w='100%' h='100%' justify='center'>
            <Text fontSize='20px'>{dictionary().NO_PAYMENT}</Text>
          </Flex>
        )}
      </Card>
    </>
  )
}

export default PaymentsTable
