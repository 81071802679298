import { useRef, useState } from 'react'

import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, useDisclosure } from '@chakra-ui/react'

import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import dictionary from '../../../dictionary'
import fetch from '../../../helpers/fetch'
import { formatDate } from '../../../helpers/formatDate'
import useAlert from '../../../helpers/useAlert'
import AccountantPaymentDataInputs from './AccountantPaymentDataInputs'
import PaymentRejectReson from './PaymentRejectReson'

interface Props {
  data: any
  handleIsBalancesChange?: () => void
  role?: string
}

const AccountantReviewPaymentModel: React.FC<Props> = ({ data, handleIsBalancesChange, role }) => {
  const isNotEditable = ['REJECTED', 'CONFIRMED'].includes(data?.status)
  const [isRejected, setIsRejected] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isConfirmed, setIsConfirmed] = useState(false)
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const { onSuccess } = useAlert()
  const {
    register,
    formState: { errors },
    trigger,
    setValue,
    control,
    getValues,
    watch,
  } = useForm({ mode: 'onChange' })
  const depositType = watch('deposit_type')
  const PaymentCashType = ['CASH', 'CHEQUE']

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', `/deposit-request/${data?.id}/accountant/review`, { data: vars }), {
    onSuccess: (): void => {
      handleIsBalancesChange?.()
      onSuccess()
      onClose()
    },
  })

  const confirmPayment = async (formData: any) => {
    if (!isRejected) {
      formData.amount = +formData?.amount
      PaymentCashType.includes(depositType) && (formData.bank_name = null)
      mutate({
        ...formData,
        status: 'CONFIRMED',
      })
    } else {
      mutate({
        status: 'REJECTED',
        reason: formData?.reason === 'false' ? formData?.other_reason : formData?.reason,
      })
    }
  }
  const rejectPayment = async () => {
    setIsRejected(!isRejected)
    setIsConfirmed(false)
    error && delete (error as any)?.data
  }

  const handleOpen = () => {
    onOpen()
    setValue('ref', data?.ref)
    setValue('amount', data?.actual_amount)
    setValue('reason', data?.reason)
    setValue('deposit_type', data?.deposit_type)
    data?.bank_name ? setValue('bank_name', data?.bank_name) : setValue('bank_name', '')
    data?.date && setValue('date', formatDate(data?.date))
    setIsConfirmed(false)
    errors?.ref && delete errors?.ref
    errors?.date && delete errors?.date
    setIsRejected(false)
    error && delete (error as any)?.data
  }

  return (
    <>
      {role === 'SUPER' ? (
        <Button data-test={dictionary().FINAL_CONFIRMATION} onClick={handleOpen} background={'#7165E3'} color={'#fff'}>
          {dictionary().FINAL_CONFIRMATION}
        </Button>
      ) : (
        <img data-test={dictionary().FINAL_CONFIRMATION} src='/assets/imageIcon.svg' onClick={handleOpen} style={{ cursor: 'pointer' }} />
      )}
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} size='5xl' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().RELATED_IMAGES} </ModalHeader>
          <ModalBody pb={6}>
            {!isRejected ? (
              <AccountantPaymentDataInputs {...{ register, errors, watch, data, isConfirmed }} />
            ) : (
              <PaymentRejectReson {...{ setValue, control, errors, watch, register }} />
            )}
            {(error as any)?.data?.message === 'ref exists already' && (
              <p
                style={{
                  fontSize: '14px',
                  color: '#C34545',
                  marginTop: '10px',
                  padding: ' 6px 7px 6px 5px',
                  borderRadius: '5px',
                  backgroundColor: 'rgba(234, 118, 103, 0.20)',
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  direction: 'rtl',
                }}
              >
                <img src='/assets/InvaliIcon.svg' />
                {dictionary().THE_DEPOSIT_REF_IS_DUPLICATE}
                {/* {(error as any)?.data?.message} */}
              </p>
            )}
          </ModalBody>
          <ModalFooter dir='rtl' display={'flex'} justifyContent={'space-between'}>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
            {!isNotEditable && (
              <Box>
                <Button onClick={() => rejectPayment()} mr={3}>
                  {isRejected ? dictionary().BACK : dictionary().REJECT}
                </Button>
                <Button
                  onClick={async () => (!isConfirmed ? setIsConfirmed(true) : (await trigger()) && confirmPayment(getValues() as any))}
                  colorScheme='blue'
                  mr={3}
                  data-test={dictionary().SAVE}
                >
                  {isLoading ? <Spinner /> : isRejected ? dictionary().SAVE : dictionary().CONFIRM_IT}
                </Button>
              </Box>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AccountantReviewPaymentModel
