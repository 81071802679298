import { Flex } from '@chakra-ui/react'
import EditPos from '../../../pages/pos/EditPos'
import { ForceLogoutButton } from '../../ForceLogoutButton'
import CommentModel from '../CommentModel'
import ConfirmReviewTransactionModel from '../ConfirmReviewTransactionModel'
import ConfirmTransactionModel from '../ConfirmTransactionModel'
import ImageModal from '../ImageModal'
import NoteModel from '../NoteModel'
import PaymentReview from '../PaymentsReview/PaymentReview'
import RechargeBalance from '../RechargeBalance'
import RefundDuplicateTransaction from '../RefundDuplicateTransaction'
import ResetCDriverPinCode from '../ResetCDriverPinCode'
import ResetPassword from '../ResetPasswordModal'
import ReviewDuplicateTransactionModel from '../ReviewDuplicateTransactionModel'
import FuelPumpDataEntryModal from '../Transactions/FuelPumpDataEntryModal'
import TranseferBetweenEntitiesForCorporateModal from '../TranseferBetweenEntitiesForCorporateModal'
import UnassignDriver from '../UnassignDriver'
import UpdateOdometerReadingDataEntry from '../UpdateOdometerReadingDataEntry'
import UpdateOdometerReadingModal from '../UpdateOdometerReadingModal'
import UpdateVehicleStatus from '../UpdateVehicleStatus'
import ViewDashboard from './../ViewDashboard'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const modalCases = (header: any, row: any, value: any, handleIsDriverRemoved?: () => void, handleIsBalancesChange?: () => void) => {
  switch (header.id) {
    case 'forceLogout':
      return <ForceLogoutButton data={row} />
    case 'paymentImage':
      return <PaymentReview data={row} handleIsBalancesChange={handleIsBalancesChange} />
    case 'openDashboard':
      return <ViewDashboard data={row} />
    case 'comment':
      return <CommentModel data={row} />
    case 'note':
      return <NoteModel data={row} />
    case 'ConfirmationImage':
      return <ImageModal data={row} header={header} pump={false} />
    case 'OdometerImageModal':
      return <ImageModal data={row} header={header} pump={false} />
    case 'PumpImageModal':
      return <ImageModal data={row} header={header} pump={true} />
    case 'fuelPumpDataEntryModal':
      return <FuelPumpDataEntryModal data={row} handleIsBalancesChange={handleIsBalancesChange} />
    case 'confirmTransactionModal':
      return <ConfirmTransactionModel data={row} />
    case 'confirmAndReviewTransactionModal':
      return (
        <Flex>
          <ConfirmTransactionModel data={row} />
          <ConfirmReviewTransactionModel data={row} />
        </Flex>
      )
    case 'unassignDriver':
      return <UnassignDriver driverId={value} handleIsDriverRemoved={handleIsDriverRemoved} />
    case 'resetPassword':
      return <ResetPassword data={row} />
    case 'resetCDriverPinCodeAndTransferAmount':
      return (
        <>
          <ResetCDriverPinCode data={row} />
          {'  '}
          <TranseferBetweenEntitiesForCorporateModal data={row} handleIsBalancesChange={handleIsBalancesChange} />
          {'  '}
          <RechargeBalance data={row} handleIsBalancesChange={handleIsBalancesChange} />
        </>
      )
    case 'transferAmount':
      return (
        <>
          <TranseferBetweenEntitiesForCorporateModal data={row} handleIsBalancesChange={handleIsBalancesChange} />
          {'  '}
          <RechargeBalance data={row} handleIsBalancesChange={handleIsBalancesChange} />
        </>
      )
    case 'updateOdometerReadingModal':
      return <UpdateOdometerReadingModal data={row} />
    case 'editVehicleStatusModal':
      return <UpdateVehicleStatus data={row} handleIsBalancesChange={handleIsBalancesChange} />
    case 'updateOdometerReadingDataEntryModal':
      return <UpdateOdometerReadingDataEntry data={row} handleIsBalancesChange={handleIsBalancesChange} />
    case 'posActions':
      return <EditPos data={row} handleDateUpdate={handleIsBalancesChange} />
    case 'duplicatedActions':
      return (
        <Flex gap={2} minWidth='60px'>
          <RefundDuplicateTransaction data={row} />
          <ReviewDuplicateTransactionModel data={row} handleIsBalancesChange={handleIsBalancesChange} />
        </Flex>
      )

    default:
      return null
  }
}
