import React, { useState } from 'react'
import { Text, Box, Button } from '@chakra-ui/react'
import { BsExclamationLg } from 'react-icons/bs'
import { NotAllowedIcon } from '@chakra-ui/icons'
import { formatArabicDate } from '../../../helpers/formatArabicDate'

const ChargingProfileComment = ({ comment }: any) => {
  const [isReadMore, setIsReadMore] = useState(false)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }

  const truncateText = (text: any, length: any) => {
    if (text.length <= length) {
      return text
    }
    return text.substring(0, length) + '...'
  }

  return (
    <Box mb={3} pr={2} sx={{ display: 'flex', flexDirection: 'column', gap: '2px', alignItems: 'flex-start', mt: '10px' }}>
      <h2>
        <Box as='span' flex='1' textAlign='right'>
          <Text fontSize='19px' fontWeight='600' display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
            {comment?.status === 'PENDING_ADJUSTMENTS' && (
              <BsExclamationLg
                style={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: 'rgba(233, 159, 39, 0.2)',
                  color: 'rgba(233, 159, 39, 1)',
                  borderRadius: '50%',
                  padding: '4px',
                  marginTop: '5px',
                  marginLeft: '5px',
                }}
              />
            )}
            {comment?.status === 'REJECTED' && (
              <NotAllowedIcon
                style={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: 'rgba(234, 118, 103, 0.2)',
                  color: 'rgba(195, 69, 69, 1)',
                  borderRadius: '50%',
                  padding: '4px',
                  marginTop: '5px',
                  marginLeft: '5px',
                }}
              />
            )}
            <span style={{ marginRight: '3px' }}>{comment?.status === 'PENDING_ADJUSTMENTS' ? 'Pending Adjustments' : 'Rejected'}</span>
          </Text>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'} width={'100%'} gap={2} mt={-5}>
            <Text fontSize='19px' fontWeight='600' display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
              <span>By: {comment?.admin_name?.ar}</span>
            </Text>
            <Text fontSize='16px' fontWeight='500' display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
              <span style={{ color: '#808080', fontSize: '16px' }}>{formatArabicDate(comment?.created_at)}</span>
            </Text>
          </Box>
        </Box>
      </h2>

      <Text fontSize='19px' fontWeight={'500'} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
        <span style={{ color: '#808080', fontSize: '16px' }}>
          {comment?.comment.length > 1200 ? (isReadMore ? comment?.comment : truncateText(comment?.comment, 1200)) : comment?.comment}
          {comment?.comment.length > 1200 && (
            <Button onClick={toggleReadMore} variant='link' colorScheme='blue' mr={2} style={{ color: '#1B202B', fontSize: '14px', fontWeight: 600 }}>
              {isReadMore ? 'اقرأ أقل' : 'اقرأ المزيد'}
            </Button>
          )}
        </span>
      </Text>
    </Box>
  )
}

export default ChargingProfileComment
