import { Link, Tbody, Td, Tr } from '@chakra-ui/react'
import { parse } from 'query-string'
import { Link as ReactLink, useHistory, useLocation } from 'react-router-dom'
import { FuelTransactionsData } from '../../../interfaces/data'
import { TableBodyProps, TableHeader } from '../../../interfaces/tableProps'
import DuplicateTransactionsModel from '../duplicate-transactions/DuplicateTransactionsModel'
import { Row } from './TableRow'

export const TableBody: React.FC<TableBodyProps> = ({
  headers,
  data,
  enableIdRedirect,
  handleIsDriverRemoved,
  renewalDate,
  footer,
  handleIsBalancesChange,
  register,
  errors,
}) => {
  const { is_active } = parse(window.location.search)
  const { push, location, replace } = useHistory()
  const isChargingProfileList = location?.pathname.includes('/charging-profiles')
  const { pathname } = useLocation()
  const redirect = (id: any) => {
    push(`${pathname}/${id}`)
  }

  const handleRedirection = (header: TableHeader, data: FuelTransactionsData) => {
    if (header?.type === 'stationId') {
      replace(`/stations/${data?.id}`)
    } else if (header?.type === 'vehicleRedirect') {
      replace(`/vehicles/${data?.id}`)
    } else if (header?.type === 'driverRedirect') {
      replace(`/drivers/${data?.id}`)
    } else if (header?.key === 'station_id') {
      redirect(data?.station_id as any)
    } else if (header?.key === 'corporate_id') {
      redirect(data?.corporate_id as any)
    } else {
      return
    }
  }

  if (!Array.isArray(data)) return null
  return (
    <>
      <Tbody>
        {data.map((_data, index) => (
          <Tr key={_data?.id || _data?.key || index}>
            {headers?.map((_header) =>
              enableIdRedirect && is_active !== 'false' ? (
                <Td
                  style={{
                    color: _header?.id === 'id' ? 'black' : undefined,
                    cursor: _header?.id === 'id' ? 'pointer' : undefined,
                    fontWeight: _header?.id === 'id' ? 'bold' : undefined,
                    textDecoration: _header?.id === 'id' ? 'underline' : undefined,
                    whiteSpace: 'nowrap', // Prevents text from wrapping
                    width: 'auto', // Automatically adjusts width based on content
                    maxWidth: '100%', // Ensures the cell grows only as much as needed
                    paddingRight: '8px',
                    paddingLeft: '8px',
                    // border: '1px solid #e2e8f0',
                    textAlign: 'center',
                  }}
                  onClick={() => handleRedirection(_header, _data)}
                  key={_header.id ?? _header.key}
                  isNumeric={_header.isRight}
                >
                  {_header?.id === 'id' ? (
                    <>
                      {_header?.key === 'station_id' ? (
                        <Link as={ReactLink} to={`${pathname}/${_data?.station_id}`}>
                          <Row header={_header} row={_data} />
                        </Link>
                      ) : _header?.key === 'corporate_id' ? (
                        <Link as={ReactLink} to={`${pathname}/${_data?.corporate_id}`}>
                          <Row header={_header} row={_data} renewalDate={renewalDate} />
                        </Link>
                      ) : _header?.id === 'id' ? (
                        <Link
                          as={ReactLink}
                          to={isChargingProfileList ? `${location?.pathname}/${_data?.id}/${_data?.corporate?.id}` : `${location?.pathname}/${_data?.id}`}
                        >
                          <Row header={_header} row={_data} handleIsDriverRemoved={handleIsDriverRemoved} renewalDate={renewalDate} />
                        </Link>
                      ) : (
                        <Row
                          header={_header}
                          row={_data}
                          handleIsDriverRemoved={handleIsDriverRemoved}
                          renewalDate={renewalDate}
                          handleIsBalancesChange={handleIsBalancesChange}
                          register={register}
                          errors={errors}
                        />
                      )}
                    </>
                  ) : (
                    <Row
                      header={_header}
                      row={_data}
                      handleIsDriverRemoved={handleIsDriverRemoved}
                      renewalDate={renewalDate}
                      handleIsBalancesChange={handleIsBalancesChange}
                      register={register}
                      errors={errors}
                    />
                  )}
                </Td>
              ) : (
                <>
                  {_header?.id === 'duplicate_transaction_id' && pathname === '/duplicate-transactions' ? (
                    <DuplicateTransactionsModel data={_data} />
                  ) : (
                    <Td
                      style={_header?.id === 'id' ? { color: 'black', fontWeight: 'bold', textAlign: 'center' } : { color: 'black', textAlign: 'center' }}
                      key={_header.id ?? _header.key}
                      isNumeric={_header.isRight}
                    >
                      <Row
                        header={_header}
                        row={_data}
                        handleIsDriverRemoved={handleIsDriverRemoved}
                        renewalDate={renewalDate}
                        handleIsBalancesChange={handleIsBalancesChange}
                        register={register}
                        errors={errors}
                      />
                    </Td>
                  )}
                </>
              ),
            )}
          </Tr>
        ))}
      </Tbody>
      {footer && footer}
    </>
  )
}
