import { ArrowBackIcon, ArrowForwardIcon, DownloadIcon } from '@chakra-ui/icons'
import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { FC } from 'react'
import dictionary from '../../../dictionary'
import downloadImage from './downloadFile'

type Props = {
  selectedFileIndex: number
  contractFiles: any
  isOpen: boolean
  onClose: () => void
  setSelectedFileIndex: (index: number) => void
}

const ArrowStyle = {
  backgroundColor: ' #977ED6',
  color: ' white',
  border: ' none',
  padding: ' 5px',
  cursor: ' pointer',
  marginRight: ' 10px',
  borderRadius: ' 50%',
  width: ' 30px',
  height: ' 30px',
  marginBottom: ' 10px',
}

const PreviewFile: FC<Props> = ({ selectedFileIndex, contractFiles, isOpen, onClose, setSelectedFileIndex }) => {
  const selectedFile = contractFiles?.[selectedFileIndex]
  const PDF_TYPE = ['application/pdf', 'pdf']
  const fileType = (selectedFile as string)?.split('/')?.[selectedFile?.split('/').length - 2]
  const handleNext = () => {
    if (selectedFileIndex < contractFiles?.length - 1) {
      setSelectedFileIndex(selectedFileIndex + 1)
    } else {
      setSelectedFileIndex(0)
    }
  }

  const handlePrevious = () => {
    if (selectedFileIndex > 0) {
      setSelectedFileIndex(selectedFileIndex - 1)
    } else {
      setSelectedFileIndex(contractFiles?.length - 1)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={!PDF_TYPE.includes(fileType) ? 'xl' : '6xl'} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader style={{ direction: 'rtl' }}>{dictionary().CONTRACT} </ModalHeader>
        <ModalBody>
          <ArrowBackIcon onClick={handleNext} sx={ArrowStyle} />
          <ArrowForwardIcon onClick={handlePrevious} sx={ArrowStyle} />
          <DownloadIcon onClick={() => downloadImage(selectedFile, 'pdf')} sx={ArrowStyle} />

          {!PDF_TYPE.includes(fileType) ? (
            <img
              src={selectedFile}
              alt='IMG'
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'center',
                borderRadius: '10px',
                border: '1px dashed rgba(133, 148, 177, 0.50)',
                minWidth: '100%',
                height: '450px',
                padding: '5px',
                cursor: 'pointer',
              }}
            />
          ) : (
            <iframe src={selectedFile} style={{ width: '100%', height: ' 600px' }} title={'pdf'} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PreviewFile
