import { useEffect, useRef } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Modal,
  Spinner,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormLabel,
  Button,
  FormControl,
  useDisclosure,
  MenuItem,
  Flex,
  FormErrorMessage,
  InputGroup,
  Input,
} from '@chakra-ui/react'
import useAlert from '../../helpers/useAlert'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import Reqiured from '../../components/core/reqiured'

interface Props {
  action?: any
  handleIsDriverAssigned?: () => void
}
const TransferModal: React.FC<Props> = ({ action, handleIsDriverAssigned }) => {
  const { id } = useParams<{ id: string }>()

  const { onSuccess, onError } = useAlert()
  const corporateVehicles = useQuery(['corporateVehicless'], () => fetch('GET', `/vehicles/${id}`), {})

  const { isLoading, mutate } = useMutation((vars: any) => fetch('POST', `/vehicles/assign-driver`, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      onClose()
      handleIsDriverAssigned && handleIsDriverAssigned()
    },
    onError,
  })

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
  } = useForm({ mode: 'onChange' })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const AssignDriver = async (formData: any) => {
    mutate(formData)
  }
  useEffect(() => {
    setValue('vehicleId', id)
  }, [id])
  return (
    <>
      <MenuItem onClick={onOpen}>{action.title}</MenuItem>

      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().ASSIGN_DRIVER_TO_VEHICLE} </ModalHeader>
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit(AssignDriver as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                <FormControl id={dictionary().DRIVER_NAME} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.driverIds}>
                  <FormLabel style={{ direction: 'rtl' }}>
                    <>
                      {dictionary().DRIVER_NAME} <Reqiured />
                    </>
                  </FormLabel>

                  <Controller
                    name={`driverIds`}
                    rules={{ required: dictionary().REQUIRED }}
                    control={control}
                    render={({ field: { onChange } }: any) => (
                      <AutoCompleteInput
                        isInvalid={!!errors?.driverIds}
                        onChange={onChange}
                        searchRoute={`/drivers?corporateId=${corporateVehicles?.data?.corporate?.id}`}
                        singleRoute={`/drivers?corporateId=${corporateVehicles?.data?.corporate?.id}`}
                        valueFormatter={(option) => option?.id}
                        displayFormatter={(option) => option?.name}
                        multiple={true}
                      />
                    )}
                  />

                  <FormErrorMessage>{errors.driverIds?.message}</FormErrorMessage>
                </FormControl>
                <FormControl hidden id={dictionary().ID} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.driverIds}>
                  <FormLabel>{dictionary().ID}</FormLabel>

                  <InputGroup>
                    <Input
                      type='number'
                      {...register('vehicleId', {
                        required: dictionary().REQUIRED,
                        valueAsNumber: true,
                      })}
                    />
                  </InputGroup>

                  <FormErrorMessage>{errors.driverIds?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button onClick={async () => (await trigger()) && AssignDriver(getValues() as any)} colorScheme='blue' mr={3}>
              {isLoading ? <Spinner /> : dictionary().SAVE}
            </Button>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default TransferModal
