import React, { useEffect, useRef } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, Select, Checkbox, Stack, RadioGroup, Radio } from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import Reqiured from '../../components/core/reqiured'
import PasswordAndConfirmation from '../../components/core/PasswordAndConfirmation'

const AddStation: React.FC = () => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    watch,
    setValue,
    trigger,
  } = useForm({ mode: 'onChange' })
  const stationConfig = watch('station_config')
  const headerRef = useRef<HTMLDivElement>(null)
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/
  const phoneRegex = /^01[0125][0-9]{8}$/
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/stations', { data: vars }), {
    onSuccess: (): void => {
      push('/stations')

      onSuccess()
    },
  })

  useEffect(() => {
    setValue('show_for_drivers', true)
  }, [])
  useEffect(() => {
    setValue('live_ocr', null)
    setValue('auto_skip', null)
  }, [stationConfig])

  const addStation = async () => {
    if (getValues()?.coordinates?.toString().replace(/\s+/g, '')?.split(',').length < 2) {
      alert(`شكل الإحداثيات لابد أن يكون هكذا : العرض,الطول
           مثال :  562,245
      `)
    }

    mutate([
      {
        name: { en: getValues()?.name?.en.trim(), ar: getValues()?.name?.ar.trim() },
        station_provider_id: getValues()?.station_provider_id[0],
        city: getValues()?.city,
        address: getValues()?.address.trim(),
        road: getValues()?.road.trim(),
        type: getValues()?.type,
        username: getValues()?.username.trim(),
        password: getValues()?.password,
        contact_person: {
          name: getValues()?.contact_person?.name.trim(),
          phone: getValues()?.contact_person?.phone,
          email: getValues()?.contact_person?.email.trim(),
        },
        location: {
          latitude: Number(getValues()?.coordinates?.replace(/\s+/g, '')?.split(',')[0].trim()),
          longitude: Number(getValues()?.coordinates?.replace(/\s+/g, '')?.split(',')[1].trim()),
        },
        code: getValues()?.code.trim(),
        // is_demo: getValues()?.is_demo,
        // has_printing: getValues()?.has_printing,
        // show_reports: getValues()?.show_reports,
        // shifts_number: +getValues()?.shifts_number,
        // shift_starting: getValues()?.shift_starting,
        use_liters: getValues()?.use_liters,
        odometer_first: getValues()?.odometer_first,
        show_for_drivers: getValues()?.show_for_drivers,
        tips_percentage: Number(getValues()?.tips_percentage),
        config: {
          live_ocr: {
            enabled: stationConfig,
            skip: getValues()?.live_ocr === 'allow_skip',
            auto_skip: getValues()?.auto__skip,
          },
        },
      },
    ])
  }
  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header title={dictionary().ADD_NEW_STATION} action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && addStation()} />} />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(addStation as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl
              id={dictionary().STATION_NAME_AR}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.name?.ar}
            >
              <FormLabel>
                {dictionary().STATION_NAME_AR} <Reqiured />
              </FormLabel>

              <InputGroup>
                <Input
                  data-test={dictionary().STATION_NAME_AR}
                  type='text'
                  {...register('name.ar', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.ar?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().STATION_NAME_EN}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.name?.en}
            >
              <FormLabel>
                {dictionary().STATION_NAME_EN} <Reqiured />
              </FormLabel>

              <InputGroup>
                <Input
                  data-test={dictionary().STATION_NAME_EN}
                  type='text'
                  {...register('name.en', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.en?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().USERNAME}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.username || (error as any)?.data?.message === `Station staff with given username '${getValues().username}' already exists`}
            >
              <FormLabel>
                {dictionary().USERNAME}
                <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().USERNAME}
                  type='text'
                  {...register('username', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
              <FormErrorMessage>
                {(error as any)?.data?.message === `Station staff with given username '${getValues().username}' already exists` &&
                  dictionary().THIS_USER_NAME_IS_ALREADY_TAKEN}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().CODE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.code || (error as any)?.data?.message === 'this code is already exist'}
            >
              <FormLabel>
                {dictionary().CODE}
                <Reqiured />
              </FormLabel>

              <InputGroup>
                <Input
                  data-test={dictionary().CODE}
                  type='text'
                  {...register('code', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.code?.message}</FormErrorMessage>
              <FormErrorMessage>{(error as any)?.data?.message === `this code is already exist` && dictionary().THIS_CODE_IS_ALREADY_TAKEN}</FormErrorMessage>
            </FormControl>

            <PasswordAndConfirmation register={register} errors={errors} watch={watch} width={['100%', '40%', '40%', '40%']} minHight={['50px', '100px']} />

            <FormControl id={dictionary().CITY} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.city}>
              <FormLabel>{dictionary().CITY}</FormLabel>
              <InputGroup>
                <Input data-test={dictionary().CITY} type='text' {...register('city', {})} />
              </InputGroup>
              <FormErrorMessage>{errors?.city?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().STATION_ADDRESS}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.address}
            >
              <FormLabel>{dictionary().STATION_ADDRESS}</FormLabel>
              <InputGroup>
                <Input data-test={dictionary().STATION_ADDRESS} type='text' {...register('address', {})} />
              </InputGroup>

              <FormErrorMessage>{errors?.address?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().STATION_ROAD}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.road}
            >
              <FormLabel>{dictionary().STATION_ROAD}</FormLabel>
              <InputGroup>
                <Input data-test={dictionary().STATION_ROAD} type='text' {...register('road', {})} />
              </InputGroup>

              <FormErrorMessage>{errors?.road?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id={dictionary().CHOOSE_STATION_PROVIDER}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.station_provider_id}
            >
              <FormLabel>
                {dictionary().CHOOSE_STATION_PROVIDER}
                <Reqiured />
              </FormLabel>

              <Controller
                name={`station_provider_id`}
                rules={{ required: dictionary().REQUIRED }}
                control={control}
                render={({ field: { onChange } }: any) => (
                  <AutoCompleteInput
                    dataTest={dictionary().CHOOSE_STATION_PROVIDER}
                    isInvalid={!!errors?.station_provider_id}
                    onChange={onChange}
                    searchRoute='/station-providers'
                    singleRoute='/station-providers'
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.name?.ar}
                  />
                )}
              />
              <FormErrorMessage>{errors?.station_provider_id?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().COORDINATES}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={
                !!errors.coordinates || (error as any)?.data?.message[1] === 'location.longitude must be a number conforming to the specified constraints'
              }
            >
              <FormLabel>
                {dictionary().COORDINATES}
                <Reqiured />
              </FormLabel>

              <InputGroup>
                <Input
                  data-test={dictionary().COORDINATES}
                  type='text'
                  {...register('coordinates', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.coordinates?.message}</FormErrorMessage>
              <FormErrorMessage>
                {(error as any)?.data?.message[1] === 'location.longitude must be a number conforming to the specified constraints' &&
                  dictionary().INVALID_COORDINATES}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id={dictionary().STATIONS_TYPE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.type}
              display='inline-block'
            >
              <FormLabel>
                {dictionary().STATIONS_TYPE}
                <Reqiured />
              </FormLabel>

              <Select
                data-test={dictionary().STATIONS_TYPE}
                dir='ltr'
                placeholder={dictionary().STATIONS_TYPE}
                textAlign='right'
                {...register('type', {
                  required: dictionary().REQUIRED,
                })}
              >
                <option key={1} value='TOLL'>
                  {' '}
                  {dictionary().TOLL}
                </option>
                <option key={2} value='FUEL'>
                  {' '}
                  {dictionary().FUEL}
                </option>
                <option key={3} value='STORE'>
                  {' '}
                  {dictionary().STORE}
                </option>
              </Select>
              <FormErrorMessage>{errors?.type?.message} </FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STATION_PERCENTAGE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.tips_percentage}>
              <FormLabel>
                {dictionary().STATION_PERCENTAGE} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().STATION_PERCENTAGE}
                  type='number'
                  {...register('tips_percentage', {
                    required: dictionary().REQUIRED,
                    // pattern: {
                    //   value: /^\d+$/,
                    //   message: dictionary().THIS_INPUT_ACCEPTED_ONLY_INTEGER_NUMBERS,
                    // },
                    validate: (value) =>
                      value < 0 ? dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO : value > 100 ? dictionary().THIS_INPUT_CANT_BE_OVER_HUNDRED : undefined,
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.tips_percentage?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id={dictionary().STATIONS_CONTACT_NAME}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.contact_person?.name}
            >
              <FormLabel>
                {dictionary().STATIONS_CONTACT_NAME}
                <Reqiured />
              </FormLabel>

              <InputGroup>
                <Input
                  data-test={dictionary().STATIONS_CONTACT_NAME}
                  type='text'
                  {...register('contact_person.name', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.contact_person?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().STATIONS_CONTACT_NUMBER}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.contact_person?.phone}
            >
              <FormLabel>
                {dictionary().STATIONS_CONTACT_NUMBER}
                <Reqiured />
              </FormLabel>

              <InputGroup>
                <Input
                  data-test={dictionary().STATIONS_CONTACT_NUMBER}
                  type='number'
                  {...register('contact_person.phone', {
                    required: dictionary().REQUIRED,
                    validate: (value) => (!phoneRegex.test(value) ? dictionary().INVALID_PHONE : undefined),
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.contact_person?.phone?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().STATIONS_CONTACT_EMAIL}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.contact_person?.email}
            >
              <FormLabel>
                {dictionary().STATIONS_CONTACT_EMAIL}
                <Reqiured />
              </FormLabel>

              <InputGroup>
                <Input
                  data-test={dictionary().STATIONS_CONTACT_EMAIL}
                  type='text'
                  {...register('contact_person.email', {
                    required: dictionary().REQUIRED,
                    validate: (value) => (!emailRegex.test(value) ? dictionary().INVALID_EMAIL : undefined),
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.contact_person?.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={'Live OCR'} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <Controller
                control={control}
                name='station_config'
                defaultValue={false}
                render={({ field: { onChange } }) => (
                  <Checkbox data-test={'Live OCR'} onChange={onChange} textTransform='capitalize' value='true'>
                    Live OCR
                  </Checkbox>
                )}
              />
            </FormControl>
            {stationConfig && (
              <>
                <FormControl w='40%' minH='100px' display='inline-block' isInvalid={!!errors.live_ocr}>
                  <FormLabel>
                    إعدادات Live OCR <Reqiured />
                  </FormLabel>
                  <Controller
                    name='live_ocr'
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <Stack direction='row'>
                          <Radio value='allow_skip' color='purple'>
                            {dictionary().ALLOW_SKIP}
                          </Radio>
                          <Radio value='no_skip' color='purple'>
                            {dictionary().NO_SKIP}
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                    rules={{
                      required: true && dictionary().REQUIRED,
                    }}
                  />
                  <FormErrorMessage>{errors.live_ocr?.message}</FormErrorMessage>
                </FormControl>
                <FormControl id={'Live OCR'} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
                  <Controller
                    control={control}
                    name='auto_skip'
                    defaultValue={false}
                    render={({ field: { onChange } }) => (
                      <Checkbox data-test={dictionary().ALLOW_AUTO_SKIP} onChange={onChange} textTransform='capitalize' value='true'>
                        {dictionary().ALLOW_AUTO_SKIP} Live OCR
                      </Checkbox>
                    )}
                  />
                </FormControl>
              </>
            )}
            <FormControl id={dictionary().LITRES} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <Controller
                control={control}
                name='use_liters'
                defaultValue={false}
                render={({ field: { onChange } }) => (
                  <Checkbox data-test={dictionary().LITRES} onChange={onChange} textTransform='capitalize' value='true'>
                    {dictionary().USE_LITERS}
                  </Checkbox>
                )}
              />
            </FormControl>
            <FormControl id={dictionary().ODOMETER} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().ODOMETER}</FormLabel>
              <Controller
                control={control}
                name='odometer_first'
                render={({ field: { onChange } }) => (
                  <Checkbox data-test={dictionary().ODOMETER} onChange={onChange} textTransform='capitalize' value='true'>
                    {dictionary().ODOMETER_FIRST}
                  </Checkbox>
                )}
              />
            </FormControl>
            <FormControl id={dictionary().SHOW} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().SHOW}</FormLabel>
              <Controller
                control={control}
                name='show_for_drivers'
                render={({ field: { onChange } }) => (
                  <Checkbox data-test={dictionary().SHOW_STATION} onChange={onChange} textTransform='capitalize' defaultChecked value='true'>
                    {dictionary().SHOW_STATION}
                  </Checkbox>
                )}
              />
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddStation
