import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  Spinner,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import dictionary from '../../dictionary'
import formatAsCurrency from '../../helpers/formatAsCurrency'

interface Props {
  trigger: any
  addPayments: any
  getValues: any
  isLoading: any
  data: any[]
  width: any
  isDataFetched?: any
  message?: string
  label?: string
}

const RefundConfirmation: React.FC<Props> = ({ trigger, addPayments, getValues, isLoading, data, width, isDataFetched, message, label }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)
  const { pathname } = useLocation()
  const [isDataUpdated, setIsDataUpdated] = useState(false)
  const [totalAmount, setTotalAmount] = useState(0)
  const [FinalData, setFinalData] = useState<any[]>([])
  const displayedMessage = message || dictionary().ADDING_PAYMENT_TO

  const handleOpen = async () => {
    ;(await trigger()) && getValues() && onOpen()
  }

  useEffect(() => {
    let sumAmount = 0
    if (isOpen) {
      data?.map((item: any) => {
        item.amount = getValues()?.amount ? getValues()?.amount : getValues()[item?.id + '']
        item.payment_method = getValues()?.payment_method ? getValues()?.payment_method : getValues()[item?.id + 'method']
        sumAmount += +item.amount
      })
      setFinalData([...data])
      setIsDataUpdated(true)
      setTotalAmount(sumAmount)
    }
  }, [isOpen, data])

  return (
    <>
      <Button onClick={handleOpen} colorScheme='blue' variant={'solid'} marginTop={8} marginRight={5} w={width}>
        {label || dictionary().ADD_DEPSOIT}
      </Button>
      <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
        <AlertDialogOverlay />

        <AlertDialogContent dir='rtl'>
          <AlertDialogHeader>{dictionary().ARE_YOU_SURE_YOU_WANT_TO}</AlertDialogHeader>
          <AlertDialogBody>
            <b>
              {dictionary().TOTAL_AMOUNT} {formatAsCurrency(totalAmount)}
            </b>
            {isDataUpdated ? (
              FinalData.map((item: any) => (
                <ul key={item?.id}>
                  <li style={{ margin: '10px', direction: 'rtl' }}>
                    <span>{displayedMessage}</span> <span>{pathname.includes('/corporates') ? item?.name : item?.name?.ar}</span>{' '}
                    <span>{dictionary().EQUAL}</span> <span>{formatAsCurrency(item?.amount || item?.amount === 0 ? item?.amount : getValues()?.amount)}</span>{' '}
                  </li>
                </ul>
              ))
            ) : (
              <Spinner />
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {dictionary().CANCEL}
            </Button>
            <Button mr='3' colorScheme='blue' variant={'solid'} disabled={isLoading} onClick={async () => addPayments(getValues())}>
              {isLoading ? <Spinner /> : dictionary().SAVE}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default RefundConfirmation
