import React from 'react'
import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { LocalStorageData } from '../../interfaces/localStorageData'
import { useStore } from '../../store'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'trans_litre_amount',
    key: 'liters',
    name: dictionary().LITRES_AMOUNT,
    type: 'text',
  },
  {
    id: 'pump_reading',
    key: 'pump_reading',
    name: dictionary().PUMP_READING,
    type: 'text',
  },
  {
    id: 'odometer_reading',
    key: 'odometer_reading',
    name: dictionary().ODOMETER_READING,
    type: 'text',
  },
  {
    id: 'pump_reading_reviewed_at',
    key: 'pump_reading_reviewed_at',
    name: dictionary().PUMP_READING_REVIEW_DATE,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().AMOUNT,
    type: 'cash',
  },
  {
    id: 'total_amount',
    key: 'total_amount',
    name: dictionary().TOTAL_AMOUNT,
    type: 'cash',
  },
  {
    id: 'fuelPumpDataEntryModal',
    key: 'images.pump',
    name: dictionary().RELATED_IMAGES,
    type: 'modal',
  },
  {
    id: 'updateOdometerReadingDataEntryModal',
    key: 'updateOdometerReadingDataEntryModal',
    name: dictionary().UPDATE_ODOMETER_READING,
    type: 'modal',
  },
]

const FuelPumpDataEntry: React.FC = (props) => {
  const { userType } = useStore((state) => state)
  const [storedValue] = useLocalStorage('activeRole', {} as LocalStorageData)

  const role = storedValue?.role?.toLowerCase()

  return (
    <ViewAll
      headers={headers}
      title={dictionary().FUEL_TRANSACTIONS}
      tags={[
        { name: dictionary().ALL_TRANSACTIONS, filter: [{ name: '', value: '' }] },
        {
          name: dictionary().NEW_TRANSACTIONS,
          filter: [{ name: 'reviewed', value: false }],
        },
      ]}
      hasSearch={true}
      hasSearchDate={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['fuel-transaction']}
      countArrayPath={['count']}
      restRoute={'/fuel-transactions?normal=true&status=CONFIRMED'}
      cashingKey={CASHING_KEY.GET_FUEL_TRANSACTIONS_FOR_DATA_ENTRY}
      enableIdRedirect={false}
    />
  )
}

export default FuelPumpDataEntry
