import { Flex, Spinner, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import Card from '../../components/core/Card'
import Container from '../../components/core/Container'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import { AddFuelTransactionVariables, StateManagementVariables } from '../../types/AddFuelTransactionVariables'
import { CommentControl, ExternalControl, ExternalStationControl, FuelAmountControl, HeaderControl, OdoMeterControlControl } from './components'
import AutoCompleteControl from './components/AutoCompleteControl'
import DropDownSelectControl from './components/DropDownSelectControl'
import { updateCorporate, updateDriver, updateFuelType, updateisExternal, updateStaff, updateStation, updateStationProvider, updateVehicle } from './useEffects'
import { addFuelTransaction, getWatchers } from './utils'
import prepareDropDownData from './utils/prepareDropDownData'

const AddFuelTransaction: React.FC = () => {
  const toast = useToast()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    watch,
    control,
  } = useForm({
    mode: 'onChange',
  })
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()

  const [stateManager, setStateManager] = useState<StateManagementVariables>({
    isInputLoading: false,
    isCorporateSelected: false,
    corporateVehicles: [],
    corporateDrivers: [],
    stationProdivers: [],
    isExternalSelected: false,
    isVehicleSelected: false,
    isStationProviderSelected: false,
    isStationSelected: false,
    isStationStaffSelected: false,
    isFuelTypeSelected: false,
    isDriverSelected: false,
    stations: [],
    stationStaff: [],
  })

  const { isLoading, mutate, error } = useMutation((vars: AddFuelTransactionVariables) => fetch('POST', '/fuel-transactions', { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push('/fuel-transactions')
    },
    onError: (error: any) => {
      if (error?.data?.message === 'this amount exceed the vehicle capacity') {
        error.data.message = 'كمية الوقود يجب ان تكون اقل من الحد الاقصى لوقود السيارة'
      } else {
        error.data.message = error?.data?.message
      }
      toast({
        containerStyle: {
          direction: 'rtl',
        },
        title: dictionary().ERROR,
        description: error?.data?.message,
        status: 'error',
        duration: 3000,
        position: 'top',
      })
    },
  })

  const { corporateId, driver_id, fuelTypeId, isExternal, stationId, stationProviderId, stationStaffId, vehicleId, comment } = getWatchers(watch)

  useEffect(() => {
    updateCorporate({ corporateId, setStateManager, setValue })
  }, [corporateId.current])

  useEffect(() => {
    updateVehicle({ vehicleId, stateManager, setStateManager, setValue })
  }, [vehicleId.current])

  useEffect(() => {
    updateisExternal({ isExternal, stateManager, setStateManager, setValue })
  }, [isExternal.current])

  useEffect(() => {
    updateStationProvider({ stationProviderId, stateManager, setStateManager, setValue })
  }, [stationProviderId.current])

  useEffect(() => {
    updateStation({ stationId, stateManager, setStateManager, setValue })
  }, [stationId.current])

  useEffect(() => {
    updateStaff({ stationStaffId, stateManager, setStateManager, setValue })
  }, [stationStaffId.current])

  useEffect(() => {
    updateFuelType({ fuelTypeId, stateManager, setStateManager, setValue })
  }, [fuelTypeId.current])

  useEffect(() => {
    updateDriver({ driver_id, stateManager, setStateManager, setValue })
  }, [driver_id.current])

  return (
    <Container>
      <HeaderControl
        corporateId={corporateId}
        getValues={getValues}
        isLoading={isLoading}
        mutate={mutate}
        stateManager={stateManager}
        stationId={stationId}
        stationProviderId={stationProviderId}
        trigger={trigger}
      />
      <Card p='8' style={{ minHeight: '680px' }}>
        <form onSubmit={async () => (await trigger()) && handleSubmit(addFuelTransaction as any)}>
          <Flex className='margin-100' justify='center' align='center' direction='column'>
            <AutoCompleteControl
              control={control}
              errors={errors}
              identifier='corporate_id'
              label={dictionary().CORPORATE}
              route='/corporates'
              displayFormatter={(option: any) => option?.name}
            />
            {stateManager.isCorporateSelected && !stateManager.isVehicleSelected && stateManager.isInputLoading && <Spinner />}
            {stateManager.isCorporateSelected && stateManager.corporateVehicles.length > 0 && (
              <DropDownSelectControl
                errors={errors}
                register={register}
                identifier='vehicle_id'
                label={dictionary().NUMBER_PLATE}
                data={prepareDropDownData({ data: stateManager.corporateVehicles, first_key: 'number_plate' })}
                first_key='number_plate'
                setValue={setValue}
              />
            )}
            {stateManager.isCorporateSelected && !stateManager.isInputLoading && stateManager.corporateVehicles.length === 0 && (
              <Text fontSize='md' color='red.500'>
                {dictionary().NO_VEHICLES_SELECT_ANTHER_CORPORATE}
              </Text>
            )}
            {stateManager.isVehicleSelected && !stateManager.isExternalSelected && stateManager.isInputLoading && <Spinner />}
            {stateManager.isVehicleSelected && stateManager.isCorporateSelected && <ExternalControl control={control} errors={errors} />}
            {stateManager.isCorporateSelected && isExternal.current === 'true' && stateManager.corporateDrivers.length === 0 && (
              <Text fontSize='md' color='red.500'>
                {dictionary().NO_DRIVERS_SELECT_ANTHER_CORPORATE}
              </Text>
            )}
            {stateManager.isExternalSelected && isExternal.current === 'true' && stateManager.corporateDrivers.length > 0 && (
              <DropDownSelectControl
                errors={errors}
                register={register}
                identifier='driver_id'
                label={dictionary().CHOOSE_DRIVER}
                data={prepareDropDownData({ data: stateManager.corporateDrivers, first_key: 'username' })}
                first_key='username'
                setValue={setValue}
              />
            )}
            {stateManager.isDriverSelected && (
              <>
                <ExternalStationControl errors={errors} register={register} />
                <OdoMeterControlControl errors={errors} register={register} />
              </>
            )}
            {isExternal.current === 'false' && !stateManager.isStationProviderSelected && stateManager.isInputLoading && <Spinner />}
            {stateManager.isExternalSelected && isExternal.current === 'false' && (
              <AutoCompleteControl
                control={control}
                errors={errors}
                identifier='station_provider_id'
                label={dictionary().CHOOSE_STATION_PROVIDER}
                route='/station-providers'
                displayFormatter={(option: any) => option?.name?.ar}
              />
            )}
            {stateManager.isStationProviderSelected && !stateManager.isStationSelected && stateManager.isInputLoading && <Spinner />}
            {isExternal.current === 'false' && stateManager.isStationProviderSelected && stateManager.stations.length > 0 && (
              <AutoCompleteControl
                control={control}
                errors={errors}
                identifier='station_id'
                label={dictionary().CHOOSE_STATION}
                route={`/stations?stationProviderId=${stationProviderId.current}`}
                displayFormatter={(option: any) => option?.name?.ar}
              />
            )}
            {stateManager.isStationProviderSelected && stateManager.stations.length === 0 && !stateManager.isInputLoading && (
              <Text fontSize='md' color='red.500'>
                {dictionary().NO_STATIONS_SELECT_ANTHER_STATION_PROVIDER}
              </Text>
            )}
            {stateManager.isStationSelected && !stateManager.isStationStaffSelected && stateManager.isInputLoading && <Spinner />}
            {isExternal.current === 'false' && stateManager.isStationSelected && (
              <DropDownSelectControl
                errors={errors}
                register={register}
                identifier='station_staff_id'
                label={dictionary().STAFF_ROLE}
                data={prepareDropDownData({ data: stateManager.stationStaff, first_key: 'name', second_key: 'ar' })}
                first_key='name'
                second_key='ar'
                setValue={setValue}
              />
            )}

            {(stateManager.isStationStaffSelected || (isExternal.current === 'true' && stateManager.isDriverSelected)) && (
              <AutoCompleteControl
                control={control}
                errors={errors}
                identifier='fuel_type_id'
                label={dictionary().FUEL_TYPES}
                route={'/fuel-types'}
                displayFormatter={(option: any) => option?.name?.ar}
              />
            )}
            {stateManager.isFuelTypeSelected && (
              <>
                <FuelAmountControl error={error} errors={errors} register={register} />
                <CommentControl errors={errors} register={register} />
              </>
            )}
          </Flex>
        </form>
      </Card>
    </Container>
  )
}
export default AddFuelTransaction
