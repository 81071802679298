import { AccordionButton, extendTheme } from '@chakra-ui/react'

const focusStyles = {
  _focus: {
    boxShadow: 'none',
  },
}

export const theme = extendTheme({
  shadows: {
    outline: 'none',
  },
  components: {
    Button: {
      baseStyle: focusStyles,
    },
    Input: {
      baseStyle: focusStyles,
    },
    Textarea: {
      baseStyle: focusStyles,
    },
    Select: {
      baseStyle: focusStyles,
    },
    Checkbox: {
      baseStyle: focusStyles,
    },
    Radio: {
      baseStyle: focusStyles,
    },
    Switch: {
      baseStyle: focusStyles,
    },
    Link: {
      baseStyle: focusStyles,
    },
    IconButton: {
      baseStyle: focusStyles,
    },
    NumberInput: {
      baseStyle: focusStyles,
    },
    PinInput: {
      baseStyle: focusStyles,
    },
    AccordionButton: {
      baseStyle: focusStyles,
    },
  },
  fonts: {
    heading: 'Ubuntu',
    body: 'Ubuntu',
  },
  colors: {
    error: {
      // same values as default red in theme
      50: '#FFF5F5',
      100: '#FED7D7',
      200: '#FEB2B2',
      300: '#FC8181',
      400: '#F56565',
      500: '#E53E3E',
      600: '#C53030',
      700: '#9B2C2C',
      800: '#822727',
      900: '#63171B',
    },
    yellow: {
      50: '#fffcdd',
      100: '#fff6b0',
      200: '#fff3b0',
      300: '#fff080',
      400: '#FFF07D',
      500: '#ffea4f',
      600: '#ffe522',
      700: '#e6cb0f',
      800: '#b39e05',
      900: '#807100',
    },
    blue: {
      50: '#9797e8',
      100: '#6d6ddd',
      200: '#4644d3',
      300: '#2c2bb9',
      400: '#222191',
      500: '#7165E3',
      600: '#0d0e40',
      700: '#0d0e40',
      800: '#0d0e40',
      900: '#0d0e40',
    },
  },
})
