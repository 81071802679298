import axios, { Method } from 'axios'
import { store } from '../store'

const BASE_URL = import.meta.env.VITE_BASE_URL

type apiVariables = {
  query?: { [key: string]: number | string | boolean | Array<number> | Array<string> | Array<boolean> }
  data?: { [key: string]: any }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const fetch = async (httpMethod: Method, route: string, variables?: apiVariables, baseUrl: string = BASE_URL) => {
  if (route.includes('undefined')) {
    return
  }
  const user = window.localStorage.getItem('activeRole')
  const userData = user && JSON.parse(user)
  const mustSearch = userData?.mustSearch || false

  if (userData?.userType === 'admin' && userData?.role === 'SALES_OPS') {
    localStorage.removeItem('activeRole')
    localStorage.removeItem('main-store')
    window.location.replace('/admin/login')
  }

  const { token } = store.getState() as any

  axios.defaults.baseURL = baseUrl
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  return axios({
    method: httpMethod,
    url: route,
    data: variables?.data,
    params: mustSearch && !variables?.query?.searchTerm ? { ...variables?.query, searchTerm: 'null' } : variables?.query,
  })
    .then((response: any) => {
      return response.data
    })
    .catch((error: { response: any }) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem('activeRole')
        localStorage.removeItem('main-store')
        localStorage.clear()
        window.location.replace('/admin/login')
      }
      throw error.response
    })
}

export default fetch
