import fetch from '../../../helpers/fetch'
import { StateManagementVariables } from '../../../types/AddFuelTransactionVariables'

type updateStationParams = {
  stationId: any
  stateManager: StateManagementVariables
  setStateManager: any
  setValue: any
}
const updateStation = ({ stationId, stateManager, setStateManager, setValue }: updateStationParams): void => {
  if (stationId?.current?.length) {
    // Set loading state while fetching data
    setStateManager({ ...stateManager, isInputLoading: true, isStationSelected: true })
    // Fetch station staff data
    fetch('GET', `/station-staff?stationId=${stationId.current}`).then((data) => {
      // Update state with fetched data
      setStateManager({ ...stateManager, isStationSelected: true, stationStaff: data.data, isInputLoading: false })
    })
  } else {
    // Reset state if stationId is not available
    setStateManager({ ...stateManager, isStationSelected: false })
    // Reset form values
    setValue('station_staff_id', '')
    setValue('fuel_type_id', [])
    setValue('amount', '')
  }
}

export default updateStation
