// const StationDropList: React.FC<Props> = ({ setSelectedStation, selectedStation }) => {
//   const { data } = useQuery(['StationDropList'], () => fetch('GET', `/stations`, { query: { pageSize: 200 } }), {})
//   const selectedStationProvider = data?.data.find((station: any) => station?.id === selectedStation)

//   return (
//     <FormControl id={dictionary().CHOOSE_STATION} w='100%' display='inline-block'>
//       <FormLabel>{dictionary().CHOOSE_STATION}</FormLabel>
//       <Select placeholder={dictionary().CHOOSE_STATION} dir='ltr' onChange={(event) => setSelectedStation(event?.target?.value)} textAlign='right'>
//         {data?.data?.map((item: any) => (
//           <option key={item?.id} value={item?.id}>
//             {' '}
//             {item?.name?.en}
//           </option>
//         ))}
//       </Select>
//     </FormControl>
//   )
// }

// export default StationDropList
import { useLocation } from 'react-router-dom'
import React, { useEffect, useRef } from 'react'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useFilterStore } from '../../stores/filterStore'
import dictionary from '../../dictionary'
import AutoCompleteInput from './AutoCompleteInput'

interface Props {
  setSelectedStation: (val: any) => void
}

const StationDropList: React.FC<Props> = ({ setSelectedStation }) => {
  const { pathname } = useLocation()
  const { stationId: selectedStation, setStationId, stationIds, corporateIds, advancedSearch } = useFilterStore()
  const {
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useForm({ mode: 'onChange' })

  const station = useRef({})
  station.current = watch('station', '')
  const queryParams = new URLSearchParams(window.location.search)

  const stationId = queryParams.get('stationId')

  useEffect(() => {
    if (typeof station.current !== null && station.current !== undefined && station.current !== '') {
      if (pathname === '/fuel-transactions') {
        setStationId(getValues()?.station[0])
      } else {
        setSelectedStation(getValues()?.station[0])
      }
    }
  }, [station.current])

  useEffect(() => {
    if (pathname === '/fuel-transactions') {
      selectedStation && setValue('station', [selectedStation])
    } else {
      stationId && setValue('station', [stationId])
    }
  }, [])

  return (pathname === '/fuel-transactions' && stationIds.length > 0) || corporateIds.length > 0 ? (
    <></>
  ) : (
    <FormControl id={dictionary().CHOOSE_STATION} w='100%' dir='rtl' isDisabled={corporateIds.length > 0}>
      <FormLabel>{dictionary().CHOOSE_STATION}</FormLabel>
      <Controller
        name={`station`}
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <AutoCompleteInput
            isInvalid={!!errors?.second_ref_id}
            onChange={onChange}
            value={pathname === '/fuel-transactions' ? (stationIds.length > 0 ? [] : []) : value}
            searchRoute='/stations'
            singleRoute='/stations'
            valueFormatter={(option) => option?.id}
            displayFormatter={(option) => option?.name?.ar}
          />
        )}
      />
    </FormControl>
  )
}

export default StationDropList
