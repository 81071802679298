import { Center, Container, Flex, useToast } from '@chakra-ui/react'

import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import { clone, isEmpty } from 'ramda'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import PasswordInput from '../../components/core/PasswordInput'
import { getMustSearch } from '../../constants/mustSearch'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import { useStore } from '../../store'
import Header from '../../components/core/Header'
import { useRef, useState } from 'react'

type MutationLoginArgs = any

interface FormTypes {
  current: string
  password: string
  password_confirmed: string
}

type ChangePasswordProps = {}

export const ChangePassword = ({}: ChangePasswordProps) => {
  const headerRef = useRef<HTMLDivElement>(null)
  const toast = useToast()
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<FormTypes>()
  const { setToken, setUserType } = useStore((state) => state)

  const [isSuccess, setIsSuccess] = useState(false)
  const { push } = useHistory()
  const { go } = useHistory()
  const { pathname } = useLocation()
  const route = pathname.split('/')[1]
  const { isLoading, mutate, error } = useMutation((vars: MutationLoginArgs) => fetch('post', `admin/change-admin-password`, vars), {
    onSuccess: (data): void => {
      setIsSuccess(true)
      setTimeout(() => {
        setToken(null)
        localStorage.clear()
        push('/admin/login')
      }, 1200)
    },

    onError: (error: any) => {
      toast({
        containerStyle: {
          direction: 'rtl',
        },
        title: dictionary().ERROR,
        description: error?.data?.message,
        status: 'error',
        duration: 3000,
        position: 'top',
      })
    },
  })
  const login = async (formData: FormTypes) => {
    mutate({
      data: {
        current: formData.current,
        new: formData.password,
        confirmed: formData.password_confirmed,
      },
    })
  }

  return (
    <Container maxW='100%' width='100%' h='100vh' padding={8}>
      <Flex ref={headerRef} flexDir='column'>
        <Header title={'تغيير كلمة المرور'} />
      </Flex>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '95%' }}>
        <Center>
          <form onSubmit={handleSubmit(login)}>
            <Box
              className='margin-100'
              sx={{ minWidth: '300px', maxWidth: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
            >
              <FormControl id='current' isInvalid={!!errors.current}>
                <FormLabel>كلمة المرور القديمة</FormLabel>
                <PasswordInput
                  inputProps={{
                    ...register('current', {
                      required: dictionary().REQUIRED,
                    }),
                    isInvalid: !!errors.current,
                    autoComplete: 'current',
                  }}
                />
                <FormErrorMessage>{errors.current?.message}</FormErrorMessage>
              </FormControl>

              <FormControl id='password' isInvalid={!!errors.password}>
                <FormLabel>كلمة المرور الجديدة</FormLabel>
                <PasswordInput
                  inputProps={{
                    ...register('password', {
                      required: dictionary().REQUIRED,
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z])(?!.*\s).{8,}$/,
                        message: dictionary().WEAK_PASSWORD,
                      },
                    }),

                    isInvalid: !!errors.password,
                    autoComplete: 'password',
                  }}
                />
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id='password_confirmed' isInvalid={!!errors.password_confirmed}>
                <FormLabel>تأكيد كلمة المرور الجديدة</FormLabel>
                <PasswordInput
                  inputProps={{
                    ...register('password_confirmed', {
                      required: dictionary().REQUIRED,
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z])(?!.*\s).{8,}$/,
                        message: dictionary().WEAK_PASSWORD,
                      },
                      validate: (value) => {
                        if (getValues('password') !== value) {
                          return 'كلمات المرور غير متطابقة'
                        }
                      },
                    }),
                    isInvalid: !!errors.password_confirmed,
                    autoComplete: 'password_confirmed',
                  }}
                />
                <FormErrorMessage>{errors?.password_confirmed?.message}</FormErrorMessage>
              </FormControl>
              <p style={{ color: 'red' }}>{(error as any)?.data?.message && dictionary().INCORRECT_USERNAME_OR_PASSWORD}</p>
              <Button
                isDisabled={isLoading || isSuccess}
                data-test='sign-in-button'
                isLoading={isLoading}
                loadingText={'تغيير كلمة المرور'}
                colorScheme='blue'
                variant='solid'
                width='100%'
                type='submit'
                mb={10}
              >
                تغيير كلمة المرور
              </Button>
              {isSuccess && <p style={{ color: 'green' }}>{dictionary().PASSWORD_CHANGED_SUCCESSFULLY}</p>}
            </Box>
          </form>
        </Center>
      </Box>
    </Container>
  )
}
