import dictionary from '../../dictionary'

const headers = [
  {
    id: 'corporate_name',
    key: 'corporate_name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'station_name',
    key: 'station_name',
    name: dictionary().STATION,
    type: 'text',
  },
  {
    id: 'drivername',
    key: 'drivername',
    name: dictionary().DRIVER,
    type: 'text',
  },
  {
    id: 'plate_number',
    key: 'plate_number',
    name: dictionary().NUMBER_PLATE,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().AMOUNT,
    type: 'cash',
  },
  {
    id: 'date_and_time',
    key: 'date_and_time',
    name: dictionary().DATE,
    type: 'date_UTC',
  },
  {
    id: 'date_and_time',
    key: 'date_and_time',
    name: dictionary().TIME,
    type: 'time_UTC',
  },
]

export const originHeaders: any[] = [
  {
    id: 'id',
    key: 'original_transaction_id',
    name: dictionary().TRANSACTION_ID,
    type: 'text',
  },
  ...headers,
  {
    id: 'duplicatedActions',
    key: 'duplicatedActions',
    name: '',
    type: 'modal',
  },
]
export const duplicateHeaders: any[] = [
  {
    id: 'id',
    key: 'transaction_id',
    name: dictionary().TRANSACTION_ID,
    type: 'text',
  },
  ...headers,
  {
    id: 'duplicateStatus',
    key: 'is_duplicate_status',
    name: dictionary().STATUS,
    type: 'text',
  },
  {
    id: 'isRefunded',
    key: 'isRefunded',
    name: dictionary().RECOVERY_STATUS,
    type: 'boolean',
  },
]
