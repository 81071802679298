import React, { useEffect, useRef } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, Select, Checkbox, InputGroup, InputLeftElement, Spinner } from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { parse } from 'query-string'
import { useHistory } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import formatAsCurrency from '../../helpers/formatAsCurrency'
import Reqiured from '../../components/core/reqiured'
import PinCodeInput from '../../components/core/PinCodeInput'

type AddTRIPMutationVariables = {
  vehicle_id: number
  driver_id: string
  allow_fuel: boolean
  allow_toll: boolean
  product_id: number
  allow_store: boolean
  stationIds: string
  capacity: number
  weight: number
  starting_point: string
  destination: string
  pin_code: number
}

const AddTrip: React.FC = () => {
  const [isTollSelected, setIsTollSelected] = React.useState(true)
  const [isDriverSelected, setIsDriverSelected] = React.useState(true)

  React.useEffect(() => {
    setValue('stationIds', [1])
  }, [])

  const {
    watch,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    control,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const watchFees = watch(['product_id', 'vehicle_id', 'weight'])

  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const query = parse(location.search, { parseBooleans: true })
  const vehicles = useQuery([query, 'vechile'], () => fetch('GET', '/vehicles'))
  const productTypes = useQuery([query, 'product'], () => fetch('GET', '/product-types'))
  const drivers = useQuery([query, 'driver'], () => fetch('GET', '/driver'))
  const { isLoading, mutate } = useMutation((vars: AddTRIPMutationVariables) => fetch('POST', '/trips', { data: vars }), {
    onSuccess: (): void => {
      push('/trips')

      onSuccess()
    },
    onError,
  })
  const fees = useMutation((vars: any) => fetch('POST', '/toll-station-prices/calculate-toll', { data: vars }))

  useEffect(() => {
    if (!watchFees?.includes(undefined)) {
      const selectedVehicle = vehicles?.data?.data?.filter((vehicle: any) => vehicle?.id === getValues()?.vehicle_id)
      if (!watchFees?.includes(NaN)) {
        fees.mutate({
          productTypeId: getValues()?.product_id as number,
          vehicleTypeId: selectedVehicle[0]?.vehicleTypeId as number,
          productWeight: getValues()?.weight as number,
        })
      }
    }
  }, [getValues()?.product_id, getValues()?.vehicle_id, getValues()?.weight])

  const addTrip = async (formData: AddTRIPMutationVariables) => {
    mutate(formData)
  }

  return (
    <Container minHeight='100vh'>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().ADD_NEW_TRIP}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && addTrip(getValues() as AddTRIPMutationVariables)} />}
        />
      </Flex>

      <Card p='8'>
        <form onSubmit={handleSubmit(addTrip as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='10%' align='center' justify='center'>
            {isDriverSelected && <PinCodeInput register={register} setValue={setValue} getValues={getValues} pinLength={4} />}
            <FormControl
              id={dictionary().CAR_ID}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.vehicle_id}
              display='inline-block'
            >
              <FormLabel>
                {dictionary().CAR_ID} <Reqiured />
              </FormLabel>
              <Select
                dir='ltr'
                textAlign='right'
                {...register('vehicle_id', {
                  required: dictionary().REQUIRED,
                  valueAsNumber: true,
                })}
                placeholder={dictionary().CAR_ID}
              >
                {vehicles?.data?.data?.map((item: any) => (
                  <option key={item?.id} value={item?.id}>
                    {' '}
                    {item?.number_plate}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors?.vehicle_id?.message} </FormErrorMessage>
            </FormControl>
            <FormControl hidden={true} id={dictionary().DRIVER} w='70%' isInvalid={!!errors.driver_id}>
              <FormLabel>{dictionary().DRIVER}</FormLabel>
              <Select
                disabled={true}
                dir='ltr'
                textAlign='right'
                {...register('driver_id', {
                  valueAsNumber: true,
                })}
                onChange={(e) => (e.target.value === '' ? setIsDriverSelected(true) : setIsDriverSelected(false))}
                placeholder={dictionary().DRIVER}
              >
                {drivers?.data?.data?.map((item: any) => (
                  <option key={item?.userId} value={item?.userId}>
                    {' '}
                    {item?.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.driver_id?.message}</FormErrorMessage>
            </FormControl>
            <FormControl hidden={true} id={dictionary().ALLOW_FUEL} w='70%'>
              <FormLabel>{dictionary().ALLOW_FUEL}</FormLabel>
              <Checkbox disabled={true} {...register('allow_fuel', {})}>
                {dictionary().ALLOW_FUEL}
              </Checkbox>
            </FormControl>
            <FormControl hidden={true} id={dictionary().TOLL_AMOUNT} w='70%'>
              <FormLabel>{dictionary().TOLL_AMOUNT}</FormLabel>
              <Checkbox {...register('allow_toll')} defaultChecked onChange={(e) => setIsTollSelected(e.target.checked)}>
                {dictionary().TOLL_AMOUNT}
              </Checkbox>
            </FormControl>
            <FormControl hidden={true} id={dictionary().STORE} w='70%' isInvalid={!!errors.allow_store}>
              <FormLabel>{dictionary().STORE}</FormLabel>
              <Checkbox disabled={true} {...register('allow_store', {})}>
                {dictionary().STORE}
              </Checkbox>
            </FormControl>
            <FormControl
              id={dictionary().PRODUCT}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.product_id}
            >
              <FormLabel>
                {dictionary().PRODUCT} <Reqiured />
              </FormLabel>
              <Select
                dir='ltr'
                textAlign='right'
                {...register('product_id', {
                  required: isTollSelected ? dictionary().REQUIRED : '',
                  valueAsNumber: true,
                })}
                placeholder={dictionary().PRODUCT}
              >
                {productTypes?.data?.data?.map((item: any) => (
                  <option key={item?.id} value={item?.id}>
                    {' '}
                    {item?.name?.ar}
                  </option>
                ))}
              </Select>

              <FormErrorMessage>{errors?.product_id?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().STATIONS}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.stationIds}
            >
              <FormLabel>{dictionary().STATIONS}</FormLabel>
              <Controller
                name={`stationIds`}
                control={control}
                render={({ field: { onChange } }) => (
                  <AutoCompleteInput
                    onChange={onChange}
                    searchRoute='/stations'
                    singleRoute='/stations'
                    multiple={true}
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.name?.ar}
                    isInvalid={!!errors?.stationIds}
                  />
                )}
              />
              <FormErrorMessage>{errors.stationIds?.message}</FormErrorMessage>
            </FormControl>
            <FormControl hidden={true} id={dictionary().CAPACITY} w='70%' isInvalid={!!errors.capacity}>
              <FormLabel>{dictionary().CAPACITY}</FormLabel>
              <Select
                dir='ltr'
                textAlign='right'
                {...register('capacity', {
                  valueAsNumber: true,
                })}
                placeholder={dictionary().CAPACITY}
              >
                <option key='0' value={0}>
                  {' '}
                  0
                </option>
                <option key='0.5' value={0.5}>
                  {' '}
                  0.5
                </option>
                <option key='1' value={1}>
                  {' '}
                  1
                </option>
              </Select>
              <FormErrorMessage>{errors.capacity?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().WEIGHT} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.weight}>
              <FormLabel>
                {dictionary().WEIGHT} <Reqiured />
              </FormLabel>
              <InputGroup>
                <InputLeftElement height='100%' pointerEvents='none'>
                  {dictionary().TON}
                </InputLeftElement>
                <Input
                  type='number'
                  {...register('weight', {
                    required: isTollSelected ? dictionary().REQUIRED : '',
                    valueAsNumber: true,
                    validate: (value) => (value <= 0 ? dictionary().THIS_INPUT_CANT_EQUAL_TO_ZERO : undefined),
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors.weight?.message}</FormErrorMessage>
            </FormControl>
            <FormControl hidden={true} id={dictionary().STARTING_POINT_NAME} w='70%' isInvalid={!!errors.starting_point}>
              <FormLabel>{dictionary().STARTING_POINT_NAME}</FormLabel>
              <Input type='text' {...register('starting_point')} />
              <FormErrorMessage>{errors.starting_point?.message}</FormErrorMessage>
            </FormControl>
            <FormControl hidden={true} id={dictionary().DESTINATION} w='70%' isInvalid={!!errors.destination}>
              <FormLabel>{dictionary().DESTINATION}</FormLabel>
              <Input type='text' {...register('destination')} />
              <FormErrorMessage>{errors.destination?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
        {fees?.isLoading && (
          <>
            <br /> <hr /> <br />
            <Spinner />
          </>
        )}
        {fees?.data ? (
          <>
            <br /> <hr />
            <br />
            <h3 style={{ fontWeight: 'bolder', fontSize: '18px' }}>
              {' '}
              - {dictionary().TOLL_AMOUNT} : {formatAsCurrency(fees?.data?.tollFees)}
            </h3>
            <h3 style={{ fontWeight: 'bolder', fontSize: '18px' }}>
              - {dictionary().WEIGHT_FEES} : {formatAsCurrency(fees?.data?.weightFees)}
            </h3>
          </>
        ) : (
          <div></div>
        )}{' '}
        {fees?.error ? (
          <>
            <br /> <hr /> <br />
            <h1 style={{ color: 'red', fontWeight: 'bolder', fontSize: '18px' }}>{dictionary().FINANCIAL_CACLULATIONS_ERR}</h1>
          </>
        ) : (
          <div></div>
        )}
      </Card>
    </Container>
  )
}

export default AddTrip
