import React, { useRef, useEffect } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'

type AddFuelVariables = {
  name_en: string
  name_ar: string
  price: number
}

const EditFuel: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
  } = useForm({
    mode: 'onChange',
  })

  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const query = useQuery(['fuel - type', id], () => fetch('GET', `/fuel-types/${id}`), {})
  const { isLoading, mutate } = useMutation((vars: any) => fetch('PATCH', `/fuel-types/${id}`, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push('/fuel-types')
    },
    onError,
  })
  useEffect(() => {
    setValue('price', query?.data?.price)
    setValue('en', query?.data?.name?.en)
    setValue('ar', query?.data?.name?.ar)
  }, [query?.data])
  const addFuel = async () => {
    mutate({
      name: {
        en: getValues()?.en,
        ar: getValues()?.ar,
      },
      price: getValues()?.price,
    })
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header title={dictionary().EDIT_FUEL} action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && addFuel()} />} />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(addFuel as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl id={dictionary().FUEL_NAME_AR} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.ar}>
              <FormLabel>
                {dictionary().FUEL_NAME_AR} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('ar', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.en?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().FUEL_NAME_EN} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.en}>
              <FormLabel>
                {dictionary().FUEL_NAME_EN} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('en', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.en?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().PRICE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.price}>
              <FormLabel>
                {dictionary().PRICE} <Reqiured />
              </FormLabel>
              <InputGroup>
                <InputLeftElement height='100%' pointerEvents='none'>
                  {dictionary().PRICING_CURRENCY}
                </InputLeftElement>
                <Input
                  type='number'
                  {...register('price', {
                    required: dictionary().REQUIRED,
                    valueAsNumber: true,
                    min: {
                      value: 0.1,
                      message: dictionary().MORE_THAN_ZERO,
                    },
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.price?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default EditFuel
