import { useRef } from 'react'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import {
  Modal,
  Spinner,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormLabel,
  Button,
  FormControl,
  Input,
  useDisclosure,
  Flex,
  InputGroup,
  FormErrorMessage,
  InputLeftElement,
} from '@chakra-ui/react'
import useAlert from '../../helpers/useAlert'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'
import Reqiured from '../../components/core/reqiured'
import AutoCompleteInput from './AutoCompleteInput'

interface Props {
  data?: any
  handleIsBalancesChange?: () => void
}

const TranseferBetweenEntitiesForCorporateModal: React.FC<Props> = ({ data, handleIsBalancesChange }) => {
  const { id } = data
  const { onSuccess, onError } = useAlert()

  const corporateData = window.localStorage.getItem('activeRole')
  const { pathname } = useLocation()

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/corporates/transfer-balance-between-corporate-entities', { data: vars }), {
    onSuccess: (): void => {
      handleIsBalancesChange && handleIsBalancesChange()
      onSuccess()
      onClose()
    },
    onError,
  })

  const {
    setValue,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    control,
    trigger,
  } = useForm({ mode: 'onChange' })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  const AddTransfer = async (formData: any) => {
    formData.account_type = 'FUEL'
    formData.transfer_direction = 'CREDIT'
    formData.corporateId = corporateData && JSON.parse(corporateData)?.corporateStaff?.corporate?.id
    formData.first_ref_id = id

    if (pathname.includes('drivers')) {
      formData.first_ref_type = 'driver'
      formData.second_ref_type = 'driver'
    } else if (pathname.includes('vehicles')) {
      formData.second_ref_type = 'vehicle'
      formData.first_ref_type = 'vehicle'
    }
    formData.second_ref_id = formData?.second_ref_id[0]
    mutate(formData)
  }

  const handleOnOpen = () => {
    onOpen()
    setValue('amount', null)
    setValue('second_ref_id', null)
    error && (error.data = {})
  }

  return (
    <>
      <Button mb={1} onClick={handleOnOpen}>
        {dictionary().TRANSFER}
      </Button>

      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().TRANSFER} </ModalHeader>
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit(AddTransfer as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                <FormControl
                  dir='rtl'
                  id={dictionary().REQUIRED}
                  w={['100%', '70%', '70%', '70%']}
                  display='inline-block'
                  isInvalid={
                    !!errors.amount ||
                    error?.data?.message === 'balance is less than the transactions amount' ||
                    error?.data?.message === 'this ref not found or the balance not enough'
                  }
                >
                  <FormLabel style={{ direction: 'rtl' }}>
                    <>
                      {dictionary().AMOUNT} <Reqiured />
                    </>
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement height='100%' pointerEvents='none'>
                      {dictionary().PRICING_CURRENCY}
                    </InputLeftElement>
                    <Input
                      dir='ltr'
                      textAlign='right'
                      type='number'
                      {...register('amount', {
                        required: dictionary().REQUIRED,
                        valueAsNumber: true,
                        min: {
                          value: 0,
                          message: dictionary().NO_NEGATIVE_NUMBER,
                        },
                      })}
                    />
                  </InputGroup>

                  <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
                  {
                    <FormErrorMessage>
                      {error?.data?.message === 'balance is less than the transactions amount' && dictionary().BALANCE_IS_LESS_THAN_TRANSACTION_AMOUNT}
                      {error?.data?.message === 'this ref not found or the balance not enough' && dictionary().ACC_TYPE_NOT_FOUND}
                    </FormErrorMessage>
                  }
                </FormControl>
                <FormControl
                  dir='rtl'
                  id={pathname.includes('drivers') ? dictionary().DRIVER : dictionary().NUMBER_PLATE}
                  w={['100%', '70%', '70%', '70%']}
                  isInvalid={!!errors.second_ref_id}
                >
                  <FormLabel style={{ direction: 'rtl' }}>
                    <>
                      {pathname.includes('drivers') ? dictionary().DRIVER : dictionary().NUMBER_PLATE} <Reqiured />
                    </>
                  </FormLabel>

                  <Controller
                    name={`second_ref_id`}
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        isInvalid={!!errors?.second_ref_id}
                        onChange={onChange}
                        value={value}
                        searchRoute={pathname.includes('drivers') ? '/drivers' : '/vehicles'}
                        singleRoute={pathname.includes('drivers') ? '/drivers' : '/vehicles'}
                        valueFormatter={(option) => option?.id}
                        displayFormatter={pathname.includes('drivers') ? (option) => option?.name : (option) => option?.number_plate}
                        id={id}
                      />
                    )}
                  />

                  <FormErrorMessage>{errors.second_ref_id?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button onClick={async () => (await trigger()) && AddTransfer(getValues() as any)} colorScheme='blue' mr={3}>
              {isLoading ? <Spinner /> : dictionary().SAVE}
            </Button>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default TranseferBetweenEntitiesForCorporateModal
