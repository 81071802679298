import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'corporate_id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'name',
    key: 'corporate_name',
    name: dictionary().CORPORATES_NAME,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().AMOUNT,
    type: 'cash',
  },
  {
    id: 'volume',
    key: 'volume',
    name: dictionary().LITRES_AMOUNT,
    type: 'text',
  },
  {
    id: 'transactions_count',
    key: 'transactions',
    name: dictionary().NUMBER_OF_TRANSACTIONS,
    type: 'text',
  },
]

const CorporateAccounts: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().CORPORATES_ACCOUNTS}
      tags={[{ name: dictionary().ALL, filter: [{ name: '', value: '' }] }]}
      hasSearch={true}
      hasSearchDate={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['corporates']}
      countArrayPath={['count']}
      restRoute={'/corporates/breakdown-by-amount'}
      cashingKey={CASHING_KEY.GET_CORPORATES_ACCOUNTS}
    />
  )
}

export default CorporateAccounts
