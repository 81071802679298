import { Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Td, Text, useDisclosure } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { duplicateHeaders, originHeaders } from '../../../constants/headers/duplicateTransactionHeaders'
import dictionary from '../../../dictionary'
import fetch from '../../../helpers/fetch'
import Table from '../../core/Table'

interface Props {
  data: any
}

const DuplicateTransactionsModel: React.FC<Props> = ({ data }) => {
  const [originTransactionData, setoriginTransactionData] = useState(data)
  const [isUpdated, setIsUpdated] = useState(false)
  const {
    isLoading,
    mutate,
    data: duplicateTranactions,
  } = useMutation(() => fetch('GET', `/fuel-transactions/duplicate/duplicates/${data?.original_transaction_id}`), {
    onSuccess: (): void => {},
  })
  const { mutate: getOriginTransaction } = useMutation(
    () => fetch('GET', `/fuel-transactions/duplicate/original-transaction/${data?.original_transaction_id}`),
    {
      onSuccess: (data): void => {
        setoriginTransactionData({ ...data, original_transaction_id: data?.transaction_id })
      },
    },
  )

  const { onClose, isOpen, onOpen } = useDisclosure()

  useEffect(() => {
    setoriginTransactionData(data)
    isOpen && mutate()
  }, [isOpen])

  useEffect(() => {
    isOpen && getOriginTransaction()
  }, [isUpdated])

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  return (
    <>
      <Td onClick={onOpen} cursor='pointer' style={{ fontWeight: 'bold' }}>
        {data?.original_transaction_id}
      </Td>
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} size='6xl'>
        <ModalOverlay />
        <ModalContent dir='rtl'>
          <ModalHeader style={{ direction: 'rtl', borderBottom: '2px solid #E2E8F0', marginBottom: '15px' }}>
            {dictionary().DUPLICATE_TRANSACTION_DETAIL}{' '}
          </ModalHeader>
          <ModalBody pb={6}>
            <p style={{ marginBottom: '10px', fontWeight: 'bold' }}>{dictionary().BASE_TRANSACTIONS_DATA}</p>
            <Table
              raised
              enableIdRedirect={false}
              headers={originHeaders}
              data={[originTransactionData]}
              handleIsBalancesChange={() => setIsUpdated(!isUpdated)}
            />
            <p style={{ marginBottom: '10px', marginTop: '25px', fontWeight: 'bold' }}>{dictionary().DUPLICATE_TRANSACTIONS_DATA}</p>
            {isLoading ? (
              <Flex w='100%' h='100%' align='center' justify='center'>
                <Text fontSize='2xl'>
                  {dictionary().SEARCH_LOADING} {dictionary().IN} {dictionary().DUPLICATE_TRANSACTIONS}
                </Text>
              </Flex>
            ) : (
              <Table raised enableIdRedirect={false} headers={duplicateHeaders} data={duplicateTranactions} height={'300px'} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DuplicateTransactionsModel
