import fetch from '../../../helpers/fetch'
import { StateManagementVariables } from '../../../types/AddFuelTransactionVariables'

type updateStationProviderParams = {
  stationProviderId: any
  stateManager: StateManagementVariables
  setStateManager: any
  setValue: any
}
const updateStationProvider = ({ stationProviderId, stateManager, setStateManager, setValue }: updateStationProviderParams): void => {
  // Check if stationProviderId is not null or undefined
  if (stationProviderId?.current?.length) {
    // Set loading state and mark stationProvider as selected
    setStateManager({ ...stateManager, isInputLoading: true, isStationProviderSelected: true })

    // Fetch stations data based on stationProviderId
    fetch('GET', `/stations?stationProviderId=${stationProviderId.current}`).then((data) => {
      // Update state with fetched data and mark loading state as false
      setStateManager({ ...stateManager, isStationProviderSelected: true, stations: data.data, isInputLoading: false })
    })
  } else {
    // If stationProviderId is null or undefined, reset state and value
    setStateManager({ ...stateManager, isStationProviderSelected: false, stations: [] })
    setValue('station_id', [])
  }
}

export default updateStationProvider
