import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import Required from '../../../components/core/reqiured'
import dictionary from '../../../dictionary'

type ExternalStationControlProps = {
  errors: any
  register: any
}
const ExternalStationControl = ({ errors, register }: ExternalStationControlProps) => {
  return (
    <FormControl id={dictionary().STATION_NAME} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.external_station_name}>
      <FormLabel>
        {dictionary().STATION_NAME} <Required />
      </FormLabel>
      <Input
        type='string'
        {...register('external_station_name', {
          required: dictionary().REQUIRED,
          validate: (value: any) => value.trim() != '' || dictionary().REQUIRED,
        })}
      />
      <FormErrorMessage>{errors.external_station_name?.message}</FormErrorMessage>
    </FormControl>
  )
}

export default ExternalStationControl
