import { AddFuelTransactionVariables } from '../../../types/AddFuelTransactionVariables'

/**
 * Adds a fuel transaction with the given form data.
 *
 * @param {AddFuelTransactionVariables} formData - The form data for the fuel transaction.
 * @param {any} corporateId - The corporate ID.
 * @param {any} stationProviderId - The station provider ID.
 * @param {any} stationId - The station ID.
 * @param {any} getValues - A function to get the form values.
 * @param {any} stateManager - The state manager.
 * @param {any} mutate - A function to mutate the form data.
 */
const addFuelTransaction = async (
  formData: AddFuelTransactionVariables,
  corporateId: any,
  stationProviderId: any,
  stationId: any,
  getValues: any,
  stateManager: any,
  mutate: any,
) => {
  // Set the corporate ID, station provider ID, and station ID in the form data
  formData.corporate_id = corporateId.current[0]
  formData.station_provider_id = stationProviderId.current[0]
  formData.station_id = stationId.current[0]

  // Check if the transaction is external
  if (getValues().is_external === 'true') {
    // Check if there are corporate vehicles and drivers
    if (stateManager.corporateVehicles.length > 0 && stateManager.corporateDrivers.length > 0) {
      // Set the fuel type ID, mark as external, and delete unnecessary fields
      formData.fuel_type_id = formData.fuel_type_id[0]
      formData.is_external = true
      delete formData.station_id
      delete formData.station_provider_id
      delete formData.station_staff_id
    }
  } else {
    // Check if there are corporate vehicles and stations
    if (stateManager.corporateVehicles.length > 0 && stateManager.stations.length > 0) {
      // Set the fuel type ID, mark as not external, and delete unnecessary fields
      formData.fuel_type_id = formData.fuel_type_id[0]
      formData.is_external = false
      delete formData.driver_id
      delete formData.external_station_name
      delete formData.odometer_reading
    }
  }

  if (!formData.amount) {
    alert('يجب إدخال كمية الوقود')
    return
  }

  // Mutate the form data
  mutate(formData)
}

export default addFuelTransaction
