import { Redirect, Route, Switch } from 'react-router-dom'
import AddStation from '../../pages/stations/add'
import AddNewStationStaff from '../../pages/stations/addNewStaff'
import EditStation from '../../pages/stations/edit'
import Station from '../../pages/stations/one'
import Stations from '../../pages/stations/stations'
import FuelTransactions from '../../pages/transactions/fuel'
import { ChangePassword } from '../../pages/ChangePassword/ChangePassword'

export const ATTENDANT_ROUTES = (
  <Switch>
    <Route exact path='/stations' render={() => <Stations />} />
    <Route exact path='/stations/new' render={() => <AddStation />} />
    <Route exact path='/stations/:id/edit' render={() => <EditStation />} />
    <Route exact path='/stations/:id/add-new-staff' render={() => <AddNewStationStaff />} />
    <Route exact path='/stations/:id' render={() => <Station />} />
    <Route exact path='/fuel-transactions' render={() => <FuelTransactions />} />
    <Route exact path='/change-password' render={() => <ChangePassword />} />
    <Redirect exact from='/*' to='/fuel-transactions' />
  </Switch>
)
