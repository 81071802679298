import { IoIosCloseCircle } from 'react-icons/io'
import { Box } from '@chakra-ui/react'
import { FC } from 'react'

const PreviewFileStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'center',
  borderRadius: '10px',
  border: '1px dashed rgba(133, 148, 177, 0.50)',
  minWidth: '150px',
  height: '100px',
  padding: '5px',
  cursor: 'pointer',
}

type Props = {
  file: any
  deleteFile?: () => void
}

const PreviewContractFile: FC<Props> = ({ file, deleteFile }) => {
  const isAddindForm = file?.content
  const PDF_TYPE = ['application/pdf', 'pdf']
  const fileType = isAddindForm ? file?.type : (file as string)?.split('/')?.[file?.split('/').length - 2]
  return (
    <Box sx={{ position: 'relative' }}>
      {deleteFile && (
        <span onClick={() => deleteFile()} style={{ position: 'absolute', top: '-7px', right: '-4px', cursor: 'pointer', color: '#F70000', fontSize: '20px' }}>
          <IoIosCloseCircle />
        </span>
      )}
      {PDF_TYPE.includes(fileType) ? (
        <p style={PreviewFileStyle as any}>
          <img src='/assets/pdf.svg' width={'40px'} height={'40px'} />
          {deleteFile ? file.name : 'pdf'}
        </p>
      ) : (
        <img src={isAddindForm ? file?.content : file} style={PreviewFileStyle as any} />
      )}
    </Box>
  )
}

export default PreviewContractFile
