import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'name',
    key: 'name',
    name: dictionary().NAME,
    type: 'text',
  },
]

const Customers: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().CUSTOMERS}
      to='/customers/new'
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['toll-station-prices']}
      countArrayPath={['count']}
      restRoute={'/customers'}
      cashingKey={CASHING_KEY.GET_CUSTOMERS}
    />
  )
}

export default Customers
