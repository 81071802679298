import { Box, Flex, GridItem, Skeleton, Stack, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import Card from './Card'
import CsvDownloader from './Download'

const CustomerDistinctiveTaxNumbers: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [taxIdsData, setTaxIdsData] = React.useState<any>([])
  const { mutate, isLoading } = useMutation(() => fetch('GET', `/customers/${id}/unique-tax-ids`), {
    onSuccess: (data) => {
      setTaxIdsData(data)
    },
  })

  const taxIdHeaders = [
    {
      id: 'tax_id',
      key: 'tax_id',
      name: dictionary().TAX_ID,
      type: 'text',
    },
  ]

  useEffect(() => {
    mutate()
  }, [])

  return (
    <>
      <Card h='100%' p={3}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Text m={3} fontSize='20px' fontWeight={700} p={[5, 2, 2, 2]}>
            {dictionary().DISTINCTIVE_TAX_NUMBERS}
          </Text>
          <Flex gap={3}>
            <CsvDownloader
              query={''}
              headers={taxIdHeaders}
              PAGE_SIZE={''}
              setPAGE_SIZE={''}
              restRoute={`/customers/${id}/unique-tax-ids`}
              isUpload={true}
              accounting={false}
            />
          </Flex>
        </Flex>
        <hr />
        {isLoading ? (
          <>
            <Skeleton height='50px' />
            <Skeleton height='50px' my={4} />
          </>
        ) : (
          <GridItem m='2' colSpan={8} mt={7}>
            <Box w='100%' pr='0'>
              <Stack>
                <GridItem>
                  <Card h='100%'>
                    {taxIdsData?.data?.length > 0 ? (
                      <Table variant='striped' maxH={'70vh'} overflowY='auto'>
                        <Tbody>
                          {taxIdsData?.data?.map((taxId: any) => (
                            <Tr key={taxId}>
                              <Td
                                style={{
                                  fontWeight: 700,
                                  width: 'auto',
                                  maxWidth: '100%',
                                  paddingRight: '8px',
                                  paddingLeft: '8px',
                                  fontSize: '18px',
                                }}
                              >
                                {taxId?.tax_id}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    ) : (
                      <Text>{dictionary().NO_RESULTS_FOUND}</Text>
                    )}
                  </Card>
                </GridItem>
              </Stack>
            </Box>
          </GridItem>
        )}
      </Card>
    </>
  )
}

export default CustomerDistinctiveTaxNumbers
