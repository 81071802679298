import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import fetch from '../../helpers/fetch'
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, Text, useToast } from '@chakra-ui/react'
import dictionary from '../../dictionary'
import Reqiured from '../../components/core/reqiured'

type ForgetPasswordProps = {}

const fieldStyle = {
  '& label': {
    color: 'black',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'yellow',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ddd',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#7F56D9',
    },
  },
}

interface FormTypes {
  email: string
}

const ForgetPassword: FC<ForgetPasswordProps> = () => {
  const [resetMailSent, setResetMailSent] = useState(false)

  const { go } = useHistory()
  const toast = useToast()

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<FormTypes>()

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('post', `/admin/${getValues('email')}/reset-admin-password`, vars), {
    onSuccess: (data: any): void => {
      setResetMailSent(true)
      // setTimeout(() => {
      //   go(-1);
      // }, 1500);
    },
    onError: (error: any): void => {
      console.log(error)
      toast({
        containerStyle: {
          direction: 'rtl',
        },
        description: error?.data?.message || dictionary().ERROR,
        status: 'error',
        duration: 3000,
        position: 'top',
      })
    },
  })

  const sendResetPassword = async (formData: FormTypes) => {
    mutate({ data: { ...formData } })
    // setResetMailSent(true);
  }

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/

  return (
    <div
      style={{
        backgroundColor: 'rgb(249 250 251)',
        height: '100%',
        width: '100%',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <form onSubmit={handleSubmit(sendResetPassword)}>
        <Box
          sx={{
            width: '29rem',
            backgroundColor: 'rgb(255 255 255)',
            borderRadius: '0.5rem',
            boxShadow: '0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)',
            padding: '2rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Text fontSize='1.5rem' fontWeight={600} color='rgb(55 65 81)' lineHeight='2rem' fontFamily='Segoe UI' style={{ marginBottom: '1.5rem' }}>
            إعادة تعيين كلمة المرور
          </Text>

          <FormControl id='email'>
            <FormControl
              id={dictionary().EMAIL}
              minH='100px'
              display='inline-block'
              isDisabled={resetMailSent}
              isInvalid={!!errors.email || error?.data?.message}
            >
              <FormLabel>
                {dictionary().EMAIL} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('email', {
                    required: dictionary().REQUIRED,
                    validate: (value) => (!emailRegex.test(value) ? dictionary().INVALID_EMAIL : undefined),
                    // validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
          </FormControl>

          {!resetMailSent && (
            <Button type='submit' disabled={resetMailSent} style={{ textTransform: 'none', marginTop: '20px' }} colorScheme='blue' _hover={{ bg: 'blue.500' }}>
              إعادة تعيين كلمة المرور
            </Button>
          )}

          {resetMailSent && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '20px',
                color: '#66BB6A',
                gap: '10px',
              }}
            >
              <Text>
                تم بنجاح! يرجى التحقق من صندوق البريد الوارد الخاص بك للعثور على رسالة إلكترونية تحتوي على تعليمات حول كيفية إعادة تعيين كلمة المرور الخاصة بك
              </Text>
              <Button onClick={() => go(-1)} disabled={false} style={{ textTransform: 'none' }} colorScheme='blue'>
                الذهاب لتسجيل الدخول
              </Button>
            </Box>
          )}
          {!resetMailSent && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text fontSize='0.875rem' fontWeight={500} color='rgb(55 65 81)' lineHeight='1.25rem' style={{ marginTop: '0.8rem', marginRight: '0.2rem' }}>
                العودة
              </Text>
              <Text
                onClick={() => go(-1)}
                fontSize='0.875rem'
                fontWeight={600}
                color='#9575CD'
                lineHeight='1.25rem'
                style={{ marginTop: '0.8rem', cursor: 'pointer', marginRight: '0.2rem' }}
              >
                لتسجيل الدخول
              </Text>
            </Box>
          )}
          {!!error ? (
            <Text color='red' style={{ marginTop: '30px' }}>
              {error.data.message}
            </Text>
          ) : (
            <></>
          )}
        </Box>
      </form>
    </div>
  )
}

export default ForgetPassword
