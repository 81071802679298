import React, { useRef } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, Select } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { parse } from 'query-string'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'

type AddStoreProductsVariables = {
  name_en: string
  price: number
  station: string
}

const AddStoreProduct: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const { isLoading, mutate } = useMutation((vars: any) => fetch('POST', '/store-products', { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push('/store-products')
    },
    onError,
  })

  const query = parse(location.search, { parseBooleans: true })
  const stations = useQuery([query, 'stations'], () => fetch('GET', '/stations'))

  const addStoreProduct = async (formData: AddStoreProductsVariables) => {
    mutate(formData)
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().ADD_STORE_PRODUCT}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && addStoreProduct(getValues() as AddStoreProductsVariables)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(addStoreProduct as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl
              id={dictionary().PRODUCT_NAME_AR}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.name?.ar}
            >
              <FormLabel>
                {dictionary().PRODUCT_NAME_AR} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('name.ar', {
                    required: dictionary().REQUIRED,
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.name_ar?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().PRODUCT_NAME_EN}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.name?.en}
            >
              <FormLabel>
                {dictionary().PRODUCT_NAME_EN} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('name.en', {
                    required: dictionary().REQUIRED,
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.name_ar?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().PRICE} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.price}>
              <FormLabel>
                {dictionary().PRICE} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='number'
                  {...register('price', {
                    required: dictionary().REQUIRED,
                    valueAsNumber: true,
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.price?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().STATION_NAME}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.stationId}
            >
              <FormLabel>
                {dictionary().STATION_NAME} <Reqiured />{' '}
              </FormLabel>
              <Select
                dir='ltr'
                textAlign='right'
                {...register('stationId', { valueAsNumber: true, required: dictionary().REQUIRED })}
                placeholder={dictionary().STATION}
              >
                {stations?.data?.data?.map((item: any) => (
                  <option key={item?.id} value={item?.id}>
                    {' '}
                    {item?.name?.ar}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.stationId?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddStoreProduct
