import { useRef } from 'react'

import { Modal, Box, Spinner, ModalOverlay, Stack, ModalContent, ModalHeader, ModalFooter, ModalBody, Image, Button, useDisclosure } from '@chakra-ui/react'

import dictionary from '../../dictionary'
import { useStore } from '../../store'

interface Props {
  data?: any
  header?: any
  pump?: boolean
  srcValue?: string | null
}

const ImageModal: React.FC<Props> = ({ data, header, pump, srcValue }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const is_pump_cropped = data?.images?.is_pump_cropped || false
  let src
  if (srcValue) {
    src = srcValue
  } else {
    src = header.key === 'images.pump' ? data?.images?.pump : header.key === 'ConfirmationImage' ? data.image : data?.images?.odometer
  }
  const imageTitle = header.key === 'images.pump' ? dictionary().PUMP_IMG : header.name
  const { userType } = useStore((state) => state)
  const { role } = useStore()

  return (
    <>
      <Button onClick={onOpen}>{dictionary().VIEW_IMAGE}</Button>
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{header.name} </ModalHeader>
          <ModalBody pb={6}>
            <Stack direction='column'>
              <Box display='flex' flexDirection={'column'} alignItems='center'>
                <h2
                  style={{
                    display: 'block',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  {imageTitle}
                </h2>
                {!src && <h3>{dictionary().NO_IMG} </h3>}

                {((src && ['SUPER', 'MANAGER'].includes(role ?? '')) || (src && !pump) || (src && pump && !is_pump_cropped)) && (
                  <Image fallback={<Spinner />} borderRadius='xl' boxSize='300' src={src} alt='IMG' />
                )}
                {src && !['SUPER', 'MANAGER'].includes(role ?? '') && pump && is_pump_cropped && (
                  <div
                    style={{
                      overflow: 'hidden',
                      width: '295.5px',
                      height: '201.5px',
                      textAlign: 'center',
                      margin: '20px 125px',
                    }}
                  >
                    <img
                      onDragStart={() => {
                        return false
                      }}
                      draggable={false}
                      src={src}
                      alt='IMG'
                      style={{
                        maxWidth: '402px',
                        maxHeight: '591px',
                        overflow: 'hidden',
                        transform: 'scale(1.1)',
                        marginBottom: '-305px',
                        marginLeft: '-30px',
                        pointerEvents: 'none',
                      }}
                    />
                  </div>
                )}
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ImageModal
