import fetch from '../../../helpers/fetch'

// const downloadImage = (fileLink: string, fileName: string) => {
//   const url = fileLink // URL of the image to download
//   const filename = fileName // Name for the downloaded file

//   // const url = window.URL.createObjectURL(new Blob([blob]))
//   const link = document.createElement('a')
//   link.href = url
//   link.setAttribute('download', filename)
//   document.body.appendChild(link)
//   link.click()
// }

// // export default downloadImage
// const download = (fileLink: string, fileName: string) => {
//   const isImage = fileLink.includes('/image')
//   const element = document.createElement('a')
//   const file = new Blob([fileLink], { type: 'image/*, .pdf' })
//   element.href = URL.createObjectURL(file)
//   element.download = !isImage ? `${fileName}.pdf` : `${fileName}.jpg`
//   element.click()
// }

const download = (imageUrl: string, fileName: string): void => {
  const image = new Image()
  image.crossOrigin = 'Anonymous' // This allows the canvas to use images from other domains

  image.onload = () => {
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height

    const ctx = canvas.getContext('2d')
    ctx?.drawImage(image, 0, 0)

    // Convert canvas content to a data URL representing the image
    const dataUrl = canvas.toDataURL('image/jpeg')

    // Create a temporary link element to trigger the download
    const element = document.createElement('a')
    element.href = dataUrl
    element.download = `${fileName}.jpg`
    element.click()

    // Clean up
    canvas.remove()
  }

  // Set the image source to trigger the onload event
  image.src = imageUrl
}

export default download
