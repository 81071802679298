import { ROLES } from '../ROLES'

export const canEditEntity = (role: string) => {
  switch (role) {
    case ROLES.ACCOUNT_MANAGER:
      return false
    default:
      return true
  }
}
