import { ChevronRightIcon } from '@chakra-ui/icons'
import { Link } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import { TableLinkProps } from '../../../interfaces/tableProps'
import TranslateXOnHover from '../TranslateXOnHover'

export const TableDetails: React.FC<TableLinkProps> = ({ to, name }) => {
  return (
    <TranslateXOnHover>
      <Link as={ReactLink} to={to} fontSize='.78em'>
        {name} <ChevronRightIcon />
      </Link>
    </TranslateXOnHover>
  )
}
