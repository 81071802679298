import React, { useEffect, useRef, useState, useCallback } from 'react'
import { pathOr } from 'ramda'
import { useQuery } from 'react-query'
import { useLocation, useParams, useHistory } from 'react-router'
import { parse, stringify } from 'query-string'
import { Flex, GridItem, Spacer, Text } from '@chakra-ui/react'
import fetch from '../../helpers/fetch'
import Container from '../core/Container'
import Header from '../core/Header'
import Table from '../core/Table'
import SearchDate from '../core/SearchDate'
import dictionary from '../../dictionary'
import { useStore } from '../../store'
import { ViewProps } from '../../interfaces/viewAllProps'
import SearchAndFilter from '../core/Search'
import { CASHING_KEY, USER_TYPE } from '../../constants'
import { createVariablesFromQueryString } from '../../helpers/queryStringVariables'
import BalanceScreen from '../core/BalanceScreen'
import Filters from '../core/Filters'
import TopRow from '../core/TopRow'
import axios from 'axios'
import { useFilterStore } from '../../stores/filterStore'

const ViewAllPage: React.FC<ViewProps> = (props) => {
  const {
    restRoute,
    headers,
    title,
    to,
    tags,
    hasSearch,
    hasSearchDate,
    sortOptions,
    querystringToVariables = createVariablesFromQueryString,
    cashingKey,
    height,
    dataOne,
    enableIdRedirect,
    dataPathFormatter,
    accounting = false,
    table,
    fuelData,
    addNew,
  } = props

  const headerRef = useRef<HTMLDivElement>(null)
  const [selectedStationProvider, setSelectedStationProvider] = useState(null)
  const [selectedStation, setSelectedStation] = useState(null)
  const [selectedCorporate, setSelectedCorporate] = useState(null)
  const [PAGE_SIZE, setPAGE_SIZE] = useState(30)
  const { userType: authenticatedUser } = useStore((state) => state)
  const { pathname, search } = useLocation()
  const { push } = useHistory()
  const { userType, id, account_id } = useParams<{ userType: string; id: string; account_id: string }>()
  const { from, to: toFilter, corporateIds } = useFilterStore()
  let query: any = {}
  const ledger_account = {
    cash_id: 0,
    fuel_id: 0,
    store_id: 0,
    toll_id: 0,
    name: '',
    cash_balance: 0,
    fuel_balance: 0,
    store_balance: 0,
    toll_balance: 0,
  }
  let fuel_selected = '#FFFFFF'
  let store_selected = '#FFFFFF'
  let toll_selected = '#FFFFFF'

  if (cashingKey === CASHING_KEY.INVOICES) {
    query = { corporateId: parseInt(id) }
  }

  if (cashingKey === CASHING_KEY.GET_FUEL_TRANSACTIONS_FOR_ID || cashingKey === CASHING_KEY.GET_TOLL_TRANSACTIONS_FOR_ID) {
    if (userType === 'corporates') {
      query = { corporateId: parseInt(id) }
    } else if (userType === 'drivers') {
      query = { driverId: parseInt(id) }
    } else if (userType === 'vehicles') {
      query = { vehicleId: parseInt(id) }
    } else if (userType === 'stations') {
      query = { stationId: parseInt(id) }
    } else if (userType === 'station-providers') {
      query = { stationProviderId: parseInt(id) }
    }
  } else if (cashingKey === CASHING_KEY.GET_TOLL_TRANSACTIONS) {
    query = parse(search, { parseNumbers: true, parseBooleans: true })
  } else if (
    cashingKey === CASHING_KEY.GET_FUEL_TRANSACTIONS ||
    cashingKey === CASHING_KEY.TRANSACTIONS_REVIEWED ||
    cashingKey === CASHING_KEY.GET_STORE_TRANSACTIONS
  ) {
    query = parse(location.search, { parseNumbers: true, parseBooleans: true })
  } else {
    query = parse(location.search, { parseNumbers: true, parseBooleans: true })
    if (cashingKey === CASHING_KEY.GET_FUEL_TRANSACTIONS_FOR_DATA_ENTRY) {
      query = { ...query }
    }
    useEffect(() => {
      if (query.stationId) {
        setSelectedStation(query.stationId)
      }
      if (query.corporateId) {
        setSelectedCorporate(query.corporateId)
      }
    }, [])
  }

  useEffect(() => {
    if (!query.status && pathname.includes('/toll-transactions')) {
      push('?status=CONFIRMED')
    }
    if (!query.sort_column && (pathname.includes('/fuel-transactions') || pathname.includes('/fuel-transactions-data-entry'))) {
      push('?sort_column=fuel-transaction.id&sort_direction=DESC')
    }
    if (query.stationId) {
      setSelectedStation(query.stationId)
    }
    if (query.corporateId) {
      setSelectedCorporate(query.corporateId)
    }
  }, [])

  if (cashingKey === CASHING_KEY.GET_LEDGER_TRANSACTIONS_FOR_ID) {
    if (userType === 'corporates') {
      ledger_account.name = dataOne?.data?.name
      if (dataOne?.data?.ledger_accounts !== undefined) {
        ledger_account.cash_id = dataOne?.data?.ledger_accounts[0]?.account_id
        ledger_account.cash_balance = dataOne?.data?.ledger_accounts[0]?.balance
      }
    } else if (userType === 'drivers') {
      ledger_account.name = dataOne?.data?.data?.name
      const ledger_accounts = dataOne?.data?.data?.ledger_accounts
      if (dataOne?.data?.data?.ledger_accounts !== undefined) {
        {
          ledger_accounts?.map((account: any) => {
            if (account?.account_type === 'CASH' || account?.account_type === 'STORE') {
              ledger_account.store_id = account?.account_id
              ledger_account.store_balance = account?.balance
            } else if (account?.account_type === 'TOLL') {
              ledger_account.toll_id = account?.account_id
              ledger_account.toll_balance = account?.balance
            } else if (account?.account_type === 'FUEL') {
              ledger_account.fuel_id = account?.account_id
              ledger_account.fuel_balance = account?.balance
            }
          })
        }

        {
          ledger_accounts?.map((account: any) => {
            if ((account?.account_type === 'CASH' || account?.account_type === 'STORE') && account.account_id == account_id) {
              store_selected = '#D3D3D3'
            } else if (account?.account_type === 'TOLL' && account.account_id == account_id) {
              toll_selected = '#D3D3D3'
            } else if (account?.account_type === 'FUEL' && account.account_id == account_id) {
              fuel_selected = '#D3D3D3'
            }
          })
        }
      }
    } else if (userType === 'vehicles') {
      ledger_account.name = dataOne?.data?.vehicle?.number_plate
      const ledger_accounts = dataOne?.data?.vehicle?.ledger_accounts
      if (dataOne?.data?.vehicle?.ledger_accounts !== undefined) {
        {
          ledger_accounts?.map((account: any) => {
            if (account?.account_type === 'CASH' || account?.account_type === 'STORE') {
              ledger_account.store_id = account?.account_id
              ledger_account.store_balance = account?.balance
            } else if (account?.account_type === 'TOLL') {
              ledger_account.toll_id = account?.account_id
              ledger_account.toll_balance = account?.balance
            } else if (account?.account_type === 'FUEL') {
              ledger_account.fuel_id = account?.account_id
              ledger_account.fuel_balance = account?.balance
            }
          })
        }

        {
          ledger_accounts?.map((account: any) => {
            if ((account?.account_type === 'CASH' || account?.account_type === 'STORE') && account.account_id == account_id) {
              store_selected = '#D3D3D3'
            } else if (account?.account_type === 'TOLL' && account.account_id == account_id) {
              toll_selected = '#D3D3D3'
            } else if (account?.account_type === 'FUEL' && account.account_id == account_id) {
              fuel_selected = '#D3D3D3'
            }
          })
        }
      }
    }
  }

  if (selectedCorporate !== null && selectedCorporate !== '') {
    if (query.corporateId != selectedCorporate) {
      query.corporateId = selectedCorporate
      delete query.page
      push({ search: stringify(query) })
    }
  } else if (selectedCorporate === '' && query.corporateId) {
    delete query.corporateId
    push({ search: stringify(query) })
  }
  if (selectedStation !== '' && selectedStation !== null) {
    if (query.stationId != selectedStation) {
      query.stationId = selectedStation
      delete query.page
      push({ search: stringify(query) })
    }
  } else if (selectedStation === '' && query.stationId) {
    delete query.stationId
    push({ search: stringify(query) })
  }
  if (selectedStationProvider !== '' && selectedStationProvider !== null) {
    if (query.stationProviderId != selectedStationProvider) {
      query.stationProviderId = selectedStationProvider
      delete query.page
      push({ search: stringify(query) })
    }
  } else if (selectedStationProvider === '' && query.stationProviderId) {
    delete query.stationProviderId
    push({ search: stringify(query) })
  }

  let userTitle = ''
  const loggedUser = useQuery(['userData'], () => fetch('GET', `/${authenticatedUser}/current`))
  if (pathname === '/fuel-transactions') {
    if (authenticatedUser == USER_TYPE.STATION_PROVIDER_STAFF) {
      userTitle = loggedUser?.data?.stationProviderStaff?.station_provider?.name?.ar
    } else if (authenticatedUser == USER_TYPE.STATION_STAFF) {
      userTitle = loggedUser?.data?.stationStaff?.station?.name?.ar
    } else if (authenticatedUser == USER_TYPE.CORPORATE_STAFF) {
      userTitle = loggedUser?.data?.corporateStaff?.corporate?.name
    } else if (authenticatedUser == USER_TYPE.ADMIN) {
      userTitle = dictionary().ADMIN
    }
  }

  const variables = querystringToVariables(query)

  const [isBalancesChange, setIsBisBalancesChange] = useState(false)
  const handleIsBalancesChange = useCallback(() => {
    setIsBisBalancesChange(!isBalancesChange)
  }, [isBalancesChange])

  const { data, isFetching, error, refetch, isLoading } = useQuery(
    [cashingKey, query],
    () => {
      if (pathname === '/e-invoices') {
        if (from && toFilter && corporateIds.length > 0) {
          return fetch('POST', restRoute + '?limit=1000&page_size=1000', {
            data: {
              startDate: from,
              endDate: toFilter,
              corporateIds: corporateIds,
            },
          }).then((data) => {
            const dataObj = data?.data
            let sortedData = dataObj.sort((a: any, b: any) => {
              if (a.contact_name < b.contact_name) {
                return -1
              }
              if (a.contact_name > b.contact_name) {
                return 1
              }
              return 0
            })
            return {
              data: sortedData,
            }
          })
        } else {
          return {
            data: [],
          }
        }
      } else {
        return fetch('GET', restRoute, {
          query: { ...variables, page_size: PAGE_SIZE, ...query },
        })
      }
    },
    {
      enabled: pathname !== '/fuel-transactions',
    },
  )

  useEffect(() => {
    if (pathname === '/fuel-transactions') {
      return
    }
    refetch()
  }, [isBalancesChange, corporateIds, from, toFilter])

  const tableHeight = height ?? (headerRef.current ? `calc(100vh - ${headerRef.current.clientHeight}px)` : undefined)

  if (error && pathname !== '/fuel-transactions') return <p>error...</p>

  return (
    <Container maxW='100%' width='100%' h='100vh' padding={8}>
      <Flex ref={headerRef} flexDir='column' sx={{ '& > *:not(:last-child)': { marginBottom: '15px' } }}>
        {cashingKey !== CASHING_KEY.GET_LEDGER_TRANSACTIONS_FOR_ID ? (
          <Header
            userType={authenticatedUser as string}
            title={title}
            to={to}
            isLoading={isFetching}
            cashingKey={cashingKey}
            loggedUser={pathname === '/fuel-transactions' && loggedUser}
            userTitle={userTitle}
            addNew={addNew}
          />
        ) : (
          <Header userType={authenticatedUser as string} title={title + ': ' + ledger_account?.name} to={to} isLoading={isFetching} addNew={addNew} />
        )}
        {cashingKey === CASHING_KEY.GET_CORPORATE_DRIVERS && (
          <GridItem colSpan={8}>
            <Flex mt='0' direction='row' display={['block', 'flex', 'flex', 'flex']}>
              {headers.map((h: any) => (
                <>
                  <Spacer />
                  <TopRow header={h} row={loggedUser?.data} isLoading={loggedUser?.isLoading} />
                  <Spacer />
                </>
              ))}
            </Flex>
          </GridItem>
        )}
        <BalanceScreen
          cashingKey={cashingKey}
          ledgerAccount={ledger_account}
          fuelSelected={fuel_selected}
          storeSelected={store_selected}
          tollSelected={toll_selected}
        />
        {(cashingKey === CASHING_KEY.GET_FUEL_TRANSACTIONS || cashingKey === CASHING_KEY.TRANSACTIONS_REVIEWED) &&
          (authenticatedUser === 'station-staff' || authenticatedUser === 'corporate-staff') && (
            <GridItem colSpan={[12, 12, 5, 5]} display='flex' alignItems='center'>
              {hasSearchDate && <SearchDate />}
            </GridItem>
          )}
        <Filters
          cashingKey={cashingKey}
          userType={authenticatedUser}
          tags={tags}
          query={query}
          hasSearchDate={hasSearchDate}
          setSelectedStation={setSelectedStation}
          setSelectedCorporate={setSelectedCorporate}
          setSelectedStationProvider={setSelectedStationProvider}
        />
        <SearchAndFilter
          headers={headers}
          selectedCorporate={selectedCorporate}
          selectedStation={selectedStation}
          selectedStationProvider={selectedStationProvider}
          PAGE_SIZE={PAGE_SIZE}
          setPAGE_SIZE={setPAGE_SIZE}
          restRoute={restRoute}
          hasSearch={hasSearch}
          hasSearchDate={hasSearchDate}
          sortOptions={sortOptions}
          totalCount={pathname === '/fuel-transactions' ? fuelData?.total : data?.total}
          query={query}
          pageSize={PAGE_SIZE}
          accounting={accounting}
          data={data}
        />
      </Flex>

      {/* Fuel Transactions */}
      {table && table()}

      {/* All Tables Except Fuel Transactions */}
      {((data && data?.data?.length > 0) || (data && data?.length)) && pathname !== '/fuel-transactions' ? (
        <Table
          raised
          handleIsBalancesChange={handleIsBalancesChange}
          enableIdRedirect={enableIdRedirect}
          height={tableHeight}
          headers={headers.filter((h) => h.type !== 'unusedBalance')}
          data={pathOr([], ['data'], dataPathFormatter ? { data } : data)}
        />
      ) : !isFetching && pathname !== '/fuel-transactions' ? (
        <Flex w='100%' h='100%' align='center' justify='center'>
          <Text fontSize='2xl'>
            {dictionary().NO_RESULTS_FOUND} {dictionary().IN} {title}
          </Text>
        </Flex>
      ) : (
        pathname !== '/fuel-transactions' && (
          <Flex w='100%' h='100%' align='center' justify='center'>
            <Text fontSize='2xl'>
              {dictionary().SEARCH_LOADING} {dictionary().IN} {title}
            </Text>
          </Flex>
        )
      )}
    </Container>
  )
}

export default ViewAllPage
