import React, { useCallback, useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import AuthLayout from './components/layout/Auth'
import WithSidebarLayout from './components/layout/WithSidebar'
import { ROLES } from './constants/ROLES'
import { ACCOUNT_MANAGER_ROUTES } from './constants/routes/ACCOUNT_MANAGER'
import { ADMIN_ROUTES } from './constants/routes/ADMIN'
import { ATTENDANT_ROUTES } from './constants/routes/ATTENDANT'
import { FIN_OPS_ROUTES } from './constants/routes/FIN_OPS'
import LoginForm from './pages/login'
import { useStore } from './store'
import NewPassword from './pages/NewPassword/NewPassword'
import ForgetPassword from './pages/ForgetPassword/ForgetPassword'
import AmplitudeInit from './amplitudeInit'
import { useFilterStore } from './stores/filterStore'

const PrivateRouter: React.FC = () => {
  const { role, user } = useStore()
  const { pathname } = useLocation()
  const { resetFilters } = useFilterStore()
  const initializeAmplitude = useCallback(() => {
    AmplitudeInit(user)
  }, [user])

  useEffect(() => {
    initializeAmplitude()
  }, [initializeAmplitude])

  useEffect(() => {
    if (!role) {
      localStorage.clear()
    }
  }, [role])

  useEffect(() => {
    if (pathname) {
      resetFilters()
    }
  }, [pathname])

  const getRoutes = () => {
    switch (role) {
      case ROLES.FIN_OPS:
        return FIN_OPS_ROUTES
      case ROLES.ATTENDANT:
        return ATTENDANT_ROUTES
      case ROLES.ACCOUNT_MANAGER:
        return ACCOUNT_MANAGER_ROUTES
      default:
        return ADMIN_ROUTES()
    }
  }

  return (
    <WithSidebarLayout>
      <>{role && getRoutes()}</>
    </WithSidebarLayout>
  )
}

const AuthRouter: React.FC = () => {
  return (
    <AuthLayout>
      <Switch>
        <Route exact path='/admin/login' render={() => <LoginForm />} />
        <Redirect exact from='/*' to='/admin/login' />
      </Switch>
    </AuthLayout>
  )
}

const Router: React.FC = () => {
  const { token } = useStore((state) => state)

  return (
    <>
      <Switch>
        <Route exact path='/new-password' render={() => <NewPassword />} />
        <Route exact path='/forget-password' render={() => <ForgetPassword />} />
        <Route exact path='/*' render={() => (!token ? <AuthRouter /> : <PrivateRouter />)} />
      </Switch>
    </>
  )
}

export default Router
