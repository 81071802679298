import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'max_weight',
    key: 'max_weight',
    name: dictionary().MAX_WEIGHT,
    type: 'text',
  },
  {
    id: 'volume',
    key: 'volume',
    name: dictionary().VOLUME,
    type: 'text',
  },
  {
    id: 'dimensions',
    key: 'dimensions',
    name: dictionary().DIMENSIONS,
    type: 'text',
  },
  {
    id: 'name',
    key: 'name.ar',
    name: dictionary().VEHICLE_TYPE_NAME,
    type: 'text',
  },
  {
    id: 'code',
    key: 'code',
    name: dictionary().CODE,
    type: 'text',
  },
  {
    id: 'vehicle_type_date_created',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'vehicle_type_time_created',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'toll_amount',
    key: 'toll_amount',
    name: dictionary().TOLL_AMOUNT,
    type: 'cash',
    isRight: true,
  },
]

const VehicleTypes: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().VEHICLE_TYPES}
      to='/vehicle-types/add'
      tags={[{ name: dictionary().ALL, filter: [{ name: '', value: '' }] }]}
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['vehicle-types']}
      countArrayPath={['count']}
      restRoute={'/vehicle-types'}
      cashingKey={CASHING_KEY.GET_VEHICLE_TYPES}
      enableIdRedirect={true}
    />
  )
}

export default VehicleTypes
