import create from 'zustand'
import createVanilla from 'zustand/vanilla'
import { persist } from 'zustand/middleware'

export interface Store {
  token: string | null
  userType: string | null
  role: string | null
  transferLoad: boolean
  user: any
  setToken: (token: string | null) => void
  setUserType: (userType: string | null) => void
  setRole: (role: string | null) => void
  setTransferLoad: (transferLoad: boolean) => void
  setUser: (user: any) => void
}

export const store = createVanilla<Store>(
  persist(
    (set) =>
      ({
        userType: null,
        token: null,
        role: null,
        error: null,
        user: null,
        setUserType: (userType: string | null) => {
          set({ userType })
        },
        setToken: (token: string | null) => {
          set({ token })
        },
        setRole: (role: string | null) => {
          set({ role })
        },
        setUser: (user: any) => {
          set({ user })
        },
      } as any),

    {
      name: 'main-store',
    },
  ),
)

export const useStore = create<Store>(store as any)
