import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { USER_TYPE } from '../../constants'
import dictionary from '../../dictionary'
import { useStore } from '../../store'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'driverName',
    key: 'driver.name',
    name: dictionary().DRIVER_NAME,
    type: 'text',
  },
  {
    id: 'corporateName',
    key: 'corporate.name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'plate_number',
    key: 'vehicle.number_plate',
    name: dictionary().NUMBER_PLATE,
    type: 'text',
  },
  {
    id: 'vehicle_code',
    key: 'vehicle.code',
    name: dictionary().VEHICLE_CODE,
    type: 'text',
  },
  {
    id: 'fuel_type',
    key: 'fuel_type.name.en',
    name: dictionary().FUEL_TYPES,
    type: 'text',
  },
  {
    id: 'trans_litre_amount',
    key: 'liters',
    name: dictionary().LITRES_AMOUNT,
    type: 'text',
  },
  {
    id: 'fuel_conumption',
    key: 'fuel_consumption',
    name: dictionary().FUEL_CONSUMPTION,
    type: 'text',
  },
  {
    id: 'odometer_reading',
    key: 'odometer_reading',
    name: dictionary().ODOMETER_READING,
    type: 'text',
  },
  {
    id: 'station_staff',
    key: 'station_staff.username',
    name: dictionary().FUEL_ATTENDANT_NAME,
    type: 'text',
  },
  {
    id: 'station',
    key: 'station.name.en',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().AMOUNT,
    type: 'cash',
  },
  {
    id: 'trans_status',
    key: 'status',
    name: dictionary().STATUS,
    type: 'text',
  },
  {
    id: 'comment',
    key: 'comment',
    name: dictionary().COMMENT,
    type: 'text',
  },
  {
    id: 'trans_date_created',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'trans_time_created',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'imageModal',
    key: 'images.pump',
    name: dictionary().RELATED_IMAGES,
    type: 'modal',
  },
]
let flag = false

const AccountingTransactions: React.FC = () => {
  const { userType } = useStore((state) => state)
  if (userType === USER_TYPE.ADMIN && !flag) {
    headers.push({
      id: 'confirmTransactionModal',
      key: 'confirmTransaction',
      name: dictionary().ACTIONS,
      type: 'modal',
    })
    flag = true
  }
  return (
    <ViewAll
      headers={headers}
      title={dictionary().FUEL_TRANSACTIONS}
      to=''
      tags={[
        { name: dictionary().ALL, filter: [{ name: '', value: '' }] },
        { name: dictionary().CONFIRMED, filter: [{ name: 'status', value: 'CONFIRMED' }] },
      ]}
      hasSearch={true}
      hasSearchDate={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['fuel-transaction']}
      countArrayPath={['count']}
      restRoute={'/fuel-transactions'}
      cashingKey={'getFuelTransactions'}
      enableIdRedirect={false}
      accounting={true}
    />
  )
}

export default AccountingTransactions
