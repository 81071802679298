import { ROLES } from '../ROLES'

export const canEditCGStaff = (role: string) => {
  switch (role) {
    case ROLES.OPERATIONS:
      return true
    case ROLES.SUPER_ADMIN:
      return true
    case ROLES.MANAGER:
      return true
    default:
      return false
  }
}
