import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, Spinner } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import AutoCompleteInput from '../../../components/core/AutoCompleteInput'
import Required from '../../../components/core/reqiured'
import Table from '../../../components/core/Table'
import dictionary from '../../../dictionary'
import fetch from '../../../helpers/fetch'
import useAlert from '../../../helpers/useAlert'
import RefundConfirmationModel from './RefundConfirmationModel'

interface CheckDuplicatesVar {
  originalTransaction: number
  duplicateTransactions: number[]
}

interface header {
  id: string
  key: string
  name: string
  type: string
}
interface Props {
  headers: header[]
  handleClose: () => void
  queryParams: any
}

const DuplicateRefund: FC<Props> = ({ headers, handleClose, queryParams }) => {
  const [transactionsData, setTransactionsData] = useState<any>([])
  const [baseTransactionData, setBaseTransactionData] = useState<any>({})
  const [isClosed, setIsClosed] = useState<boolean>(false)
  const [isDataReady, setIsDataReady] = useState<boolean>(false)
  const [isDuplicated, setIsDuplicated] = useState<boolean>(false)
  const [isRefundFetched, setIsRefundedFetched] = useState<boolean>(false)
  const { onSuccess, onError } = useAlert()
  const { push } = useHistory()
  const hasQueryParams = Object.keys(queryParams).length > 0
  const {
    register,
    control,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  })
  const transactionId = watch('baseTransaction')
  const duplicatetransactionsIds = watch('duplicateTransactions')

  useEffect(() => {
    if (hasQueryParams) {
      queryParams?.baseTransaction && setValue('baseTransaction', [+queryParams?.baseTransaction])
      if (queryParams?.duplicateTransactions) {
        const duplicateIds = queryParams?.duplicateTransactions?.split(',').map((id: string) => +id)
        setValue('duplicateTransactions', duplicateIds)
      }
    }
  }, [queryParams])

  const { isLoading, mutate, data, error } = useMutation(
    (vars: CheckDuplicatesVar) => fetch('POST', 'fuel-transactions/duplicate/validate?lang=ar', { data: vars }),
    {
      onSuccess: (): void => {
        onSuccess()
        setIsDuplicated(true)
      },
      onError: () => {
        setIsDuplicated(false)
        onError('')
      },
    },
  )
  const {
    isLoading: isRefundLoading,
    mutate: RefundMutate,
    error: RefundError,
  } = useMutation((vars: CheckDuplicatesVar) => fetch('POST', 'fuel-transactions/duplicate/refund?lang=ar', { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push(hasQueryParams ? '/duplicate-transactions' : '/fuel-transactions')
      setIsRefundedFetched(true)
    },
    onError: () => {
      setIsRefundedFetched(true)
      onError('')
    },
  })

  const checkIsDuplicateTransactions = () => {
    mutate({ originalTransaction: transactionId[0], duplicateTransactions: duplicatetransactionsIds })
  }
  const handleRefundTransaction = () => {
    RefundMutate({ originalTransaction: transactionId[0], duplicateTransactions: duplicatetransactionsIds })
  }

  // Get Transactions Data
  useEffect(() => {
    if (transactionId !== undefined && transactionId?.length > 0 && duplicatetransactionsIds?.length > 0 && isClosed) {
      setIsDataReady(false)
      setTransactionsData([])
      setBaseTransactionData({})
      const data: any[] = []
      ;(async () => {
        await fetch('GET', `/fuel-transactions/${transactionId?.[0]}`).then((res) => {
          setBaseTransactionData(res)
        })
      })()

      duplicatetransactionsIds?.map(
        async (id: number) =>
          await fetch('GET', `/fuel-transactions/${id}`).then((res) => {
            data.push(res)
            setTransactionsData([...data])
          }),
      )
      setIsDataReady(true)
    } else {
      setTransactionsData([])
      setBaseTransactionData({})
    }
  }, [transactionId, duplicatetransactionsIds, isClosed])

  useEffect(() => {
    ;(error as any)?.data && ((error as any).data = null)
    ;(RefundError as any)?.data && ((RefundError as any).data = null)
  }, [transactionId, duplicatetransactionsIds])

  return (
    <>
      {!isDuplicated && (
        <>
          <FormControl id={dictionary().TRANSACTION_NUMBER} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.baseTransaction}>
            <FormLabel>
              {dictionary().TRANSACTION_NUMBER} <Required />
              <span style={{ color: '#a9a5a5', fontSize: '12px' }}>{dictionary().ENETER_THREE_DIGITS_TO_SEARCH}</span>
            </FormLabel>
            <Controller
              name='baseTransaction'
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutoCompleteInput
                  onChange={onChange}
                  searchRoute='/fuel-transactions/searchById'
                  singleRoute='/fuel-transactions'
                  query={{ status: 'CONFIRMED' }}
                  valueFormatter={(option) => option?.id}
                  displayFormatter={(option) => option?.id}
                  isInvalid={!!errors?.baseTransaction}
                  value={value}
                  pageSize={10}
                  inputType='number'
                  minInputLengthToSearch={3}
                />
              )}
              rules={{
                required: true && dictionary().REQUIRED,
              }}
            />
            <FormErrorMessage>{errors.baseTransaction?.message}</FormErrorMessage>
          </FormControl>
          {transactionId?.length > 0 && (
            <FormControl id={dictionary().TRANSACTION_NUMBER} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.baseTransaction}>
              <FormLabel>
                {dictionary().DUPLICATE_TRANSACTIONS} <Required />
                <span style={{ color: '#a9a5a5', fontSize: '12px' }}>{dictionary().ENETER_THREE_DIGITS_TO_SEARCH}</span>
              </FormLabel>
              <Controller
                name='duplicateTransactions'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    onChange={onChange}
                    searchRoute='/fuel-transactions/searchById'
                    singleRoute='/fuel-transactions'
                    query={{ status: 'CONFIRMED' }}
                    multiple={true}
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.id}
                    isInvalid={!!errors?.duplicateTransactions}
                    value={transactionId?.length === 0 ? [] : value}
                    pageSize={10}
                    setIsClosed={setIsClosed}
                    id={transactionId?.[0]}
                    inputType='number'
                    minInputLengthToSearch={3}
                  />
                )}
                rules={{
                  required: true && dictionary().REQUIRED,
                }}
              />
              <FormErrorMessage>{errors.duplicateTransactions?.message}</FormErrorMessage>
            </FormControl>
          )}
          {error && <b style={{ width: '70%', color: 'red' }}>{(error as any)?.data?.message}</b>}
          {duplicatetransactionsIds?.length > 0 && transactionId?.length > 0 && (
            <Button
              colorScheme='blue'
              variant={'solid'}
              marginTop={8}
              w={['100%', '70%']}
              disabled={duplicatetransactionsIds?.length < 1 || transactionId?.length < 1 || !isDataReady || isLoading}
              onClick={async () => {
                ;(await trigger()) && checkIsDuplicateTransactions()
              }}
            >
              {isLoading ? <Spinner /> : dictionary().CONFIRM_IS_NOT_DUPLICATED_TRANSACTIONS}
            </Button>
          )}
        </>
      )}

      {transactionsData?.length > 0 && isClosed && isDataReady && isDuplicated && (
        <>
          <b style={{ width: '70%' }}>{dictionary().BASE_TRANSACTIONS_DATA}</b>
          <Table raised enableIdRedirect={false} width={'70%'} headers={headers} data={[baseTransactionData]} />

          <b style={{ width: '70%' }}>{dictionary().DUPLICATE_TRANSACTIONS_DATA}</b>
          <Table raised enableIdRedirect={false} width={'70%'} headers={headers} data={transactionsData} />

          <Flex
            justify='space-evenly'
            align='center'
            direction='row'
            w={['100%', '70%', '70%', '70%']}
            sx={{ marginTop: '20px', shadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)', padding: '20px 15px', borderRadius: '10px' }}
          >
            <FormControl id={dictionary().AMOUNT} w={['50%', '70%', '70%', '70%']} marginLeft={5}>
              <FormLabel>{dictionary().AMOUNT}</FormLabel>
              <InputGroup>
                <Input value={data?.total_amount || 0} disabled {...register('amount')} />
              </InputGroup>
            </FormControl>

            <FormControl id={dictionary().CORRECTION_VALUE} w={['50%', '70%', '70%', '70%']} marginRight={5}>
              <FormLabel>{dictionary().CORRECTION_VALUE}</FormLabel>
              <InputGroup>
                <Input value={data?.total_correction || 0} disabled {...register('correction')} />
              </InputGroup>
            </FormControl>
          </Flex>

          {RefundError && <b style={{ width: '70%', color: 'red' }}>{(RefundError as any)?.data?.message}</b>}

          <Flex justify='space-evenly' align='center' direction='row' w={['100%', '70%', '70%', '70%']}>
            <Button marginTop={8} w={['50%', '70%']} marginLeft={5} onClick={() => setIsDuplicated(false)}>
              {dictionary().PREVIOUS}
            </Button>
            <RefundConfirmationModel
              trigger={trigger}
              handleRefund={handleRefundTransaction}
              handleCancel={handleClose}
              isLoading={isRefundLoading}
              data={data}
              width={'70%'}
              isDataFetched={isRefundFetched}
              setIsDataFetched={setIsRefundedFetched}
            />
          </Flex>
        </>
      )}
    </>
  )
}

export default DuplicateRefund
