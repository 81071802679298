import React, { useEffect, useRef, useState } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputLeftElement } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'
import ImagesDropDown from './ImagesDropDown'

type AddVehicleTypeMutationVariables = {
  name_en: string
  name_ar: string
  icon: string
  dimensions_depth: number
  dimensions_width: number
  dimensions_height: number
  volume: number
  toll_amount: number
  max_weight: number
  toll_multiplier: number
  toll_weight_modifier: number
  code: string
  km_max: number
  km_min: number
  avg_consumption: any
}

const AddVehicleType: React.FC = () => {
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    watch,
  } = useForm({ mode: 'onChange' })

  const [icons, setIcons] = useState([])
  const [selectedIcon, setSelectedIcon] = useState('')
  const [isSubmit, setisSubmit] = useState(false)
  const kmMax = useRef({})
  kmMax.current = watch('km_max', 0)

  const { data, isFetched } = useQuery(['vehicleType'], () => fetch('GET', '/vehicle-types/icons'))

  const { isLoading, mutate } = useMutation((vars: any) => fetch('POST', '/vehicle-types', { data: vars }), {
    onSuccess: (): void => {
      push('/vehicle-types')
      onSuccess()
    },
    onError,
  })

  const addVehicleType = async (formData: AddVehicleTypeMutationVariables) => {
    formData.icon = selectedIcon
    formData.avg_consumption = +formData.avg_consumption
    formData.km_max = +formData.km_max
    formData.km_min = +formData.km_min
    mutate([formData])
  }

  useEffect(() => {
    data && setIcons(data)
  }, [data])

  useEffect(() => {
    setValue('km_max', 1500)
    setValue('km_min', 100)
    setValue('avg_consumption', 25)
  }, [])

  const handleSubmitStatus = () => {
    setisSubmit(true)
    return true
  }

  return (
    <Container>
      <Flex ref={headerRef} flexDir='column' className='margin-150'>
        <Header
          title={dictionary().ADD_NEW_VEHICLE}
          action={
            <SaveButton
              isLoading={isLoading}
              onClick={async () =>
                (await trigger()) && handleSubmitStatus() && selectedIcon !== '' && addVehicleType(getValues() as AddVehicleTypeMutationVariables)
              }
            />
          }
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(addVehicleType as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl
              id={dictionary().VEHICLE_TYPE_NAME_AR}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.name?.ar}
            >
              <FormLabel>
                {dictionary().VEHICLE_TYPE_NAME_AR}
                <Reqiured />
              </FormLabel>
              <Input type='string' {...register('name.ar', { required: dictionary().REQUIRED })} />
              <FormErrorMessage>{errors?.name?.ar?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().VEHICLE_TYPE_NAME_EN}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.name?.en}
            >
              <FormLabel>
                {dictionary().VEHICLE_TYPE_NAME_EN}
                <Reqiured />
              </FormLabel>
              <Input type='string' {...register('name.en', { required: dictionary().REQUIRED })} />
              <FormErrorMessage>{errors?.name?.en?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().DIMENSIONS_WIDTH}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.dimensions?.width}
            >
              <FormLabel>
                {dictionary().DIMENSIONS_WIDTH}
                <Reqiured />
              </FormLabel>
              <InputLeftElement height='100%' pointerEvents='none' padding={3}>
                {dictionary().METER}
              </InputLeftElement>
              <Input
                type='number'
                {...register('dimensions.width', {
                  required: dictionary().REQUIRED,
                  valueAsNumber: true,
                })}
              />
              <FormErrorMessage>{errors.dimensions?.width?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().DIMENSIONS_HEIGHT}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.dimensions?.height}
            >
              <FormLabel>
                {dictionary().DIMENSIONS_HEIGHT}
                <Reqiured />
              </FormLabel>
              <InputLeftElement height='100%' pointerEvents='none' padding={3}>
                {dictionary().METER}
              </InputLeftElement>
              <Input
                type='number'
                {...register('dimensions.height', {
                  required: dictionary().REQUIRED,
                  valueAsNumber: true,
                })}
              />
              <FormErrorMessage>{errors.dimensions?.height?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().DIMENSIONS_DEPTH}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.dimensions?.depth}
            >
              <FormLabel>
                {dictionary().DIMENSIONS_DEPTH}
                <Reqiured />
              </FormLabel>
              <InputLeftElement height='100%' pointerEvents='none' padding={3}>
                {dictionary().METER}
              </InputLeftElement>
              <Input
                type='number'
                {...register('dimensions.depth', {
                  required: dictionary().REQUIRED,
                  valueAsNumber: true,
                })}
              />
              <FormErrorMessage>{errors.dimensions?.depth?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id={dictionary().VOLUME} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.volume}>
              <FormLabel>{dictionary().VOLUME}</FormLabel>
              <InputLeftElement height='100%' pointerEvents='none' padding={3}>
                {dictionary().CUBIC_METER}
              </InputLeftElement>
              <Input type='number' {...register('volume', { valueAsNumber: true })} />
              <FormErrorMessage>{errors?.volume?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().TOLL_AMOUNT}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.toll_amount}
            >
              <FormLabel>{dictionary().TOLL_AMOUNT}</FormLabel>
              <InputLeftElement height='100%' pointerEvents='none' padding={3}>
                {dictionary().PRICING_CURRENCY}
              </InputLeftElement>
              <Input type='number' {...register('toll_amount', { valueAsNumber: true })} />
              <FormErrorMessage>{errors.toll_amount?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().MAX_WEIGHT}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.max_weight}
            >
              <FormLabel>{dictionary().MAX_WEIGHT}</FormLabel>
              <InputLeftElement height='100%' pointerEvents='none' padding={3}>
                {dictionary().TON}
              </InputLeftElement>
              <Input type='number' {...register('max_weight', { valueAsNumber: true })} />
              <FormErrorMessage>{errors.max_weight?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().TOLL_MULTIPLIER}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.toll_multiplier}
            >
              <FormLabel>
                {dictionary().TOLL_MULTIPLIER}
                <Reqiured />
              </FormLabel>
              <Input type='number' {...register('toll_multiplier', { required: dictionary().REQUIRED, valueAsNumber: true })} />
              <FormErrorMessage>{errors.toll_multiplier?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().TOLL_WEIGHT_MODIFIER}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.toll_weight_modifier}
            >
              <FormLabel>
                {dictionary().TOLL_WEIGHT_MODIFIER}
                <Reqiured />
              </FormLabel>
              <Input type='number' {...register('toll_weight_modifier', { required: dictionary().REQUIRED, valueAsNumber: true })} />
              <FormErrorMessage>{errors.toll_weight_modifier?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().CODE} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.code}>
              <FormLabel>
                {dictionary().CODE}
                <Reqiured />
              </FormLabel>
              <Input type='string' {...register('code', { required: dictionary().REQUIRED })} />
              <FormErrorMessage>{errors.code?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().MAXIMUM_DISTANCE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.km_max}
            >
              <FormLabel>
                {dictionary().MAXIMUM_DISTANCE}
                <Reqiured />
              </FormLabel>
              <InputLeftElement height='100%' pointerEvents='none' padding={3}>
                {dictionary().KM}
              </InputLeftElement>
              <Input
                placeholder='0'
                type='number'
                {...register('km_max', {
                  required: true && dictionary().REQUIRED,
                  pattern: {
                    value: /^\d+$/,
                    message: dictionary().THIS_INPUT_ACCEPTED_ONLY_INTEGER_NUMBERS,
                  },
                  min: {
                    value: 0,
                    message: dictionary().NO_NEGATIVE_NUMBER,
                  },
                })}
              />
              <FormErrorMessage>{errors.km_max?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().MINIMUM_DISTANCE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.km_min}
            >
              <FormLabel>
                {dictionary().MINIMUM_DISTANCE}
                <Reqiured />
              </FormLabel>
              <InputLeftElement height='100%' pointerEvents='none' padding={3}>
                {dictionary().KM}
              </InputLeftElement>
              <Input
                placeholder='0'
                type='number'
                {...register('km_min', {
                  required: true && dictionary().REQUIRED,
                  pattern: {
                    value: /^\d+$/,
                    message: dictionary().THIS_INPUT_ACCEPTED_ONLY_INTEGER_NUMBERS,
                  },
                  min: {
                    value: 0,
                    message: dictionary().NO_NEGATIVE_NUMBER,
                  },
                  validate: (value) => {
                    if (value > +kmMax.current) return dictionary().KM_MIN_LESS_THAN_KM_MAX
                  },
                })}
              />
              <FormErrorMessage>{errors.km_min?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().AVG_FUEL_CONSUMPTION}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.avg_consumption}
            >
              <FormLabel>
                {dictionary().AVG_FUEL_CONSUMPTION}
                <Reqiured />
              </FormLabel>
              <InputLeftElement height='100%' pointerEvents='none' padding={3}>
                {dictionary().LITERS_PER_HUNDRED_KM}
              </InputLeftElement>
              <Input
                placeholder='0'
                type='number'
                {...register('avg_consumption', {
                  required: true && dictionary().REQUIRED,
                  pattern: {
                    value: /^\d+$/,
                    message: dictionary().THIS_INPUT_ACCEPTED_ONLY_INTEGER_NUMBERS,
                  },
                  min: {
                    value: 0,
                    message: dictionary().NO_NEGATIVE_NUMBER,
                  },
                })}
              />
              <FormErrorMessage>{errors.avg_consumption?.message}</FormErrorMessage>
            </FormControl>
            <ImagesDropDown isSubmit={isSubmit} isFetched={isFetched} icons={icons} setSelectedIcon={setSelectedIcon} />
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddVehicleType
