import { Flex, Text } from '@chakra-ui/react'
import React, { useRef } from 'react'
import Header from '../../../components/core/Header'
import SaveButton from '../../../components/core/SaveButton'
import dictionary from '../../../dictionary'
import { addFuelTransaction } from '../utils'

type Props = {
  isLoading: boolean
  getValues: any
  corporateId: any
  stationProviderId: any
  stationId: any
  stateManager: any
  mutate: any
  trigger: any
}

const HeaderControl: React.FC<Props> = ({ isLoading, getValues, corporateId, stationProviderId, stationId, trigger, stateManager, mutate }) => {
  const headerRef = useRef<HTMLDivElement>(null)
  const handleSaveButtonClick = async () => {
    await trigger()
    addFuelTransaction(getValues(), corporateId, stationProviderId, stationId, getValues, stateManager, mutate)
  }

  return (
    <Flex ref={headerRef} flexDir='column' className='margin-150'>
      <Header title={dictionary().ADD_NEW_VEHICLE} action={<SaveButton isLoading={isLoading} onClick={handleSaveButtonClick} />} />
    </Flex>
  )
}

export default HeaderControl
