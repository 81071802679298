import { ArrowBackIcon, ArrowForwardIcon, DownloadIcon } from '@chakra-ui/icons'
import { Flex } from '@chakra-ui/react'
import { FC, useState } from 'react'
import downloadImage from './downloadImage'

type Props = {
  files: any
}

const ArrowStyle = {
  backgroundColor: ' #977ED6',
  color: ' white',
  border: ' none',
  padding: ' 5px',
  cursor: ' pointer',
  marginRight: ' 10px',
  borderRadius: ' 50%',
  width: ' 30px',
  height: ' 30px',
  marginBottom: ' 10px',
}

const PreviewFile: FC<Props> = ({ files }) => {
  const [selectedFileIndex, setSelectedFileIndex] = useState(0)
  const selectedFile = files?.[selectedFileIndex]
  const PDF_TYPE = ['application/pdf', 'pdf']
  const fileType = (selectedFile as string)?.split('/')?.[selectedFile?.split('/').length - 2]
  const handleNext = () => {
    if (selectedFileIndex < files?.length - 1) {
      setSelectedFileIndex(selectedFileIndex + 1)
    } else {
      setSelectedFileIndex(0)
    }
  }

  const handlePrevious = () => {
    if (selectedFileIndex > 0) {
      setSelectedFileIndex(selectedFileIndex - 1)
    } else {
      setSelectedFileIndex(files?.length - 1)
    }
  }

  return (
    <>
      <Flex justifyContent='space-between'>
        <div>
          <ArrowBackIcon onClick={handleNext} sx={ArrowStyle} />
          <ArrowForwardIcon onClick={handlePrevious} sx={ArrowStyle} />
          {!PDF_TYPE.includes(fileType) && <DownloadIcon onClick={() => downloadImage(selectedFile, 'pdf')} sx={ArrowStyle} />}
        </div>
        <p>
          ملف {selectedFileIndex + 1} من {files?.length}
        </p>
      </Flex>

      {!PDF_TYPE.includes(fileType) ? (
        <img
          src={selectedFile}
          alt='IMG'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
            borderRadius: '10px',
            border: '1px dashed rgba(133, 148, 177, 0.50)',
            minWidth: '100%',
            height: '318px',
            padding: '5px',
          }}
        />
      ) : (
        <iframe src={selectedFile} style={{ width: '100%', height: ' 600px' }} title={'pdf'} />
      )}
    </>
  )
}

export default PreviewFile
