import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { endOfDay, startOfDay, startOfMonth, add } from 'date-fns'
import { Box } from '@chakra-ui/react'

import { parse, stringify } from 'query-string'

import DateRange from '../../pages/stats/DateRange'
import { useFilterStore } from '../../stores/filterStore'

const SearchDate: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const query = parse(location.search)
  // const from = query?.from ? new Date(query.from as string) : startOfMonth(new Date())
  const { from, setFrom, to, setTo } = useFilterStore()
  // const to = query?.to ? new Date(query.to as string) : endOfDay(new Date())

  // const [status, setStatus] = useState<string>('CONFIRMED')
  // React.useEffect(() => {
  //   setStatus(query.status as string)
  // }, [query?.status])
  // if (status === 'CONFIRMED') {
  //   query.status = 'CONFIRMED'
  //   delete query.pump_reading_reviewed
  // }
  // if (!location?.search?.includes('from' as any, 'to' as any)) {
  //   query.from = startOfDay(new Date(from as string))?.toISOString()
  //   ;(query.to = endOfDay(new Date(to as string))?.toISOString()), history.replace({ search: stringify(query) })
  // }

  return (
    <div className='customDatePickerWidth'>
      <Box
        style={{
          width: '100%',
        }}
      >
        <DateRange
          from={new Date(from as string)}
          onChange={(newDate: [Date, Date]) => {
            setFrom(startOfDay(newDate[0])?.toISOString())
            setTo(endOfDay(newDate[1])?.toISOString())
            history.push({
              search: stringify({
                ...query,
                from: startOfDay(newDate[0])?.toISOString(),
                to: endOfDay(newDate[1])?.toISOString(),
              }),
            })
          }}
          to={new Date(to as string)}
        />
      </Box>
    </div>
  )
}

export default SearchDate
