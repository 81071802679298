import dictionary from '../../dictionary'
import { ROLES } from '../ROLES'

export const getCorporateStaffHeaders = (role: string | null): any => {
  switch (role) {
    case ROLES.ACCOUNT_MANAGER:
      return [
        {
          id: 'corporateStaffID',
          key: 'corporateStaffID',
          name: dictionary().ID,
          type: 'text',
        },
        {
          id: 'email',
          key: 'email',
          name: dictionary().EMAIL,
          type: 'text',
        },
        {
          id: 'name',
          key: 'name.ar',
          name: dictionary().NAME,
          type: 'text',
        },
        {
          id: 'role',
          key: 'role',
          name: dictionary().ROLE,
          type: 'text',
        },

        {
          id: 'openDashboard',
          key: 'openDashboard',
          name: dictionary().DASHBOARD,
          type: 'modal',
          // payload: { name: 'تغيير' },
        },
      ]
      break
    case ROLES.SUPER_ADMIN:
      return [
        {
          id: 'corporateStaffID',
          key: 'corporateStaffID',
          name: dictionary().ID,
          type: 'text',
        },
        {
          id: 'email',
          key: 'email',
          name: dictionary().EMAIL,
          type: 'text',
        },
        {
          id: 'name',
          key: 'name.ar',
          name: dictionary().NAME,
          type: 'text',
        },
        {
          id: 'role',
          key: 'role',
          name: dictionary().ROLE,
          type: 'text',
        },
        {
          id: 'resetPassword',
          key: 'resetPassword',
          name: dictionary().PASSWORD,
          type: 'modal',
          payload: { name: 'تغيير' },
        },
        {
          id: 'openDashboard',
          key: 'openDashboard',
          name: dictionary().DASHBOARD,
          type: 'modal',
          // payload: { name: 'تغيير' },
        },
        {
          id: 'forceLogout',
          key: 'forceLogout',
          name: dictionary().ACTIONS,
          type: 'modal',
          // payload: { name: 'تغيير' },
        },
      ]
      break
    case ROLES.MANAGER:
      return [
        {
          id: 'corporateStaffID',
          key: 'corporateStaffID',
          name: dictionary().ID,
          type: 'text',
        },
        {
          id: 'email',
          key: 'email',
          name: dictionary().EMAIL,
          type: 'text',
        },
        {
          id: 'name',
          key: 'name.ar',
          name: dictionary().NAME,
          type: 'text',
        },
        {
          id: 'role',
          key: 'role',
          name: dictionary().ROLE,
          type: 'text',
        },
        {
          id: 'resetPassword',
          key: 'resetPassword',
          name: dictionary().PASSWORD,
          type: 'modal',
          payload: { name: 'تغيير' },
        },
        {
          id: 'openDashboard',
          key: 'openDashboard',
          name: dictionary().DASHBOARD,
          type: 'modal',
          // payload: { name: 'تغيير' },
        },
      ]
    default:
      return [
        {
          id: 'corporateStaffID',
          key: 'corporateStaffID',
          name: dictionary().ID,
          type: 'text',
        },
        {
          id: 'email',
          key: 'email',
          name: dictionary().EMAIL,
          type: 'text',
        },
        {
          id: 'name',
          key: 'name.ar',
          name: dictionary().NAME,
          type: 'text',
        },
        {
          id: 'role',
          key: 'role',
          name: dictionary().ROLE,
          type: 'text',
        },
        {
          id: 'resetPassword',
          key: 'resetPassword',
          name: dictionary().PASSWORD,
          type: 'modal',
          payload: { name: 'تغيير' },
        },
        // {
        //   id: 'openDashboard',
        //   key: 'openDashboard',
        //   name: dictionary().DASHBOARD,
        //   type: 'modal',
        //   // payload: { name: 'تغيير' },
        // },
      ]
      break
  }
}
