import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Radio, RadioGroup, Spinner, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import Card from '../../components/core/Card'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Required from '../../components/core/reqiured'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'

const RestoreTransactionImage: React.FC = () => {
  const { onSuccess } = useAlert()
  const {
    formState: { errors },
    control,
    trigger,
    getValues,
  } = useForm({
    mode: 'onChange',
  })

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/fuel-transactions/upload-from-backup?lang=ar', { data: vars }), {
    onSuccess: (): void => {
      onSuccess(dictionary().RESTORED_IMAGE_SUCCESSFULLY)
    },
  })

  const handleSubmit = async (formData: any) => {
    formData.id = formData.id[0]
    mutate({ ...formData })
  }

  return (
    <Container>
      <Flex className='margin-150' flexDir='column'>
        <Header title={dictionary().REFUND_TRANSACTION} />
      </Flex>
      <Card p='8' marginTop={['20px', '0px', '20px']} style={{ minHeight: '85vh' }}>
        <form>
          <Flex className='margin-100' justify='center' align='center' direction='column'>
            <Text fontSize={20}>{dictionary().RESTORE_TRANSACTION_IMAGE}</Text>
            <FormControl id={dictionary().TRANSACTION_NUMBER} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.id}>
              <FormLabel>
                {dictionary().TRANSACTION_NUMBER} <Required />
                <span style={{ color: '#a9a5a5', fontSize: '12px' }}>{dictionary().ENETER_THREE_DIGITS_TO_SEARCH}</span>
              </FormLabel>
              <Controller
                name='id'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    onChange={onChange}
                    searchRoute='/fuel-transactions/searchById'
                    singleRoute='/fuel-transactions/searchById'
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.id}
                    isInvalid={!!errors?.id}
                    value={value}
                    pageSize={10}
                    inputType='number'
                    minInputLengthToSearch={3}
                  />
                )}
                rules={{
                  required: true && dictionary().REQUIRED,
                }}
              />
              <FormErrorMessage>{errors.id?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id='type' w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.type}>
              <FormLabel>
                {dictionary().IMAGE_TYPE} <Required />
              </FormLabel>
              <Controller
                name='type'
                control={control}
                rules={{
                  required: true && dictionary().REQUIRED,
                }}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction='row'>
                      <Radio value='odometer' color='purple'>
                        {dictionary().VEHICLE_ODOMETER_IMAGE}
                      </Radio>
                      <Radio value='pump' color='purple'>
                        {dictionary().STATION_PUMP_IMAGE}
                      </Radio>
                      <Radio value='reset' color='purple' hidden></Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>{errors.type?.message}</FormErrorMessage>
            </FormControl>
            {error && <b style={{ width: '70%', color: 'red' }}>{(error as any)?.data?.message}</b>}
            <Button
              colorScheme='blue'
              disabled={isLoading}
              variant={'solid'}
              w={['100%', '70%', '70%', '70%']}
              onClick={async () => (await trigger()) && handleSubmit(getValues() as any)}
            >
              {isLoading ? <Spinner /> : dictionary().RESTOR_IMAGE}
            </Button>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default RestoreTransactionImage
