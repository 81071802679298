import { CheckCircleIcon } from '@chakra-ui/icons'
import { Flex, Icon, Text } from '@chakra-ui/react'
import { BsXCircleFill } from 'react-icons/bs'
import { useLocation } from 'react-router-dom'
import dictionary from '../../dictionary'
import { getReadingStatus } from '../../helpers/getReadingStatus'
import { FuelTransaction } from '../../types/FuelTransaction'
import CommentModel from '../core/CommentModel'
import ImageModal from '../core/ImageModal'
import { OctaneTableBuilder } from '../core/OctaneTable/OctaneTableBuilder'
import UpdateOdometerReadingModal from '../core/UpdateOdometerReadingModal'
import { getFuelTransactionHeaders } from '../../constants/fuelTransactionHeaders'
import financialNums from '../../helpers/financialNums'
import { useFilterStore } from './../../stores/filterStore'
import ConfirmTransactionModel from './../core/ConfirmTransactionModel'
import { getPumpReadingValue } from './../../helpers/getPumpReading'

type FuelTransactionTableProps = {
  data: any
  loading: boolean
}

const FuelTransactionTable = ({ data, loading }: FuelTransactionTableProps): React.ReactNode => {
  const { pathname } = useLocation()
  const filterStore = useFilterStore()
  const FUEL_TRANSACTION_HEADERS = getFuelTransactionHeaders(filterStore)
  const tableBuilder = new OctaneTableBuilder(data, FUEL_TRANSACTION_HEADERS)
    .addSpecialFields('id', (row: FuelTransaction) => {
      return <p style={{ fontWeight: 600 }}>{row?.id}</p>
    })
    .addSpecialFields('pump_image', (row: FuelTransaction) => {
      return <ImageModal pump srcValue={row?.pump_image} data={row} header={{ key: 'images.pump' }} />
    })
    .addSpecialFields('odometer_image', (row: FuelTransaction) => {
      return <ImageModal data={row} srcValue={row?.odometer_image} header={{ key: 'odometer' }} pump={false} />
    })
    .addSpecialFields('comment', (row: FuelTransaction) => {
      return <CommentModel data={row} />
    })
    .addSpecialFields('updateOdometerReadingModal', (row: FuelTransaction) => {
      return <UpdateOdometerReadingModal data={row} />
    })
    .addSpecialFields('odometer_reading', (row: FuelTransaction) => getReadingStatus(row, 'odometer_reading'))
    .addSpecialFields('pump_reading_value', (row: FuelTransaction) => getPumpReadingValue(row, row.pump_reading_value))
    .addSpecialFields('ConfirmTransactionModel', (row: FuelTransaction) => <ConfirmTransactionModel data={row} />)
    .addSpecialFields('is_fraud', (row: FuelTransaction) => {
      const isFraud = row?.is_fraud
      return (
        <>{isFraud === null ? dictionary().IS_READING : isFraud === 'true' ? <CheckCircleIcon color='green.500' /> : <CheckCircleIcon color='red.500' />}</>
      )
    })
    .addSpecialFields('fraud_reason', (row: FuelTransaction) => {
      return <>{row?.fraud_reason === 'No liters' ? dictionary().NO_LITERS : row?.fraud_reason === 'mobile image' ? dictionary().MOBILE_IMAGE : '-'}</>
    })

    .addSpecialFields('station_tips', (row: FuelTransaction) => {
      return new Intl.NumberFormat('en-US').format(Number(row?.station_tips))
    })
    .addSpecialFields('station_tips_refund', (row: FuelTransaction) => {
      return new Intl.NumberFormat('en-US').format(Number(row?.station_tips_refund))
    })
    .addSpecialFields('station_tips_vat', (row: FuelTransaction) => {
      return new Intl.NumberFormat('en-US').format(Number(row?.station_tips_vat))
    })
    .addSpecialFields('station_tips_vat_refund', (row: FuelTransaction) => {
      return new Intl.NumberFormat('en-US').format(Number(row?.station_tips_vat_refund))
    })
    .addSpecialFields('station_total_amount', (row: FuelTransaction) => {
      return new Intl.NumberFormat('en-US').format(Number(row?.station_total_amount))
    })

  const fuelTransactionTable = tableBuilder.build()

  return pathname === '/fuel-transactions' && data && !loading && data?.data?.length !== 0 ? (
    fuelTransactionTable
  ) : pathname === '/fuel-transactions' && !loading && data && data?.data?.length == 0 ? (
    <Flex w='100%' h='100%' align='center' justify='center'>
      <Text fontSize='2xl'>
        {dictionary().NO_RESULTS_FOUND} {dictionary().IN} {dictionary().FUEL_TRANSACTIONS}
      </Text>
    </Flex>
  ) : (
    pathname === '/fuel-transactions' && (
      <Flex w='100%' h='100%' align='center' justify='center'>
        <Text fontSize='2xl'>
          {dictionary().SEARCH_LOADING} {dictionary().IN} {dictionary().FUEL_TRANSACTIONS}
        </Text>
      </Flex>
    )
  )
}

export default FuelTransactionTable
