import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { FC } from 'react'
import { Controller } from 'react-hook-form'
import AutoCompleteInput from '../../../components/core/AutoCompleteInput'
import Required from '../../../components/core/reqiured'
import dictionary from '../../../dictionary'

type AutoCompleteControlProps = {
  errors: any
  control: any
  identifier: string
  label: string
  route: string
  displayFormatter: any
  width?: string
  boxProps?: any
  labelStyle?: any
  dataTest?: string
}

const AutoCompleteControl: FC<AutoCompleteControlProps> = ({
  dataTest,
  labelStyle,
  boxProps,
  errors,
  control,
  identifier,
  label,
  route,
  displayFormatter,
  width,
}) => {
  return (
    <FormControl id={dictionary().CORPORATE} w={width || ['100%', '70%', '70%', '70%']} isInvalid={!!errors[identifier]}>
      <FormLabel style={{ ...labelStyle }}>
        {label} <Required />
      </FormLabel>
      <Controller
        name={identifier}
        rules={{ required: dictionary().REQUIRED }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <AutoCompleteInput
            isInvalid={!!errors[identifier]}
            onChange={onChange}
            value={value}
            searchRoute={route}
            dataTest={dataTest}
            singleRoute={route}
            valueFormatter={(option) => option?.id}
            displayFormatter={displayFormatter}
            boxProps={boxProps}
          />
        )}
      />
      <FormErrorMessage>{errors?.[identifier]?.message}</FormErrorMessage>
    </FormControl>
  )
}

export default AutoCompleteControl
