import { Flex, FormControl, FormErrorMessage, FormLabel, Hide, Input, InputGroup, InputLeftElement, Select, Textarea } from '@chakra-ui/react'
import { format } from 'date-fns'
import { parse } from 'query-string'
import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import Card from '../../components/core/Card'
import ConfirmationModel from '../../components/core/ConfirmationModel'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Reqiured from '../../components/core/reqiured'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'

const AddWithdraw: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' })

  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const query = parse(location.search, { parseBooleans: true })

  const { id } = useParams<{ id: string }>()
  const { onSuccess, onError } = useAlert()
  const restApi = `/ledger-transactions/withdraw`

  const entityData = useQuery([query, 'corporates'], () => fetch('GET', `/corporates/${id}`))
  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', restApi, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push(`/corporates/${id}`)
    },
    onError,
  })

  const addPayments = async (formData: any) => {
    formData.entity_id = +id
    formData.entity_type = 'CORPORATE'
    mutate([formData])
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Hide below='769px'>
          <Header title='' />
        </Hide>
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(addPayments as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            {' '}
            <FormControl
              id={dictionary().SERVICE_TYPE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.payment_method}
            >
              <FormLabel>
                {dictionary().SERVICE_TYPE}
                <Reqiured />
              </FormLabel>
              <InputGroup>
                <Select
                  dir='ltr'
                  defaultValue={'VEHICLES_SUBSCRIPTION'}
                  textAlign='right'
                  {...register('payment_method', {
                    required: dictionary().REQUIRED,
                  })}
                >
                  <option key={1} value='VEHICLES_SUBSCRIPTION'>
                    {dictionary().VEHICLE_SUBSCRIPTION}
                  </option>
                </Select>
              </InputGroup>
              <FormErrorMessage>{errors?.payment_method?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().SERVICE_FEE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.amount || error?.data?.message[0] === 'amount should not be empty'}
            >
              <FormLabel>
                <>
                  {dictionary().SERVICE_FEE}
                  <Reqiured />
                </>
              </FormLabel>

              <InputGroup>
                <InputLeftElement height='100%' pointerEvents='none'>
                  {dictionary().PRICING_CURRENCY}
                </InputLeftElement>
                <Input
                  type='number'
                  {...register('amount', {
                    required: dictionary().REQUIRED,
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.amount?.message}</FormErrorMessage>
              <FormErrorMessage>
                {error?.data?.message === 'balance is less than the transaction amount' && dictionary().BALANCE_IS_LESS_THAN_TRANSACTION_AMOUNT}
              </FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().DATE} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors?.date}>
              <FormLabel>
                {dictionary().DATE}
                <Reqiured />
              </FormLabel>

              <InputGroup>
                <Input
                  defaultValue={format(new Date(), 'yyyy-MM-dd')}
                  type='date'
                  {...register('date', {
                    required: dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.date?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().COMMENT} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} isInvalid={!!errors.note}>
              <FormLabel>{dictionary().COMMENT}</FormLabel>
              <Textarea
                size='sm'
                height='150px'
                fontSize='md'
                p='0'
                {...register('note', {
                  required: false,
                })}
              />
              <FormErrorMessage>{errors.note?.message}</FormErrorMessage>
            </FormControl>
            <ConfirmationModel
              trigger={trigger}
              addPayments={addPayments}
              getValues={getValues}
              isLoading={isLoading}
              data={[entityData?.data]}
              isDataFetched={[entityData?.isFetched]}
              width={['100%', '85%', '85%', '85%']}
              message={dictionary().WITHDRAW_BALANCE_FROM}
              label={dictionary().DEBIT}
            />
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddWithdraw
