import { GridItem, Grid, Text, FormControl, FormErrorMessage, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { FC } from 'react'
import { Controller } from 'react-hook-form'
import dictionary from '../../../dictionary'
import Card from '../Card'

type Props = {
  errors: any
  control: any
}

const AfterTrial: FC<Props> = ({ errors, control }) => {
  return (
    <Card p={5}>
      <Grid templateColumns='repeat(12, 1fr)' gap={4} pb={4}>
        <GridItem colSpan={[12]}>
          <Text
            style={{
              fontFamily: 'Noto Kufi Arabic',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'right',
            }}
            mb={5}
            pb={5}
            pr={2}
            borderBottom='1px solid rgba(226, 232, 240, 1)'
          >
            {dictionary().AFTER_TRIAL}
          </Text>
        </GridItem>
        <GridItem colSpan={[12]} mb={2}>
          <Text fontSize='19px' fontWeight='600'>
            <FormControl id={dictionary().AFTER_TRIAL} w='50%' isInvalid={!!errors?.post_trial_path}>
              <Controller
                name={'post_trial_path'}
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack
                      direction='row'
                      gap={8}
                      color='#808080'
                      mb={2}
                      style={{
                        fontFamily: 'Noto Kufi Arabic',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        textAlign: 'left',
                      }}
                    >
                      <Radio value={'AFTER'} color='purple' alignItems={'center'}>
                        {dictionary().REPEAT_EXPERIMENT_AND_DETERMINE_FEES}
                      </Radio>
                      <Radio value={'STOP'} color='purple' alignItems={'center'}>
                        {dictionary().STOP_EXPERIMENT}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
                rules={{
                  required: true && dictionary().REQUIRED,
                }}
              />
              <FormErrorMessage>{errors?.post_trial_path?.message}</FormErrorMessage>
            </FormControl>
          </Text>
        </GridItem>
      </Grid>
    </Card>
  )
}

export default AfterTrial
