import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'driverName',
    key: 'driver.name.ar',
    name: dictionary().DRIVER_NAME,
    type: 'text',
  },
  {
    id: 'corporateName',
    key: 'corporate.name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'plate_number',
    key: 'vehicle.number_plate',
    name: dictionary().NUMBER_PLATE,
    type: 'text',
  },
  {
    id: 'vehicle_code',
    key: 'vehicle.code',
    name: dictionary().VEHICLE_CODE,
    type: 'text',
  },
  {
    id: 'product',
    key: 'store_product.name.ar',
    name: dictionary().PRODUCT_NAME,
    type: 'text',
  },
  {
    id: 'station_staff',
    key: 'station_staff.name.ar',
    name: dictionary().STAFF_NAME,
    type: 'text',
  },
  {
    id: 'station',
    key: 'station.name.ar',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().AMOUNT,
    type: 'cash',
  },
  {
    id: 'status',
    key: 'status',
    name: dictionary().STATUS,
    type: 'text',
  },
  {
    id: 'date_created',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'time_created',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
]

const StoreTransactions: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().STORE_TRANSACTIONS}
      to='/store-transactions/new'
      tags={[
        { name: dictionary().ALL, filter: [{ name: '', value: '' }] },
        { name: dictionary().CONFIRMED, filter: [{ name: 'status', value: 'CONFIRMED' }] },
      ]}
      hasSearch={true}
      hasSearchDate={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['store-transaction']}
      countArrayPath={['count']}
      restRoute={'/store-transactions'}
      cashingKey={CASHING_KEY.GET_STORE_TRANSACTIONS}
      enableIdRedirect={false}
    />
  )
}

export default StoreTransactions
