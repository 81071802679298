export const VERSION_STATUS = {
  CREATED: 'CREATED',
  PENDING_ADJUSTMENTS: 'PENDING_ADJUSTMENTS',
  REVIEW_NEEDED: 'REVIEW_NEEDED',
  REVIEWED: 'REVIEWED',
  CONFIRMED: 'CONFIRMED',
  SIGNED_OFF: 'SIGNED_OFF',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  REJECTED: 'REJECTED',
}

export type VERSION_STATUS_TYPE = 'CREATED' | 'REVIEWED' | 'CONFIRMED' | 'SIGNED_OFF' | 'ACTIVE' | 'EXPIRED'
