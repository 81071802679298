import React from 'react'
import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().PAYMENT_ID,
    type: 'text',
  },
  {
    id: 'corporate.name',
    key: 'corporate.name',
    name: dictionary().CORPORATE_NAME,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().AMOUNT,
    type: 'cash',
  },
  {
    id: 'date',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date_UTC',
  },
  {
    id: 'time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time_UTC',
  },
  {
    id: 'payment_method',
    key: 'payment_method',
    name: dictionary().PAYMENT_METHOD,
    type: 'text',
  },
]

const ConfirmedPayments: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().PAYMENTS}
      hasSearch={false}
      hasSearchDate={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['payment-details']}
      countArrayPath={['count']}
      restRoute={'/corporates/payments'}
      cashingKey={CASHING_KEY.GET_CONFITMED_PAYMENTS}
      enableIdRedirect={false}
    />
  )
}

export default ConfirmedPayments
