import React from 'react'

import ViewOne from '../../components/layout/ViewOne'
import { CASHING_KEY } from '../../constants'
import { getCorporateGroupActions } from '../../constants/actions/corporateGroup'
import dictionary from '../../dictionary'
import { store, useStore } from '../../store'

const header = [
  {
    id: 'name',
    key: 'name',
    name: dictionary().NAME,
    type: 'text',
  },
  {
    id: 'use_shifts',
    key: 'use_shifts',
    name: dictionary().SHIFTS,
    type: 'boolean',
  },
  {
    id: 'shift_starting',
    key: 'shift_starting',
    name: dictionary().SHIFT_START_TIME,
    type: 'text',
  },
  {
    id: 'consumption_measurement',
    key: 'consumption_measurement',
    name: dictionary().CONSUMPTION_MEASUREMENT,
    type: 'text',
  },
  {
    id: 'all_vehicles_access',
    key: 'all_vehicles_access',
    name: dictionary().DRIVERS,
    type: 'boolean',
  },
  {
    id: 'date',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
]

const { role, userType } = store.getState()
if (role === 'SUPER' && userType === 'admin') {
  header.splice(5, 0, {
    id: 'deposit_confirmation_type',
    key: 'deposit_confirmation_type',
    name: dictionary().DEPOSIT_CONFIGURATION,
    type: 'text',
  })
}
const CorporateGroupPage: React.FC = () => {
  const userData = window.localStorage.getItem('activeRole')

  const actions = getCorporateGroupActions(role)

  return (
    <ViewOne
      actions={actions}
      header={header}
      restRoute='/corporate-group'
      dataLocation={''}
      title={dictionary().CORPORATE_GROUPS}
      cashingKey={CASHING_KEY.GET_SINGLE_CORPORATE_GROUP}
      userType='corporate-group'
    />
  )
}

export default CorporateGroupPage
