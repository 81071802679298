import React, { createContext, useState, useCallback, useContext } from 'react'
import {
  ButtonProps,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useDisclosure,
} from '@chakra-ui/react'

import dictionary from '../dictionary'

const ConfirmContext = createContext((onConfirm = () => {}, options: Options = {}) => {}) // eslint-disable-line

export interface Options {
  title?: string
  description?: string
  confirmationText?: string
  cancellationText?: string
  confirmationButtonProps?: ButtonProps
  cancellationButtonProps?: ButtonProps
}

const DEFAULT_OPTIONS: Options = {
  title: dictionary().DEFAULT_CONFIRM_TITLE,
  description: dictionary().DEFAULT_CONFIRM_DESCRIPTION,
  confirmationText: dictionary().DEFAULT_CONFIRM_CONFIRMATION_TEXT,
  cancellationText: dictionary().DEFAULT_CONFIRM_CANCELLATION_TEXT,
  confirmationButtonProps: {},
  cancellationButtonProps: {},
}

interface DialogProps {
  options: Options
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onConfirm: () => void
}

const ConfirmationDialog: React.FC<DialogProps> = ({ options, isOpen, isLoading, onClose, onConfirm }) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null)

  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {options.title}
            </AlertDialogHeader>

            <AlertDialogBody>{options.description}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} {...options.cancellationButtonProps}>
                {options.cancellationText}
              </Button>
              <Button colorScheme='error' onClick={onConfirm} ml={3} {...options.confirmationButtonProps} isLoading={isLoading}>
                {options.confirmationText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export const ConfirmProvider: React.FC = ({ children }) => {
  const [options, setOptions] = useState(DEFAULT_OPTIONS)
  const [onConfirm, setOnConfirm] = useState<() => void>(() => {})
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const confirm = useCallback((onConfirm = () => {}, options: Options = {}) => {
    return (() => {
      setOptions({ ...DEFAULT_OPTIONS, ...options })
      setOnConfirm(() => onConfirm)
      onOpen()
    })()
  }, [])

  const handleClose = useCallback(() => {
    setOnConfirm(() => {})
    onClose()
  }, [])

  const handleCancel = useCallback(() => {
    handleClose()
  }, [handleClose])

  const handleConfirm = useCallback(async () => {
    setIsLoading(true)
    await onConfirm()
    handleClose()
    setIsLoading(false)
  }, [onConfirm, handleClose])

  return (
    <>
      <ConfirmContext.Provider value={confirm}>{children}</ConfirmContext.Provider>
      <ConfirmationDialog isOpen={isOpen} options={options} onClose={handleCancel} onConfirm={handleConfirm} isLoading={isLoading} />
    </>
  )
}

export const useConfirm = (): ((onConfirm: () => void, options?: Options) => void) => useContext(ConfirmContext)
