export function formatArabicDate(dateString: string) {
  const months = ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر']

  const date = new Date(dateString)

  const day = date.getUTCDate()
  const month = months[date.getUTCMonth()]
  const year = date.getUTCFullYear()

  let hours = date.getUTCHours()
  let minutes: any = date.getUTCMinutes()

  const period = hours >= 12 ? 'مساءً' : 'صباحاً'
  hours = hours % 12 || 12 // Convert to 12-hour format
  minutes = minutes < 10 ? '0' + minutes : minutes // Add leading zero if needed

  const formattedDate = ` ${day} ${month} ${year} `
  const formattedTime = `- ${hours}:${minutes} ${period}`

  return `${formattedDate} ${formattedTime}`
}
