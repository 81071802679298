import { WarningIcon } from '@chakra-ui/icons'
// eslint-disable-next-line import/named
import { Flex, Tooltip } from '@chakra-ui/react'
import dictionary from '../dictionary'
import financialNums from './financialNums'

export const getReadingStatus = (row: any, type: string): JSX.Element => {
  const value = type === 'odometer_reading' ? row?.odometer_reading : row?.pump_reading_value
  const status = type === 'odometer_reading' ? row?.indicator_message : row?.pump_reading

  if (value == null && (status === null || status === 'NULL')) {
    return <>-</>
  }

  let color = ''
  let label = ''
  if (status === 'VALID' || (type === 'pump_reading' && status === null && value !== null)) {
    color = 'green.400'
    label = dictionary().CONFIRMED
  } else if (status === 'NULL' || status === null) {
    color = 'orange.400'
    label = dictionary().PENDING
  } else if (status === 'INVALID' || status === 'NOT_READABLE') {
    color = 'red.500'
    label = dictionary().NOT_READABLE
  } else if (status === 'MISSING') {
    color = 'gray.500'
    label = dictionary().NO_IMG
  }

  return !['VARIANCE_REFUND', 'DUPLICATE_REFUND'].includes(row?.correction_status) ? (
    <Flex alignItems='center'>
      <Tooltip label={label} placement='top' fontSize='md'>
        <WarningIcon color={color} marginLeft='5px' />
      </Tooltip>
      {value === 'null' || value === null ? <>-</> : financialNums(value)}
    </Flex>
  ) : (
    <Flex alignItems='center'>{value === 'null' || value === null ? <>-</> : financialNums(value)}</Flex>
  )
}
