import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import { getCorporateAddNew } from '../../constants/addNewButton/corporate'
import dictionary from '../../dictionary'
import { useStore } from '../../store'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'corporates_name',
    key: 'name',
    name: dictionary().NAME,
    type: 'text',
  },
  {
    id: 'corporateGroupId',
    key: 'corporateGroupId',
    name: dictionary().CORPORATES_GROUP,
    type: 'text',
  },
  {
    id: 'industry',
    key: 'industry',
    name: dictionary().INDUSTRY,
    type: 'text',
  },
  // {
  //   id: 'tax_id',
  //   key: 'tax_id',
  //   name: dictionary().TAX_ID,
  //   type: 'text',
  // },
  {
    id: 'settlement_type',
    key: 'settlement',
    name: dictionary().SETTLEMENT_TYPE,
    type: 'text',
  },
  {
    id: 'credit_limit',
    key: 'credit_limit',
    name: dictionary().CREDIT_LIMIT,
    type: 'text',
  },
  {
    id: 'threshold',
    key: 'threshold',
    name: dictionary().THRESHOLD,
    type: 'text',
  },
  {
    id: 'cash_balance',
    key: 'ledger_accounts',
    name: dictionary().TOTAL_DUE,
    type: 'Cash_balance',
    isRight: true,
  },
  {
    id: 'consumed_balance',
    key: 'ledger_accounts',
    name: dictionary().CONSUMED_BALANCE,
    type: 'Consumed_balance',
    isRight: true,
  },
  {
    id: 'shift_starting',
    key: 'shift_starting',
    name: dictionary().SHIFT_START_TIME,
    type: 'text',
  },
  {
    id: 'corporates_date_created',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'corporates_time_created',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'is_demo',
    key: 'is_demo',
    name: dictionary().TYPE,
    type: 'boolean',
  },
]

const Corporates: React.FC = () => {
  const userData = window.localStorage.getItem('activeRole')
  const { role } = useStore()

  return (
    <ViewAll
      headers={headers}
      title={dictionary().CORPORATES}
      to='/corporates/new'
      tags={[
        { name: dictionary().ALL, filter: [{ name: '', value: '' }] },
        { name: dictionary().IS_ACTIVE, filter: [{ name: 'is_active', value: true }] },
        { name: dictionary().NOT_ACTIVE, filter: [{ name: 'is_active', value: false }] },
      ]}
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['toll-station-prices']}
      countArrayPath={['count']}
      restRoute={'/corporates'}
      cashingKey={CASHING_KEY.GET_CORPORATES}
      addNew={getCorporateAddNew(role ?? '')}
    />
  )
}

export default Corporates
