import React, { useRef, useEffect } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'


const AddNewCustomerStaff: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/customer-staff', { data: vars }), {
    onSuccess: (): void => {
      push(`/corporates/${id}`)

      onSuccess()
    },
    onError,
  })
  useEffect(() => {
    setValue('corporate_id', id)
  }, [])
  const AddNewStaff = async () => {
    mutate([
      {
        name: getValues()?.name,
      },
    ])
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header title={dictionary().ADD_NEW_STAFF} action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && AddNewStaff()} />} />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(AddNewStaff as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl id={dictionary().NAME} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.name}>
              <FormLabel>
                {dictionary().NAME} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('name', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddNewCustomerStaff
