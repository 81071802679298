import { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Modal,
  Spinner,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormLabel,
  Button,
  FormControl,
  useDisclosure,
  MenuItem,
  Flex,
  FormErrorMessage,
} from '@chakra-ui/react'
import useAlert from '../../helpers/useAlert'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import Reqiured from '../../components/core/reqiured'

interface Props {
  action?: any
  handleIsCorporateSPUnbilled?: () => void
}
const UnbilledCorporateForStationProvider: React.FC<Props> = ({ action, handleIsCorporateSPUnbilled }) => {
  const { id } = useParams<{ id: string }>()

  const [isRenderd, setIsRenderd] = useState(false)
  const { onSuccess, onError } = useAlert()
  const { isLoading, mutate } = useMutation((vars: any) => fetch('POST', `/corporates/assign-to-station-providers`, { data: vars }), {
    onSuccess: (): void => {
      handleIsCorporateSPUnbilled && handleIsCorporateSPUnbilled()
      onSuccess()
      onClose()
    },
    onError,
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    trigger,
    setValue,
  } = useForm({ mode: 'onChange' })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const AssignDriver = async (formData: any) => {
    formData.corporateId = +id
    mutate(formData)
  }
  useEffect(() => {
    const stationProviderIds: any[] = []
    if (isOpen) {
      fetch('GET', `/corporate-station-provider-charge-config?corporateId=${id}`).then((data) => {
        if (data?.data?.length > 0) {
          data?.data.map((item: any) => stationProviderIds.push(item?.stationProvider?.id))
          setValue('stationProviderIds', stationProviderIds)
        }
      })
    }
  }, [isOpen])

  return (
    <>
      <MenuItem
        onClick={() => {
          onOpen()
          setIsRenderd(!isRenderd)
        }}
      >
        {action.title}
      </MenuItem>

      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{action?.title} </ModalHeader>
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit(AssignDriver as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                <FormControl id={dictionary().STATION_PROVIDERS} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.driverIds}>
                  <FormLabel style={{ direction: 'rtl' }}>
                    <>{dictionary().STATION_PROVIDERS}</>
                  </FormLabel>

                  <Controller
                    name={`stationProviderIds`}
                    rules={{ required: false }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        isInvalid={!!errors?.stationProviderIds}
                        onChange={onChange}
                        value={value}
                        searchRoute={`/station-providers`}
                        singleRoute={`/station-providers`}
                        valueFormatter={(option) => option?.id}
                        multiple={true}
                      />
                    )}
                  />

                  <FormErrorMessage>{errors.stationProviderIds?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button onClick={async () => (await trigger()) && AssignDriver(getValues() as any)} colorScheme='blue' mr={3}>
              {isLoading ? <Spinner /> : dictionary().SAVE}
            </Button>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UnbilledCorporateForStationProvider
