import React, { useEffect, useRef } from 'react'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import dictionary from '../../dictionary'
import AutoCompleteInput from './AutoCompleteInput'

interface Props {
  setSelectedStationProvider: (val: any) => void
}

const StationProviderDropList: React.FC<Props> = ({ setSelectedStationProvider }) => {
  const {
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useForm({ mode: 'onChange' })

  const stationProvider = useRef({})
  stationProvider.current = watch('stationProvider', '')
  const queryParams = new URLSearchParams(window.location.search)
  const stationProviderId = queryParams.get('stationProviderId')

  useEffect(() => {
    if (typeof stationProvider.current !== null && stationProvider.current !== undefined && stationProvider.current !== '') {
      setSelectedStationProvider(getValues()?.stationProvider[0])
    }
  }, [stationProvider.current])

  useEffect(() => {
    stationProviderId && setValue('stationProvider', [stationProviderId])
  }, [])

  return (
    <FormControl id={dictionary().CHOOSE_STATION_PROVIDER} w='40%' display='inline-block'>
      <FormLabel>{dictionary().CHOOSE_STATION_PROVIDER}</FormLabel>
      <Controller
        name={`stationProvider`}
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <AutoCompleteInput
            isInvalid={!!errors?.second_ref_id}
            onChange={onChange}
            value={value}
            searchRoute='/station-providers'
            singleRoute='/station-providers'
            valueFormatter={(option) => option?.id}
            displayFormatter={(option) => option?.name?.ar}
          />
        )}
      />
    </FormControl>
  )
}

export default StationProviderDropList
