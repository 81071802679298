import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

const Card: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box borderRadius='lg' boxShadow='sm' borderWidth='1px' bg='white' maxW='100%' {...props}>
      {children}
    </Box>
  )
}

export default Card
