import React from 'react'
import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'paymentCorporateName',
    key: 'entity',
    name: dictionary().CORPORATE_NAME,
    type: 'text',
  },
  {
    id: 'date',
    key: 'created_at',
    name: dictionary().CREATED_AT,
    type: 'date',
  },
  {
    id: 'date',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'date',
    key: 'date',
    name: dictionary().DEPOSIT_DATE,
    type: 'date',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().ENTERED_AMOUNT,
    type: 'cash',
  },
  {
    id: 'amount',
    key: 'actual_amount',
    name: dictionary().ACTUAL_AMOUNT,
    type: 'cash',
  },
  {
    id: 'reason',
    key: 'reason',
    name: dictionary().REASON,
    type: 'text',
  },
  // {
  //   id: 'reason',
  //   key: 'reason',
  //   name: dictionary().REJECTION_REASON,
  //   type: 'text',
  // },
  {
    id: 'bank_name',
    key: 'bank_name',
    name: dictionary().BANK_NAME,
    type: 'text',
  },
  {
    id: 'deposit_type',
    key: 'deposit_type',
    name: dictionary().DEPOSIT_TYPE,
    type: 'text',
  },
  {
    id: 'status',
    key: 'status',
    name: dictionary().STATUS,
    type: 'text',
  },
  {
    id: 'comment',
    key: 'comment',
    name: dictionary().COMMENT,
    type: 'modal',
  },
  {
    id: 'paymentImage',
    key: 'image',
    name: dictionary().RELATED_IMAGES,
    type: 'modal',
  },
]

const PaymentsDetails: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().PAYMENTS}
      hasSearch={false}
      hasSearchDate={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['payment-details']}
      countArrayPath={['count']}
      restRoute={'/deposit-request/filter'}
      cashingKey={CASHING_KEY.GET_PAYMENTS_DETAILS}
      enableIdRedirect={false}
    />
  )
}

export default PaymentsDetails
