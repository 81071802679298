import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { Flex, Divider, Avatar, Menu, MenuButton, MenuItem, MenuList, Button, Icon } from '@chakra-ui/react'
import { FiLogOut, FiLock } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { useStore } from '../../store'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'
import useAuth from '../../hooks/useAuth'

const UserMenu: React.FC = () => {
  const { setToken, userType, setUserType } = useStore((state) => state)
  const { refetch, data } = useQuery([`admin`], () => fetch('GET', `/admin/current`), {
    enabled: false,
  })
  const { signOut } = useAuth()
  useEffect(() => {
    refetch()
  }, [])

  const { push } = useHistory()
  let usernameAr = ''
  const corporateName = data?.corporate?.name
  let userTypeAr
  switch (userType) {
    case 'corporate-staff':
      userTypeAr = dictionary().CORPORATE
      usernameAr = data?.corporateStaff?.corporate?.name
      break
    case 'station-provider-staff':
      userTypeAr = dictionary().STATION_PROVIDER
      usernameAr = data?.stationProviderStaff?.station_provider?.name?.ar
      break
    case 'station-staff':
      userTypeAr = dictionary().STATION
      usernameAr = data?.stationStaff?.station?.name.ar
      break
    case 'admin':
      userTypeAr = dictionary().ADMINS
      usernameAr = data?.name?.ar
      break
  }
  return (
    <Flex p='5%' pos='absolute' bottom='0' flexDir='column' w='100%' alignItems='flex-start' mb={2}>
      <Divider display='flex' />
      <span className=' pulse'></span>

      <Menu>
        <MenuButton as={Button} variant='ghost' h='100%' w='100%' display='flex' mt={4} alignItems='center' py={2} className='sidebarItems'>
          <Flex align='center'>
            <Avatar size='sm' />
            <Flex flexDir='column' alignItems='flex-end' mb={2}>
              {userType === 'corporate-staff' ? (
                <MenuItem>
                  {userTypeAr}: {corporateName}{' '}
                </MenuItem>
              ) : userType === 'station-provider-staff' ? (
                <MenuItem>{userTypeAr}</MenuItem>
              ) : userType === 'station-staff' ? (
                <MenuItem>{userTypeAr}</MenuItem>
              ) : userType === 'admin' ? (
                <MenuItem>{userTypeAr}</MenuItem>
              ) : (
                <MenuItem> </MenuItem>
              )}
              <MenuItem>{usernameAr} </MenuItem>
            </Flex>
          </Flex>
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => {
              push('/change-password')
            }}
            icon={<Icon as={FiLock} />}
          >
            تغيير كلمة المرور
          </MenuItem>
          <MenuItem onClick={() => signOut()} icon={<Icon as={FiLogOut} />}>
            {dictionary().LOGOUT}
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )
}

export default UserMenu
