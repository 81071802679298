import { ROLES } from './ROLES'

export const canDownload = (role: string | null): any => {
  switch (role) {
    case ROLES.ACCOUNT_MANAGER:
      return false
    default:
      return true
  }
}
