import { FC, useEffect, useRef } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Text,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import filterIconSrc from '../../assets/filter.svg'
import dictionary from '../../dictionary'
import { arrayToQueryString } from '../../helpers/arrayToQueryString'
import useQueryParams from '../../hooks/useQueryParams '
import AutoCompleteInput from './AutoCompleteInput'
import { CustomSelect } from './CustomSelectInput'

type ChargingProfileFilterModalProps = any

const ChargingProfileFilterModal: FC<ChargingProfileFilterModalProps> = ({ isOpen, onOpen, onClose }: any) => {
  const { add, remove } = useQueryParams()

  // const { setCorporateIds, setStationIds, setVehicleIds, setDriverIds, setStationId, setCorporateId, setAdvancedSearch } = useFilterStore()
  const searchRef = useRef<HTMLButtonElement>(null)
  const {
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
  } = useForm({ mode: 'onChange' })

  const fieldValues = {
    // transaction_id: watch('transaction_id', []),
    corporate: watch('corporate', []),
    profile_type: watch('profile_type', []),
    profile_status: watch('profile_status', []),
    version_number: watch('version_number', []),
    creator: watch('creator', []),
    reviewer: watch('reviewer', []),
    confirmer: watch('confirmer', []),
    signer: watch('signer', []),
    vehicle_code: watch('vehicle_code', []),
    driver_name: watch('driver_name', []),
    driver_code: watch('driver_code', []),
  }

  useEffect(() => {
    setValue('corporate', [])
    setValue('profile_type', [])
    setValue('profile_status', [])
    setValue('creator', [])
    setValue('reviewer', [])
    setValue('confirmer', [])
    setValue('signer', [])
    setValue('version_number', [])
    setValue('vehicle_code', [])
    setValue('driver_name', [])
    setValue('driver_code', [])
  }, [])

  const clearAll = () => {
    remove('corporate_id')
    remove('type')
    remove('status')
    remove('creator_id')
    remove('reviewer_id')
    remove('confirmer_id')
    remove('signer_id')

    Object.keys(fieldValues).forEach((field) => setValue(field, []))
    // ;[setCorporateIds, setStationIds, setVehicleIds, setDriverIds].forEach((setIds) => setIds([]))
    // setAdvancedSearch(false)
    onClose()
  }

  const submit = async () => {
    remove('corporate_id')
    remove('type')
    remove('status')
    remove('creator_id')
    remove('reviewer_id')
    remove('confirmer_id')
    remove('signer_id')

    getValues('corporate')?.length > 0 && add('corporate_id', getValues('corporate'))
    getValues('profile_type')?.length > 0 && add('type', getValues('profile_type'))
    getValues('profile_status')?.length > 0 &&
      add('status', getValues('profile_status')[0] === 'CREATED' ? ['CREATED', 'REVIEW_NEEDED'] : getValues('profile_status'))
    getValues('creator')?.length > 0 && add('creator_id', getValues('creator'))
    getValues('reviewer')?.length > 0 && add('reviewer_id', getValues('reviewer'))
    getValues('confirmer')?.length > 0 && add('confirmer_id', getValues('confirmer'))
    getValues('signer')?.length > 0 && add('signer_id', getValues('signer'))

    onClose()
  }

  return (
    <>
      <Box position='relative' display='inline-block' sx={{ mr: '10px' }}>
        <Button onClick={onOpen} variant='outline' style={{ backgroundColor: '#7165E3' }} _focus={{ boxShadow: 'none' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
            <Text style={{ color: 'white' }}>{dictionary().ADVANCED_FILTERATION}</Text>
            <Image src={filterIconSrc} width={25} height={25} />
          </div>
        </Button>
        {/* Red dot indicator */}
        {Object.values(fieldValues).some((value) => value?.length > 0) && (
          <Box position='absolute' top='-1' right='-1' width='15px' height='15px' borderRadius='50%' backgroundColor='red'></Box>
        )}
      </Box>
      <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={searchRef} onClose={submit} isOpen={isOpen} isCentered autoFocus colorScheme={'purple'}>
        <AlertDialogOverlay />
        <AlertDialogContent dir='rtl' maxW='900px'>
          <AlertDialogHeader>{dictionary().ADVANCED_FILTERATION}</AlertDialogHeader>
          <AlertDialogBody>
            {/* <FormControl id={dictionary().TRANSACTION_ID} w='100%' dir='rtl' marginTop={5}>
              <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                {dictionary().TRANSACTION_ID}{' '}
                <Text
                  style={{
                    width: '20px',
                    height: '20px',
                    color: '#7165E3',
                    borderRadius: '50%',
                    backgroundColor: '#EDF2F7',
                    textAlign: 'center',
                    fontSize: '12px',
                  }}
                >
                  {fieldValues.transaction_id?.length || 0}
                </Text>
              </FormLabel>
              <Controller
                name={`transaction_id`}
                rules={{ required: false }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    isInvalid={!!errors?.second_ref_id}
                    onChange={onChange}
                    value={value}
                    searchRoute='/fuel-transactions'
                    singleRoute='/fuel-transactions'
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.id}
                    multiple
                    boxProps={{ maxHeight: '40px', overflowY: 'auto' }}
                  />
                )}
              />
            </FormControl> */}
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
              <FormControl id={dictionary().CHOOSE_CORPORATE} w='40%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().CHOOSE_CORPORATE}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.corporate?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`corporate`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      isInvalid={!!errors?.second_ref_id}
                      onChange={onChange}
                      value={value}
                      searchRoute='/corporates'
                      singleRoute='/corporates'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name}
                      multiple
                      boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                    />
                  )}
                />
              </FormControl>
              <FormControl id={dictionary().PROFILE_TYPE} w='30%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().PROFILE_TYPE}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.profile_type?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`profile_type`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      placeholder='اختر نوع السجل'
                      options={[
                        { label: dictionary().CHARGED, value: 'CHARGED' },
                        { label: dictionary().PAID_TRIAL, value: 'PAID_TRIAL' },
                        { label: dictionary().FREE_TRIAL, value: 'FREE_TRIAL' },
                      ]}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </FormControl>
              <FormControl id={dictionary().PROFILE_STATUS} w='30%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().PROFILE_STATUS}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.profile_status?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`profile_status`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      placeholder={'اختر حالة السجل'}
                      options={[
                        { label: dictionary().NEEDS_REVIEW, value: 'CREATED' },
                        { label: dictionary().NEEDS_CONFIRMATION, value: 'REVIEWED' },
                        { label: dictionary().NEEDS_SIGNOFF, value: 'CONFIRMED' },
                        { label: dictionary().PENDING_ADJUSTMENTS, value: 'PENDING_ADJUSTMENTS' },
                        { label: dictionary().INACTIVE, value: 'SIGNED_OFF' },
                        { label: dictionary().ACTIVE_STATUS, value: 'ACTIVE' },
                        { label: dictionary().EXPIRED_STATUS, value: 'EXPIRED' },
                        { label: dictionary().REJECTED, value: 'REJECTED' },
                      ]}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
              {' '}
              <FormControl id={dictionary().VERSION_CREATOR} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().VERSION_CREATOR}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.creator?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`creator`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      isInvalid={!!errors?.creator}
                      onChange={onChange}
                      value={value}
                      searchRoute='/admin'
                      singleRoute='/admin'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name?.en}
                      multiple={true}
                      boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                    />
                  )}
                />
              </FormControl>
              <FormControl id={dictionary().VERSION_REVIEWER} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().VERSION_REVIEWER}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.reviewer?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`reviewer`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      isInvalid={!!errors?.reviewer}
                      onChange={onChange}
                      value={value}
                      searchRoute='/admin'
                      singleRoute='/admin'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name?.en}
                      multiple={true}
                      boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
              <FormControl id={dictionary().VERSION_CONFIRMER} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().VERSION_CONFIRMER}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.confirmer?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`confirmer`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      isInvalid={!!errors?.confirmer}
                      onChange={onChange}
                      value={value}
                      searchRoute='/admin'
                      singleRoute='/admin'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name?.en}
                      multiple={true}
                      boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                    />
                  )}
                />
              </FormControl>
              <FormControl id={dictionary().SIGNED_OF_BY} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().SIGNED_OF_BY}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.signer?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`signer`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      isInvalid={!!errors?.signer}
                      onChange={onChange}
                      value={value}
                      searchRoute='/admin'
                      singleRoute='/admin'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name?.en}
                      multiple={true}
                      boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant={'outline'} onClick={onClose}>
              {dictionary().CANCEL}
            </Button>
            <Button mr='3' style={{ backgroundColor: '#E0E0E0' }} variant={'solid'} onClick={() => clearAll()}>
              {dictionary().CLEAR_ALL}
            </Button>
            <Button ref={searchRef} mr='3' colorScheme='blue' variant={'solid'} onClick={() => submit()}>
              {dictionary().SEARCH}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default ChargingProfileFilterModal
