import { useToast } from '@chakra-ui/react'
import dictionary from '../dictionary'

type responseType = any

const useAlert = (): {
  onError: (error: responseType | any) => void
  onSuccess: (successMessage?: string) => void
  showInfoMessage: (infoMessage?: string) => void
} => {
  const toast = useToast()
  const onError = (error: responseType) => {
    toast({
      containerStyle: {
        direction: 'rtl',
      },
      description: error || dictionary().ERROR,
      status: 'error',
      duration: 20000,
      position: 'top',
      isClosable: true,
    })
  }
  const onSuccess = (successMessage?: string) => {
    toast({
      description: successMessage || dictionary().SUCCESS,
      containerStyle: {
        direction: 'rtl',
      },
      status: 'success',
      duration: 3000,
      position: 'top',
      isClosable: true,
    })
  }
  const showInfoMessage = (infoMessage?: string) => {
    toast({
      description: infoMessage || '',
      status: 'info',
      duration: 3000,
      position: 'top',
      isClosable: true,
    })
  }
  return { onError, onSuccess, showInfoMessage }
}

export default useAlert
