import { IFilterStore } from './../stores/filterStore'

const filtersList = [
  'from',
  'positiveValues',
  'status',
  'to',
  'stationId',
  'corporateId',
  'id',
  'transactionIds',
  'corporateIds',
  'stationIds',
  'vehicleIds',
  'driverIds',
]

export const getFilters = (state: IFilterStore) => {
  const filters: any = {}
  for (const key in state) {
    const value: any = state[key as keyof IFilterStore]
    if (filtersList.includes(key) && value !== null) {
      if (typeof value === 'object') {
        value.length > 0 && (filters[key] = value)
      } else {
        filters[key] = value
      }
    }
  }

  return {
    filters,
    language: state.language,
    sort_column: state.sort_column,
    sort_direction: state.sort_direction,
    page: state.page,
    page_size: state.page_size,
  }
}
