import { Box, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { useLocation } from 'react-router-dom'
import PaymentsFilterModel from '../PaymentsReview/PaymentsFilterModel'
import ReviewTransactionsFilterModel from './ReviewTransactionsFilteModel'

const AdvancedFilter = () => {
  const { pathname } = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const getAdvancedFilter = () => {
    switch (pathname) {
      case '/payments-details':
        return <PaymentsFilterModel isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      case '/transactions-reviewed':
        return <ReviewTransactionsFilterModel isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    }
  }

  return <Box mr='20px'>{getAdvancedFilter()}</Box>
}

export default AdvancedFilter
