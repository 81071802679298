import React from 'react'
import { Flex, FlexProps } from '@chakra-ui/react'

type Props = {
  width?: string | number
  height?: string | number
  disableMargin?: boolean
} & FlexProps

const Container: React.FC<Props> = ({ children, width, height, disableMargin, ...props }) => {
  return (
    <Flex
      flexDir='column'
      width={width ?? '100%'}
      height={height ?? '100%'}
      bg='#f1f5f9'
      p='8'
      className={disableMargin ? undefined : 'margin-50'}
      {...props}
    >
      {children}
    </Flex>
  )
}

export default Container
