import React from 'react'
import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import dictionary from '../../dictionary'
import Actions from './Actions'

interface Props {
  redirect?: any
  actions?: any
  id?: number
  handleIsBalancesChange?: () => void
  handleIsDriverAssigned?: () => void
  handleIsCorporateSPUnbilled?: () => void
  handleIsCorporateAccessSP?: () => void
  handleIsBalanseChanged?: () => void
  data?: any
}

const ActionsButton: React.FC<Props> = ({
  id,
  redirect,
  actions,
  handleIsBalancesChange,
  handleIsDriverAssigned,
  handleIsCorporateSPUnbilled,
  handleIsCorporateAccessSP,
  handleIsBalanseChanged,
  data,
}) => {
  return (
    <Menu matchWidth>
      <MenuButton mr='10px' as={Button} rightIcon={<ChevronDownIcon />} colorScheme='blue' h='48px' letterSpacing='widest'>
        {dictionary().ACTIONS}
      </MenuButton>
      <MenuList boxShadow='lg' zIndex={100} data-test='actions'>
        {actions?.map((action: any) => (
          <Actions
            id={id}
            key={action.title}
            action={action}
            redirect={redirect}
            handleIsBalancesChange={handleIsBalancesChange}
            handleIsDriverAssigned={handleIsDriverAssigned}
            handleIsCorporateSPUnbilled={handleIsCorporateSPUnbilled}
            handleIsCorporateAccessSP={handleIsCorporateAccessSP}
            handleIsBalanseChanged={handleIsBalanseChanged}
            data={data}
          />
        ))}
      </MenuList>
    </Menu>
  )
}

export default ActionsButton
