import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { parse, stringify } from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import fetch from '../../../helpers/fetch'

import dictionary from '../../../dictionary'
import useAlert from '../../../helpers/useAlert'
import ZoomImage from '../ZoomImage'
import Required from '../reqiured'

interface Props {
  data?: any
  handleIsBalancesChange?: () => void
}

const FuelPumpDataEntryModal: React.FC<Props> = ({ data, handleIsBalancesChange }) => {
  const [isSaveClicked, setIsSaveClicked] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [isNoImgClicked, setIsNoImgClicked] = useState(false)
  const [isEnaleUpdat, setIsEnaleUpdat] = useState(true)
  const [isFraud, setIsFraud] = useState<any>(data?.is_fraud)

  const { onSuccess, onError } = useAlert()

  const { push } = useHistory()
  const query = parse(location.search, { parseNumbers: true, parseBooleans: true })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [liters, setLiters] = useState(null)

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', `/fuel-transactions/${data.id}/pump-reading`, { data: vars }), {
    onSuccess: (): void => {
      handleIsBalancesChange && handleIsBalancesChange()
      onSuccess()
      onClose()
      push({ search: stringify(query) })
    },
    onError,
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    watch,
    control,
  } = useForm({ mode: 'onChange' })

  const litersValue = watch('liters', '')

  useEffect(() => {
    setValue('fraud_reason', data?.fraud_reason)
  }, [isOpen, isFraud])

  useEffect(() => {
    litersValue !== undefined && setLiters(litersValue)
  }, [litersValue])

  const Save = async () => {
    setIsClicked(true)
    const values: { [key: string]: any } = {
      pump_reading_error_status: 'NOT_READABLE',
    }
    values.is_fraud = isFraud
    if (isFraud) {
      values.fraud_reason = getValues().fraud_reason
    }
    mutate(values)
    isLoading && setIsClicked(false)
  }

  const SaveNoImg = async () => {
    setIsNoImgClicked(true)
    const values: { [key: string]: any } = {
      pump_reading_error_status: 'MISSING',
    }
    values.is_fraud = isFraud
    if (isFraud) {
      values.fraud_reason = getValues().fraud_reason
    }
    mutate(values)
    isLoading && setIsNoImgClicked(false)
  }

  const Update = async () => {
    const pumpReading = data?.station?.use_liters ? Math.round(Number(liters) * data?.fuel_type?.price * 100) / 100 : getValues()?.pump_reading
    setIsSaveClicked(true)
    const values: { [key: string]: any } = {
      pump: +pumpReading,
    }
    values.is_fraud = isFraud
    if (isFraud) {
      values.fraud_reason = getValues().fraud_reason
    }
    mutate(values)
    isLoading && setIsSaveClicked(false)
  }

  const handleOpen = () => {
    onOpen()
    setIsSaveClicked(false)
    setIsClicked(false)
    setIsNoImgClicked(false)
    errors?.pump_reading && delete errors?.pump_reading
  }

  const checkEnableUpdate = () => {
    data.correction_status != 'NORMAL' && setIsEnaleUpdat(false)
  }

  useEffect(() => {
    checkEnableUpdate()
  }, [])

  useEffect(() => {
    setValue('pump_reading', data?.pump_reading)
    setIsFraud(data?.is_fraud)
    data?.pump_reading && setValue('liters', data?.pump_reading / data?.fuel_type?.price)
  }, [data, isOpen])

  const finalRef = useRef(null)

  return (
    <>
      <Button data-test='view-pump-image' onClick={handleOpen}>
        {dictionary().VIEW_IMAGE}
      </Button>

      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent sx={{ minWidth: '600px' }}>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().RELATED_IMAGES} </ModalHeader>
          <ModalBody pb={6}>
            <Box display='flex' flexDirection={'column'} alignItems='center'>
              <h2 style={{ display: 'block', textAlign: 'center', fontWeight: 'bold' }}>{dictionary().PUMP_IMG}</h2>
              {data?.images?.pump ? (
                <div>
                  <ZoomImage data={data} src={data?.images?.pump} pump={true} />
                  <div dir='rtl'>
                    <p>
                      {dictionary().USE_LITERS}: {data?.station?.use_liters ? dictionary().YES : dictionary().NO}
                    </p>
                    <p>
                      {dictionary().PRICE}: {data?.fuel_type?.price}
                    </p>
                  </div>
                </div>
              ) : (
                <h3>{dictionary().NO_IMG} </h3>
              )}
            </Box>

            <form dir='rtl' onSubmit={handleSubmit(Update as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                {data?.station?.use_liters && (
                  <FormControl id={dictionary().LITERS} w='90%' minH='100px' display='inline-block' isInvalid={!!errors.liters}>
                    <FormLabel>{dictionary().LITERS}</FormLabel>
                    <InputGroup size='md'>
                      <Input
                        data-test={dictionary().LITERS}
                        pr='1rem'
                        {...register('liters', {
                          required: dictionary().REQUIRED,
                          min: {
                            value: 0,
                            message: dictionary().NO_NEGATIVE_NUMBER,
                          },
                          valueAsNumber: true,
                        })}
                        type={'number'}
                      />
                    </InputGroup>
                    <FormErrorMessage>{errors.liters?.message}</FormErrorMessage>
                  </FormControl>
                )}
                <FormControl id={dictionary().AMOUNT} w='90%' minH='90px' display='inline-block' isInvalid={!!errors.pump_reading}>
                  <FormLabel>{dictionary().AMOUNT}</FormLabel>
                  <InputGroup size='md'>
                    <Input
                      data-test={dictionary().AMOUNT}
                      pr='1rem'
                      autoFocus
                      disabled={data?.station?.use_liters}
                      value={data?.station?.use_liters ? (liters !== null ? Math.round(liters * data?.fuel_type?.price * 100) / 100 : 0) : undefined}
                      {...register('pump_reading', {
                        required: !data?.station?.use_liters && dictionary().REQUIRED,
                        min: {
                          value: 0,
                          message: dictionary().NO_NEGATIVE_NUMBER,
                        },
                        pattern: {
                          value: /^(?!.*[^\d.])\d*\.?\d+$/,
                          message: dictionary().THIS_INPUT_ACCEPTED_ONLY_NUMBERS,
                        },
                      })}
                      type='text'
                    />
                  </InputGroup>
                  <FormErrorMessage>{errors.pump_reading?.message}</FormErrorMessage>
                </FormControl>
                <FormControl id={dictionary().LITERS} w='90%' display='inline-block'>
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', alignItems: 'center' }}>
                    <Alert
                      status='error'
                      sx={
                        isFraud ? { height: '40px', borderRadius: '4px' } : { backgroundColor: '#f9fafb', border: '0px', height: '40px', borderRadius: '4px' }
                      }
                    >
                      <Checkbox
                        data-test={dictionary().FRAUD}
                        isInvalid={isFraud ? true : false}
                        onChange={(e) => {
                          setIsFraud(e.target.checked)
                        }}
                        isChecked={isFraud !== null ? isFraud : false}
                        spacing='0.5rem'
                        ml={'0.5rem'}
                        size='lg'
                        colorScheme={'error'}
                        textAlign={'center'}
                        alignItems={'center'}
                      />
                      <AlertTitle>{dictionary().FRAUD}</AlertTitle>
                      <AlertDescription>{dictionary().FRAUD_SENTENCE}</AlertDescription>
                    </Alert>
                  </Box>
                </FormControl>
                {!isEnaleUpdat && <span style={{ color: 'red', fontWeight: 'bold' }}>{dictionary().CAN_NOT_UPDATE_PUMP_READING_THIS_TRANSACTION}</span>}
                {isFraud && (
                  <FormControl id='fraud_reason' w='90%' isInvalid={!!errors.fraud_reason}>
                    <FormLabel>
                      {dictionary().FRAUD_REASON} <Required />
                    </FormLabel>
                    <Controller
                      name='fraud_reason'
                      control={control}
                      // defaultValue='NO_LITERS'
                      render={({ field }) => (
                        <RadioGroup {...field} data-test={dictionary().FRAUD_REASON}>
                          <Stack direction='row'>
                            <Radio value='No liters' color='purple'>
                              {dictionary().NO_LITERS}
                            </Radio>
                            <Radio value='mobile image' color='purple'>
                              {dictionary().MOBILE_IMAGE}
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                      rules={{
                        required: true && dictionary().REQUIRED,
                      }}
                    />
                    <FormErrorMessage>{errors.fraud_reason?.message}</FormErrorMessage>
                  </FormControl>
                )}
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button verticalAlign={'center'} onClick={onClose} mr={3}>
              <Text mt={-1}>{dictionary().CANCEL}</Text>
            </Button>
            <Button
              verticalAlign={'center'}
              data-test={dictionary().SAVE}
              onClick={async () => (await trigger()) && Update()}
              colorScheme='blue'
              disabled={!isEnaleUpdat}
              mr={3}
            >
              <Text mt={-1}>{isSaveClicked ? <Spinner /> : dictionary().SAVE}</Text>
            </Button>
            <Button verticalAlign={'center'} onClick={async () => SaveNoImg()} colorScheme='blue' disabled={!isEnaleUpdat} mr={3} textAlign={'center'}>
              <Text mt={-1}>{isNoImgClicked ? <Spinner /> : dictionary().NO_IMG}</Text>
            </Button>
            <Button verticalAlign={'center'} onClick={async () => Save()} colorScheme='blue' disabled={!isEnaleUpdat}>
              <Text mt={-1}>{isClicked ? <Spinner /> : dictionary().CANT_READ}</Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default FuelPumpDataEntryModal
