import { useParams } from 'react-router'
import { useQuery } from 'react-query'
import fetch from '../../helpers/fetch'

import ViewAll from '../../components/layout/ViewAll'
import dictionary from '../../dictionary'
import { CASHING_KEY } from '../../constants'

const headers = [
  {
    id: 'ledger_transaction_date',
    key: 'created_at',
    name: dictionary().TRANSACTION_DATE,
    type: 'date',
  },
  {
    id: 'ledger_transaction_time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'credit_balance',
    key: 'credit_balance',
    name: dictionary().DEBIT_BALANCE,
    type: 'ledger_transaction',
  },
  {
    id: 'debit_balance',
    key: 'debit_balance',
    name: dictionary().CREDIT_BALANCE,
    type: 'ledger_transaction',
  },
  {
    id: 'balance',
    key: 'balance',
    name: dictionary().DRIVER_BALANCE,
    type: 'ledger_transaction',
  },
]

const LedgerTransactionsByID: React.FC = () => {
  const { account_id } = useParams<{ account_id: string }>()

  const query = {}
  let restRoute = '/drivers'
  const { userType, id } = useParams<{ userType: string; id: string }>()
  if (userType === 'corporates') {
    restRoute = '/corporates'
  } else if (userType === 'drivers') {
    restRoute = '/drivers'
  } else if (userType === 'vehicles') {
    restRoute = '/vehicles'
  } else if (userType === 'stations') {
    restRoute = '/stations'
  } else if (userType === 'station-providers') {
    restRoute = '/station-providers'
  }
  const data = useQuery([query], async () => await fetch('GET', `${restRoute}/${parseInt(id)}`), {})

  return (
    <ViewAll
      headers={headers}
      title={dictionary().STATEMENT}
      to=''
      hasSearch={false}
      dataArrayPath={['/ledger-transactions']}
      countArrayPath={['count']}
      restRoute={`/ledger-transactions/${account_id}`}
      cashingKey={CASHING_KEY.GET_LEDGER_TRANSACTIONS_FOR_ID}
      dataOne={data}
    />
  )
}

export default LedgerTransactionsByID
