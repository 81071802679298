import { Button, CircularProgress, Spinner, useToast } from '@chakra-ui/react'
import * as jose from 'jose'
import { FC, useEffect, useState } from 'react'
import { FiCodesandbox } from 'react-icons/fi'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import { useStore } from './../../store'

type ViewDashboardProps = {
  data?: any
}

const ViewDashboard: FC<ViewDashboardProps> = ({ data }) => {
  const { pathname } = useLocation()
  const { token } = useStore()
  const [staffData, setStaffData] = useState<any>({})
  const toast = useToast()
  const userData = window.localStorage.getItem('activeRole')
  const adminUserName = userData && JSON.parse(userData)?.username

  const route = pathname.startsWith('/corporates/') ? `/corporate-staff/current-corporate-staff` : `/corporate-group-staff/current-corporate-group-staff`

  const {
    isLoading: loadingGetCorporateStaff,
    mutate: mutateGetCorporateStaff,
    error: errorGetCorporateStaff,
  } = useMutation(() => fetch('GET', `${route}?id=${data?.id}`), {
    onSuccess: (data: any): void => {
      setStaffData(data)
    },
    onError: (error: any) => {
      toast({
        containerStyle: {
          direction: 'rtl',
        },
        title: dictionary().ERROR,
        description: error?.data?.message,
        status: 'error',
        duration: 3000,
        position: 'top',
      })
    },
  })

  useEffect(() => {
    mutateGetCorporateStaff()
  }, [])

  return (
    <Button
      disabled={loadingGetCorporateStaff || errorGetCorporateStaff}
      sx={{
        ':hover': { bg: '#B8B2F1' },
        ':focus': { border: 'none', boxShadow: 'none' },
      }}
      // ml='40px'
      colorScheme='blue'
      variant={'outline'}
      backgroundColor={'#fff'}
      h='48px'
      letterSpacing='widest'
      color={'#7165E3'}
      onClick={async () => {
        if (token) {
          const secret = new TextEncoder().encode(import.meta.env.VITE_CORPORATE_JWT_SECRET)
          const alg = 'HS256'

          const hashedToken = await new jose.SignJWT({
            token,
            admin: {
              username: adminUserName,
            },
            activeRole: { ...staffData },
          })
            .setProtectedHeader({ alg })
            .setExpirationTime('1h')
            .sign(secret)
          window.open(
            pathname.startsWith('/corporates/')
              ? import.meta.env.VITE_CORPORATE_DASHBOARD_URL + `/admin-redirect?token=${hashedToken}`
              : import.meta.env.VITE_CORPORATE_GROUP_DASHBOARD_URL + `/admin-redirect?token=${hashedToken}`,
            '_blank',
          )
        }
      }}
    >
      {loadingGetCorporateStaff ? (
        <CircularProgress isIndeterminate color='blue.500' />
      ) : (
        <>
          <FiCodesandbox size={'20px'} style={{ marginLeft: '10px' }} />
          <text>{dictionary().VIEW_DASHBOARD}</text>
        </>
      )}
    </Button>
  )
}

export default ViewDashboard
