import React, { useEffect } from 'react'
import { HStack, Stat, StatArrow, StatHelpText, SimpleGrid, StatLabel, StatNumber, Box, Container } from '@chakra-ui/react'
import { useQuery } from 'react-query'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import { useStore } from '../../store'
import financialNums from '../../helpers/financialNums'
import formatAsCurrency from '../../helpers/formatAsCurrency'
import { SummaryItemProps } from '../../interfaces/summaryItemProps'
import BalanceBox from './BalanceBox'

export const SummaryItem: React.FC<SummaryItemProps> = ({ label, value, helperText, sty }) => {
  return (
    <Box bg='white' borderRadius={10} p={4} paddingRight={4} boxShadow={'rgb(112 144 176 / 18%) 14px 17px 40px 4px'} style={sty}>
      <Stat>
        <StatLabel>{label}</StatLabel>
        <StatNumber>{value}</StatNumber>
        {helperText && (
          <StatHelpText>
            <StatArrow type='increase' />
            {helperText}%
          </StatHelpText>
        )}
      </Stat>
    </Box>
  )
}

const Summary: React.FC<{ from: any; to: any; restRoute: any }> = ({ from, to, restRoute }) => {
  const { userType } = useStore((state) => state)
  const summaryStats = useQuery(['summary'], () => fetch('GET', `/${restRoute}/summary?from=${from}&to=${to}`), {})
  const vehiclesStats = useQuery(['vehiclesStats'], () => fetch('GET', `/vehicles/progress/{date}?from=${from}&to=${to}`), {})
  const corporateStats = useQuery(['corps'], () => fetch('GET', `/corporates/progress/{date}?from=${from}&to=${to}`), {})
  useEffect(() => {
    summaryStats.refetch()
    vehiclesStats.refetch()
    corporateStats.refetch()
  }, [from, to])
  return (
    <HStack>
      (
      <>
        <Container maxWidth={'100%'} pr={0}>
          <SimpleGrid columns={[1, 5, 5]} spacing='4' margin={0} pr={0}>
            {userType !== 'admin' ? (
              <>
                <BalanceBox />
              </>
            ) : (
              <></>
            )}
            <SummaryItem
              label={dictionary().TOTAL_INCOME}
              value={`${formatAsCurrency(summaryStats?.data?.summary?.transactions_total_amount || 0)} `}
              helperText={!isNaN(summaryStats?.data?.progress?.totalIncomeProgress) ? (summaryStats?.data?.progress?.totalIncomeProgress as any) : '-'}
            />
            <SummaryItem
              label={dictionary().TOTAL_TRANSACTION_COUNT}
              value={`${financialNums(summaryStats?.data?.summary?.transactions_total_count || 0)} `}
              helperText={
                !isNaN(summaryStats?.data?.progress?.totalTransactionsCountsProgress)
                  ? (summaryStats?.data?.progress?.totalTransactionsCountsProgress as any)
                  : '-'
              }
            />
            <SummaryItem
              label={dictionary().AVERAGE_TRANSACTIONS_NUMBER_PER_DAY}
              value={`${financialNums(summaryStats?.data?.summary?.transactionsAverageCountPerDay || 0)} / اليوم`}
              helperText={
                !isNaN(summaryStats?.data?.progress?.transactionsAverageCountPerDayLastMonthProgress)
                  ? (summaryStats?.data?.progress?.transactionsAverageCountPerDayLastMonthProgress as any)
                  : '-'
              }
            />
            {restRoute === 'fuel-transactions' && (
              <SummaryItem
                label={dictionary().LITRES_AMOUNT}
                value={`${financialNums(summaryStats?.data?.summary.transactions_total_liters || 0)} / ${dictionary().LITRES}`}
                helperText={!isNaN(summaryStats?.data?.progress?.totalLitresProgress) ? (summaryStats?.data?.progress?.totalLitresProgress as any) : '-'}
              />
            )}
          </SimpleGrid>
        </Container>
      </>
      )
    </HStack>
  )
}

export default Summary
