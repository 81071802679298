import React from 'react'

import ViewOne from '../../components/layout/ViewOne'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const header = [
  {
    id: 'name.ar',
    key: 'name.ar',
    name: dictionary().PRODUCT_NAME_AR,
    type: 'text',
  },
  {
    id: 'name.en',
    key: 'name.en',
    name: dictionary().PRODUCT_NAME_EN,
    type: 'text',
  },
  {
    id: 'price',
    key: 'price',
    name: dictionary().PRICE,
    type: 'cash',
  },
  {
    id: 'station',
    key: 'station.name.ar',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
]
const actions = [
  {
    type: 'path',
    action: 'edit',
    title: dictionary().EDIT,
  },
  {
    type: 'delete',
    apiRoute: `store-products`,
    redirectRoute: '/store-products',
    title: dictionary().DELETE,
  },
]

const StoreProduct: React.FC = () => {
  return (
    <ViewOne
      actions={actions}
      header={header}
      restRoute='/store-products'
      dataLocation={'data'}
      title={dictionary().STORE_PRODUCTS}
      cashingKey={CASHING_KEY.GET_SINGLE_STORE_PRODUCT}
    />
  )
}

export default StoreProduct
