import { StateManagementVariables } from '../../../types/AddFuelTransactionVariables'

type updateFuelTypeParams = {
  fuelTypeId: any
  stateManager: StateManagementVariables
  setStateManager: any
  setValue: any
}
const updateFuelType = ({ fuelTypeId, stateManager, setStateManager, setValue }: updateFuelTypeParams): void => {
  // Check if the fuelTypeId is an object and not NaN
  if (fuelTypeId?.current?.length) {
    // If fuelTypeId is valid, set isFuelTypeSelected to true
    setStateManager({ ...stateManager, isFuelTypeSelected: true })
  } else {
    // If fuelTypeId is invalid, set isFuelTypeSelected to false
    setStateManager({ ...stateManager, isFuelTypeSelected: false })
    setValue('amount', '')
    setValue('odometer_reading', '')
    setValue('comment', '')
  }
}

export default updateFuelType
