import { useRef } from 'react'
import { useQuery } from 'react-query'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button, useDisclosure } from '@chakra-ui/react'
import { reverse } from 'ramda'
import fetch from '../../../helpers/fetch'
import dictionary from '../../../dictionary'
import Table from '../Table'

interface Props {
  id: any
  activate: boolean
  setActivate: any
}

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'driverName',
    key: 'driver.name',
    name: dictionary().DRIVER_NAME,
    type: 'text',
  },
  {
    id: 'plate_number',
    key: 'vehicle.number_plate',
    name: dictionary().NUMBER_PLATE,
    type: 'text',
  },
  {
    id: 'fuel_type',
    key: 'fuel_type.name.ar',
    name: dictionary().FUEL_TYPES,
    type: 'text',
  },
  {
    id: 'trans_litre_amount',
    key: 'liters',
    name: dictionary().LITRES_AMOUNT,
    type: 'text',
  },
  {
    id: 'station',
    key: 'station.name.ar',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().AMOUNT,
    type: 'cash',
  },
  {
    id: 'trans_date_created',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'trans_time_created',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
]

const InvoiceDetails: React.FC<Props> = ({ id, activate, setActivate }) => {
  const { data } = useQuery([], () => fetch('GET', `/invoices/details/${id}`), {})

  const { onClose } = useDisclosure()

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  const handle = () => {
    setActivate(false)
    onClose()
  }

  return (
    <>
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={activate} onClose={onClose} size='4xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().INVOICE_NUMBER + ' ' + data?.data?.invoice?.id} </ModalHeader>
          <ModalBody pb={6}>
            <Table raised enableIdRedirect={false} height={500} headers={reverse(headers)} data={data?.data?.transactions?.data} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={handle}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default InvoiceDetails
