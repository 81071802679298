import create from 'zustand'
import createVanilla from 'zustand/vanilla'
import { persist } from 'zustand/middleware'

export interface IFilterStore {
  from: string
  to: string
  positiveValues: boolean | null
  language: string
  sort_column: string
  sort_direction: string
  page: number
  page_size: number
  status: string | null
  stationId: number | null
  corporateId: number | null
  id: number | null
  store: IFilterStore
  transactionIds: number[]
  corporateIds: number[]
  stationIds: number[]
  vehicleIds: number[]
  driverIds: number[]
  advancedSearch: boolean
  setFrom: (from: string) => void
  setTo: (to: string) => void
  setPositiveValues: (positiveValues: boolean | null) => void
  setLanguage: (language: string) => void
  setSortColumn: (sortColumn: string) => void
  setSortDirection: (sortDirection: string) => void
  setPage: (page: number | null) => void
  setPageSize: (pageSize: number) => void
  setStatus: (status: string | null) => void
  setStationId: (stationId: number | null) => void
  setCorporateId: (corporateId: number | null) => void
  setId: (id: number | null) => void
  setStore: (store: IFilterStore) => void
  setTransactionIds: (transactionIds: number[]) => void
  setCorporateIds: (corporateIds: number[]) => void
  setStationIds: (stationIds: number[]) => void
  setVehicleIds: (vehicleIds: number[]) => void
  setDriverIds: (driverIds: number[]) => void
  setAdvancedSearch: (advancedSearch: boolean) => void
  resetFilters: () => void
}

function getFirstDateOfCurrentMonth() {
  const currentDate = new Date()
  const firstDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  return firstDateOfMonth.toISOString()
}

export const filterStore = createVanilla<IFilterStore>(
  (set: any, get: any) =>
    ({
      from: getFirstDateOfCurrentMonth(),
      to: new Date().toISOString(),
      positiveValues: null,
      status: 'CONFIRMED',
      language: 'ar',
      sort_column: 'id',
      sort_direction: 'DESC',
      page: 1,
      page_size: 30,
      stationId: null,
      corporateId: null,
      id: null,
      transactionIds: [],
      corporateIds: [],
      stationIds: [],
      vehicleIds: [],
      driverIds: [],
      advancedSearch: false,
      filters: {
        from: getFirstDateOfCurrentMonth(),
        to: new Date().toISOString(),
        positiveValues: null,
        status: 'CONFIRMED',
        language: 'ar',
        sort_column: 'id',
        sort_direction: 'DESC',
        page: 1,
        page_size: 30,
        stationId: null,
        corporateId: null,
        id: null,
        transactionIds: [],
        corporateIds: [],
        stationIds: [],
        vehicleIds: [],
        driverIds: [],
      },
      store: get(),
      getFilters: () => {
        get()
      },
      setFrom: (from: string) => set({ from }),
      setTo: (to: string) => set({ to }),
      setPositiveValues: (positiveValues: boolean) => set({ positiveValues }),
      setLanguage: (language: string) => set({ language }),
      setSortColumn: (sortColumn: string) => set({ sort_column: sortColumn }),
      setSortDirection: (sortDirection: string) => set({ sort_direction: sortDirection }),
      setPage: (page: number) => set({ page }),
      setPageSize: (pageSize: number) => set({ page_size: pageSize }),
      setStatus: (status: string | null) => set({ status }),
      setStationId: (stationId: number | null) => set({ stationId }),
      setCorporateId: (corporateId: number | null) => {
        if (corporateId === null) {
          set({ corporateId: null })
        } else {
          set({ corporateId: Number(corporateId) })
        }
      },
      setId: (id: number | null) => {
        set({ id })
      },
      setStore: (store: IFilterStore) => {
        set({ ...store })
      },
      setTransactionIds: (transactionIds: number[]) => set({ transactionIds }),
      setCorporateIds: (corporateIds: number[]) => set({ corporateIds }),
      setStationIds: (stationIds: number[]) => set({ stationIds }),
      setVehicleIds: (vehicleIds: number[]) => set({ vehicleIds }),
      setDriverIds: (driverIds: number[]) => set({ driverIds }),
      setAdvancedSearch: (advancedSearch: boolean) => set({ advancedSearch }),
      resetFilters: () => set({ ...get().filters }),
    } as any),
)

export const useFilterStore = create<IFilterStore>(filterStore as any)
