import React from 'react'
import ViewOne from '../../components/layout/ViewOne'
import dictionary from '../../dictionary'
import { CASHING_KEY } from '../../constants'

const header = [
  {
    id: 'fuel_balance',
    key: 'ledger_accounts',
    name: dictionary().AVIALABLE_BALANCE,
    type: 'FuelBalance',
    isRight: true,
  },
  {
    id: 'number_plate',
    key: 'number_plate',
    name: dictionary().NUMBER_PLATE,
    type: 'text',
  },
  {
    id: 'chassis_number',
    key: 'chassis_number',
    name: dictionary().CHASSIS_NUMBER,
    type: 'text',
  },
  {
    id: 'model',
    key: 'model',
    name: dictionary().CAR_MODEL,
    type: 'text',
  },
  {
    id: 'color',
    key: 'color',
    name: dictionary().CAR_COLOR,
    type: 'text',
  },
  {
    id: 'fuel_consumption_benchmark',
    key: 'fuel_consumption_benchmark',
    name: dictionary().CONSUMPTION_BENCHMARK,
    type: 'text',
  },
  {
    id: 'starting_balance_fuel',
    key: 'starting_balance_fuel',
    name: dictionary().STARTING_BALANCE,
    type: 'cash',
  },
  {
    id: 'replenish_amount',
    key: 'replenish_amount',
    name: dictionary().REPLENISH_AMOUNT,
    type: 'cash',
  },
  {
    id: 'date',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'recommended_fuel_types',
    key: 'recommended_fuel_types',
    name: dictionary().FUEL_TYPES,
    type: 'fuel-types',
  },
  {
    id: 'offline_verification',
    key: 'offline_verification',
    name: dictionary().DRIVER_VERIFICATION,
    type: 'boolean',
  },
  {
    id: 'is_active',
    key: 'is_active',
    name: dictionary().STATUS,
    type: 'boolean',
  },
]
const actions = [
  {
    action: 'edit',
    title: dictionary().EDIT,
  },
  {
    type: 'AssignDriverToVehicleModal',
    title: dictionary().ASSIGN_DRIVER_TO_VEHICLE,
  },
  {
    type: 'transeferModal',
    title: dictionary().TRANSFER,
  },
  // {
  //   type: 'transeferBetweenEntitiesModal',
  //   title: dictionary().TRANSFER_TO_DRIVER,
  // },
  {
    type: 'transeferBetweenEntitiesModal',
    title: dictionary().TRANSFER_TO_VEHICLE,
  },
]

const Vehicle: React.FC = () => {
  return (
    <ViewOne
      actions={actions}
      header={header}
      restRoute='/vehicles'
      dataLocation='vehicle'
      title={dictionary().VEHICLE_ID}
      cashingKey={CASHING_KEY.GET_SINGLE_VEHICLE}
      userType='vehicles'
    />
  )
}

export default Vehicle
