import { Spinner, Tooltip } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'

interface Props {
  data: any
}

const RefundDuplicateTransaction: FC<Props> = ({ data }) => {
  const [isClicked, setIsClicked] = useState<any>(false)
  const [duplicateIds, setDuplicateIds] = useState<any>([])
  const { showInfoMessage } = useAlert()
  const { push } = useHistory()
  const { isLoading, mutate } = useMutation(() => fetch('GET', `/fuel-transactions/duplicate/duplicates/${data?.original_transaction_id}`), {
    onSuccess: (data): void => {
      const temp: any = []
      data?.map((item: any) => {
        !item?.isRefunded && temp.push(item.transaction_id)
      })
      setIsClicked(true)
      setDuplicateIds(temp)
    },
  })
  const getDuplicatedTransactions = () => {
    mutate()
  }

  const goToRestoreForm = () => {
    push(`/refund-tranactions?refundType=duplicate&baseTransaction=${data.original_transaction_id}&duplicateTransactions=${duplicateIds}`)
  }

  useEffect(() => {
    if (isClicked) {
      if (duplicateIds.length > 0) {
        goToRestoreForm()
      } else if (duplicateIds.length === 0) {
        showInfoMessage('تم رد جميع النُسخ المُكررة لهذه العملية بالفعل')
      }
    }
  }, [duplicateIds])

  return isLoading ? (
    <Spinner color='#7165e3' />
  ) : (
    <Tooltip label='إرداد' placement='top'>
      <img src='/assets/ic_refund.svg' style={{ cursor: 'pointer', width: '20px', height: '20px' }} onClick={getDuplicatedTransactions} />
    </Tooltip>
  )
}

export default RefundDuplicateTransaction
