import React, { useState, useEffect, useCallback, useRef } from 'react'
import { reject, isNil } from 'ramda'
import { Button, Box, Flex, Text, useControllableState } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import ImageList from './ImageList'

interface Props {
  multiple?: boolean
  onChange?: (e: unknown) => void
  value?: string | string[] | File | File[] | null
}

const UploadImage: React.FC<Props> = ({ multiple, onChange, value }) => {
  const [internalValue, setInternalValue] = useControllableState({ value, onChange })
  const [previews, setPreviews] = useState<string[]>([])
  const ref = useRef<HTMLInputElement>(null)

  const onImageListRemove = (index: number) => {
    if (!internalValue) return
    if (!multiple) setInternalValue(null)
    const newState = (internalValue as string[]).filter((item, i) => i !== index)
    setInternalValue(newState)
  }

  const onFileAdded = async (fileList: FileList | null) => {
    if (!fileList) return
    if (multiple) {
      const files: File[] = []
      for (const file of fileList) {
        files.push(file)
      }
      setInternalValue([...(Array.isArray(internalValue) ? (internalValue as File[]) : []), ...files])
    } else {
      setInternalValue(fileList[0])
    }
  }

  const getBase64 = useCallback((file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])

  const getPreviews = useCallback(
    async (list) => {
      const previewList = [...list]
      for (const i in previewList) {
        if (typeof previewList[i] === 'string') continue
        previewList[i] = await getBase64(previewList[i])
      }
      setPreviews([...previewList])
    },
    [getBase64, setPreviews],
  )

  useEffect(() => {
    if (multiple) getPreviews(internalValue)
    else getPreviews(reject(isNil, [internalValue]))
  }, [getPreviews, multiple, internalValue])

  return (
    <>
      <Flex>
        <input ref={ref} accept='image/*' style={{ display: 'none' }} multiple={multiple} type='file' onChange={(e) => onFileAdded(e.target.files)} />
        <Box>
          <ImageList list={previews} onRemove={onImageListRemove} />
        </Box>

        <Box>
          <Button onClick={() => ref.current?.click()} variant='outline' w='100px' h='100px' borderColor='blue.500' borderRadius='8px' color='blue.500'>
            <Flex justifyContent='center' flexDirection='column' alignItems='center'>
              <AddIcon />
              <Text mt='1'>Upload</Text>
            </Flex>
          </Button>
        </Box>
      </Flex>
    </>
  )
}

export default UploadImage
