import { Redirect, Route, Switch } from 'react-router-dom'
import CorporateAccounts from '../../pages/accounting/corporates'
import StationsAccounts from '../../pages/accounting/stations'
import AccountingTransactions from '../../pages/accounting/transactions'
import AddAdmin from '../../pages/admins/add'
import Admins from '../../pages/admins/admins'
import DVehicleSubscription from '../../pages/admins/DVehicleSubscription'
import AddCorporateGroup from '../../pages/corporate-groups/add'
import AddNewCGStaff from '../../pages/corporate-groups/AddNewCGStaff'
import CorporateGroups from '../../pages/corporate-groups/CorporateGroups'
import EditCorporateGroup from '../../pages/corporate-groups/edit'
import CorporateGroupPage from '../../pages/corporate-groups/one'
import AdminVehicles from '../../pages/corporate-staff/AdminVehicles'
import AddCorporate from '../../pages/corporates/add'
import AddCPayments from '../../pages/corporates/AddCPayments'
import AddCorporateDeposits from '../../pages/corporates/AddDeposits'
import AddInvoice from '../../pages/corporates/AddInvoice'
import AddNewStaff from '../../pages/corporates/addNewStaff'
import AddWithdraw from '../../pages/corporates/AddWithdraw'
import Corporates from '../../pages/corporates/corporates'
import EditCorporate from '../../pages/corporates/edit'
import EditCorporateStaff from '../../pages/corporates/EditCorporateStaff'
import Invoices from '../../pages/corporates/invoices'
import CorporatePage from '../../pages/corporates/one'
import AddCustomer from '../../pages/customers/Add'
import AddNewCustomerStaff from '../../pages/customers/AddNewCustomerStaff'
import Customers from '../../pages/customers/Customers'
import EditCustomer from '../../pages/customers/Edit'
import CustomerPage from '../../pages/customers/One'
import AddDriver from '../../pages/drivers/add'
import Drivers from '../../pages/drivers/drivers'
import EditDriver from '../../pages/drivers/edit'
import DriverPage from '../../pages/drivers/one'
import AddFuel from '../../pages/fuel-types/add'
import EditFuelType from '../../pages/fuel-types/edit'
import FuelTypes from '../../pages/fuel-types/fuelTypes'
import FuelTypePage from '../../pages/fuel-types/one'
import ConfirmedPayments from '../../pages/payments/ConfirmedPayments'
import PaymentsDetails from '../../pages/payments/PaymentsDetails'
import AddTollStationPricing from '../../pages/pricing/add'
import Pricing from '../../pages/pricing/pricing'
import AddProductType from '../../pages/product-types/add'
import ProductTypes from '../../pages/product-types/product-types'
import AddStationProviders from '../../pages/station-providers/add'
import AddNewSatationProviderStaff from '../../pages/station-providers/addNewStaff'
import AddSPPayments from '../../pages/station-providers/AddSPPayments'
import EditStationProvider from '../../pages/station-providers/edit'
import StationProvider from '../../pages/station-providers/one'
import StationProviders from '../../pages/station-providers/station-providers'
import StationProviderStations from '../../pages/station-providers/stations'
import AddStation from '../../pages/stations/add'
import AddNewStationStaff from '../../pages/stations/addNewStaff'
import EditStation from '../../pages/stations/edit'
import Station from '../../pages/stations/one'
import Stations from '../../pages/stations/stations'
import AddStoreProduct from '../../pages/store-products/add'
import EditStoreProduct from '../../pages/store-products/edit'
import StoreProduct from '../../pages/store-products/one'
import StoreProducts from '../../pages/store-products/store-products'
import AddVehicleSubscription from '../../pages/subscriptions/AddVehicleSubscription'
import VehicleSubscriptions from '../../pages/subscriptions/VehicleSubscriptions'
import AddFuelTransaction from '../../pages/transactions/AddFuelTransaction'
import FuelTransactions from '../../pages/transactions/fuel'
import FuelTransactionsByID from '../../pages/transactions/fuelById'
import FuelPumpDataEntry from '../../pages/transactions/fuelPumpDataEntry'
import LedgerTransactionsByID from '../../pages/transactions/ledgerById'
import RefundTransaction from '../../pages/transactions/RefundTransaction'
import RestoreTransactionImage from '../../pages/transactions/RestoreTransactionImage'
import StoreTransactions from '../../pages/transactions/store'
import TollTransactions from '../../pages/transactions/toll'
import TollTransactionsByID from '../../pages/transactions/tollById'
import TransactionsReviewed from '../../pages/transactions/transactionsReviewed'
import AddTrip from '../../pages/trips/add'
import EditTrip from '../../pages/trips/edit'
import Trip from '../../pages/trips/one'
import Trips from '../../pages/trips/trips'
import AddVehicleType from '../../pages/vehicle-types/add'
import EditVehicleType from '../../pages/vehicle-types/edit'
import VehicleType from '../../pages/vehicle-types/one'
import VehicleTypes from '../../pages/vehicle-types/vehicle-types'
import AddVehicle from '../../pages/vehicles/add'
import EditVehicle from '../../pages/vehicles/edit'
import Vehicle from '../../pages/vehicles/one'

import ChargingProfiles from '../../pages/charging-profiles/ChargingProfiles'
import AddChargingProfile from '../../pages/charging-profiles/add'
import EditChargingProfile from '../../pages/charging-profiles/edit'
import ChargingProfilePage from '../../pages/charging-profiles/ChargingProfileDetails'

import { useStore } from '../../store'
import EditCorporateGroupStaff from '../../pages/corporate-groups/EditCGStaff'
import EditAdmin from '../../pages/admins/edit'
import { ChangePassword } from '../../pages/ChangePassword/ChangePassword'
import { ROLES } from '../ROLES'
import DuplicateTransactions from '../../pages/duplicate-transactions/DuplicateTransactions'
import EInvoices from '../../pages/E-Invoices/E-Invoices'
import PosList from '../../pages/pos/PosList'

export const ADMIN_ROUTES = (): JSX.Element => {
  const { userType } = useStore((state) => state)
  const userData = window.localStorage.getItem('activeRole')
  const { role } = useStore()
  const isSuperAdmin = userType === 'admin' && role === 'SUPER'
  const showPos = userType === 'admin' && (role === 'SUPER' || role === 'MANAGER')
  const showPayments = role === 'OPERATIONS' || role === 'ACCOUNTANT' || role === 'SUPER' || role === 'MANAGER'

  return (
    <Switch>
      {role === ROLES.SUPER_ADMIN && <Route exact path='/e-invoices' render={() => <EInvoices />} />}

      <Route exact path='/change-password' render={() => <ChangePassword />} />
      <Route exact path='/refund-tranactions' render={() => <RefundTransaction />} />
      <Route exact path='/restore-transaction-image' render={() => <RestoreTransactionImage />} />
      <Route exact path='/duplicate-transactions' render={() => <DuplicateTransactions />} />
      <Route exact path='/corporates/staff/:id' render={() => <EditCorporateStaff />} />
      <Route exact path='/corporates' render={() => <Corporates />} />
      <Route exact path='/corporates-accounts' render={() => <CorporateAccounts />} />
      <Route exact path='/corporates-accounts/:id' render={() => <CorporatePage />} />
      <Route exact path='/corporate-groups' render={() => <CorporateGroups />} />
      <Route exact path='/corporate-groups/new' render={() => <AddCorporateGroup />} />
      <Route exact path='/corporate-groups/:id' render={() => <CorporateGroupPage />} />

      {showPos && <Route exact path='/pos-devices' render={() => <PosList />} />}

      {[ROLES.SUPER_ADMIN, ROLES.MANAGER, ROLES.OPERATIONS].includes(role ?? '') && (
        <Route exact path='/corporate-groups/staff/:id' render={() => <EditCorporateGroupStaff />} />
      )}

      <Route exact path='/corporate-groups/:id/edit' render={() => <EditCorporateGroup />} />
      {[ROLES.SUPER_ADMIN, ROLES.MANAGER, ROLES.OPERATIONS].includes(role ?? '') && (
        <Route exact path='/corporate-groups/:id/add-new-staff' render={() => <AddNewCGStaff />} />
      )}
      <Route exact path='/corporates/new' render={() => <AddCorporate />} />
      <Route exact path='/corporates/:id' render={() => <CorporatePage />} />
      <Route exact path='/corporates/:id/edit' render={() => <EditCorporate />} />
      <Route exact path='/corporates/:id/deposits' render={() => <AddCorporateDeposits />} />
      <Route exact path='/corporates/:id/withdraw' render={() => <AddWithdraw />} />

      <Route exact path='/corporates/:id/add-new-staff' render={() => <AddNewStaff />} />

      <Route exact path='/corporates/:id/invoices' render={() => <Invoices />} />
      <Route exact path='/corporates/:id/invoices/new' render={() => <AddInvoice />} />

      {isSuperAdmin && <Route exact path='/admins' render={() => <Admins />} />}
      {isSuperAdmin && <Route exact path='/admins/new' render={() => <AddAdmin />} />}
      {isSuperAdmin && <Route exact path='/admins/:id' render={() => <EditAdmin />} />}

      <Route exact path='/charging-profiles' render={() => <ChargingProfiles />} />
      <Route exact path='/charging-profiles/new' render={() => <AddChargingProfile />} />
      <Route exact path='/charging-profiles/:profileId/:corporateId' render={() => <ChargingProfilePage />} />
      <Route exact path='/charging-profiles/:profileId/edit' render={() => <EditChargingProfile />} />
      {showPayments && <Route exact path='/payments-details' render={() => <PaymentsDetails />} />}

      <Route exact path='/vehicles' render={() => <AdminVehicles />} />
      <Route exact path='/vehicle-subscription' render={() => <VehicleSubscriptions />} />
      <Route exact path='/vehicle-subscription/new' render={() => <AddVehicleSubscription />} />
      <Route exact path='/vehicle-subscription/:id' render={() => <DVehicleSubscription />} />
      <Route exact path='/station-providers' render={() => <StationProviders />} />
      <Route exact path='/station-providers/new' render={() => <AddStationProviders />} />
      <Route exact path='/station-providers/:id/stations' render={() => <StationProviderStations />} />
      <Route exact path='/station-providers/:id' render={() => <StationProvider />} />
      <Route exact path='/station-providers/:id/add-new-staff' render={() => <AddNewSatationProviderStaff />} />
      <Route exact path='/station-providers/:id/edit' render={() => <EditStationProvider />} />
      <Route exact path='/station-providers/:id/deposits' render={() => <AddCorporateDeposits />} />
      <Route exact path='/vehicles/new' render={() => <AddVehicle />} />
      <Route exact path='/fuel-transactions/new' render={() => <AddFuelTransaction />} />
      <Route exact path='/vehicles/:id/edit' render={() => <EditVehicle />} />
      <Route exact path='/drivers/new' render={() => <AddDriver />} />
      <Route exact path='/drivers/:id' render={() => <DriverPage />} />
      <Route exact path='/drivers/:id/edit' render={() => <EditDriver />} />
      <Route exact path='/drivers' render={() => <Drivers />} />
      <Route exact path='/vehicles/:id' render={() => <Vehicle />} />
      <Route exact path='/:userType/:id/fuel-transactions' render={() => <FuelTransactionsByID />} />
      <Route exact path='/:userType/:id/toll-transactions' render={() => <TollTransactionsByID />} />
      <Route exact path='/:userType/:id/ledger-transactions/:account_id' render={() => <LedgerTransactionsByID />} />
      <Route exact path='/vehicle-types' render={() => <VehicleTypes />} />
      <Route exact path='/vehicle-types/add' render={() => <AddVehicleType />} />
      <Route exact path='/vehicle-types/:id' render={() => <VehicleType />} />
      <Route exact path='/vehicle-types/:id/edit' render={() => <EditVehicleType />} />
      <Route exact path='/product-types' render={() => <ProductTypes />} />
      <Route exact path='/product-types/add' render={() => <AddProductType />} />
      <Route exact path='/stations' render={() => <Stations />} />
      <Route exact path='/stations-accounts' render={() => <StationsAccounts />} />
      <Route exact path='/stations-accounts/:id' render={() => <Station />} />
      <Route exact path='/stations/new' render={() => <AddStation />} />
      <Route exact path='/stations/:id/edit' render={() => <EditStation />} />
      <Route exact path='/stations/:id/add-new-staff' render={() => <AddNewStationStaff />} />
      <Route exact path='/stations/:id' render={() => <Station />} />
      <Route exact path='/trips' render={() => <Trips />} />
      <Route exact path='/trips/new' render={() => <AddTrip />} />
      <Route exact path='/trips/:id' render={() => <Trip />} />
      <Route exact path='/trips/:id/edit' render={() => <EditTrip />} />
      <Route exact path='/toll-transactions' render={() => <TollTransactions />} />
      <Route exact path='/transactions-reviewed' render={() => <TransactionsReviewed />} />
      <Route exact path='/fuel-transactions' render={() => <FuelTransactions />} />
      <Route exact path='/accounting-transactions' render={() => <AccountingTransactions />} />
      <Route exact path='/fuel-transactions-data-entry' render={() => <FuelPumpDataEntry />} />
      <Route exact path='/store-transactions' render={() => <StoreTransactions />} />
      <Route exact path='/pricing' render={() => <Pricing />} />
      <Route exact path='/pricing/new' render={() => <AddTollStationPricing />} />
      <Route exact path='/fuel-types' render={() => <FuelTypes />} />
      <Route exact path='/fuel-types/new' render={() => <AddFuel />} />
      <Route exact path='/fuel-types/:id' render={() => <FuelTypePage />} />
      <Route exact path='/fuel-types/:id/edit' render={() => <EditFuelType />} />
      <Route exact path='/store-products' render={() => <StoreProducts />} />
      <Route exact path='/store-products/new' render={() => <AddStoreProduct />} />
      <Route exact path='/store-products/:id' render={() => <StoreProduct />} />
      <Route exact path='/store-products/:id/edit' render={() => <EditStoreProduct />} />
      <Route exact path='/corporates-deposits' render={() => <AddCPayments />} />
      <Route exact path='/station-providers-deposits' render={() => <AddSPPayments />} />
      <Route exact path='/customers' render={() => <Customers />} />
      <Route exact path='/customers/new' render={() => <AddCustomer />} />
      <Route exact path='/customers/:id' render={() => <CustomerPage />} />
      <Route exact path='/customers/:id/edit' render={() => <EditCustomer />} />
      <Route exact path='/customers/:id/add-new-staff' render={() => <AddNewCustomerStaff />} />
      <Route exact path='/confirmed-payments' render={() => <ConfirmedPayments />} />

      <Redirect exact from='/' to='/fuel-transactions' />
      <Redirect exact from='/admin/login' to='/fuel-transactions' />
      <Redirect exact from='/*' to='/fuel-transactions' />
    </Switch>
  )
}
