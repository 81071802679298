import { useEffect, useRef, useState } from 'react'

import {
  Modal,
  Box,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
  FormLabel,
  Input,
  FormErrorMessage,
  FormControl,
  Spinner,
} from '@chakra-ui/react'

import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import Required from '../../components/core/reqiured'
import useAlert from '../../helpers/useAlert'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'

interface Props {
  data: any
  date: any
  paid: boolean
  SPId: any
  handleIsSubscriptionPaid: () => void
}

const PaymentModel: React.FC<Props> = ({ data, date, paid, handleIsSubscriptionPaid, SPId }) => {
  const { id } = useParams<{ id: string }>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const options: any = { year: 'numeric', month: 'long' }
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const { onSuccess, onError } = useAlert()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  })
  const [isModalOpen, setisModalOpen] = useState(false)
  const { push } = useHistory()

  useEffect(() => {
    setValue('amount', +data.claim_amount)
  }, [isModalOpen])

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/stations', { data: vars }), {
    onSuccess: (): void => {
      handleIsSubscriptionPaid && handleIsSubscriptionPaid()
      onSuccess()
      push(`/vehicle-subscription/${id}`)
      onClose()
    },
    onError,
  })

  const paySubscription = async (formData: any) => {
    mutate([formData])
  }

  const handleOnOpen = () => {
    onOpen()
    setisModalOpen(!isModalOpen)
  }

  return (
    <>
      <Button style={{ color: paid ? 'green' : 'red' }} disabled={paid} onClick={handleOnOpen} size='sm'>
        {paid ? dictionary().PAID : dictionary().PAY}
      </Button>

      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>
            {dictionary().PAY_VEHICLE_SUBSCRIPTION} {new Date(date).toLocaleDateString('ar-EG', options)}{' '}
          </ModalHeader>
          <form onSubmit={() => handleSubmit(paySubscription as any)} style={{ direction: 'rtl' }} id='uploadform'>
            <ModalBody pb={6}>
              <FormControl id='AMOUNT' w='100%' isInvalid={!!errors.amount} style={{ direction: 'rtl' }}>
                <Box display='flex' flexDirection={'column'}>
                  <FormLabel>
                    {dictionary().AMOUNT} <Required />
                  </FormLabel>
                  <Input
                    type='number'
                    {...register('amount', {
                      required: dictionary().REQUIRED,
                    })}
                  />
                  <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl id={dictionary().COMMENT} w='100%' isInvalid={!!errors.confirmation_comment}>
                <FormLabel>
                  {dictionary().COMMENT} <span style={{ fontSize: '13px' }}>({dictionary().NUMBERS})</span>
                </FormLabel>
                <Input
                  type='number'
                  {...register('confirmation_comment', {
                    required: false,
                  })}
                />
                <FormErrorMessage>{errors.confirmation_comment?.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button mx={2} colorScheme='blue' onClick={async () => (await trigger()) && paySubscription(getValues() as any)}>
                {isLoading ? <Spinner /> : dictionary().PAY}
              </Button>
              <Button onClick={onClose}>{dictionary().CANCEL}</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default PaymentModel
