import React, { FC, useEffect, useState } from 'react'
import './style.css'
import { FormControl, FormLabel, Spinner } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import dictionary from '../../dictionary'
import Reqiured from '../../components/core/reqiured'

interface Props {
  icons: any[]
  setSelectedIcon: any
  selectedIcon?: any
  isFetched: any
  isSubmit: any
}

const ImagesDropDown: FC<Props> = ({ icons, isSubmit, isFetched, setSelectedIcon, selectedIcon }) => {
  const [showOptions, setShowOptions] = useState(false)
  const [selectedOption, setSelectedOption] = useState(false)
  const [vehiclesIcons, setVehiclesIcons] = useState<any[]>([])

  useEffect(() => {
    if (icons) {
      const iconContent: any[] = []
      icons?.map((icon: string) => {
        iconContent.push(icon.split('/')[icon.split('/').length - 2])
      })
      setVehiclesIcons(iconContent)
    }
  }, [icons])

  useEffect(() => {
    if (selectedOption) {
      getSelectedIconUrl()
    }
  }, [selectedOption])

  useEffect(() => {
    if (selectedIcon) {
      setSelectedOption(selectedIcon.split('/')[selectedIcon.split('/').length - 2])
    }
  }, [selectedIcon])

  const getSelectedIconUrl = () => {
    if (selectedOption) {
      icons.filter((icon) => {
        icon.includes(selectedOption) && setSelectedIcon(icon)
      })
    } else {
      setSelectedIcon('')
    }
  }

  return (
    <FormControl style={{ position: 'relative' }} id={dictionary().ICON} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
      <FormLabel>
        {dictionary().ICON} <Reqiured />
        <p onClick={() => setShowOptions(!showOptions)} id='dropdown-input' className='dropdown-input'>
          <ChevronDownIcon style={{ marginLeft: '3px' }} />
          {selectedOption ? <img src={`/assets/${selectedOption}`} alt='Selected Option' /> : <p style={{ padding: '5px' }}>{dictionary().ICON}</p>}
        </p>
      </FormLabel>
      {showOptions &&
        (isFetched ? (
          <ul className='dropdown'>
            {vehiclesIcons.map((icon: any, index: any) => (
              <li
                key={index}
                className='dropdown-option'
                onClick={() => {
                  setSelectedOption(icon)
                  setShowOptions(false)
                }}
              >
                <img src={`/assets/${icon}`} />
              </li>
            ))}
          </ul>
        ) : (
          <Spinner />
        ))}
      {isSubmit && !selectedOption && <p style={{ color: '#e53e3e' }}>{dictionary().REQUIRED}</p>}
    </FormControl>
  )
}

export default ImagesDropDown
