import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'vehicle',
    key: 'vehicle.number_plate',
    name: dictionary().NUMBER_PLATE,
    type: 'text',
  },
  {
    id: 'product_type',
    key: 'product_type.name.ar',
    name: dictionary().PRODUCT_TYPE,
    type: 'text',
  },
  {
    id: 'weight',
    key: 'weight',
    name: dictionary().WEIGHT,
    type: 'text',
  },
  {
    id: 'trips_date_created',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'trips_time_created',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
]

const Trips: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().TRIPS}
      to='/trips/new'
      tags={[{ name: dictionary().ALL, filter: [{ name: '', value: '' }] }]}
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['trips']}
      countArrayPath={['count']}
      restRoute={'/trips'}
      cashingKey={CASHING_KEY.GET_TRIPS}
    />
  )
}

export default Trips
