import React from 'react'
import { parse } from 'query-string'
import { pathOr } from 'ramda'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { GridItem, Text, Box, Stack } from '@chakra-ui/react'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import Card from '../../components/core/Card'
import Table from '../core/Table'
import { useStore } from '../../store'
import { ROLES } from '../../constants/ROLES'

interface Props {
  restRoute: string
  height?: string | number
}

const StationProviderStaff: React.FC<Props> = ({ restRoute }) => {
  const query = parse(location.search, { parseBooleans: true })
  const { id } = useParams<{ id: string }>()
  const station_provider_staff = useQuery([query], () => fetch('GET', restRoute + `${id}`), {})

  const { role } = useStore()

  const stationProviderStaffHeaders = [
    {
      id: 'id',
      key: 'id',
      name: dictionary().ID,
      type: 'text',
    },
    {
      id: 'username',
      key: 'username',
      name: dictionary().USERNAME,
      type: 'text',
    },
    {
      id: 'name',
      key: 'name.ar',
      name: dictionary().NAME,
      type: 'text',
    },
    {
      id: 'resetPassword',
      key: 'resetPassword',
      name: dictionary().PASSWORD,
      type: 'modal',
      payload: { name: 'تغيير' },
    },
  ]

  if (role === ROLES.SUPER_ADMIN) {
    stationProviderStaffHeaders.push({
      id: 'forceLogout',
      key: 'forceLogout',
      name: dictionary().ACTIONS,
      type: 'modal',
      // payload: { name: 'تغيير' },
    })
  }

  return (
    <>
      <Card h='100%' p={4}>
        <Text m={3} fontSize='20px'>
          {dictionary().STAFF}
        </Text>
        <GridItem colSpan={6}>
          <Box w='100%'>
            <Stack>
              <GridItem>
                <Card h='100%'>
                  <Table
                    raised
                    enableIdRedirect={false}
                    height={170}
                    headers={stationProviderStaffHeaders}
                    data={pathOr([], ['data'], station_provider_staff?.data)}
                  />
                </Card>
              </GridItem>
            </Stack>
          </Box>
        </GridItem>
      </Card>
    </>
  )
}

export default StationProviderStaff
