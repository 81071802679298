import React from 'react'
import ViewOne from '../../components/layout/ViewOne'
import dictionary from '../../dictionary'
import { CASHING_KEY } from '../../constants'

const header = [
  {
    id: 'name',
    key: 'name.ar',
    name: dictionary().VEHICLE_TYPE_NAME,
    type: 'text',
  },
  {
    id: 'icon',
    key: 'icon',
    name: dictionary().ICON,
    type: 'img',
  },
  {
    id: 'code',
    key: 'code',
    name: dictionary().CODE,
    type: 'text',
  },
  {
    id: 'dimensions',
    key: 'dimensions',
    name: dictionary().DIMENSIONS,
    type: 'text',
  },
  {
    id: 'max_weight',
    key: 'max_weight',
    name: dictionary().MAX_WEIGHT,
    type: 'text',
  },
  {
    id: 'volume',
    key: 'volume',
    name: dictionary().VOLUME,
    type: 'text',
  },
  {
    id: 'avg_consumption',
    key: 'avg_consumption',
    name: dictionary().AVG_FUEL_CONSUMPTION,
    type: 'text',
  },
  {
    id: 'toll_amount',
    key: 'toll_amount',
    name: dictionary().TOLL_AMOUNT,
    type: 'cash',
    isRight: true,
  },
  {
    id: 'vehicle_type_date_created',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'vehicle_type_time_created',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
]
const actions = [
  {
    action: 'edit',
    title: dictionary().EDIT,
  },
  {
    type: 'delete',
    apiRoute: 'vehicle-types',
    redirectRoute: '/vehicle-types',
    title: dictionary().DELETE,
  },
]

const VehicleType: React.FC = () => {
  return (
    <ViewOne
      actions={actions}
      header={header}
      restRoute='/vehicle-types'
      dataLocation=''
      title={dictionary().VEHICLE_TYPE}
      cashingKey={CASHING_KEY.GET_SINGLE_VEHICLE_TYPE}
      userType='vehicle-types'
    />
  )
}

export default VehicleType
