import Pusher, { Channel } from 'pusher-js'
import create from 'zustand'
import createVanilla from 'zustand/vanilla'

export interface NotificationStore {
  pusher: Pusher | null
  channel: Channel | null
  initiatePusher: (user_type: string | null | undefined, user_id: string | null | undefined) => void
  bind: (event: string, callback: (data: any) => void) => void
  events: {
    RUNNING: 'export_csv_running'
    SUCCESS: 'export_csv_success'
    FAILED: 'export_csv_failed'
  }
  terminatePusher: () => void
  enabled: boolean
}

export const notificationStore = createVanilla<NotificationStore>(
  (set: any, get: any) =>
    ({
      pusher: null,
      channel: null,
      enabled: false,
      events: {
        RUNNING: 'export_csv_running',
        SUCCESS: 'export_csv_success',
        FAILED: 'export_csv_failed',
      },
      initiatePusher: (user_type: string | null | undefined, user_id: string | null | undefined) => {
        // console.log('PUSHER INITIATED')
        if (!user_type || !user_id) return
        const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
          cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        })
        const channelName = `EXPORT_CSV_${user_type}_${user_id}`
        const channel = pusher.subscribe(channelName)
        set({ pusher, channel, enabled: true })
      },
      bind: (event: string, callback: (data: any) => void) => {
        get().channel?.bind(event, callback)
      },
      terminatePusher: () => {
        const { pusher } = get()
        if (pusher) {
          // Disconnect the Pusher instance
          pusher.disconnect()
          // Reset pusher and channel to null
          set({ pusher: null, channel: null, enabled: false })
          // console.log('PUSHER TERMINATED')
        }
      },
    } as any),
)

export const useNotificationStore = create<NotificationStore>(notificationStore as any)
