import React, { useRef } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'

type AddFuelVariables = {
  name_en: string
  name_ar: string
  price: number
}

const AddFuel: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const { isLoading, mutate } = useMutation((vars: any) => fetch('POST', '/fuel-types', { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push('/fuel-types')
    },
    onError,
  })
  const addFuel = async (formData: AddFuelVariables) => {
    mutate(formData)
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().ADD_NEW_FUEL}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && addFuel(getValues() as AddFuelVariables)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(addFuel as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl
              id={dictionary().FUEL_NAME_AR}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.name?.ar}
            >
              <FormLabel>
                {dictionary().FUEL_NAME_AR} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('name.ar', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.en?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().FUEL_NAME_EN}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.name?.en}
            >
              <FormLabel>
                {dictionary().FUEL_NAME_EN} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('name.en', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.en?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().PRICE} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.price}>
              <FormLabel>
                {dictionary().PRICE} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='number'
                  {...register('price', {
                    required: dictionary().REQUIRED,
                    valueAsNumber: true,
                    min: {
                      value: 0.1,
                      message: dictionary().MORE_THAN_ZERO,
                    },
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.price?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddFuel
