import { useRef } from 'react'
import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import {
  Modal,
  Spinner,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  FormErrorMessage,
} from '@chakra-ui/react'
import useAlert from '../../helpers/useAlert'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'
import Reqiured from './reqiured'

interface Props {
  data?: any
}

const ResetCDriverPinCode: React.FC<Props> = ({ data }) => {
  const { onSuccess, onError } = useAlert()
  let { isLoading, mutate, error }: any = useMutation((vars: any) => fetch('POST', `/drivers/${data.id}/reset-pin-code`, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      handleClose()
    },
    onError,
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
  } = useForm({ mode: 'onChange' })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  const Reset = async (formData: any) => {
    mutate(formData)
  }
  const handleOnOpen = () => {
    onOpen()
    setValue('pin_code', null)
  }

  const handleClose = () => {
    setValue('pin_code', null)
    error = {}
    onClose()
  }

  return (
    <>
      <Button mb={1} onClick={handleOnOpen}>
        {dictionary().CHANGE_PIN_CODE}
      </Button>
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().CHANGE_PIN_CODE} </ModalHeader>
          <ModalBody pb={6}>
            <form dir='rtl' onSubmit={handleSubmit(Reset as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                <FormControl
                  id={dictionary().NEW_PIN_CODE}
                  w='40%'
                  minH='100px'
                  display='inline-block'
                  isInvalid={!!errors.pin_code || error?.data?.message?.length > 0 || error?.data?.statusCode === 404}
                >
                  <FormLabel style={{ direction: 'rtl' }}>
                    <>
                      {dictionary().NEW_PIN_CODE} <Reqiured />
                    </>
                  </FormLabel>

                  <InputGroup>
                    <Input
                      type='number'
                      {...register('pin_code', {
                        required: dictionary().REQUIRED,
                        // pattern: {
                        //   value: /^\d{4}$/,
                        //   message: dictionary().INVALID_PIN_CODE_FOUR_NUMBERS,
                        // },
                      })}
                    />
                  </InputGroup>

                  <FormErrorMessage>{errors.pin_code?.message}</FormErrorMessage>
                  <FormErrorMessage>{error?.data?.message?.length > 0 && typeof error?.data?.message !== 'string' && error?.data?.message[0]}</FormErrorMessage>
                  <FormErrorMessage>{error?.status === 404 && error?.data?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button onClick={async () => (await trigger()) && Reset(getValues() as any)} colorScheme='blue' mr={3}>
              {isLoading ? <Spinner /> : dictionary().SAVE}
            </Button>
            <Button onClick={handleClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ResetCDriverPinCode
