import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx-js-style'

const headerStyle = {
  font: { bold: true },
  alignment: { horizontal: 'center', vertical: 'center' },
  fill: { fgColor: { rgb: 'E4B1ED' } },
  border: {
    top: { style: 'thin', color: { rgb: '000000' } },
    bottom: { style: 'thin', color: { rgb: '000000' } },
    left: { style: 'thin', color: { rgb: '000000' } },
    right: { style: 'thin', color: { rgb: '000000' } },
  },
}

const cellStyle = {
  alignment: { horizontal: 'center', vertical: 'center' },
  border: {
    top: { style: 'thin', color: { rgb: '000000' } },
    bottom: { style: 'thin', color: { rgb: '000000' } },
    left: { style: 'thin', color: { rgb: '000000' } },
    right: { style: 'thin', color: { rgb: '000000' } },
  },
}

interface Header {
  key: string
  name: string
}

function getValueByPath(obj: any, path: string): any {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj)
}

function columnToLetter(column: any) {
  let temp,
    letter = ''
  while (column > 0) {
    temp = (column - 1) % 26
    letter = String.fromCharCode(temp + 65) + letter
    column = (column - temp - 1) / 26
  }
  return letter
}

export const useExport = () => {
  const exportToExcel = (
    data: Array<Record<string, any>>,
    headers: Header[],
    customRenderers: Record<string, (value: any) => any> = {},
    fileName = 'exported_file',
    sheetTitle = 'Exported File',
  ) => {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([headers.map((header) => header.name)])

    // Manually apply styles to header row
    const headerRange: XLSX.Range = XLSX.utils.decode_range(ws['!ref']!)
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const headerCellRef = XLSX.utils.encode_cell({ r: 0, c: C })
      ws[headerCellRef].s = headerStyle
    }

    data.forEach((row, rowIndex) => {
      headers.forEach((header, columnIndex) => {
        const originalValue = getValueByPath(row, header.key) || '-'
        const renderedValue = customRenderers[header.key] ? customRenderers[header.key](originalValue) : originalValue
        const cellRef = XLSX.utils.encode_cell({ r: rowIndex + 1, c: columnIndex })

        XLSX.utils.sheet_add_aoa(ws, [[renderedValue]], { origin: { r: rowIndex + 1, c: columnIndex } })

        // Check if cell was successfully added before applying style
        if (ws[cellRef]) {
          if (!ws[cellRef].s) ws[cellRef].s = {}
          ws[cellRef].s = { ...cellStyle }
        }
      })
    })

    const totalRows = data.length + 1 // Including header row
    const totalColumns = headers.length
    const endColumnLetter = columnToLetter(totalColumns) // Use the function to convert

    ws['!autofilter'] = { ref: `A1:${endColumnLetter}${totalRows}` }

    // Optional: Adjust column widths
    ws['!cols'] = headers.map(() => ({ wch: 20 }))

    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, sheetTitle)

    const wbout: ArrayBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName + '.xlsx')
  }

  return { exportToExcel }
}
