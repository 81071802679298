import { FormControl, FormLabel } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import dictionary from '../../dictionary'
import useQueryParams from '../../hooks/useQueryParams '
import AutoCompleteInput from './AutoCompleteInput'

const MultipeSerialNumberDropdown: FC = () => {
  const { add, remove } = useQueryParams()
  const {
    formState: { errors },
    control,
    watch,
    getValues,
  } = useForm({ mode: 'onChange' })

  const serial_number = watch('serial_number')

  useEffect(() => {
    remove('serial_number')
    getValues('serial_number')?.length > 0 && add('serial_number', getValues('serial_number')[0])
  }, [serial_number])

  return (
    <FormControl id={dictionary().SERIAL_NUMBER} w='100%' dir='rtl' marginTop={5}>
      <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
        {dictionary().SERIAL_NUMBER}
        <span style={{ color: '#AEA8A8', fontSize: '12px' }}>{dictionary().ENETER_SERIAL_NUMBER_MINIMUM_LENGTH_THREETEEN_DIGITS_TO_SEARCH}</span>
      </FormLabel>
      <Controller
        name={`serial_number`}
        rules={{ required: false }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <AutoCompleteInput
            isInvalid={!!errors?.second_ref_id}
            onChange={onChange}
            value={value}
            searchRoute='/pos-devices'
            singleRoute='/pos-devices'
            valueFormatter={(option) => option?.serial_number}
            displayFormatter={(option) => option?.serial_number}
            boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
            minInputLengthToSearch={13}
            searchKey='serial_number'
            dataTest={dictionary().SERIAL_NUMBER}
          />
        )}
      />
    </FormControl>
  )
}

export default MultipeSerialNumberDropdown
