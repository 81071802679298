import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { parse, stringify } from 'query-string'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'

interface Props {
  data?: any
  handleIsBalancesChange?: () => void
}

const ReviewDuplicateTransactionModel: React.FC<Props> = ({ data, handleIsBalancesChange }) => {
  const query = parse(location.search, { parseNumbers: true, parseBooleans: true })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const { handleSubmit, trigger } = useForm({
    mode: 'onChange',
  })

  const { isLoading, mutate, error } = useMutation(() => fetch('POST', `/fuel-transactions/duplicate/set-as-reviewed/${data?.original_transaction_id}`), {
    onSuccess: (): void => {
      handleIsBalancesChange && handleIsBalancesChange()
      onSuccess()
      onClose()
      push({ search: stringify(query) })
    },
  })
  const reviewTransaction = async () => {
    mutate()
  }

  return (
    <>
      {!data?.is_reviewed && (
        <Tooltip label='مراجعة' placement='top'>
          <img src='/assets/ic_review.svg' onClick={onOpen} style={{ cursor: 'pointer', width: '20px', height: '20px' }} />
        </Tooltip>
      )}

      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent dir='rtl'>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().CONFIRM_REVIEWING} </ModalHeader>
          <form onSubmit={handleSubmit(reviewTransaction as any)}>
            <ModalBody pb={6}>
              <Text fontWeight={'bold'} fontSize={'14px'}>
                {dictionary().DEFAULT_CONFIRM_DESCRIPTION}
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>{dictionary().CANCEL}</Button>
              <Button colorScheme='blue' mx={2} onClick={async () => (await trigger()) && reviewTransaction()}>
                {isLoading ? <Spinner /> : dictionary().DEFAULT_CONFIRM_CONFIRMATION_TEXT}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ReviewDuplicateTransactionModel
