import fetch from '../../../helpers/fetch'
import { StateManagementVariables } from '../../../types/AddFuelTransactionVariables'
type updateVehicleParams = {
  vehicleId: any
  stateManager: StateManagementVariables
  setStateManager: any
  setValue: any
}
const updateVehicle = ({ vehicleId, stateManager, setStateManager, setValue }: updateVehicleParams) => {
  // Check if vehicleId is a valid number
  if (typeof vehicleId.current === 'number' && !Number.isNaN(vehicleId.current)) {
    // Set loading state and mark vehicle as selected
    setStateManager({ ...stateManager, isInputLoading: true, isVehicleSelected: true })

    // Fetch station providers data
    fetch('GET', '/station-providers', { query: { pageSize: 200 } }).then((data) => {
      // Update state with fetched data and toggle loading state
      setStateManager({ ...stateManager, isVehicleSelected: true, stationProdivers: data.data, isInputLoading: false })
    })
  } else {
    // If vehicleId is not a valid number, mark vehicle as not selected and clear input value
    setStateManager({ ...stateManager, isVehicleSelected: false })
    setValue('is_external', '')
  }
}

export default updateVehicle
