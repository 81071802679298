import fetch from '../../../helpers/fetch'
import { StateManagementVariables } from '../../../types/AddFuelTransactionVariables'

type updateCorporateParams = {
  corporateId: any
  setStateManager: any
  setValue: any
}
const updateCorporate = ({ corporateId, setStateManager, setValue }: updateCorporateParams): void => {
  // Check if corporateId has a value
  if (corporateId.current?.length > 0) {
    setStateManager((prevState: StateManagementVariables) => ({
      ...prevState,
      isCorporateSelected: true,
      isInputLoading: true,
    }))
    // Fetch data from server
    fetch('GET', `/corporates/${corporateId.current}`)
      .then((data: any) => {
        setStateManager((prevState: StateManagementVariables) => ({
          ...prevState,
          corporateVehicles: data?.vehicles,
          corporateDrivers: data?.drivers,
          isInputLoading: false,
        }))
      })
      .catch((error) => {
        // Handle error
      })
  } else {
    setStateManager((prevState: StateManagementVariables) => ({
      ...prevState,
      corporateVehicles: [],
      isCorporateSelected: false,
      isVehicleSelected: false,
    }))
    // Reset form values
    setValue('vehicle_id', '')
    setValue('station_provider_id', [])
    setValue('station_id', [])
    setValue('is_external', '')
    setValue('station_staff_id', '')
    setValue('fuel_type_id', [])
    setValue('amount', '')
  }
}

export default updateCorporate
