import React, { useRef, useEffect, useState } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, Select, RadioGroup, Stack, Radio, Checkbox } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'
import { useStore } from '../../store'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import { USER_TYPE } from '../../constants'

const EditVehicle: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { userType } = useStore((state) => state)
  const userData = window.localStorage.getItem('activeRole')
  const { role } = useStore()

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    control,
    watch,
  } = useForm({
    mode: 'onChange',
  })

  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const stratingBalance = useRef({})
  stratingBalance.current = watch('starting_balance_fuel', 0)
  const query = useQuery(['vehicles', id], () => fetch('GET', `/vehicles/${id}`), {})
  const vehicle_types = useQuery(['vehicle-types', id], () => fetch('GET', `/vehicle-types`), {})
  const corporates = useQuery(['corporates', id], () => fetch('GET', `/corporates`), {})
  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('PATCH', `/vehicles`, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push(`/vehicles/${id}`)
    },
    onError,
  })

  useEffect(() => {
    if (query?.data) {
      const fuels: any[] = []
      if (query?.data?.vehicle?.recommended_fuel_types !== undefined) {
        query?.data?.vehicle?.recommended_fuel_types.map((item: any) => fuels.push(item?.id))
      }
      setValue('vehicleTypeId', query?.data?.vehicle?.vehicle_type_id)
      setValue('number_plate', query?.data?.vehicle?.number_plate)
      setValue('chassis_number', query?.data?.vehicle?.chassis_number)
      setValue('model', query?.data?.vehicle?.model)
      setValue('brand', query?.data?.vehicle?.brand)
      setValue('color', query?.data?.vehicle?.color)
      setValue('max_fuel_liters', query?.data?.vehicle?.max_fuel_liters)
      setValue('fuel_consumption_benchmark', query?.data?.vehicle?.fuel_consumption_benchmark)
      setValue('starting_balance_fuel', query?.data?.vehicle?.starting_balance_fuel)
      setValue('replenish_amount', query?.data?.vehicle?.replenish_amount)
      setValue('offline_verification', query?.data?.vehicle?.offline_verification + '')
      setValue('corporateId', [query?.data?.corporate?.id])
      setValue('fuelTypeIds', fuels)
      setValue('code', query?.data?.vehicle?.code)
      setValue('is_active', query?.data?.vehicle?.is_active)
    }
  }, [query?.data, vehicle_types?.data])

  const editVehicle = async (formData: any) => {
    formData.offline_verification = formData?.offline_verification === 'true' ? true : false
    if (formData?.number_plate === query?.data?.vehicle?.number_plate) {
      delete formData?.number_plate
    } else {
      formData.number_plate = formData?.number_plate?.trim()
    }
    if (getValues()?.chassis_number === query?.data?.vehicle?.chassis_number) {
      delete formData?.chassis_number
    } else if (getValues()?.chassis_number === '') {
      delete formData?.chassis_number
    }
    formData.replenish_amount = +formData?.replenish_amount
    formData.id = query?.data?.vehicle?.id

    if (formData?.corporateId) {
      formData.corporateId = formData.corporateId?.[0]
    }
    if (!['SUPER', 'MANAGER'].includes(role ?? '')) {
      delete formData?.corporateId
    }
    mutate(formData)
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().EDIT_VEHICLE + ': ' + getValues().id}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && editVehicle(getValues() as any)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(editVehicle as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl
              id={dictionary().VEHICLES}
              w='40%'
              minH='100px'
              display='inline-block'
              isInvalid={!!errors.number_plate || error?.data?.message === 'Given code, plate or chassis number already exists on another vehicle'}
            >
              <FormLabel>
                <>
                  {dictionary().NUMBER_PLATE}
                  <Reqiured />
                </>
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('number_plate', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.number_plate?.message}</FormErrorMessage>
              <FormErrorMessage>
                {error?.data?.message === `Given code, plate or chassis number already exists on another vehicle` &&
                  dictionary().THIS_PLATE_NUMBER_IS_ALREADY_TAKEN}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().CHASSIS_NUMBER}
              w='40%'
              minH='100px'
              display='inline-block'
              isInvalid={!!errors.chassis_number || error?.data?.message === 'Given code, plate or chassis number already exists on another vehicle'}
            >
              <FormLabel>{dictionary().CHASSIS_NUMBER}</FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('chassis_number', {
                    required: false,
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.chassis_number?.message}</FormErrorMessage>
              <FormErrorMessage>
                {error?.data?.message === `Given code, plate or chassis number already exists on another vehicle` &&
                  dictionary().THIS_PLATE_NUMBER_IS_ALREADY_TAKEN}
              </FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().CAR_MODEL} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.model}>
              <FormLabel>{dictionary().CAR_MODEL}</FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('model', {
                    required: false,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.model?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().CAR_BRAND} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.brand}>
              <FormLabel>{dictionary().CAR_BRAND} </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('brand', {
                    required: false,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.brand?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().CAR_COLOR} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.color}>
              <FormLabel>{dictionary().CAR_COLOR}</FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('color', {
                    required: false,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.color?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().MAX_FUEL_LITERS} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.max_fuel_liters}>
              <FormLabel>
                <>
                  {dictionary().MAX_FUEL_LITERS}
                  <Reqiured />
                </>
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('max_fuel_liters', {
                    required: dictionary().REQUIRED,
                    valueAsNumber: true,
                    min: {
                      value: 0,
                      message: dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO,
                    },
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.max_fuel_liters?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().CONSUMPTION_BENCHMARK} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.fuel_consumption_benchmark}>
              <FormLabel>{dictionary().CONSUMPTION_BENCHMARK}</FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('fuel_consumption_benchmark', {
                    required: false,
                    valueAsNumber: true,
                    min: {
                      value: 0,
                      message: dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO,
                    },
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.fuel_consumption_benchmark?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STARTING_BALANCE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.starting_balance_fuel}>
              <FormLabel>{dictionary().STARTING_BALANCE}</FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('starting_balance_fuel', {
                    required: false,
                    valueAsNumber: true,
                    min: {
                      value: 0,
                      message: dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO,
                    },
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.starting_balance_fuel?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().REPLENISH_AMOUNT} w='40%' minH='100px' isInvalid={!!errors.replenish_amount}>
              <FormLabel>{dictionary().REPLENISH_AMOUNT}</FormLabel>
              <Input
                placeholder='0'
                type='number'
                {...register('replenish_amount', {
                  required: false,
                  pattern: {
                    value: /^\d+$/,
                    message: dictionary().THIS_INPUT_ACCEPTED_ONLY_INTEGER_NUMBERS,
                  },
                  min: {
                    value: 0,
                    message: dictionary().NO_NEGATIVE_NUMBER,
                  },
                  validate: (value) => {
                    if (value > +stratingBalance.current) return dictionary().REPLENISH_AMOUNT_LESS_THAN_STARTING_BALANCE
                  },
                })}
              />
              <FormErrorMessage>{errors.replenish_amount?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id={dictionary().VEHICLE_TYPE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.vehicleTypeId}>
              <FormLabel>{dictionary().VEHICLE_TYPE}</FormLabel>
              <InputGroup>
                <Select
                  dir='ltr'
                  textAlign='right'
                  {...register('vehicleTypeId', {
                    required: false,
                    valueAsNumber: true,
                  })}
                >
                  <option value={''}> {dictionary().VEHICLE_TYPE}</option>
                  {vehicle_types?.data?.data?.map((vehicle_type: any) => (
                    <option key={vehicle_type?.id} value={vehicle_type?.id}>
                      {vehicle_type?.name?.ar}
                    </option>
                  ))}
                </Select>
              </InputGroup>
              <FormErrorMessage>{errors?.vehicleTypeId?.message}</FormErrorMessage>
            </FormControl>

            {userType === USER_TYPE.ADMIN && (
              <FormControl
                isDisabled={!['SUPER', 'MANAGER'].includes(role ?? '')}
                id={dictionary().CORPORATE}
                w='40%'
                minH='100px'
                display='inline-block'
                isInvalid={!!errors.corporateId}
              >
                <FormLabel>
                  <>
                    {dictionary().CORPORATE}
                    <Reqiured />
                  </>
                </FormLabel>
                <Controller
                  name='corporateId'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      onChange={onChange}
                      searchRoute='/corporates'
                      singleRoute='/corporates'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name}
                      isInvalid={!!errors?.corporateId}
                      value={value}
                      multiple={false}
                    />
                  )}
                  rules={{
                    required: true && dictionary().REQUIRED,
                  }}
                />
                <FormErrorMessage>{errors?.corporateId?.message}</FormErrorMessage>
              </FormControl>
            )}
            <FormControl id={dictionary().FUEL_TYPES} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.fuelTypeIds}>
              <FormLabel>
                {dictionary().FUEL_TYPES} <Reqiured />
              </FormLabel>
              <Controller
                name={`fuelTypeIds`}
                control={control}
                rules={{ required: dictionary().REQUIRED }}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    onChange={onChange}
                    searchRoute='/fuel-types'
                    singleRoute='/fuel-types'
                    multiple={true}
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.name?.ar}
                    isInvalid={!!errors?.fuelTypeIds}
                    value={value}
                  />
                )}
              />
              <FormErrorMessage>{errors.fuelTypeIds?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().CODE}
              w='40%'
              minH='100px'
              display='inline-block'
              isInvalid={!!errors.code || error?.data?.message === 'Given code, plate or chassis number already exists on another vehicle'}
            >
              <FormLabel>
                <>
                  {dictionary().CODE}
                  <Reqiured />
                </>
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('code', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.code?.message}</FormErrorMessage>
              <FormErrorMessage>
                {error?.data?.message === `Given code, plate or chassis number already exists on another vehicle` &&
                  dictionary().THIS_PLATE_NUMBER_IS_ALREADY_TAKEN}
              </FormErrorMessage>
            </FormControl>
            <FormControl w='40%' minH='100px' display='inline-block' isInvalid={!!errors.offline_verification}>
              <FormLabel>{dictionary().DRIVER_VERIFICATION}</FormLabel>
              <Controller
                name='offline_verification'
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction='row'>
                      <Radio value='true' color='purple'>
                        {dictionary().OFFLINE_PIN}
                      </Radio>
                      <Radio value='false' color='purple'>
                        {dictionary().QR_CODE}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>{errors.offline_verification?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STATUS} w='40%' minH='100px' display='inline-block'>
              <FormLabel>{dictionary().STATUS}</FormLabel>
              <Controller
                control={control}
                name='is_active'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().is_active}>
                    {dictionary().IS_ACTIVE}
                  </Checkbox>
                )}
              />
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default EditVehicle
