import React, { useRef, useEffect, useState } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, Checkbox } from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import UploadImage from '../../components/core/UploadImage'
import Reqiured from '../../components/core/reqiured'

const EditStationProvider: React.FC = () => {
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/
  const phoneRegex = /^01[0125][0-9]{8}$/

  const { id } = useParams<{ id: string }>()
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
  })

  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const [isFlatRate, setIsFlatRate] = useState(true)

  const stationProvider = useQuery(['station providers', id], () => fetch('GET', `/station-providers/${id}`), {})

  const { isLoading, mutate } = useMutation((vars: any) => fetch('PUT', `/station-providers`, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push(`/station-providers/${id}`)
    },
    onError,
  })

  useEffect(() => {
    setValue('id', stationProvider?.data?.id)
    setValue('ar', stationProvider?.data?.name?.ar)
    setValue('en', stationProvider?.data?.name?.en)

    setValue('logo', stationProvider?.data?.logo)
    setValue('marker_img', stationProvider?.data?.marker_img)
    setValue('contact_person.name', stationProvider?.data?.contact_person?.name)
    setValue('contact_person.phone', stationProvider?.data?.contact_person?.phone)
    setValue('contact_person.email', stationProvider?.data?.contact_person?.email)
    setValue('fee_rate', stationProvider?.data?.fee_rate)
    setValue('is_flat_rate', stationProvider?.data?.is_flat_rate)
    setValue('show_balance', stationProvider?.data?.show_balance)
    setValue('is_active', stationProvider?.data?.is_active)
    setValue('fee_percentage_rate', stationProvider?.data?.fee_percentage_rate)
  }, [stationProvider?.dataUpdatedAt])
  const editStationProviderData = async () => {
    mutate([
      {
        id: getValues()?.id,
        name: { ar: getValues()?.ar?.trim(), en: getValues()?.en?.trim() },
        station_provider_id: getValues()?.station_provider_id,

        username: getValues()?.username?.trim(),
        logo: getValues()?.logo,
        marker_img: getValues()?.marker_img,
        contact_person: {
          name: getValues()?.contact_person?.name?.trim(),
          phone: getValues()?.contact_person?.phone,
          email: getValues()?.contact_person?.email,
        },
        fee_rate: +getValues()?.fee_rate,
        is_active: getValues()?.is_active,
        is_flat_rate: getValues()?.is_flat_rate,
        show_balance: getValues()?.show_balance,
        fee_percentage_rate: !getValues().is_flat_rate ? +getValues().fee_percentage_rate : 0,
      },
    ])
  }
  useEffect(() => {
    if (watch('is_flat_rate')) {
      setIsFlatRate(true)
    } else {
      setIsFlatRate(false)
      setValue('fee_percentage_rate', 0)
    }
  }, [watch('is_flat_rate')])
  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().EDIT_STATION}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && editStationProviderData()} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(editStationProviderData as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <Input
              hidden
              type='text'
              {...register('id', {
                required: dictionary().REQUIRED,
              })}
            />
            <FormControl id={dictionary().STATION_NAME_AR} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.ar}>
              <FormLabel>
                {dictionary().STATION_NAME_AR} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('ar', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.ar?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STATION_NAME_EN} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.en}>
              <FormLabel>
                {dictionary().STATION_NAME_EN} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('en', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.en?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().LOGO} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.logo}>
              <FormLabel>{dictionary().LOGO}</FormLabel>
              <InputGroup>
                <Controller name={`logo`} control={control} render={({ field: { onChange } }) => <UploadImage onChange={onChange} />} />
              </InputGroup>

              <FormErrorMessage>{errors?.logo?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().IMAGE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.marker_img}>
              <FormLabel>{dictionary().IMAGE}</FormLabel>
              <InputGroup>
                <Controller name={`marker_img`} control={control} render={({ field: { onChange } }) => <UploadImage onChange={onChange} />} />
              </InputGroup>

              <FormErrorMessage>{errors?.marker_img?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id={dictionary().STATIONS_CONTACT_NAME} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.contact_person?.name}>
              <FormLabel>
                {dictionary().STATIONS_CONTACT_NAME} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('contact_person.name', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.contact_person?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STATIONS_CONTACT_NUMBER} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.contact_person?.phone}>
              <FormLabel>
                {dictionary().STATIONS_CONTACT_NUMBER} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='number'
                  {...register('contact_person.phone', {
                    required: dictionary().REQUIRED,
                    validate: (value) => (!phoneRegex.test(value) ? dictionary().INVALID_PHONE : undefined),
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.contact_person?.phone?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STATIONS_CONTACT_EMAIL} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.contact_person?.email}>
              <FormLabel>
                {dictionary().STATIONS_CONTACT_EMAIL} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('contact_person.email', {
                    required: dictionary().REQUIRED,
                    validate: (value) => (!emailRegex.test(value) ? dictionary().INVALID_EMAIL : undefined),
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.contact_person?.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().FLAG_RATE} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().FLAG_RATE_OPTION}</FormLabel>
              <Controller
                control={control}
                name='is_flat_rate'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().is_flat_rate}>
                    {dictionary().FLAG_RATE}
                  </Checkbox>
                )}
              />
            </FormControl>
            <FormControl id={dictionary().BALANCE} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().BALANCE}</FormLabel>
              <Controller
                control={control}
                name='show_balance'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().show_balance}>
                    {dictionary().SHOW_BALANCE}
                  </Checkbox>
                )}
              />
            </FormControl>
            {isFlatRate ? (
              <FormControl
                id={dictionary().COMMISSION_FEE}
                w={['100%', '40%', '40%', '40%']}
                minH={['50px', '100px']}
                display='inline-block'
                isInvalid={!!errors?.fee_rate}
              >
                <FormLabel>
                  {dictionary().COMMISSION_FEE} <Reqiured />
                </FormLabel>
                <InputGroup>
                  <Input
                    type='number'
                    {...register('fee_rate', {
                      required: dictionary().REQUIRED,
                      min: {
                        value: 0,
                        message: dictionary().NO_NEGATIVE_NUMBER,
                      },
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.fee_rate?.message}</FormErrorMessage>
              </FormControl>
            ) : (
              <FormControl
                id={dictionary().FEE_PERCENTAGE_RATE}
                w={['100%', '40%', '40%', '40%']}
                minH={['50px', '100px']}
                display='inline-block'
                isInvalid={!!errors?.fee_percentage_rate}
              >
                <FormLabel>
                  {dictionary().FEE_PERCENTAGE_RATE} <Reqiured />
                </FormLabel>
                <InputGroup>
                  <Input
                    type='number'
                    {...register('fee_percentage_rate', {
                      required: dictionary().REQUIRED,
                      min: {
                        value: 0,
                        message: dictionary().NO_NEGATIVE_NUMBER,
                      },
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.fee_percentage_rate?.message}</FormErrorMessage>
              </FormControl>
            )}
            <FormControl id={dictionary().STATUS} w='40%' minH='100px' display='inline-block'>
              <FormLabel>{dictionary().STATUS}</FormLabel>
              <Controller
                control={control}
                name='is_active'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().is_active}>
                    {dictionary().IS_ACTIVE}
                  </Checkbox>
                )}
              />
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default EditStationProvider
