import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'name',
    key: 'name.ar',
    name: dictionary().PRODUCT_NAME,
    type: 'text',
  },
  {
    id: 'price',
    key: 'price',
    name: dictionary().PRICE,
    type: 'cash',
  },
  {
    id: 'station',
    key: 'station.name.ar',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
]

const StoreProducts: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().STORE_PRODUCTS}
      to='/store-products/new'
      tags={[{ name: dictionary().ALL, filter: [{ name: '', value: '' }] }]}
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['store-products']}
      countArrayPath={['count']}
      restRoute={'/store-products'}
      cashingKey={CASHING_KEY.GET_STORE_PRODUCSTS}
      enableIdRedirect={true}
    />
  )
}

export default StoreProducts
