import { Method } from 'axios'
import React from 'react'

import dictionary from '../../dictionary'
import GenericAction from './GenericAction'

interface Props {
  apiMethod: Method
  apiRoute: string
  variables: Record<string, unknown>
  onSuccess?: () => void
}

const DeleteAction: React.FC<Props> = ({ apiRoute, apiMethod, variables, onSuccess }) => {
  return (
    <>
      <GenericAction
        apiRoute={apiRoute}
        apiMethod={apiMethod}
        title={dictionary().DELETE}
        onSuccess={onSuccess}
        variables={variables}
        description={dictionary().DEFAULT_CONFIRM_DESCRIPTION}
        color='red'
      />
    </>
  )
}

export default DeleteAction
