import { Grid } from '@chakra-ui/react'
import { FC } from 'react'
import { ChargingProfile } from '../../../interfaces/data'
import ContractFiles from './ContractFiles'
import VersionDataDetails from './VersionFixedData'

interface Props {
  versionData: ChargingProfile
  isCurrentVersion?: boolean
  reference?: any
  setGetValues?: any
  disableEditable?: boolean
  actions?: any
}

const VersionDetails: FC<Props> = ({ actions, versionData, reference, setGetValues, disableEditable }) => {
  return (
    <Grid gap={4}>
      <VersionDataDetails versionData={versionData} reference={reference} setGetValues={setGetValues} disableEditable={disableEditable} actions={actions} />
      {/* <VersionEditableData versionData={versionData} /> */}
      <ContractFiles contractFiles={versionData?.files} />
    </Grid>
  )
}
//   [
//   { type: 'pdf', name: 'Charging System', link: '/assets/Charging-system-BRD.pdf' },
//   { type: 'pdf', name: 'Fuel Logic', link: '/assets/Fuel-transactions-logic.pdf' },
//   { type: 'image', name: 'Flower', link: '/assets/simple-flower.jpg' },
//   { type: 'image', name: 'pump', link: 'https://uat-fuel-attendant.s3.us-east-2.amazonaws.com/fuel-transaction/1288633/pump/image' },
// ]
// }

export default VersionDetails
