import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { autocapturePlugin } from '@amplitude/plugin-autocapture-browser'

const AmplitudeInit = (user: any) => {
  try {
    const apiKey = import.meta.env.VITE_AMPLITUDE_API_KEY!

    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: undefined,
    })

    amplitude.add(sessionReplayTracking)

    const plugin = autocapturePlugin({
      cssSelectorAllowlist: ['.amp-tracking', '[amp-tracking]'],
      pageUrlAllowlist: ['https://amplitude.com', new RegExp('https://amplitude.com/blog/*')],
    })

    amplitude.add(plugin)

    amplitude.add(autocapturePlugin())

    const userId = `admin-${user?.id}`

    amplitude.init(apiKey, userId, {
      defaultTracking: {
        sessions: true,
        pageViews: true,
        formInteractions: true,
      },
      appVersion: import.meta.env.VITE_ENTITY! + ' ' + import.meta.env.VITE_VERSION!, // to know if it's corporate or corporate-group
      identityStorage: 'localStorage',
    })

    const identifyEvent = new amplitude.Identify()

    user?.id && identifyEvent.set('id', user?.id)
    user?.role && identifyEvent.set('role', user?.role)
    user?.userType && identifyEvent.set('userType', user?.userType)
    user?.username && identifyEvent.set('username', user?.username)
    user?.email && identifyEvent.set('email', user?.email)

    amplitude.identify(identifyEvent)
  } catch (error) {
    console.log(error)
  }
}

export default AmplitudeInit
