import { FC, useEffect, useRef } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Text,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import filterIconSrc from '../../assets/filter.svg'
import dictionary from '../../dictionary'
import { useFilterStore } from '../../stores/filterStore'
import AutoCompleteInput from './AutoCompleteInput'

type AdvancedFilterModalProps = any

const AdvancedFilterModal: FC<AdvancedFilterModalProps> = ({ isOpen, onOpen, onClose }: any) => {
  const { setCorporateIds, setStationIds, setVehicleIds, setDriverIds, setStationId, setCorporateId, setAdvancedSearch } = useFilterStore()
  const searchRef = useRef<HTMLButtonElement>(null)
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({ mode: 'onChange' })

  const fieldValues = {
    // transaction_id: watch('transaction_id', []),
    corporate: watch('corporate', []),
    stations: watch('stations', []),
    plate_number: watch('plate_number', []),
    vehicle_code: watch('vehicle_code', []),
    driver_name: watch('driver_name', []),
    driver_code: watch('driver_code', []),
  }

  useEffect(() => {
    setValue('corporate', [])
    setValue('stations', [])
    setValue('plate_number', [])
    setValue('vehicle_code', [])
    setValue('driver_name', [])
    setValue('driver_code', [])
  }, [])

  const clearAll = () => {
    setStationId(null)
    setCorporateId(null)
    Object.keys(fieldValues).forEach((field) => setValue(field, []))
    ;[setCorporateIds, setStationIds, setVehicleIds, setDriverIds].forEach((setIds) => setIds([]))
    setAdvancedSearch(false)
    onClose()
  }

  const submit = async () => {
    if (Object.values(fieldValues).some((value) => value?.length > 0 && value !== undefined)) {
      setAdvancedSearch(true)
      setStationId(null)
      setCorporateId(null)
    }

    // if (fieldValues.transaction_id?.length) {
    //   setTransactionIds(fieldValues.transaction_id || [])
    // }

    setCorporateIds(fieldValues?.corporate || [])
    setStationIds(fieldValues?.stations || [])
    setVehicleIds([...fieldValues?.vehicle_code, ...fieldValues?.plate_number] || [])
    setDriverIds([...fieldValues?.driver_name, ...fieldValues?.driver_code] || [])

    onClose()
  }

  return (
    <>
      <Box position='relative' display='inline-block'>
        <Button onClick={onOpen} variant='outline' style={{ backgroundColor: '#7165E3' }} _focus={{ boxShadow: 'none' }}>
          <Image src={filterIconSrc} width={25} height={30} />
        </Button>
        {/* Red dot indicator */}
        {Object.values(fieldValues).some((value) => value?.length > 0) && (
          <Box position='absolute' top='-1' right='-1' width='15px' height='15px' borderRadius='50%' backgroundColor='red'></Box>
        )}
      </Box>
      <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={searchRef} onClose={submit} isOpen={isOpen} isCentered autoFocus colorScheme={'purple'}>
        <AlertDialogOverlay />
        <AlertDialogContent dir='rtl' maxW='900px'>
          <AlertDialogHeader>{dictionary().ADVANCED_FILTERATION}</AlertDialogHeader>
          <AlertDialogBody>
            {/* <FormControl id={dictionary().TRANSACTION_ID} w='100%' dir='rtl' marginTop={5}>
              <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                {dictionary().TRANSACTION_ID}{' '}
                <Text
                  style={{
                    width: '20px',
                    height: '20px',
                    color: '#7165E3',
                    borderRadius: '50%',
                    backgroundColor: '#EDF2F7',
                    textAlign: 'center',
                    fontSize: '12px',
                  }}
                >
                  {fieldValues.transaction_id?.length || 0}
                </Text>
              </FormLabel>
              <Controller
                name={`transaction_id`}
                rules={{ required: false }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    isInvalid={!!errors?.second_ref_id}
                    onChange={onChange}
                    value={value}
                    searchRoute='/fuel-transactions'
                    singleRoute='/fuel-transactions'
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.id}
                    multiple
                    boxProps={{ maxHeight: '40px', overflowY: 'auto' }}
                  />
                )}
              />
            </FormControl> */}
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
              <FormControl id={dictionary().CHOOSE_CORPORATE} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().CHOOSE_CORPORATE}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.corporate?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`corporate`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      isInvalid={!!errors?.second_ref_id}
                      onChange={onChange}
                      value={value}
                      searchRoute='/corporates'
                      singleRoute='/corporates'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name}
                      multiple
                      boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                    />
                  )}
                />
              </FormControl>
              <FormControl id={dictionary().STATIONS} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().CHOOSE_STATION}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.stations?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`stations`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      isInvalid={!!errors?.second_ref_id}
                      onChange={onChange}
                      value={value}
                      searchRoute='/stations'
                      singleRoute='/stations'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name?.en}
                      multiple={true}
                      boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
              {' '}
              <FormControl id={dictionary().NUMBER_PLATE} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().NUMBER_PLATE}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.plate_number?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`plate_number`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      isInvalid={!!errors?.second_ref_id}
                      onChange={onChange}
                      value={value}
                      searchRoute='/vehicles'
                      singleRoute='/vehicles'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.number_plate}
                      multiple={true}
                      boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                    />
                  )}
                />
              </FormControl>
              <FormControl id={dictionary().VEHICLE_CODE} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().VEHICLE_CODE}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.vehicle_code?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`vehicle_code`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      isInvalid={!!errors?.second_ref_id}
                      onChange={onChange}
                      value={value}
                      searchRoute='/vehicles'
                      singleRoute='/vehicles'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => {
                        return option?.code !== null ? option?.code : ''
                      }}
                      multiple={true}
                      boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
              <FormControl id={dictionary().DRIVER_NAME} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().DRIVER_NAME}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.driver_name?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`driver_name`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      isInvalid={!!errors?.second_ref_id}
                      onChange={onChange}
                      value={value}
                      searchRoute='/drivers'
                      singleRoute='/drivers'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name}
                      multiple={true}
                      boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                    />
                  )}
                />
              </FormControl>
              <FormControl id={dictionary().DRIVER_CODE} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().DRIVER_CODE}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.driver_code?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`driver_code`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      isInvalid={!!errors?.second_ref_id}
                      onChange={onChange}
                      value={value}
                      searchRoute='/drivers'
                      singleRoute='/drivers'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.code}
                      multiple={true}
                      boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant={'outline'} onClick={onClose}>
              {dictionary().CANCEL}
            </Button>
            <Button mr='3' style={{ backgroundColor: '#E0E0E0' }} variant={'solid'} onClick={() => clearAll()}>
              {dictionary().CLEAR_ALL}
            </Button>
            <Button ref={searchRef} mr='3' colorScheme='blue' variant={'solid'} onClick={() => submit()}>
              {dictionary().SEARCH}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default AdvancedFilterModal
