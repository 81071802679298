import React, { useRef, useEffect } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, Select } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'
import PasswordAndConfirmation from '../../components/core/PasswordAndConfirmation'

const roles = [
  {
    id: 1,
    role: 'MANAGER',
    name: dictionary().MANAGER,
  },
  {
    id: 2,
    role: 'ACCOUNTANT',
    name: dictionary().ACCOUNTANT,
  },
]

const AddNewStaff: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    getValues,
    watch,
    trigger,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const phoneRegex = /^01[0125][0-9]{8}$/

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/station-provider-staff', { data: vars }), {
    onSuccess: (): void => {
      push(`/station-providers/${id}`)

      onSuccess()
    },
    onError,
  })
  useEffect(() => {
    setValue('station_provider_id', id)
  }, [])
  const AddNewStaff = async () => {
    mutate([
      {
        name: {
          en: getValues()?.en,
          ar: getValues()?.ar,
        },
        username: getValues()?.username,
        password: getValues()?.password,
        phone_number: getValues()?.phone_number,
        role: getValues()?.role,
        station_provider_id: getValues()?.station_provider_id,
      },
    ])
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header title={dictionary().ADD_NEW_STAFF} action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && AddNewStaff()} />} />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(AddNewStaff as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl id={dictionary().NAME_AR} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.ar}>
              <FormLabel>
                {dictionary().NAME_AR} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('ar', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.ar?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().NAME_EN} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.en}>
              <FormLabel>
                {dictionary().NAME_EN} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('en', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.en?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().USERNAME}
              w='40%'
              minH='100px'
              display='inline-block'
              isInvalid={!!errors.username || error?.data?.message === `Station provider staff with given username '${getValues().username}' already exists`}
            >
              <FormLabel>
                {dictionary().USERNAME} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('username', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
              <FormErrorMessage>
                {error?.data?.message === `Station provider staff with given username '${getValues().username}' already exists` &&
                  dictionary().THIS_USER_NAME_IS_ALREADY_TAKEN}
              </FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STATIONS_CONTACT_NUMBER} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.phone_number}>
              <FormLabel>
                {dictionary().STATIONS_CONTACT_NUMBER} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='number'
                  {...register('phone_number', {
                    required: dictionary().REQUIRED,
                    validate: (value) => (!phoneRegex.test(value) ? dictionary().INVALID_PHONE : undefined),
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.phone_number?.message}</FormErrorMessage>
            </FormControl>
            <PasswordAndConfirmation register={register} watch={watch} width='40%' minHight='100px' errors={errors} />
            <FormControl hidden={true} id={dictionary().ROLE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.role}>
              <FormLabel>{dictionary().ROLE}</FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('role', {
                    required: dictionary().REQUIRED,
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors.role?.message}</FormErrorMessage>
            </FormControl>

            <FormControl hidden={true} id={dictionary().ID} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.station_provider_id}>
              <FormLabel>{dictionary().ID}</FormLabel>
              <InputGroup>
                <Input
                  type='number'
                  {...register('station_provider_id', {
                    required: dictionary().REQUIRED,
                    valueAsNumber: true,
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.station_provider_id?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().ROLE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.role}>
              <FormLabel>{dictionary().ROLE}</FormLabel>
              <Select dir='ltr' textAlign='right' {...register('role', { required: dictionary().REQUIRED })} placeholder={dictionary().ROLE}>
                {roles?.map((item: any) => (
                  <option key={item?.id} value={item?.role}>
                    {' '}
                    {item?.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.role?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().ROLE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.role}></FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddNewStaff
