import React from 'react'
import { Flex, Image, Center } from '@chakra-ui/react'

import LogoSrc from '../../assets/logoNew.png'

interface Props {
  boxSize?: string
}

const Logo: React.FC<Props> = ({ boxSize = '200px' }) => {
  return (
    <Flex display='flex' justify='center' p={2}>
      <Center w='75%' h='100%'>
        <Image boxSize={boxSize} src={LogoSrc} />
      </Center>
    </Flex>
  )
}

export default Logo
