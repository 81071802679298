import React from 'react'
import { Box, FormControl, FormLabel, Input, Button, FormErrorMessage, Text } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { clone, isEmpty } from 'ramda'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import PasswordInput from '../../components/core/PasswordInput'
import { useStore } from '../../store'
import { getMustSearch } from '../../constants/mustSearch'

type MutationLoginArgs = any

interface FormTypes {
  username: string
  password: string
}

const LoginForm: React.FC = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormTypes>()
  const { setToken, setUserType, setRole, setUser } = useStore((state) => state)

  const { go, push } = useHistory()
  const { pathname } = useLocation()
  const route = pathname.split('/')[1]
  const { refetch } = useQuery(['admin'], () => fetch('GET', `/admin/current`), {
    enabled: false,
  })
  const { isLoading, mutate, error } = useMutation((vars: MutationLoginArgs) => fetch('post', `${route}/sign-in`, vars), {
    onSuccess: (data): void => {
      setToken(data.accessToken)
      setUserType('admin')
      refetch().then((res) => {
        if (!isEmpty(res)) {
          const mustSearch = getMustSearch(res?.data?.role)
          const roleData = clone(res.data)
          setRole(roleData.role)
          setUser({ ...roleData, userType: 'admin' })
          localStorage.setItem('activeRole', JSON.stringify({ ...roleData, userType: 'admin', mustSearch }))
        }
        go(0)
      })
    },
  })
  const login = async (formData: FormTypes) => {
    mutate({ data: { ...formData, username: formData?.username.trim().toLowerCase() } })
  }

  return (
    <form onSubmit={handleSubmit(login)}>
      <Box className='margin-100'>
        <FormControl id='username' isInvalid={!!errors.username}>
          <FormLabel>{dictionary().USERNAME}</FormLabel>
          <Input
            dir='ltr'
            type='text'
            id='username'
            autoComplete='username'
            {...register('username', {
              required: dictionary().REQUIRED,
            })}
          />
          <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
        </FormControl>

        <FormControl id='password' isInvalid={!!errors.password}>
          <FormLabel>{dictionary().PASSWORD}</FormLabel>
          <PasswordInput
            inputProps={{
              ...register('password', {
                required: dictionary().REQUIRED,
              }),
              isInvalid: !!errors.password,
              autoComplete: 'current-password',
            }}
          />
          <FormErrorMessage>{dictionary().REQUIRED}</FormErrorMessage>
        </FormControl>
        <p style={{ color: 'red' }}>{(error as any)?.data?.message && dictionary().INCORRECT_USERNAME_OR_PASSWORD}</p>
        <Button
          data-test='sign-in-button'
          isLoading={isLoading}
          loadingText={dictionary().LOGGINGIN}
          colorScheme='blue'
          variant='solid'
          width='100%'
          type='submit'
        >
          {dictionary().LOGIN}
        </Button>
        <Text className='margin-20' textAlign={'center'} onClick={() => push('/forget-password')} textDecor={'underline'} cursor={'pointer'}>
          نسيت كلمة المرور؟
        </Text>
      </Box>
    </form>
  )
}

export default LoginForm
