import { Box, Flex, GridItem, Skeleton, Stack, Text } from '@chakra-ui/react'
import { pathOr } from 'ramda'
import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import Card from '../../components/core/Card'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import Table from '../core/Table'
import CsvDownloader from './Download'

const CustomerCorporates: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [corporatesData, setCorporatesData] = React.useState<any>([])
  const { mutate, isLoading } = useMutation(() => fetch('GET', `/customers/${id}/corporates`), {
    onSuccess: (data) => {
      setCorporatesData(data)
    },
  })

  useEffect(() => {
    mutate()
  }, [])

  const corporatesHeaders = [
    {
      id: 'corporate_id',
      key: 'corporate_id',
      name: dictionary().ID,
      type: 'text',
    },
    {
      id: 'corporate_name',
      key: 'corporate_name',
      name: dictionary().CORPORATE,
      type: 'text',
    },
    {
      id: 'corporate_tax_id',
      key: 'corporate_tax_id',
      name: dictionary().TAX_ID,
      type: 'text',
    },
  ]

  return (
    <>
      <Card h='100%' p={3}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Text m={3} fontSize='20px' fontWeight={700} p={[5, 2, 2, 2]}>
            {dictionary().TAX_RECORD}
          </Text>
          <Flex gap={3}>
            <CsvDownloader
              query={''}
              headers={corporatesHeaders}
              PAGE_SIZE={''}
              setPAGE_SIZE={''}
              restRoute={`/customers/${id}/corporates`}
              isUpload={true}
              accounting={false}
            />
          </Flex>
        </Flex>
        <hr />
        {isLoading ? (
          <>
            <Skeleton height='50px' my={4} />
            <Skeleton height='50px' />
          </>
        ) : (
          <GridItem m='2' colSpan={6} mt={7}>
            <Box w='100%' pr='0'>
              <Stack>
                <GridItem>
                  <Card h='100%'>
                    {corporatesData?.data?.length > 0 ? (
                      <Table
                        raised
                        enableIdRedirect={false}
                        handleIsBalancesChange={mutate}
                        height={390}
                        headers={corporatesHeaders}
                        data={pathOr([], ['data'], corporatesData)}
                      />
                    ) : (
                      <Text>{dictionary().NO_RESULTS_FOUND}</Text>
                    )}
                  </Card>
                </GridItem>
              </Stack>
            </Box>
          </GridItem>
        )}
      </Card>
    </>
  )
}

export default CustomerCorporates
