import React from 'react'
import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'duplicate_transaction_id',
    key: 'original_transaction_id',
    name: dictionary().TRANSACTION_ID,
    type: 'text',
  },
  {
    id: 'corporate_name',
    key: 'corporate_name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'station_name',
    key: 'station_name',
    name: dictionary().STATION,
    type: 'text',
  },
  {
    id: 'drivername',
    key: 'drivername',
    name: dictionary().DRIVER,
    type: 'text',
  },
  {
    id: 'plate_number',
    key: 'plate_number',
    name: dictionary().NUMBER_PLATE,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().AMOUNT,
    type: 'cash',
  },
  {
    id: 'date_and_time',
    key: 'date_and_time',
    name: dictionary().DATE,
    type: 'date_UTC',
  },
  {
    id: 'date_and_time',
    key: 'date_and_time',
    name: dictionary().TIME,
    type: 'time_UTC',
  },
  {
    id: 'duplicatedActions',
    key: 'duplicatedActions',
    name: '',
    type: 'modal',
  },
]

const DuplicateTransactions: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().DUPLICATED_TRANSACTIONS}
      tags={[
        { name: dictionary().ALL, filter: [{ name: '', value: '' }] },
        {
          name: dictionary().TO_REVIEW,
          filter: [{ name: 'is_reviewed', value: false }],
        },
        {
          name: dictionary().REVIEWED,
          filter: [{ name: 'is_reviewed', value: true }],
        },
      ]}
      hasSearch={false}
      hasSearchDate={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['ducplicate-transaction']}
      countArrayPath={['count']}
      restRoute={'/fuel-transactions/duplicate/filtered-original-transactions'}
      cashingKey={CASHING_KEY.GET_DUPLICTE_TRANSACTIONS}
      enableIdRedirect={false}
    />
  )
}

export default DuplicateTransactions
