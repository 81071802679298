import React from 'react'
import ViewOne from '../../components/layout/ViewOne'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const header = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().TRIP_ID,
    type: 'text',
  },
  {
    id: 'driver.name',
    key: 'driver.name',
    name: dictionary().DRIVER_NAME,
    type: 'text',
  },
  {
    id: 'starting_point',
    key: 'starting_point',
    name: dictionary().STARTING_POINT_NAME,
    type: 'text',
  },
  {
    id: 'destination',
    key: 'destination',
    name: dictionary().DESTINATION,
    type: 'text',
  },
  {
    id: 'product_type.name.ar',
    key: 'product_type.name.ar',
    name: dictionary().PRODUCT_NAME,
    type: 'text',
  },
  {
    id: 'vehicle',
    key: 'vehicle.number_plate',
    name: dictionary().NUMBER_PLATE,
    type: 'text',
  },
  {
    id: 'weight',
    key: 'weight',
    name: dictionary().WEIGHT + ' (' + dictionary().KG + ')',
    type: 'text',
  },
]
const actions = [{ type: 'path', action: 'edit', title: dictionary().EDIT }]

const Trip: React.FC = () => {
  return (
    <>
      <ViewOne actions={actions} header={header} restRoute='/trips' dataLocation={''} title={dictionary().TRIPS} cashingKey={CASHING_KEY.GET_SINGLE_TRIP} />
    </>
  )
}

export default Trip
