import React, { useEffect, useRef, useState } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, Checkbox } from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'
import { useMutation } from 'react-query'

import { useHistory } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import UploadImage from '../../components/core/UploadImage'
import Reqiured from '../../components/core/reqiured'
import PasswordAndConfirmation from '../../components/core/PasswordAndConfirmation'

const AddStationProvider: React.FC = () => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/
  const phoneRegex = /^01[0125][0-9]{8}$/

  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const [isFlatRate, setIsFlatRate] = useState(true)

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/station-providers', { data: vars }), {
    onSuccess: (): void => {
      push('/station-providers')

      onSuccess()
    },
    onError,
  })

  const AddStationProvider = async () => {
    const feeRate = +getValues().fee_rate
    const feePercentageRate = !getValues().is_flat_rate ? +getValues().fee_percentage_rate : 0
    mutate([
      {
        name: { en: getValues()?.name?.en.trim(), ar: getValues()?.name?.ar.trim() },
        logo: getValues()?.logo?.name,
        marker_img: getValues()?.marker_img?.name,
        contact_person: {
          name: getValues()?.contact_person?.name.trim(),
          phone: getValues()?.contact_person?.phone,
          email: getValues()?.contact_person?.email.trim(),
        },
        username: getValues().username.trim(),
        password: getValues().password,
        fee_rate: feeRate,
        fee_percentage_rate: feePercentageRate,
        is_flat_rate: getValues().is_flat_rate,
        show_balance: getValues().show_balance,
      },
    ] as any)
  }
  useEffect(() => {
    setValue('is_flat_rate', true)
  }, [])

  useEffect(() => {
    if (watch('is_flat_rate')) {
      setIsFlatRate(true)
    } else {
      setIsFlatRate(false)
      setValue('fee_percentage_rate', 0)
    }
  }, [watch('is_flat_rate')])

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().ADD_NEW_STATION_PROVIDER}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && AddStationProvider()} />}
        />
      </Flex>

      <Card p='8'>
        <form onSubmit={handleSubmit(AddStationProvider as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='10%' align='center' justify='center'>
            <FormControl
              id={dictionary().STATION_PROVIDERS_NAME_EN}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.name?.en}
            >
              <FormLabel>
                {dictionary().STATION_PROVIDERS_NAME_EN} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('name.en', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.en?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().STATION_PROVIDERS_NAME_AR}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.name?.ar}
            >
              <FormLabel>
                {dictionary().STATION_PROVIDERS_NAME_AR} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('name.ar', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.ar?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().STATION_PROVIDER_SUPERVISOR_NAME}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.contact_person?.name}
            >
              <FormLabel>
                {dictionary().STATION_PROVIDER_SUPERVISOR_NAME} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('contact_person.name', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.contact_person?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().STATION_PROVIDER_SUPERVISOR_PHONE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.contact_person?.phone}
            >
              <FormLabel>
                {dictionary().STATION_PROVIDER_SUPERVISOR_PHONE} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('contact_person.phone', {
                    required: dictionary().REQUIRED,
                    validate: (value) => (!phoneRegex.test(value) ? dictionary().INVALID_PHONE : undefined),
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.contact_person?.phone?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().STATION_PROVIDER_SUPERVISOR_EMAIL}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.contact_person?.email}
            >
              <FormLabel>
                {dictionary().STATION_PROVIDER_SUPERVISOR_EMAIL} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='email'
                  {...register('contact_person.email', {
                    required: dictionary().REQUIRED,
                    validate: (value) => (!emailRegex.test(value) ? dictionary().INVALID_EMAIL : undefined),
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors.contact_person?.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().USERNAME}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.username || error?.data?.message === `Station provider staff with given username '${getValues().username}' already exists`}
            >
              <FormLabel>
                {dictionary().USERNAME} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('username', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
              <FormErrorMessage>
                {error?.data?.message === `Station provider staff with given username '${getValues().username}' already exists` &&
                  dictionary().THIS_USER_NAME_IS_ALREADY_TAKEN}
              </FormErrorMessage>
            </FormControl>
            <PasswordAndConfirmation register={register} errors={errors} watch={watch} width={['100%', '40%', '40%', '40%']} minHight={['50px', '100px']} />
            <FormControl
              id={dictionary().MARKER_IMG}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.marker_img}
            >
              <FormLabel>{dictionary().MARKER_IMG}</FormLabel>

              <Controller name={`marker_img`} control={control} render={({ field: { onChange } }) => <UploadImage onChange={onChange} />} />

              <FormErrorMessage>{errors.marker_img?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().LOGO} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.logo}>
              <FormLabel>{dictionary().LOGO}</FormLabel>

              <Controller name={`logo`} control={control} render={({ field: { onChange } }) => <UploadImage onChange={onChange} />} />

              <FormErrorMessage>{errors.logo?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().FLAG_RATE} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().FLAG_RATE_OPTION}</FormLabel>
              <Controller
                control={control}
                name='is_flat_rate'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} textTransform='capitalize' value='true' defaultChecked>
                    {dictionary().FLAG_RATE}
                  </Checkbox>
                )}
              />
            </FormControl>
            <FormControl id={dictionary().BALANCE} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().BALANCE}</FormLabel>
              <Controller
                control={control}
                name='show_balance'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} textTransform='capitalize' value='true' defaultChecked>
                    {dictionary().SHOW_BALANCE}
                  </Checkbox>
                )}
              />
            </FormControl>
            {isFlatRate ? (
              <FormControl
                id={dictionary().COMMISSION_FEE}
                w={['100%', '40%', '40%', '40%']}
                minH={['50px', '100px']}
                display='inline-block'
                isInvalid={!!errors?.fee_rate}
              >
                <FormLabel>
                  {dictionary().COMMISSION_FEE} <Reqiured />
                </FormLabel>
                <InputGroup>
                  <Input
                    type='number'
                    {...register('fee_rate', {
                      required: dictionary().REQUIRED,
                      min: {
                        value: 0,
                        message: dictionary().NO_NEGATIVE_NUMBER,
                      },
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.fee_rate?.message}</FormErrorMessage>
              </FormControl>
            ) : (
              <FormControl
                id={dictionary().FEE_PERCENTAGE_RATE}
                w={['100%', '40%', '40%', '40%']}
                minH={['50px', '100px']}
                display='inline-block'
                isInvalid={!!errors?.fee_percentage_rate}
              >
                <FormLabel>
                  {dictionary().FEE_PERCENTAGE_RATE} <Reqiured />
                </FormLabel>
                <InputGroup>
                  <Input
                    type='number'
                    {...register('fee_percentage_rate', {
                      required: dictionary().REQUIRED,
                      min: {
                        value: 0,
                        message: dictionary().NO_NEGATIVE_NUMBER,
                      },
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.fee_percentage_rate?.message}</FormErrorMessage>
              </FormControl>
            )}
            <FormControl w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'></FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddStationProvider
