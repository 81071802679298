import moment from 'moment'

type Props = (value: any, format?: string) => string | null

export const formatDate: Props = (value, format = 'YYYY-MM-DD') => {
  if (value) {
    return moment(new Date(value)).tz('Africa/Cairo').format(format)
  }
  return null
}
