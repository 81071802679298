import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'name',
    key: 'name.ar',
    name: dictionary().FUEL_NAME,
    type: 'text',
  },
  {
    id: 'price',
    key: 'price',
    name: dictionary().PRICE,
    type: 'cash',
  },
  {
    id: 'fuel_date_created',
    key: 'updated_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'fuel_time_created',
    key: 'updated_at',
    name: dictionary().TIME,
    type: 'time',
  },
]

const FuelTypes: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().FUEL_TYPES}
      to='/fuel-types/new'
      tags={[{ name: dictionary().ALL, filter: [{ name: '', value: '' }] }]}
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['fuel-type']}
      countArrayPath={['count']}
      restRoute={'/fuel-types'}
      cashingKey={CASHING_KEY.GET_FUEL_TYPE}
    />
  )
}

export default FuelTypes
