import React, { useEffect, useRef } from 'react'
import {
  Flex,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  ModalFooter,
  Button,
  Spinner,
  InputGroup,
  Input,
  FormErrorMessage,
  Checkbox,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'

interface Props {
  data?: any
  handleIsBalancesChange?: any
}

const UpdateVehicleStatus: React.FC<Props> = ({ data, handleIsBalancesChange }) => {
  const { push } = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isLoading, mutate } = useMutation((vars: any) => fetch('PATCH', `/vehicles`, { data: vars }), {
    onSuccess: (): void => {
      onClose()
      handleIsBalancesChange && handleIsBalancesChange()
      push('/vehicles')
    },
  })

  const { control, handleSubmit, getValues, trigger, setValue } = useForm({ mode: 'onChange' })

  const Save = async () => {
    mutate({
      id: data?.id,
      is_active: getValues().is_active === 'true' ? true : false,
    })
  }

  useEffect(() => {
    setValue('is_active', data?.is_active + '')
  }, [data])

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  return (
    <>
      <Button onClick={onOpen}>{dictionary().EDIT}</Button>

      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().EDIT_VEHICLE_STATUS} </ModalHeader>
          <ModalBody pb={6}>
            <form dir='rtl' onSubmit={handleSubmit(Save as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                <FormControl w='40%' minH='100px' display='inline-block'>
                  <FormLabel>{dictionary().STATUS}</FormLabel>
                  <Controller
                    name='is_active'
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <Stack direction='row'>
                          <Radio value='true' color='purple'>
                            {dictionary().IS_ACTIVE}
                          </Radio>
                          <Radio value='false' color='purple'>
                            {dictionary().NOT_ACTIVE}
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                    rules={{
                      required: true && dictionary().REQUIRED,
                    }}
                  />
                </FormControl>
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button onClick={async () => (await trigger()) && Save()} colorScheme='blue' mr={3}>
              {isLoading ? <Spinner /> : dictionary().SAVE}
            </Button>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UpdateVehicleStatus
