import { FC, useEffect } from 'react'

import { Box, Stack, FormControl, FormLabel, InputGroup, FormErrorMessage, RadioGroup, Radio, Textarea } from '@chakra-ui/react'

import { Controller } from 'react-hook-form'
import dictionary from '../../../dictionary'
import Reqiured from '../reqiured'

interface Props {
  setValue: any
  control: any
  errors: any
  watch: any
  register: any
}

const PaymentRejectReson: FC<Props> = ({ setValue, control, errors, watch, register }) => {
  const rejectReason = watch('reason')
  useEffect(() => {
    setValue('reason', dictionary().PICTURE_NOT_CLEAR)
  }, [])

  return (
    <>
      <Box dir={'rtl'} mt={5}>
        <FormControl id='reason' w='100%' isInvalid={!!errors.reason}>
          <Controller
            name='reason'
            control={control}
            render={({ field }) => (
              <RadioGroup {...field} defaultValue={dictionary().PICTURE_NOT_CLEAR}>
                <Stack direction='column' gap={2}>
                  <Radio value={dictionary().PICTURE_NOT_CLEAR} color='purple'>
                    {dictionary().PICTURE_NOT_CLEAR}
                  </Radio>
                  <hr />
                  <Radio value={dictionary().NO_IMG} color='purple'>
                    {dictionary().NO_IMG}
                  </Radio>
                  <hr />
                  <Radio value='false' color='purple'>
                    {dictionary().OTHER}
                  </Radio>
                </Stack>
              </RadioGroup>
            )}
            rules={{
              required: true && dictionary().REQUIRED,
            }}
          />
          <FormErrorMessage>{errors.reason?.message}</FormErrorMessage>
        </FormControl>
        {rejectReason === 'false' && (
          <FormControl id='other_reason' mt={5} w='100%' isInvalid={!!errors.other_reason}>
            <FormLabel>
              {dictionary().REASON} <Reqiured />
            </FormLabel>
            <InputGroup size='md'>
              <Textarea
                row={3}
                {...register('other_reason', {
                  required: dictionary().REQUIRED,
                })}
                type={'text'}
                placeholder={dictionary().REASON_DISCREPTION}
                dir={'rtl'}
              />
            </InputGroup>
            <FormErrorMessage>{errors.other_reason?.message}</FormErrorMessage>
          </FormControl>
        )}
      </Box>
    </>
  )
}

export default PaymentRejectReson
