import React from 'react'
import { useParams } from 'react-router'
import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

let headers: any[] = []
const vehicleHeaders = [
  {
    id: 'toll_transaction_date',
    key: 'created_at',
    name: dictionary().TRANSACTION_DATE,
    type: 'date',
  },
  {
    id: 'toll_transaction_time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'toll_fees',
    key: 'toll_fees',
    name: dictionary().TOLL_AMOUNT,
    type: 'cash',
  },
  {
    id: 'weight_fees',
    key: 'weight_fees',
    name: dictionary().WEIGHT_FEES,
    type: 'cash',
  },
  {
    id: 'fine_fees',
    key: 'fine_fees',
    name: dictionary().FINE_FEES,
    type: 'cash',
  },
  {
    id: 'station_name',
    key: 'station.name.ar',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
]
const corporateHeaders = [
  {
    id: 'toll_transaction_date',
    key: 'created_at',
    name: dictionary().TRANSACTION_DATE,
    type: 'date',
  },
  {
    id: 'toll_transaction_time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'vehicle_id',
    key: 'vehicle.id',
    name: dictionary().VEHICLE_ID,
    type: 'text',
  },
  {
    id: 'toll_fees',
    key: 'toll_fees',
    name: dictionary().TOLL_AMOUNT,
    type: 'cash',
  },
  {
    id: 'weight_fees',
    key: 'weight_fees',
    name: dictionary().WEIGHT_FEES,
    type: 'cash',
  },
  {
    id: 'fine_fees',
    key: 'fine_fees',
    name: dictionary().FINE_FEES,
    type: 'cash',
  },
  {
    id: 'station_name',
    key: 'station.name.ar',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
]
const stationProviderHeaders = [
  {
    id: 'toll_transaction_date',
    key: 'created_at',
    name: dictionary().TRANSACTION_DATE,
    type: 'date',
  },
  {
    id: 'toll_transaction_time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'vehicle_id',
    key: 'vehicle.id',
    name: dictionary().VEHICLE_ID,
    type: 'text',
  },
  {
    id: 'corporate_name',
    key: 'corporate.name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'toll_fees',
    key: 'toll_fees',
    name: dictionary().TOLL_AMOUNT,
    type: 'text',
  },
  {
    id: 'weight_fees',
    key: 'weight_fees',
    name: dictionary().WEIGHT_FEES,
    type: 'cash',
  },
  {
    id: 'fine_fees',
    key: 'fine_fees',
    name: dictionary().FINE_FEES,
    type: 'cash',
  },
  {
    id: 'station_name',
    key: 'station.name.ar',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
]
const stationHeaders = [
  {
    id: 'toll_transaction_date',
    key: 'created_at',
    name: dictionary().TRANSACTION_DATE,
    type: 'date',
  },
  {
    id: 'toll_transaction_time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'vehicle_id',
    key: 'vehicle.id',
    name: dictionary().VEHICLE_ID,
    type: 'text',
  },
  {
    id: 'corporate_name',
    key: 'corporate.name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'toll_fees',
    key: 'toll_fees',
    name: dictionary().TOLL_AMOUNT,
    type: 'text',
  },
  {
    id: 'weight_fees',
    key: 'weight_fees',
    name: dictionary().WEIGHT_FEES,
    type: 'cash',
  },
  {
    id: 'fine_fees',
    key: 'fine_fees',
    name: dictionary().FINE_FEES,
    type: 'cash',
  },
]
const TollTransactionsByID: React.FC = () => {
  const { userType } = useParams<{ userType: string }>()
  if (userType === 'corporates') {
    headers = corporateHeaders
  } else if (userType === 'station-providers') {
    headers = stationProviderHeaders
  } else if (userType === 'stations') {
    headers = stationHeaders
  } else {
    headers = vehicleHeaders
  }
  return (
    <ViewAll
      headers={headers}
      title={dictionary().TOLL_TRANSACTIONS_DETAILS}
      to=''
      hasSearch={false}
      dataArrayPath={['/toll-transactions']}
      countArrayPath={['count']}
      restRoute={'/toll-transactions'}
      cashingKey={CASHING_KEY.GET_TOLL_TRANSACTIONS_FOR_ID}
    />
  )
}

export default TollTransactionsByID
