import { Box, GridItem, Stack, Text } from '@chakra-ui/react'
import { pathOr } from 'ramda'
import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import Card from '../../components/core/Card'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import Table from '../core/Table'

const StationPosDevices: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [posData, setPosData] = React.useState<any>([])
  const { mutate } = useMutation(() => fetch('GET', `/pos-devices?station_ids[0]=${id}`), {
    onSuccess: (data) => {
      setPosData(data)
    },
  })

  useEffect(() => {
    mutate()
  }, [])

  const stationPosHeaders = [
    {
      id: 'id',
      key: 'id',
      name: dictionary().ID,
      type: 'text',
    },
    {
      id: 'serial_number',
      key: 'serial_number',
      name: dictionary().THE_SERIAL_NUMBER,
      type: 'text',
    },
    {
      id: 'version_number',
      key: 'version_number',
      name: dictionary().THE_VERSION_NUMBER,
      type: 'text',
    },
  ]

  return (
    <>
      <Card h='100%' p={3}>
        <Text m={3} fontSize='20px' fontWeight={700} p={[5, 2, 2, 2]}>
          {dictionary().POS}
        </Text>
        <hr />
        <GridItem m='2' colSpan={8} mt={7}>
          <Box w='100%' pr='0'>
            <Stack>
              <GridItem>
                <Card h='100%'>
                  <Table
                    raised
                    enableIdRedirect={false}
                    handleIsBalancesChange={mutate}
                    height={390}
                    headers={stationPosHeaders}
                    data={pathOr([], ['data'], posData)}
                  />
                </Card>
              </GridItem>
            </Stack>
          </Box>
        </GridItem>
      </Card>
    </>
  )
}

export default StationPosDevices
