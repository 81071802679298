import { Box, Button, CircularProgress, Flex, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, Select, Tag, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import Card from '../../components/core/Card'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Reqiured from '../../components/core/reqiured'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'

const roles = [
  {
    id: 1,
    role: 'MANAGER',
    name: dictionary().MANAGER,
  },
  {
    id: 2,
    role: 'ACCOUNTANT',
    name: dictionary().ACCOUNTANT,
  },
  {
    id: 2,
    role: 'TOP_MANAGER',
    name: dictionary().TOP_MANAGER,
  },
]

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/

const EditCorporateStaff: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    getValues,
    watch,
    trigger,
  } = useForm({ mode: 'onChange' })

  const headerRef = useRef<HTMLDivElement>(null)

  const { go } = useHistory()
  const toast = useToast()

  const { onSuccess, onError } = useAlert()

  const phoneRegex = /^01[0125][0-9]{8}$/

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('patch', '/corporate-group-staff', { data: vars }), {
    onSuccess: (): void => {
      go(-1)
      onSuccess()
    },
    onError: (result: any) => {
      result.formErrors?.forEach((e: any) => {
        const { field } = e // field is 'name' | 'email'
      })
      toast({
        containerStyle: {
          direction: 'rtl',
        },
        description: result?.message || dictionary().ERROR,
        status: 'error',
        duration: 3000,
        position: 'top',
      })
    },
  })
  const {
    isLoading: loadingGetCorporateStaff,
    mutate: mutateGetCorporateStaff,
    error: errorGetCorporateStaff,
  } = useMutation(() => fetch('GET', `/corporate-group-staff/current-corporate-group-staff?id=${id}`), {
    onSuccess: (data: any): void => {
      setValue('ar', data?.corporate_group_staff?.name?.ar)
      setValue('en', data?.corporate_group_staff?.name?.en)
      setValue('username', data?.corporate_group_staff?.username)
      setValue('phone_number', data?.corporate_group_staff?.phone_number)
      setValue('role', data?.corporate_group_staff?.role)
      setValue('active', data?.corporate_group_staff?.active)
    },
    onError: (result: any) => {
      result.formErrors?.forEach((e: any) => {
        const { field } = e
      })
      toast({
        containerStyle: {
          direction: 'rtl',
        },
        description: result?.message || dictionary().ERROR,
        status: 'error',
        duration: 3000,
        position: 'top',
      })
    },
  })

  const {
    isLoading: loadingActivation,
    mutate: mutateActivation,
    error: errorActivation,
  } = useMutation(() => fetch('PATCH', `/corporate-group-staff/${id}/toggle-active-status`), {
    onSuccess: (data: any): void => {
      setValue('active', data?.active)
    },
    onError: (result: any) => {
      toast({
        containerStyle: {
          direction: 'rtl',
        },
        description: result?.message || dictionary().ERROR,
        status: 'error',
        duration: 3000,
        position: 'top',
      })
    },
  })

  useEffect(() => {
    mutateGetCorporateStaff()
  }, [id])

  const EditCorporateStaff = async () => {
    mutate([
      {
        id: Number(id),
        name: {
          en: getValues()?.en,
          ar: getValues()?.ar,
        },
        phone_number: getValues()?.phone_number,
        role: getValues()?.role,
        corporate_id: getValues()?.corporate_id,
      },
    ])
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().ADD_NEW_STAFF}
          action={
            <Box
              sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '30px', width: '100%', height: '50px' }}
            ></Box>
          }
        />
      </Flex>
      <Card p='8'>
        {loadingGetCorporateStaff && (
          <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress isIndeterminate color='blue.500' />
          </Box>
        )}
        {!loadingGetCorporateStaff && (
          <form onSubmit={handleSubmit(EditCorporateStaff as any)}>
            <Flex direction='row' wrap='wrap' gap='2%' align='center' justify='right'>
              <Text sx={{ fontSize: '25px' }}>حالة الحساب :</Text>
              <Tag sx={{ fontSize: '15px', backgroundColor: getValues()?.active ? 'green.500' : 'red.500', color: 'white', fontWeight: 'bold' }} size='lg'>
                {getValues()?.active ? 'مفعل' : 'غير مفعل'}
              </Tag>
              <Text sx={{ fontSize: '15px' }}> اضغط لتغيير حالة الحساب ⬅</Text>
              <Button disabled={loadingActivation} onClick={async () => mutateActivation()} sx={{ fontSize: '15px' }}>
                تغيير حالة التفعيل
              </Button>
            </Flex>
          </form>
        )}
      </Card>
      <Card p='8'>
        {loadingGetCorporateStaff && (
          <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress isIndeterminate color='blue.500' />
          </Box>
        )}
        {!loadingGetCorporateStaff && (
          <form onSubmit={handleSubmit(EditCorporateStaff as any)}>
            <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
              <FormControl id={dictionary().NAME_AR} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.ar}>
                <FormLabel>
                  {dictionary().NAME_AR} <Reqiured />
                </FormLabel>
                <InputGroup>
                  <Input
                    type='text'
                    {...register('ar', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.ar?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id={dictionary().NAME_EN} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.en}>
                <FormLabel>
                  {dictionary().NAME_EN} <Reqiured />
                </FormLabel>
                <InputGroup>
                  <Input
                    type='text'
                    {...register('en', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.en?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                style={{ pointerEvents: 'none' }}
                id={dictionary().EMAIL}
                w='40%'
                minH='100px'
                display='inline-block'
                isInvalid={!!errors.username || error?.data?.message === `Corporate staff with given username '${getValues().username}' already exists`}
              >
                <FormLabel>
                  {dictionary().EMAIL} <Reqiured />
                </FormLabel>
                <InputGroup>
                  <Input
                    disabled
                    sx={{ border: '1px solid #9E9E9E !important', borderWidth: '1px !important', backgroundColor: '#ddd' }}
                    type='text'
                    {...register('username', {
                      required: dictionary().REQUIRED,
                      validate: (value) => (!emailRegex.test(value) ? dictionary().INVALID_EMAIL : undefined),
                      // validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
                <FormErrorMessage>
                  {error?.data?.message === `Corporate staff with given username '${getValues().username}' already exists` &&
                    dictionary().THIS_EMAIL_IS_ALREADY_TAKEN}
                </FormErrorMessage>
              </FormControl>
              <FormControl id={dictionary().STATIONS_CONTACT_NUMBER} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.phone_number}>
                <FormLabel>
                  <>
                    {dictionary().STATIONS_CONTACT_NUMBER}
                    <Reqiured />
                  </>
                </FormLabel>
                <InputGroup>
                  <Input
                    type='number'
                    {...register('phone_number', {
                      required: dictionary().REQUIRED,
                      validate: (value) => (!phoneRegex.test(value) ? dictionary().INVALID_PHONE : undefined),
                    })}
                  />
                </InputGroup>

                <FormErrorMessage>{errors?.phone_number?.message}</FormErrorMessage>
              </FormControl>
              {/* <PasswordAndConfirmation register={register} errors={errors} watch={watch} width={['100%', '40%', '40%', '40%']} minHight={['50px', '100px']} /> */}
              <FormControl hidden={true} id={dictionary().ROLE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.role}>
                <FormLabel>{dictionary().ROLE}</FormLabel>
                <InputGroup>
                  <Input
                    type='text'
                    {...register('role', {
                      required: dictionary().REQUIRED,
                    })}
                  />
                </InputGroup>

                <FormErrorMessage>{errors.role?.message}</FormErrorMessage>
              </FormControl>

              <FormControl hidden={true} id={dictionary().ID} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.corporate_id}>
                <FormLabel>{dictionary().ID}</FormLabel>
                <InputGroup>
                  <Input
                    type='number'
                    {...register('corporate_id', {
                      required: dictionary().REQUIRED,
                      valueAsNumber: true,
                    })}
                  />
                </InputGroup>

                <FormErrorMessage>{errors?.corporate_id?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id={dictionary().ROLE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.role}>
                <FormLabel>{dictionary().ROLE}</FormLabel>
                <Select dir='ltr' textAlign='right' {...register('role', { required: dictionary().REQUIRED })} placeholder={dictionary().ROLE}>
                  {roles?.map((item: any) => (
                    <option key={item?.id} value={item?.role}>
                      {item?.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.role?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id={dictionary().ROLE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.role}></FormControl>
            </Flex>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left', justifyContent: 'left', gap: '10px' }}>
              <Button mt={4} sx={{ backgroundColor: '#ddd' }} isLoading={isLoading} type='submit' justifySelf={'left'} onClick={() => go(-1)}>
                إلغاء
              </Button>
              <Button mt={4} colorScheme='blue' isLoading={isLoading} type='submit' justifySelf={'left'} onClick={async () => EditCorporateStaff()}>
                حفظ
              </Button>
            </Box>
          </form>
        )}
      </Card>
    </Container>
  )
}

export default EditCorporateStaff
