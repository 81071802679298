import { Button, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, useDisclosure } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'
import forceLogoutIconSrc from '../assets/force-logout.svg'
import dictionary from '../dictionary'
import fetch from '../helpers/fetch'
import useAlert from '../helpers/useAlert'

export const ForceLogoutButton = ({ data }: any) => {
  const [forgetPasswordDone, setForgetPasswordDone] = useState(false)
  const { onSuccess, onError } = useAlert()
  const { pathname } = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  let route = ''
  let staffId = data?.id

  if (pathname.includes('admins')) {
    route = 'admin'
  } else if (pathname.includes('station-providers')) {
    route = 'station-provider-staff'
  } else if (pathname.includes('stations')) {
    route = 'station-staff'
  } else if (pathname.includes('corporates')) {
    route = 'corporate-staff'
  } else if (pathname.includes('corporate-group')) {
    route = 'corporate-group-staff'
  }

  const { isLoading, mutate } = useMutation(
    (vars: any) =>
      fetch('POST', `${route}/force-logout`, {
        data: {
          targetUserId: staffId,
        },
      }),
    {
      onSuccess: (): void => {
        setForgetPasswordDone(true)
        onSuccess()
        onClose()
      },
      onError: (err: any): void => {
        onError(err?.data?.message)
        setForgetPasswordDone(false)
      },
    },
  )

  const forceLogOut = async () => {
    mutate({ password: null })
  }

  return (
    <>
      <Button
        disabled={forgetPasswordDone}
        variant={'outline'}
        color='secondary'
        onClick={() => onOpen()}
        style={{ borderWidth: 0, color: forgetPasswordDone ? '#000' : '#fff' }}
      >
        {forgetPasswordDone ? dictionary().FORCE_LOGOUT_DONE : <Image src={forceLogoutIconSrc} width={25} height={30} />}
      </Button>
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent style={{ textAlign: 'right', direction: 'rtl' }}>
          <ModalHeader style={{ direction: 'rtl', fontFamily: 'Cairo', fontSize: '15px', fontWeight: 600, lineHeight: '20px', textAlign: 'right' }}>
            {dictionary().FORCE_LOGOUT}{' '}
          </ModalHeader>
          <ModalBody pb={6}>
            هل أنت متأكد أنك ترغب في فرض تسجيل خروج هذا المسنخدم؟<h6 style={{ fontWeight: 'bold' }}>{data?.email}</h6>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
            <Button
              mr={3}
              onClick={() => forceLogOut()}
              variant={'outline'}
              color='secondary'
              //   colorScheme='red'
              backgroundColor={'#6941C6'}
              style={{ borderWidth: 0, color: '#fff' }}
              _hover={{ backgroundColor: '#6941C6' }}
            >
              {isLoading ? <Spinner /> : 'تسجيل خروج'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
