import { Box, Grid, GridItem, Stack } from '@chakra-ui/react'
import { FC } from 'react'
import dictionary from '../../../dictionary'
import formatAsCurrency from '../../../helpers/formatAsCurrency'
import { formatDate } from '../../../helpers/formatDate'
import PreviewFile from '../PreviewFile'
import Table from '../Table'

type Prpos = {
  data: any
  isConfirmed: boolean
}

const corporatePaymentHeader = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'name',
    key: 'name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().TOTAL_AMOUNT,
    type: 'cash',
  },
]

const PaymentInfo: FC<Prpos> = ({ data, isConfirmed }) => {
  const isNotEditable = ['REJECTED', 'CONFIRMED'].includes(data?.status)
  const notEditableData = [
    {
      title: dictionary().STATUS,
      value: dictionary()[data?.status as keyof typeof dictionary],
    },
    {
      title: dictionary().ACTUAL_AMOUNT,
      value: formatAsCurrency(data.actual_amount),
    },
    {
      title: dictionary().DEPOSIT_DATE,
      value: formatDate(data.date, 'DD/MM/YYYY'),
    },
    {
      title: dictionary().REASON_ACTUAL_AMOUNT_IS_DIFFERENT_FROM_ENTERED,
      value: data?.reason,
    },
    {
      title: dictionary().COMMENT,
      value: data?.note,
    },
  ]
  return (
    <Grid gap={6} templateColumns='repeat(12, 1fr)' dir='rtl'>
      <GridItem colSpan={7}>
        <Stack direction='column'>{data?.images ? <PreviewFile files={data?.images} /> : <b>{dictionary().NO_IMG}</b>}</Stack>
      </GridItem>
      <GridItem colSpan={5} mt={7}>
        <Box display={'flex'} gap={5} dir={'rtl'} mt={5}>
          <span style={{ color: '#101828', fontWeight: 700, fontSize: '16px', lineHeight: '20px' }}>{dictionary().ENTERED_AMOUNT} : </span>
          <span style={{ color: '#101828', fontSize: '15px', lineHeight: '20px' }}>{formatAsCurrency(data.amount)}</span>
        </Box>
        {!isConfirmed && (
          <>
            <Box display={'flex'} gap={5} dir={'rtl'} mt={5}>
              <span style={{ color: '#101828', fontWeight: 700, fontSize: '16px', lineHeight: '20px' }}>{dictionary().DEPOSIT_TYPE} : </span>
              <span style={{ color: '#101828', fontSize: '15px', lineHeight: '20px' }}>{data.deposit_type}</span>
            </Box>
            <Box display={'flex'} gap={5} dir={'rtl'} mt={5}>
              <span style={{ color: '#101828', fontWeight: 700, fontSize: '16px', lineHeight: '20px' }}>{dictionary().BANK_NAME} : </span>
              <span style={{ color: '#101828', fontSize: '15px', lineHeight: '20px' }}>{data.bank_name}</span>
            </Box>
          </>
        )}
        {isNotEditable && (
          <>
            {notEditableData.map((item, index) => (
              <Box display={'flex'} gap={5} dir={'rtl'} mt={5} key={index}>
                <span style={{ color: '#101828', fontWeight: 700, fontSize: '16px', lineHeight: '20px' }}>{item.title} : </span>
                <span style={{ color: '#101828', fontSize: '15px', lineHeight: '20px' }}>{item.value}</span>
              </Box>
            ))}
          </>
        )}
      </GridItem>
      {data?.corporate_group_id && (
        <GridItem colSpan={12} data-test='deposit-distribution'>
          <hr />
          <p style={{ color: '#101828', fontWeight: 700, fontSize: '22px', lineHeight: '20px', margin: '30px 0' }}>{dictionary().DEPOSIT_DISTRIBUTION}</p>
          <Table raised enableIdRedirect={false} height={350} headers={corporatePaymentHeader} data={JSON.parse(data?.corporates)} />
        </GridItem>
      )}
    </Grid>
  )
}

export default PaymentInfo
