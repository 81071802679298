import { Thead, Tr } from '@chakra-ui/react'
import { FC } from 'react'

type OctaneTableHeaderProps = {
  headers: React.ReactNode[]
}

const OctaneTableHeader: FC<OctaneTableHeaderProps> = ({ headers }) => {
  return (
    <Thead>
      <Tr style={{ textAlign: 'center' }}>{headers?.map((header) => header)}</Tr>
    </Thead>
  )
}

export default OctaneTableHeader
