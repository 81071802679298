import React from 'react'
import { Center, Flex } from '@chakra-ui/react'

import Sidebar from './Sidebar'

const WithSidebar: React.FC = ({ children }) => {
  return (
    <Flex width='100vw' bg='#f1f5f9'>
      <Sidebar />
      <Center w='100%' h='100%' overflowY='auto'>
        {children}
      </Center>
    </Flex>
  )
}

export default WithSidebar
