import { useRef } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button, useDisclosure, Spinner } from '@chakra-ui/react'

import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { parse, stringify } from 'query-string'
import useAlert from '../../helpers/useAlert'
import { useStore } from '../../store'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import { USER_TYPE } from '../../constants'

interface Props {
  data?: any
}

const ConfirmReviewTransactionModel: React.FC<Props> = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { search } = useLocation()
  const query = parse(search, { parseNumbers: true, parseBooleans: true })

  const { userType } = useStore((state) => state)
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const {
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    mode: 'onChange',
  })

  const { isLoading, mutate } = useMutation((vars: any) => fetch('PATCH', `/fuel-transactions/pump-reading-reviewed`, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      onClose()
      delete query.status
      query.pump_reading_reviewed = 'FALSE'
      push({ search: stringify(query) })
    },
    onError,
  })
  const confirmTransaction = async () => {
    mutate({
      id: data.id,
      is_invalid: true,
    })
  }

  if (data.status === 'CONFIRMED' && data.pump_reading_reviewed_at === null && userType === USER_TYPE.ADMIN) {
    return (
      <>
        <Button mr={1} onClick={onOpen}>
          {dictionary().CONFIRM_REVIEW}
        </Button>

        <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader style={{ direction: 'rtl' }}>{dictionary().CONFIRM_REVIEW} </ModalHeader>
            <form onSubmit={handleSubmit(confirmTransaction as any)}>
              <ModalBody pb={6} dir='rtl'>
                <h3>{dictionary().DEFAULT_CONFIRM_DESCRIPTION}</h3>
              </ModalBody>
              <ModalFooter>
                <Button onClick={onClose}>{dictionary().CANCEL}</Button>
                <Button colorScheme='blue' mx={2} onClick={async () => (await trigger()) && confirmTransaction()}>
                  {isLoading ? <Spinner /> : dictionary().DEFAULT_CONFIRM_CONFIRMATION_TEXT}
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    )
  } else {
    return <></>
  }
}

export default ConfirmReviewTransactionModel
