import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import Required from '../../../components/core/reqiured'
import dictionary from '../../../dictionary'

type OdoMeterControlProps = {
  errors: any
  register: any
}
const OdoMeterControlControl = ({ errors, register }: OdoMeterControlProps) => {
  return (
    <FormControl id={dictionary().ODOMETER_READING} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.odometer_reading}>
      <FormLabel>
        {dictionary().ODOMETER_READING} <Required />
      </FormLabel>
      <Input
        type='number'
        {...register('odometer_reading', {
          required: dictionary().REQUIRED,
          min: {
            value: 0,
            message: dictionary().NO_NEGATIVE_NUMBER,
          },
        })}
      />
      <FormErrorMessage>{errors.odometer_reading?.message}</FormErrorMessage>
    </FormControl>
  )
}

export default OdoMeterControlControl
