import { useRef } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useForm } from 'react-hook-form'
import {
  Modal,
  Spinner,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormLabel,
  Button,
  FormControl,
  Input,
  useDisclosure,
  Flex,
  InputGroup,
  FormErrorMessage,
  InputLeftElement,
  Text,
} from '@chakra-ui/react'
import formatAsCurrency from '../../helpers/formatAsCurrency'
import useAlert from '../../helpers/useAlert'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'

interface Props {
  id: any
  activate: boolean
  setActivate: any
}

const InvoicePayment: React.FC<Props> = ({ id, activate, setActivate }) => {
  const { onSuccess, onError } = useAlert()
  const { data } = useQuery([], () => fetch('GET', `/invoices/details/${id}`), {})
  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', `/invoices/add-payment`, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      setActivate(false)
      onClose()
    },
    onError,
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' })
  const { onClose } = useDisclosure()

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  const Pay = async () => {
    mutate({
      invoice_id: +getValues()?.invoice_id,
      amount: +getValues()?.amount,
    })
  }

  const handle = () => {
    setActivate(false)
    onClose()
  }

  return (
    <>
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={activate} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().PAY_INVOIVE_NUMBER + ' ' + data?.data?.invoice?.id} </ModalHeader>
          <ModalBody pb={6}>
            <form dir='rtl' onSubmit={handleSubmit(Pay as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                <FormControl id={dictionary().PERIOD_START} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
                  <FormLabel>{dictionary().PERIOD_START}</FormLabel>
                  <Text>{data?.data?.invoice?.period_start?.substring(0, 10).replace(/-/g, '/').replace(/T/g, '   ')}</Text>
                </FormControl>

                <FormControl w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
                  <FormLabel>{dictionary().PERIOD_END}</FormLabel>
                  <Text>{data?.data?.invoice?.period_end?.substring(0, 10).replace(/-/g, '/').replace(/T/g, '   ')}</Text>
                </FormControl>

                <FormControl w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
                  <FormLabel>{dictionary().TOTAL_INVOICE}</FormLabel>
                  <Text>{formatAsCurrency(data?.data?.invoice?.amount)}</Text>
                </FormControl>

                <FormControl w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
                  <FormLabel>{dictionary().AMOUNT_REMAINING}</FormLabel>
                  <Text>{formatAsCurrency(data?.data?.invoice?.amount - data?.data?.invoice?.starting_balance - data?.data?.paid_amount)}</Text>
                </FormControl>

                <FormControl w='90%' minH='100px' display='inline-block' isInvalid={!!errors?.amount?.message}>
                  <FormLabel>{dictionary().AMOUNT}</FormLabel>
                  <InputGroup size='md'>
                    <Input
                      pr='1rem'
                      {...register('amount', {
                        required: dictionary().REQUIRED,
                        valueAsNumber: true,
                        min: {
                          value: 0,
                          message: dictionary().NO_NEGATIVE_NUMBER,
                        },
                      })}
                      type={'number'}
                    />
                    <InputLeftElement height='100%' pointerEvents='none'>
                      {dictionary().PRICING_CURRENCY}
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
                </FormControl>

                <Input {...register('invoice_id', {})} type={'number'} hidden value={data?.data?.invoice?.id} />
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button onClick={async () => (await trigger()) && Pay()} colorScheme='blue' mr={3}>
              {isLoading ? <Spinner /> : dictionary().SAVE}
            </Button>
            <Button onClick={handle}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default InvoicePayment
