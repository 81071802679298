import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import dictionary from '../../dictionary'
import { CASHING_KEY } from '../../constants'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'name',
    key: 'name',
    name: dictionary().NAME,
    type: 'text',
  },
  {
    id: 'minimum_fee',
    key: 'minimum_fee',
    name: dictionary().MIMIMUM_FEE,
    type: 'text',
  },
  {
    id: 'fee_percentage',
    key: 'fee_percentage',
    name: dictionary().FEE_PERCENTAGE_RATE,
    type: 'text',
  },
]

const VehicleSubscriptions: React.FC = () => {
  return (
    <>
      <ViewAll
        headers={headers}
        title={dictionary().VEHICLE_SUBSCRIPTIONS}
        to='/vehicle-subscription/new'
        tags={[{ name: dictionary().ALL, filter: [{ name: '', value: '' }] }]}
        hasSearch={true}
        sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
        dataArrayPath={['vehicle-subscriptions']}
        countArrayPath={['count']}
        restRoute={'/Vehicle-subscriptions'}
        cashingKey={CASHING_KEY.VEHICLE_SUBSCRIPTIONS}
      />
    </>
  )
}

export default VehicleSubscriptions
