import React from 'react'
import { path } from 'ramda'
import { GridItem, Box, Link, Spinner } from '@chakra-ui/react'
import { useParams, useLocation } from 'react-router-dom'

import { useQuery } from 'react-query'
import fetch from '../../helpers/fetch'
import formatAsCurrency from '../../helpers/formatAsCurrency'
import { SummaryItem } from '../../pages/stats/Summary'

import { RowProps } from '../../interfaces/gridProps'

const TopRow: React.FC<RowProps> = ({ header, row, redirect, isLoading }) => {
  const getAccount = (item: any, type: string) => {
    const account = item?.ledger_accounts?.filter((accountItem: any) => accountItem?.account_type == type)
    return account && account[0]?.balance
  }

  const value = header.format ? header.format(path(header.key.split('.'), row), row) : path(header.key.split('.'), row)
  const { id } = useParams<{ id: string }>()
  const corporateId = id !== undefined ? id : row?.corporateStaff?.corporate?.id
  const { pathname } = useLocation()
  let totalFuelBalance: any = []
  pathname.includes('/corporate') && !isLoading
    ? (totalFuelBalance = useQuery(['rfr'], () => fetch('GET', `/corporates/${corporateId}/total-fuel-balance`), {}))
    : ''
  let netCorporateBalance: any = []
  pathname.includes('/corporates') ? (netCorporateBalance = useQuery(['cashingKey', id], () => fetch('GET', `/corporates/${corporateId}`), {})) : ''
  switch (header.type) {
    case 'Total_Balance':
      const totalCashAcc = (value as any)?.find(({ account_type }: any) => account_type === 'CASH')

      return (
        <>
          {' '}
          <Link
            onClick={() => {
              redirect && redirect(`ledger-transactions/${cashAcc.account_id}`)
            }}
          >
            <GridItem pl={'20px'} mb={['10px', '10px', 0, 0]}>
              {totalFuelBalance?.isFetching ? (
                <SummaryItem sty={{ backgroundColor: 'white', color: 'black' }} label={header.name} value={<Spinner />} />
              ) : (
                <SummaryItem
                  sty={{ backgroundColor: 'white', color: 'black' }}
                  label={header.name}
                  value={`${
                    (totalCashAcc as any)?.balance === undefined
                      ? formatAsCurrency(0)
                      : formatAsCurrency(
                          Number(totalFuelBalance?.data?.driverBalance?.sum) +
                            Number(totalFuelBalance?.data?.vehicleBalance?.sum) +
                            Number(netCorporateBalance?.data?.ledger_accounts[0]?.balance),
                        )
                  }`}
                />
              )}
            </GridItem>
          </Link>
        </>
      )
    case 'usedBalance':
      return (
        <>
          <GridItem pl={'20px'} mb={['10px', '10px', 0, 0]}>
            {totalFuelBalance?.isFetching ? (
              <SummaryItem sty={{ backgroundColor: 'white', color: 'black' }} label={header.name} value={<Spinner />} />
            ) : (
              <SummaryItem
                sty={{ backgroundColor: 'white', color: 'black' }}
                label={header.name}
                value={`${formatAsCurrency(Number(totalFuelBalance?.data?.driverBalance?.sum) + Number(totalFuelBalance?.data?.vehicleBalance?.sum))}`}
              />
            )}
          </GridItem>
        </>
      )
    case 'unusedBalance':
      const getBalance = () => {
        let balance = 0
        id === undefined && (row = row?.corporateStaff?.corporate)
        const cashBalance = getAccount(row, 'CASH') ? getAccount(row, 'CASH') : 0
        const consumptionBalance = getAccount(row, 'CONSUMPTION') ? getAccount(row, 'CONSUMPTION') : 0
        const totalWallets = Number(totalFuelBalance?.data?.driverBalance?.sum) + Number(totalFuelBalance?.data?.vehicleBalance?.sum)
        const creditLimit = row?.credit_limit
        const settlementType = row?.settlement
        if (settlementType === 'PRE_PAID') {
          balance = +cashBalance - +consumptionBalance - totalWallets
        } else if (settlementType === 'POST_PAID') {
          balance = +creditLimit + +cashBalance - +consumptionBalance - totalWallets
        }
        return balance
      }

      return (
        <>
          <GridItem pl={'20px'} mb={['10px', '10px', 0, 0]}>
            {totalFuelBalance?.isFetching ? (
              <SummaryItem sty={{ backgroundColor: 'white', color: 'black' }} label={header.name} value={<Spinner />} />
            ) : (
              <SummaryItem sty={{ backgroundColor: 'white', color: 'black' }} label={header.name} value={`${formatAsCurrency(getBalance())}`} />
            )}
          </GridItem>
        </>
      )
    case 'Cash_balance':
      const cashAcc = (value as any)?.find(({ account_type }: any) => account_type === 'CASH')
      return (
        <>
          {' '}
          <Link
            onClick={() => {
              redirect && redirect(`ledger-transactions/${cashAcc.account_id}`)
            }}
          >
            <SummaryItem
              sty={{ backgroundColor: 'white', color: 'black' }}
              label={header.name}
              value={`${(cashAcc as any)?.balance === undefined ? formatAsCurrency(0) : formatAsCurrency((cashAcc as any)?.balance)}`}
            />
          </Link>
        </>
      )
    case 'Consumed_balance':
      const consumedAcc = (value as any)?.find(({ account_type }: any) => account_type === 'CONSUMPTION')
      return (
        <>
          {' '}
          <Link
            onClick={() => {
              redirect && redirect(`ledger-transactions/${consumedAcc.account_id}`)
            }}
          >
            <SummaryItem
              sty={{ backgroundColor: 'white', color: 'black' }}
              label={header.name}
              value={`${(consumedAcc as any)?.balance === undefined ? formatAsCurrency(0) : formatAsCurrency((consumedAcc as any)?.balance)}`}
            />
          </Link>
        </>
      )
    case 'FuelBalance':
      const fuelAcc = (value as any)?.find(({ account_type }: any) => account_type === 'FUEL')

      return (
        <>
          {' '}
          <Box mb={['10px', '0', '0', '0']} ml={6}>
            {' '}
            <Link
              onClick={() => {
                redirect && redirect(`ledger-transactions/${fuelAcc.account_id}`)
              }}
            >
              <SummaryItem
                sty={{ backgroundColor: 'white', color: 'black', textAlign: 'center' }}
                label={header.name}
                value={`${(fuelAcc as any)?.balance === undefined ? formatAsCurrency(0) : formatAsCurrency((fuelAcc as any)?.balance)}`}
              />
            </Link>{' '}
          </Box>
        </>
      )

    case 'Store_Balance':
      const storeAcc = (value as any)?.find(({ account_type }: any) => account_type === 'STORE')

      return (
        <Box mb={['10px', '0', '0', '0']} width={['calc((100% / 1) - 0.1px)', 'calc((100% / 3) - 0.1px)', 'calc((100% / 3) - 0.1px)']} ml={6}>
          {' '}
          <Link
            onClick={() => {
              redirect && redirect(`ledger-transactions/${storeAcc.account_id}`)
            }}
          >
            <SummaryItem
              sty={{ backgroundColor: 'white', color: 'black', textAlign: 'center' }}
              label={header.name}
              value={`${(storeAcc as any)?.balance === undefined ? formatAsCurrency(0) : formatAsCurrency((storeAcc as any)?.balance)}`}
            />
          </Link>
        </Box>
      )
    case 'Toll_Balance':
      const tollAcc = (value as any)?.find(({ account_type }: any) => account_type === 'TOLL')

      {
        return (
          <>
            <Box mb={['10px', '0', '0', '0']} width={['calc((100% / 1) - 0.1px)', 'calc((100% / 3) - 0.1px)', 'calc((100% / 3) - 0.1px)']}>
              {' '}
              <Link
                onClick={() => {
                  redirect && redirect(`ledger-transactions/${tollAcc.account_id}`)
                }}
              >
                {' '}
                <SummaryItem
                  sty={{ backgroundColor: 'white', color: 'black', textAlign: 'center' }}
                  label={header.name}
                  value={`${(tollAcc as any)?.balance === undefined ? formatAsCurrency(0) : formatAsCurrency((tollAcc as any)?.balance)}`}
                />
              </Link>
            </Box>
          </>
        )
      }
  }
  return <></>
}

export default TopRow
