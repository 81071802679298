import { Flex, FormControl, FormErrorMessage, FormLabel, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'
import Card from '../../components/core/Card'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Required from '../../components/core/reqiured'
import dictionary from '../../dictionary'
import DuplicateRefund from './components/DuplicateRefund'
import VarianceRefund from './components/VarianceRefund'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'plate_number',
    key: 'vehicle.number_plate',
    name: dictionary().NUMBER_PLATE,
    type: 'text',
  },
  {
    id: 'corporateName',
    key: 'corporate.name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'station',
    key: 'station.name.en',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().AMOUNT,
    type: 'cash',
  },
  {
    id: 'correction',
    key: 'correction',
    name: dictionary().CORRECTION_VALUE,
    type: 'text',
  },
]

const RefundTransaction: React.FC = () => {
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
  })
  const refundType = watch('refundType')

  const [queryParams, setQueryParams] = useState<any>({})

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const params: any = {}
    for (const [key, value] of urlParams.entries()) {
      params[key as any] = value
    }
    setQueryParams(params)
  }, [])
  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      queryParams?.refundType && setValue('refundType', queryParams?.refundType)
    }
  }, [queryParams])

  const handleClose = () => {
    setValue('refundType', 'reset')
  }

  return (
    <Container>
      <Flex className='margin-150' flexDir='column'>
        <Header title={dictionary().REFUND_TRANSACTION} />
      </Flex>
      <Card p='8' marginTop={['20px', '0px', '20px']} style={{ minHeight: '85vh' }}>
        <form>
          <Flex className='margin-100' justify='center' align='center' direction='column'>
            <Text fontSize={20}>{dictionary().REFUND_TRANSACTION}</Text>
            <FormControl id='refundType' w={['100%', '70%', '70%', '70%']}>
              <FormLabel>
                {dictionary().REFUND_TYPE} <Required />
              </FormLabel>
              <Controller
                name='refundType'
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction='row'>
                      <Radio value='variance' color='purple'>
                        {dictionary().VARIANCE_TRANSACTION}
                      </Radio>
                      <Radio value='duplicate' color='purple'>
                        {dictionary().DUPLICATE_TRANSACTION}
                      </Radio>
                      <Radio value='reset' color='purple' hidden></Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>{errors.refundType?.message}</FormErrorMessage>
            </FormControl>
            {refundType === 'duplicate' && <DuplicateRefund headers={headers} handleClose={handleClose} queryParams={queryParams} />}
            {refundType === 'variance' && <VarianceRefund headers={headers} handleClose={handleClose} />}
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default RefundTransaction
