import React, { useRef } from 'react'
import { parse, ParsedQuery } from 'query-string'
import { pathOr } from 'ramda'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Flex, Grid, GridItem, Text, Stack, Box, Divider } from '@chakra-ui/react'
import Card from '../../components/core/Card'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import Table from '../core/Table'
import { CASHING_KEY } from '../../constants'
import { createVariablesFromQueryString } from '../../helpers/queryStringVariables'

interface Props {
  restRoute: string
  cashingKey: string
  querystringToVariables?: (query: ParsedQuery<string | number | boolean>) => Record<string, unknown>
  height?: string
  userType?: string
}

let headers: any[] = []
const vehicleHeaders = [
  {
    id: 'fuel_transaction_date',
    key: 'created_at',
    name: dictionary().TRANSACTION_DATE,
    type: 'date',
  },
  {
    id: 'fuel_transaction_time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'liters',
    key: 'liters',
    name: dictionary().LITRES_AMOUNT,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().PRICE,
    type: 'cash',
  },
  {
    id: 'station_name',
    key: 'station.name.ar',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
]
const corporateHeaders = [
  {
    id: 'fuel_transaction_date',
    key: 'created_at',
    name: dictionary().TRANSACTION_DATE,
    type: 'date',
  },
  {
    id: 'fuel_transaction_time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'vehicle_id',
    key: 'vehicle.id',
    name: dictionary().VEHICLE_ID,
    type: 'text',
  },
  {
    id: 'liters',
    key: 'liters',
    name: dictionary().LITRES_AMOUNT,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().PRICE,
    type: 'cash',
  },
  {
    id: 'station_name',
    key: 'station.name.ar',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
]
const stationProviderHeaders = [
  {
    id: 'fuel_transaction_date',
    key: 'created_at',
    name: dictionary().TRANSACTION_DATE,
    type: 'date',
  },
  {
    id: 'fuel_transaction_time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'vehicle_id',
    key: 'vehicle.id',
    name: dictionary().VEHICLE_ID,
    type: 'text',
  },
  {
    id: 'corporate_name',
    key: 'corporate.name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'liters',
    key: 'liters',
    name: dictionary().LITRES_AMOUNT,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().PRICE,
    type: 'cash',
  },
  {
    id: 'station_name',
    key: 'station.name.ar',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
]
const stationHeaders = [
  {
    id: 'fuel_transaction_date',
    key: 'created_at',
    name: dictionary().TRANSACTION_DATE,
    type: 'date',
  },
  {
    id: 'fuel_transaction_time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'vehicle_id',
    key: 'vehicle.id',
    name: dictionary().VEHICLE_ID,
    type: 'text',
  },
  {
    id: 'corporate_name',
    key: 'corporate.name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'liters',
    key: 'liters',
    name: dictionary().LITRES_AMOUNT,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().PRICE,
    type: 'cash',
  },
]

const FuelTransactions: React.FC<Props> = ({ restRoute, cashingKey, height, querystringToVariables = createVariablesFromQueryString, userType }) => {
  const { id } = useParams<{ userType: string; id: string }>()

  const headerRef = useRef<HTMLDivElement>(null)
  const tableHeight = height ?? (headerRef.current ? `calc(100vh - ${headerRef.current.clientHeight}px)` : undefined)

  if (userType === 'corporates') {
    headers = corporateHeaders
  } else if (userType === 'station-providers') {
    headers = stationProviderHeaders
  } else if (userType === 'stations') {
    headers = stationHeaders
  } else if (userType === 'vehicles' || userType === 'drivers') {
    headers = vehicleHeaders
  }
  let query: any = {}
  if (cashingKey === CASHING_KEY.GET_FUEL_TRANSACTIONS_FOR_ID) {
    if (userType === 'corporates') {
      query = { corporateId: parseInt(id) }
    } else if (userType === 'drivers') {
      query = { driverId: parseInt(id) }
    } else if (userType === 'vehicles') {
      query = { vehicleId: parseInt(id) }
    } else if (userType === 'stations') {
      query = { stationId: parseInt(id) }
    } else if (userType === 'station-providers') {
      query = { stationProviderId: parseInt(id) }
    }
  } else {
    query = parse(location.search, { parseNumbers: true, parseBooleans: true })
  }

  const variables = querystringToVariables(query)
  const { data, isFetching } = useQuery([cashingKey, query], () => fetch('GET', restRoute, { query: { ...variables, page_size: 0 } }), {})
  return (
    <>
      {data && data?.data?.length > 0 ? (
        <GridItem colSpan={[12, 4, 4, 4]}>
          <Card h='100%' p={3}>
            <Text m={3} fontSize='20px'>
              {cashingKey === CASHING_KEY.GET_SINGLE_STATION || cashingKey === CASHING_KEY.GET_SINGLE_STATION_PROVIDER
                ? dictionary().FUEL_TRANSACTIONS
                : dictionary().FUEL_TRANSACTIONS_DETAILS}
            </Text>
            <Grid templateColumns='repeat(6, 2fr)'>
              <GridItem m='2' colSpan={6}>
                <Box w='100%'>
                  <Stack direction='row'>
                    <Table raised height={tableHeight} headers={headers} data={pathOr([], ['data'], data)} />
                  </Stack>
                </Box>
                <Divider display='flex' width='100%' />
              </GridItem>
            </Grid>
          </Card>
        </GridItem>
      ) : !isFetching ? (
        <GridItem colSpan={[12, 4, 4, 4]}>
          <Card h='100%' p={4}>
            <Text m={3} fontSize='20px'>
              {cashingKey === CASHING_KEY.GET_SINGLE_STATION || cashingKey === CASHING_KEY.GET_SINGLE_STATION_PROVIDER
                ? dictionary().FUEL_TRANSACTIONS
                : dictionary().FUEL_TRANSACTIONS_DETAILS}
            </Text>
            <Grid templateColumns='repeat(6, 2fr)'>
              <GridItem m='2' colSpan={6}>
                <Flex w='100%' h='100%' align='center' justify='center'>
                  <Text fontSize='20px'>
                    {dictionary().NO_RESULTS_FOUND} {dictionary().IN} {dictionary().FUEL_TRANSACTIONS}
                  </Text>
                </Flex>
                <Divider display='flex' width='100%' />
              </GridItem>
            </Grid>
          </Card>
        </GridItem>
      ) : (
        <GridItem colSpan={[12, 4, 4, 4]}>
          <Card h='100%'>
            <Text m={3} fontSize='20px'>
              {cashingKey === CASHING_KEY.GET_SINGLE_STATION || cashingKey === CASHING_KEY.GET_SINGLE_STATION_PROVIDER
                ? dictionary().FUEL_TRANSACTIONS
                : dictionary().FUEL_TRANSACTIONS_DETAILS}
            </Text>
            <Grid templateColumns='repeat(6, 2fr)'>
              <GridItem m='2' colSpan={6}>
                <Flex w='100%' h='100%' align='center' justify='center'>
                  <Text fontSize='20px'>
                    {dictionary().SEARCH_LOADING} {dictionary().IN} {dictionary().FUEL_TRANSACTIONS}
                  </Text>
                </Flex>
                <Divider display='flex' width='100%' />
              </GridItem>
            </Grid>
          </Card>
        </GridItem>
      )}
    </>
  )
}

export default FuelTransactions
