import React, { useState, useCallback, useEffect } from 'react'
import { pathOr } from 'ramda'
import { useQuery } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Grids from '../../components/core/Grids'
import fetch from '../../helpers/fetch'

interface ViewProps {
  restRoute: string
  title: string
  cashingKey: string
  dataLocation: string
  header: { key: string; name: string }[]
  actions: { action?: string; title?: string; apiRoute?: string; redirectRoute?: string }[]
  userType?: string
}
export interface data {
  id: number
  data: any
}

const ViewOne: React.FC<ViewProps> = ({ actions, header, restRoute, title, dataLocation, cashingKey, userType }) => {
  const { push, replace } = useHistory()
  const { pathname } = useLocation()
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, isFetched, refetch, error } = useQuery([cashingKey, id], () => fetch('GET', `${restRoute}/${id}`))
  const redirect = (destination: any) => {
    push(`${location?.pathname}/${destination}`)
  }
  const [isBalanseChanged, setIsBalanseChanged] = useState(false)
  useEffect(() => {
    refetch()
  }, [isBalanseChanged])

  useEffect(() => {
    if (isFetched) {
      if (!data || (error as any)?.data?.message === 'Vehicle is currently not active') {
        replace(`/${pathname.split('/')[1]}`)
      }
    }
  }, [data, isFetched])

  const handleIsBalanseChanged = useCallback(() => {
    setIsBalanseChanged(!isBalanseChanged)
  }, [isBalanseChanged])

  return (
    <Grids
      header={header}
      cashingKey={cashingKey}
      isLoading={isLoading}
      redirect={redirect}
      data={pathOr(data, [dataLocation], data)}
      title={title}
      actions={actions}
      userType={userType}
      handleIsBalanseChanged={handleIsBalanseChanged}
    />
  )
}

export default ViewOne
