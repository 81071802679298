import { Box, FormControl, FormLabel, Input, useDisclosure } from '@chakra-ui/react'
import Select from 'react-select'
import { FC, useState } from 'react'
import dictionary from '../../dictionary'
import useDebounce from '../../helpers/useDebounce'
import { useFilterStore } from '../../stores/filterStore'
import AdvancedFilterModal from './AdvancedFilterModal'
// eslint-disable-next-line @typescript-eslint/ban-types
type SearchByIdProps = {}

const SearchById: FC<SearchByIdProps> = () => {
  const { id, store, setStore } = useFilterStore()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [searchType, setSearchType] = useState<{ value: string; label: string }>({ value: 'رقم المعاملة', label: 'رقم المعاملة' })
  const [searchTerm, setSearchTerm] = useState<number | null>(id)

  const search = useDebounce((value: number | null) => {
    setStore({ ...store, id: value, page: 1 })
  }, 900)

  const onChange = (e: any) => {
    if (e.target.value === '') {
      setSearchTerm(null)
      search(null)
      return
    }
    setSearchTerm(Number(e.target.value))
    search(Number(e.target.value))
  }

  return (
    <FormControl id={`ابحث برقم المعاملة`} w='100%' dir='rtl'>
      <FormLabel>{`ابحث برقم المعاملة`}</FormLabel>
      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Input
          sx={{ height: '42px', marginLeft: '10px' }}
          placeholder={dictionary().SEARCH}
          onChange={onChange}
          value={searchTerm === null ? '' : searchTerm}
          type='text'
          size='lg'
        />
        <AdvancedFilterModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      </Box>
    </FormControl>
  )
}

export default SearchById

/* <Select
  defaultValue={{ value: 'رقم المعاملة', label: 'رقم المعاملة' }}
  onChange={(value: any) => {
    console.log('🚀 ~ value:', value)
    setSearchType(value)
  }}
  isSearchable
  options={[
    {
      value: 'رقم المعاملة',
      label: 'رقم المعاملة',
    },
    {
      value: 'إسم السائق',
      label: 'إسم السائق',
    },
    {
      value: 'رقم اللوحة',
      label: 'رقم اللوحة',
    },
    {
      value: 'كود المركبة',
      label: 'كود المركبة',
    },
  ]}
  placeholder={'نوع البحث'}
  styles={{
    menu: (base) => ({ ...base, zIndex: 99989789899 }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? '#7165e3' : '#E2E8F0',
      height: '42px',
      borderRadius: '0.375rem',
      width: '150px',
      marginRight: '10px',
    }),
  }}
  theme={(theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary25: '#B8B2F1',
      primary: '#7165e3',
    },
  })}
/> */
