import React, { useEffect, useRef } from 'react'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { useFilterStore } from '../../stores/filterStore'
import dictionary from '../../dictionary'
import AutoCompleteInput from './AutoCompleteInput'

interface Props {}

const CorporateDropListEInvoices: React.FC<Props> = () => {
  const { pathname } = useLocation()
  const { corporateIds, setCorporateIds } = useFilterStore()

  const {
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useForm({ mode: 'onChange' })

  const watcher = watch('corporate')

  useEffect(() => {
    if (watcher) {
      setCorporateIds(getValues()?.corporate)
    }
  }, [watcher])

  useEffect(() => {
    corporateIds.length > 0 && setValue('corporate', corporateIds)
  }, [])

  return (
    <FormControl id={dictionary().CHOOSE_CORPORATE} w='100%' dir='rtl' mr={'-30px'}>
      <FormLabel>{dictionary().CHOOSE_CORPORATE}</FormLabel>
      <Controller
        name={`corporate`}
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <AutoCompleteInput
            dataTest='e-invoices-corporates-input'
            isInvalid={!!errors?.second_ref_id}
            onChange={onChange}
            value={value}
            searchRoute='/corporates'
            singleRoute='/corporates'
            valueFormatter={(option) => option?.id}
            displayFormatter={(option) => option?.name}
            multiple={true}
            boxProps={{ maxWidth: '400px', maxHeight: '60px', overflowY: 'scroll' }}
            maxItems={10}
          />
        )}
      />
    </FormControl>
  )
}

export default CorporateDropListEInvoices
