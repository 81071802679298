import React, { useCallback, useEffect, useState } from 'react'
import { parse } from 'query-string'
import { pathOr } from 'ramda'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { GridItem, Flex, Box, Stack, Text } from '@chakra-ui/react'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import Card from '../../components/core/Card'
import Table from '../core/Table'

interface Props {
  restRoute: string
  isDriverAssigned?: boolean
}

const stationStaffHeaders = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'driverRedirect',
  },

  {
    id: 'name',
    key: 'name',
    name: dictionary().NAME,
    type: 'text',
  },
  {
    id: 'unassignDriver',
    key: 'id',
    name: '',
    type: 'modal',
  },
]

const VehicleAssignedDrivers: React.FC<Props> = ({ restRoute, isDriverAssigned }) => {
  const query = parse(location.search, { parseBooleans: true })
  const { id } = useParams<{ id: string }>()
  const [isDriverRemoved, setisDriverRemoved] = useState(false)
  const assignedDrivers = useQuery([query], () => fetch('GET', restRoute + `${id}`))
  useEffect(() => {
    assignedDrivers.refetch()
  }, [isDriverAssigned, isDriverRemoved])

  const handleIsDriverRemoved = useCallback(() => {
    setisDriverRemoved(!isDriverRemoved)
  }, [isDriverRemoved])

  return (
    <>
      <Card h='100%' p={4}>
        <Text m={3} fontSize='20px'>
          {dictionary().ASSIGNED_DRIVERS}
        </Text>
        <GridItem m='2' colSpan={6}>
          <Box w='100%'>
            <Stack>
              <GridItem>
                {assignedDrivers && assignedDrivers?.data?.vehicle?.drivers?.length > 0 ? (
                  <Table
                    raised
                    enableIdRedirect={true}
                    height={300}
                    headers={stationStaffHeaders}
                    data={pathOr([], ['drivers'], assignedDrivers?.data?.vehicle)}
                    handleIsDriverRemoved={handleIsDriverRemoved}
                  />
                ) : !assignedDrivers.isFetching ? (
                  <Flex w='100%' h='100%' align='center' justify='center'>
                    <Text fontSize='2xl'>
                      {dictionary().NO_RESULTS_FOUND} {dictionary().IN} {dictionary().ASSIGNED_DRIVERS}
                    </Text>
                  </Flex>
                ) : (
                  <Flex w='100%' h='100%' align='center' justify='center'>
                    <Text fontSize='2xl'>
                      {dictionary().SEARCH_LOADING} {dictionary().IN} {dictionary().ASSIGNED_DRIVERS}
                    </Text>
                  </Flex>
                )}
              </GridItem>
            </Stack>
          </Box>
        </GridItem>
      </Card>
    </>
  )
}

export default VehicleAssignedDrivers
