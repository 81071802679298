import React, { useEffect, useRef, useState } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, Select, RadioGroup, Stack, Radio } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { parse } from 'query-string'
import { useHistory } from 'react-router-dom'
import { useStore } from '../../store'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Required from '../../components/core/reqiured'
import { USER_TYPE } from '../../constants'
import PasswordAndConfirmation from '../../components/core/PasswordAndConfirmation'
import PinCodeInput from '../../components/core/PinCodeInput'
import AutoCompleteControl from '../transactions/components/AutoCompleteControl'

type AddDriverMutationVariables = {
  email: string
  mobile_number: string
  password: string
  pin_code: string
  code: string
  username: string
  name: string
  corporateId: any
  starting_balance_fuel: number
  replenish_amount: number
  is_demo: boolean
  offline_verification: boolean | string
}

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/

const AddDriver: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    watch,
    control,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)

  const { userType } = useStore((state) => state)
  const { push } = useHistory()
  const { onSuccess } = useAlert()
  const [corporatePinReset, setCorporatePinReset] = useState<any>(null)

  const stratingBalance = useRef({})
  stratingBalance.current = watch('starting_balance_fuel', 0)
  const selectedCorporate = useRef(null)
  selectedCorporate.current = watch('corporateId', null)

  useEffect(() => {
    if (selectedCorporate.current !== null && !isNaN(selectedCorporate.current) && (selectedCorporate.current as Array<any>)?.length !== 0) {
      getCorporate()
      setValue('pin_code', '')
    } else {
      setCorporatePinReset(null)
    }
  }, [selectedCorporate.current])

  const { isLoading, mutate, error } = useMutation((vars: AddDriverMutationVariables) => fetch('POST', '/drivers', { data: vars }), {
    onSuccess: (): void => {
      push('/drivers')
      onSuccess()
    },
  })

  const { mutate: getCorporate } = useMutation(() => fetch('GET', `/corporates/${selectedCorporate.current}`, {}), {
    onSuccess: (data: any): void => {
      setCorporatePinReset(null)
      setCorporatePinReset(data?.config?.pin_code_digits_number)
    },
  })

  const AddDriver = async (formData: AddDriverMutationVariables) => {
    formData.offline_verification = formData.offline_verification === 'true' ? true : false
    userType === USER_TYPE.ADMIN && (formData.corporateId = +formData?.corporateId[0])
    formData.starting_balance_fuel = +formData?.starting_balance_fuel
    formData.replenish_amount = +formData?.replenish_amount
    formData.is_demo = false
    mutate(formData)
  }

  return (
    <Container>
      <Flex ref={headerRef} flexDir='column' className='margin-150'>
        <Header
          title={dictionary().ADD_NEW_DRIVER}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && AddDriver(getValues() as AddDriverMutationVariables)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(AddDriver as any)}>
          <Flex className='margin-100' justify='center' align='center' direction='column'>
            {userType === USER_TYPE.ADMIN && (
              <AutoCompleteControl
                control={control}
                errors={errors}
                identifier='corporateId'
                label={dictionary().CORPORATE}
                route='/corporates'
                displayFormatter={(option: any) => option?.name}
              />
            )}
            {selectedCorporate.current !== null && (
              <PinCodeInput
                error={error}
                errors={errors}
                register={register}
                setValue={setValue}
                getValues={getValues}
                width={['100%', '70%', '70%', '70%']}
                pinLength={corporatePinReset}
              />
            )}

            <FormControl
              id={dictionary().USERNAME}
              w={['100%', '70%', '70%', '70%']}
              isInvalid={!!errors.username || (error as any)?.data?.message === ' username already exists'}
            >
              <FormLabel>
                {dictionary().USERNAME} <Required />
              </FormLabel>
              <Input
                type='string'
                {...register('username', {
                  required: dictionary().REQUIRED,
                  validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                })}
              />
              <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
              <FormErrorMessage>
                {(error as any)?.data?.message === ' username already exists' && dictionary().THIS_USER_NAME_IS_ALREADY_TAKEN}
              </FormErrorMessage>
            </FormControl>
            <PasswordAndConfirmation error={error} register={register} errors={errors} watch={watch} width={['100%', '70%', '70%', '70%']} />
            <FormControl id={dictionary().NAME} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.name}>
              <FormLabel>
                {dictionary().NAME} <Required />
              </FormLabel>
              <Input
                type='string'
                {...register('name', {
                  required: dictionary().REQUIRED,
                  validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                })}
              />
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().DRIVER_PHONE}
              w={['100%', '70%', '70%', '70%']}
              isInvalid={!!errors.mobileNumber || (error as any)?.data?.message === 'Mobile number already exists'}
            >
              <FormLabel>
                {dictionary().DRIVER_PHONE} <Required />
              </FormLabel>
              <Input
                type='text'
                {...register('mobileNumber', {
                  required: dictionary().REQUIRED,
                  pattern: {
                    value: /^01[0125][0-9]{8}$/,
                    message: dictionary().INVALID_PHONE,
                  },
                })}
              />
              <FormErrorMessage>{errors.mobileNumber?.message}</FormErrorMessage>
              <FormErrorMessage>
                {(error as any)?.data?.message === 'Mobile number already exists' && dictionary().THIS_PHONE_NUMBER_IS_ALREADY_TAKEN}
              </FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().EMAIL} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.email}>
              <FormLabel>
                {dictionary().EMAIL} <Required />
              </FormLabel>
              <Input
                type='email'
                {...register('email', {
                  required: dictionary().REQUIRED,
                  validate: (value) => (!emailRegex.test(value) ? dictionary().INVALID_EMAIL : undefined),
                })}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id={dictionary().DRIVER_CODE}
              w={['100%', '70%', '70%', '70%']}
              isInvalid={!!errors.code || (error as any)?.data?.message === `Code already exists`}
            >
              <FormLabel>{dictionary().DRIVER_CODE}</FormLabel>
              <Input
                type='string'
                {...register('code', {
                  required: false,
                })}
              />
              <FormErrorMessage>{(error as any)?.data?.message === `Code already exists` && dictionary().THIS_CODE_IS_ALREADY_OCCURED}</FormErrorMessage>
            </FormControl>

            <FormControl id={dictionary().STARTING_BALANCE} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.starting_balance_fuel}>
              <FormLabel>
                {dictionary().STARTING_BALANCE} <Required />
              </FormLabel>
              <Input
                placeholder='0'
                type='number'
                {...register('starting_balance_fuel', {
                  required: true && dictionary().REQUIRED,
                  validate: (value) => (value < 0 ? dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO : undefined),
                })}
              />
              <FormErrorMessage>{errors.starting_balance_fuel?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id={dictionary().REPLENISH_AMOUNT} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.replenish_amount}>
              <FormLabel>
                {dictionary().REPLENISH_AMOUNT} <Required />
              </FormLabel>
              <Input
                placeholder='0'
                type='number'
                {...register('replenish_amount', {
                  required: true && dictionary().REQUIRED,
                  pattern: {
                    value: /^\d+$/,
                    message: dictionary().THIS_INPUT_ACCEPTED_ONLY_INTEGER_NUMBERS,
                  },
                  min: {
                    value: 0,
                    message: dictionary().NO_NEGATIVE_NUMBER,
                  },
                  validate: (value) => {
                    if (value > +stratingBalance.current) return dictionary().REPLENISH_AMOUNT_LESS_THAN_STARTING_BALANCE
                  },
                })}
              />
              <FormErrorMessage>{errors.replenish_amount?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id='offline_verification' w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.offline_verification}>
              <FormLabel>
                {dictionary().DRIVER_VERIFICATION} <Required />
              </FormLabel>
              <Controller
                name='offline_verification'
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction='row'>
                      <Radio value='true' color='purple'>
                        {dictionary().OFFLINE_PIN}
                      </Radio>
                      <Radio value='false' color='purple'>
                        {dictionary().QR_CODE}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
                rules={{
                  required: true && dictionary().REQUIRED,
                }}
              />
              <FormErrorMessage>{errors.offline_verification?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddDriver
