import React, { useEffect, useRef } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, Checkbox } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'

type EditChargingProfileVariables = {
  name: any
  id: number
}

const EditChargingProfile: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    control,
    watch,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const useShiftValue = useRef({})
  useShiftValue.current = watch('use_shifts', '')

  const { data } = useQuery(['corporate-group', id], () => fetch('GET', `/corporate-group/${id}`), {})

  useEffect(() => {
    setValue('name', data?.name)
    setValue('use_shifts', data?.use_shifts)
    setValue('shift_starting', data?.shift_starting)
  }, [data])

  const { isLoading, mutate } = useMutation((vars: any) => fetch('PUT', '/corporate-group', { data: vars }), {
    onSuccess: (): void => {
      push('/corporate-groups')
      onSuccess()
    },
    onError,
  })

  const EditChargingProfile = async (formData: EditChargingProfileVariables) => {
    formData.id = +id
    mutate([formData])
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().ADD_NEW_CORPORATE}
          action={
            <SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && EditChargingProfile(getValues() as EditChargingProfileVariables)} />
          }
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(EditChargingProfile as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl
              id='ADD_NEW_CORPORATE_GROUP_NAME'
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.name}
            >
              <FormLabel>
                <>
                  {' '}
                  {dictionary().CORPORATE_GROUP_NAME} <Reqiured />
                </>
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('name', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().SHIFTS} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().SHIFTS}</FormLabel>
              <Controller
                control={control}
                name='use_shifts'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().use_shifts}>
                    {dictionary().USE_SHIFTS}
                  </Checkbox>
                )}
              />
            </FormControl>
            {useShiftValue.current && (
              <FormControl
                id={dictionary().SHIFT_START_TIME}
                w={['100%', '40%', '40%', '40%']}
                minH={['50px', '100px']}
                display='inline-block'
                isInvalid={!!errors.shift_starting}
              >
                <FormLabel>
                  <>
                    {dictionary().SHIFT_START_TIME}
                    <Reqiured />
                  </>
                </FormLabel>
                <InputGroup>
                  <Input
                    type='text'
                    defaultValue='00:00'
                    {...register('shift_starting', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                      pattern: {
                        value: /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/,
                        message: dictionary().STARTING_SHIFT_PATTERN,
                      },
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.shift_starting?.message}</FormErrorMessage>
              </FormControl>
            )}
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default EditChargingProfile
