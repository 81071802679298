import { StateManagementVariables } from '../../../types/AddFuelTransactionVariables'

type updateStaffParams = {
  stationStaffId: any
  stateManager: StateManagementVariables
  setStateManager: any
  setValue: any
}
const updateStaff = ({ stationStaffId, stateManager, setStateManager, setValue }: updateStaffParams): void => {
  // Check if stationStaffId.current is a number and not NaN
  if (typeof stationStaffId.current === 'number' && !Number.isNaN(stationStaffId.current)) {
    // Set isStationStaffSelected to true in the state manager
    setStateManager({ ...stateManager, isStationStaffSelected: true })
  } else {
    // Set isStationStaffSelected to false in the state manager
    setStateManager({ ...stateManager, isStationStaffSelected: false })
    // Reset form values
    setValue('fuel_type_id', [])
    setValue('amount', '')
  }
}

export default updateStaff
