import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FC, useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import filterIconSrc from '../../../assets/filter.svg'
import dictionary from '../../../dictionary'
import useQueryParams from '../../../hooks/useQueryParams '
import { useFilterStore } from '../../../stores/filterStore'
import AutoCompleteInput from '../AutoCompleteInput'

type DuplicateFilterModelProps = any

const DuplicateFilterModel: FC<DuplicateFilterModelProps> = () => {
  const { from, to } = useFilterStore()
  const { onClose, isOpen, onOpen } = useDisclosure()
  const { add, remove } = useQueryParams()
  const searchRef = useRef<HTMLButtonElement>(null)
  const {
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
  } = useForm({ mode: 'onChange' })

  const fieldValues = {
    corporate: watch('corporateIds', []),
    station: watch('stationIds', []),
  }

  useEffect(() => {
    setValue('corporateIds', [])
    setValue('stationIds', [])
    setValue('from', '')
    setValue('to', '')
    from && add('from', from)
    to && add('to', to)
  }, [])

  const clearAll = () => {
    remove('corporateIds')
    remove('stationIds')

    setValue('corporateIds', [])
    setValue('stationIds', [])
    Object.keys(fieldValues).forEach((field) => setValue(field, []))

    onClose()
  }

  const submit = async () => {
    remove('corporateIds')
    remove('stationIds')
    remove('from')
    remove('to')

    getValues('corporateIds')?.length > 0 && add('corporateIds', getValues('corporateIds'))
    getValues('stationIds')?.length > 0 && add('stationIds', getValues('stationIds'))
    from && add('from', from)
    to && add('to', to)
    onClose()
  }

  return (
    <>
      <Box position='relative' display='inline-block' sx={{ mt: '15px' }}>
        <Button onClick={onOpen} variant='outline' style={{ backgroundColor: '#7165E3' }} _focus={{ boxShadow: 'none' }}>
          <Image src={filterIconSrc} width={25} height={30} />
        </Button>
        {/* Red dot indicator */}
        {Object.values(fieldValues).some((value) => value?.length > 0) && (
          <Box position='absolute' top='-1' right='-1' width='15px' height='15px' borderRadius='50%' backgroundColor='red'></Box>
        )}
      </Box>
      <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={searchRef} onClose={submit} isOpen={isOpen} isCentered autoFocus colorScheme={'purple'}>
        <AlertDialogOverlay />
        <AlertDialogContent dir='rtl' maxW='900px'>
          <AlertDialogHeader>{dictionary().ADVANCED_FILTERATION}</AlertDialogHeader>
          <AlertDialogBody>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
              <FormControl id={dictionary().CHOOSE_CORPORATE} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().CHOOSE_CORPORATE}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.corporate?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`corporateIds`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      multiple={true}
                      isInvalid={!!errors?.corporateIds}
                      onChange={onChange}
                      value={value}
                      searchRoute='/corporates'
                      singleRoute='/corporates'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name}
                      boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                    />
                  )}
                />
              </FormControl>
              <FormControl id={dictionary().CHOOSE_STATION} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().CHOOSE_STATION}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.station?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`stationIds`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      multiple={true}
                      isInvalid={!!errors?.stationIds}
                      onChange={onChange}
                      value={value}
                      searchRoute='/stations'
                      singleRoute='/stations'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name?.ar}
                      boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant={'outline'} onClick={onClose}>
              {dictionary().CANCEL}
            </Button>
            <Button mr='3' style={{ backgroundColor: '#E0E0E0' }} variant={'solid'} onClick={() => clearAll()}>
              {dictionary().CLEAR_ALL}
            </Button>
            <Button ref={searchRef} mr='3' colorScheme='blue' variant={'solid'} onClick={() => submit()}>
              {dictionary().SEARCH}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default DuplicateFilterModel
