import { useRef, useEffect, useState } from 'react'

import {
  Modal,
  Box,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
  FormLabel,
  Input,
  FormErrorMessage,
  FormControl,
  Spinner,
  Text,
} from '@chakra-ui/react'

import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import Required from '../../components/core/reqiured'
import useAlert from '../../helpers/useAlert'
import { useStore } from '../../store'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import { USER_TYPE } from '../../constants'
import DropDownSelectControl from './../../pages/transactions/components/DropDownSelectControl'
import prepareDropDownData from './../../pages/transactions/utils/prepareDropDownData'
import AutoCompleteInput from './AutoCompleteInput'

interface Props {
  data?: any
}

const ConfirmTransactionModel: React.FC<Props> = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { userType } = useStore((state) => state)
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const { pathname } = useLocation()
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    trigger,
    setError,
  } = useForm({
    mode: 'onChange',
  })

  const { isLoading, mutate, error, isError } = useMutation(
    (vars: any) => fetch('POST', `/fuel-transactions/${data.id}/confirm-with-driver_id`, { data: vars }),
    {
      onSuccess: (): void => {
        onSuccess()
        onClose()
        window.location.reload()
        // push(pathname)
      },
      onError: (err: any): void => {
        onError(err?.data?.message)
      },
    },
  )

  const confirmTransaction = async () => {
    mutate({
      driver_id: +getValues()?.driver_id,
    })
  }

  const handleOnOpen = () => {
    onOpen()
    delete errors?.driver_id
    error?.data && (error.data.message = '')
    setValue('driver_id', null)
  }

  if ((data.status === 'PENDING' || data?.status_value !== 'مؤكدة') && userType === USER_TYPE.ADMIN) {
    return (
      <>
        <Button mb={1} onClick={handleOnOpen}>
          {dictionary().CONFIRM_IT}
        </Button>

        <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader style={{ direction: 'rtl' }}>{dictionary().ACTIONS} </ModalHeader>
            <form onSubmit={handleSubmit(confirmTransaction as any)}>
              <ModalBody pb={6}>
                <FormControl id={dictionary().DRIVER_NAME} w='100%' dir='rtl' marginTop={5} isRequired isInvalid={!!errors?.driver_id || error?.data?.message}>
                  <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>{dictionary().DRIVER_NAME}</FormLabel>
                  <Controller
                    name={`driver_id`}
                    rules={{
                      required: true && dictionary().REQUIRED,
                    }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        isInvalid={!!errors?.driver_id}
                        onChange={onChange}
                        value={value}
                        searchRoute={`/vehicles/${data?.vehicle_id || data?.vehicle?.id}/drivers`}
                        singleRoute={`/vehicles/${data?.vehicle_id || data?.vehicle?.id}/drivers`}
                        valueFormatter={(option) => option?.id}
                        displayFormatter={(option) => option?.name}
                        boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                      />
                    )}
                  />
                  <FormErrorMessage>{!!errors?.driver_id && dictionary().REQUIRED}</FormErrorMessage>
                  <FormErrorMessage color='red.500' dir='rtl' m={1}>
                    {error?.data?.message && `${error?.data?.message}`}
                  </FormErrorMessage>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button onClick={onClose}>{dictionary().CANCEL}</Button>
                <Button
                  disabled={isLoading || !!errors?.driver_id || error?.data?.message}
                  colorScheme='blue'
                  mx={2}
                  onClick={async () => (await trigger()) && confirmTransaction()}
                >
                  {isLoading ? <Spinner /> : dictionary().DEFAULT_CONFIRM_CONFIRMATION_TEXT}
                </Button>
                <h5 style={{ textAlign: 'center' }}>{dictionary().DEFAULT_CONFIRM_DESCRIPTION}</h5>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    )
  } else {
    return <></>
  }
}

export default ConfirmTransactionModel
