import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  Modal,
  Spinner,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  useDisclosure,
  MenuItem,
  Flex,
  FormErrorMessage,
} from '@chakra-ui/react'
import useAlert from '../../helpers/useAlert'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'

interface Props {
  action?: any
}

const RefillBalancesModal: React.FC<Props> = ({ action }) => {
  const { id } = useParams<{ id: string }>()
  const { onSuccess, onError } = useAlert()
  const [hasDrivers, setHasDrivers] = useState(false)
  const [hasVehicles, setHasVehicles] = useState(false)
  const {
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({ mode: 'onChange' })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  const { isLoading, mutate, error } = useMutation(
    () => fetch('POST', `corporates/${id}/refill-balance/${action.type === 'refillVehicles' ? 'vehicles' : 'drivers'}`),
    {
      onSuccess: (): void => {
        onSuccess()
        onClose()
      },
      onError,
    },
  )

  useEffect(() => {
    if (isOpen) {
      fetch('GET', `/drivers?corporateId=${id}`).then((res) => {
        if (res?.data?.length > 0) {
          setHasDrivers(true)
        } else {
          setHasDrivers(false)
        }
      })
      fetch('GET', `/vehicles?corporateId=${id}`).then((res) => {
        if (res?.data?.length > 0) {
          setHasVehicles(true)
        } else {
          setHasVehicles(false)
        }
      })
    }
  }, [isOpen])

  const refillBalances = async () => {
    mutate()
  }

  return (
    <>
      {action.type === 'refillVehicles' ? <MenuItem onClick={onOpen}>{action.title}</MenuItem> : <MenuItem onClick={onOpen}>{action.title}</MenuItem>}
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{action.title} </ModalHeader>
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit(refillBalances as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                <FormControl id='refill-balances-error' w='40%' minH='100px' display='inline-block'>
                  <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
                  {<FormErrorMessage>{error?.data && dictionary().NOT_ENOUGH_CREDIT}</FormErrorMessage>}
                </FormControl>
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              disabled={action.type === 'refillVehicles' ? !hasVehicles : !hasDrivers}
              onClick={async () => (await trigger()) && refillBalances()}
              colorScheme='blue'
              mr={3}
            >
              {isLoading ? <Spinner /> : dictionary().CONFIRM_IT}
            </Button>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default RefillBalancesModal
