import React, { useRef } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, Select } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'

const roles = [
  {
    id: 1,
    role: 'MANAGER',
    name: dictionary().MANAGER,
  },
  {
    id: 2,
    role: 'ACCOUNTANT',
    name: dictionary().ACCOUNTANT,
  },
  {
    id: 2,
    role: 'TOP_MANAGER',
    name: dictionary().TOP_MANAGER,
  },
]

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/

const AddNewCGStaff: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    watch,
    trigger,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/corporate-group-staff', { data: vars }), {
    onSuccess: (): void => {
      push(`/corporate-groups/${id}`)
      onSuccess()
    },
    onError: (err: any): void => {
      // onError(err?.data?.message)
    },
  })
  const AddNewCGStaff = async () => {
    mutate([
      {
        name: {
          en: getValues()?.en,
          ar: getValues()?.ar,
        },
        username: getValues()?.username.trim(),
        email: getValues()?.username.trim().toLowerCase(),
        // password: getValues()?.password,
        phone_number: getValues()?.phone_number,
        role: getValues()?.role,
        corporate_group_id: +id,
      },
    ])
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header title={dictionary().ADD_NEW_STAFF} action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && AddNewCGStaff()} />} />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(AddNewCGStaff as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl id={dictionary().NAME_AR} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.ar}>
              <FormLabel>
                {dictionary().NAME_AR} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().NAME_AR}
                  type='text'
                  {...register('ar', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.ar?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().NAME_EN} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.en}>
              <FormLabel>
                {dictionary().NAME_EN} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().NAME_EN}
                  type='text'
                  {...register('en', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.en?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().EMAIL}
              w='40%'
              minH='100px'
              display='inline-block'
              isInvalid={!!errors.username || error?.data?.message === `Entity with given email '${getValues().username}' already exists`}
            >
              <FormLabel>
                {dictionary().EMAIL} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().EMAIL}
                  placeholder={dictionary().EMAIL}
                  type='email'
                  {...register('username', {
                    required: dictionary().REQUIRED,
                    validate: (value) => (!emailRegex.test(value) ? dictionary().INVALID_EMAIL : undefined),
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
              <FormErrorMessage>
                {error?.data?.message === `Entity with given email '${getValues().username}' already exists` && dictionary().ALREADY_USED_EMAIL}
              </FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().PHONE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.phone_number}>
              <FormLabel>
                <>
                  {dictionary().PHONE}
                  <Reqiured />
                </>
              </FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().PHONE}
                  type='number'
                  {...register('phone_number', {
                    required: dictionary().REQUIRED,
                    pattern: {
                      value: /^01[0125][0-9]{8}$/,
                      message: dictionary().INVALID_PHONE,
                    },
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.phone_number?.message}</FormErrorMessage>
            </FormControl>
            {/* <PasswordAndConfirmation register={register} errors={errors} watch={watch} width={['100%', '40%', '40%', '40%']} minHight={['50px', '100px']} /> */}
            <FormControl id={dictionary().ROLE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.role}>
              <FormLabel>{dictionary().ROLE}</FormLabel>
              <Select
                data-test={dictionary().ROLE}
                dir='ltr'
                textAlign='right'
                {...register('role', { required: dictionary().REQUIRED })}
                placeholder={dictionary().ROLE}
              >
                {roles?.map((item: any) => (
                  <option key={item?.id} value={item?.role}>
                    {' '}
                    {item?.name}
                  </option>
                ))}
              </Select>

              <FormErrorMessage>{errors.role?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().PHONE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.phone_number}></FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddNewCGStaff
