export const ROLES = {
  SUPER_ADMIN: 'SUPER',
  MANAGER: 'MANAGER',
  BACK_OFFICE: 'BACK_OFFICE',
  LIVE_OPS: 'LIVE_OPS',
  FIN_OPS: 'FIN_OPS',
  OPERATIONS: 'OPERATIONS',
  ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
  SALES: 'SALES',
  ACQUISITION: 'ACQUISITION',
  FINANCE: 'FINANCE',
  ATTENDANT: 'ATTENDANT',
}
