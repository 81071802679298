import React from 'react'
import ViewOne from '../../components/layout/ViewOne'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const header = [
  {
    id: 'fuel_balance',
    key: 'ledger_accounts',
    name: dictionary().AVIALABLE_BALANCE,
    type: 'FuelBalance',
    isRight: true,
  },
  {
    id: 'driver_corporate',
    key: 'corporate.name',
    name: dictionary().CORPORATE,
    type: 'text',
  },

  {
    id: 'mobile_number',
    key: 'mobile_number',
    name: dictionary().DRIVER_PHONE,
    type: 'text',
  },
  {
    id: 'username',
    key: 'username',
    name: dictionary().DRIVER_USER_NAME,
    type: 'text',
  },
  {
    id: 'code',
    key: 'code',
    name: dictionary().DRIVER_CODE,
    type: 'text',
  },
  {
    id: 'starting_balance_fuel',
    key: 'starting_balance_fuel',
    name: dictionary().STARTING_BALANCE,
    type: 'cash',
  },
  {
    id: 'replenish_amount',
    key: 'replenish_amount',
    name: dictionary().REPLENISH_AMOUNT,
    type: 'cash',
  },
  {
    id: 'date',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'offline_verification',
    key: 'offline_verification',
    name: dictionary().DRIVER_VERIFICATION,
    type: 'boolean',
  },
  {
    id: 'is_active',
    key: 'is_active',
    name: dictionary().STATUS,
    type: 'boolean',
  },
  {
    id: 'is_demo',
    key: 'is_demo',
    name: dictionary().IS_DEMO,
    type: 'boolean',
  },
]
const actions = [
  {
    type: 'path',
    action: 'edit',
    title: dictionary().EDIT,
  },
  {
    type: 'resetPinCode',
    title: dictionary().RESET_PIN_CODE,
  },
  {
    type: 'resetDriverPassword',
    title: dictionary().RESET_PASSWORD,
  },
  // {
  //   type: 'transeferModal',
  //   title: dictionary().TRANSFER,
  // },
  // {
  //   type: 'transeferBetweenEntitiesModal',
  //   title: dictionary().TRANSFER_TO_DRIVER,
  // },
  {
    type: 'transeferBetweenEntitiesModal',
    title: dictionary().TRANSFER_TO_VEHICLE,
  },
]

const Driver: React.FC = () => {
  return (
    <>
      <ViewOne
        actions={actions}
        header={header}
        restRoute='/drivers'
        dataLocation={''}
        title={dictionary().DRIVER_NAME}
        cashingKey={CASHING_KEY.GET_SINGLE_DRIVER}
        userType='drivers'
      />
    </>
  )
}

export default Driver
