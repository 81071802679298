import React, { useState } from 'react'
import { InputGroup, Input, InputRightElement, IconButton, InputProps } from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

interface Props {
  inputProps?: InputProps
}

const PasswordInput: React.FC<Props> = ({ inputProps }) => {
  const [show, setShow] = useState(false)
  const handleClick = () => setShow((prev) => !prev)

  return (
    <InputGroup size='md'>
      <Input dir='ltr' type={show ? 'text' : 'password'} placeholder='' {...inputProps} />
      <InputRightElement justifyContent='flex-end'>
        <IconButton
          icon={show ? <ViewOffIcon /> : <ViewIcon />}
          variant='ghost'
          mr='1.5'
          rounded='md'
          size='sm'
          onClick={handleClick}
          aria-label={show ? 'hide password' : 'show password'}
        />
      </InputRightElement>
    </InputGroup>
  )
}

export default PasswordInput
