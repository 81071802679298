import { Flex } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { AddIcon } from '@chakra-ui/icons'
import { useDownloadChargingProfileExcel } from '../../../hooks/charging_profile/useDownloadChargingProfileExcel'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const actionsColumn = (header: any, row: any) => {
  const { getExcelFile } = useDownloadChargingProfileExcel()

  const { push } = useHistory()
  switch (header.id) {
    case 'versionActions':
      return (
        <Flex justifyContent='center' gap='18px'>
          <img src='/assets/download-purple.svg' style={{ cursor: 'pointer' }} onClick={() => getExcelFile(row?.corporate?.id)} />
          <img
            src='/assets/plus-icon.svg'
            style={{ cursor: 'pointer', color: '#6F65DB', marginLeft: '10px' }}
            onClick={() => push(`/charging-profiles/new?corporateId=${row.corporate.id}`)}
          />
        </Flex>
      )
    default:
      return null
  }
}

export default actionsColumn
