import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { parse, stringify } from 'query-string'
import { Flex, IconButton, Text } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

import dictionary from '../../dictionary'
import { useFilterStore } from '../../stores/filterStore'

interface Props {
  totalCount: number
  pageSize: number
}

const Pagination: React.FC<Props> = ({ totalCount, pageSize }) => {
  const { page, setPage } = useFilterStore()
  const history = useHistory()
  const { pathname, search } = useLocation()
  const query = parse(search)
  const parsedPageNumber = pathname === '/fuel-transactions' ? page : parseInt(query.page as string) || 1
  const maxPage = Math.ceil(totalCount / pageSize)
  const isLastPage = parsedPageNumber >= maxPage
  const isFirstPage = parsedPageNumber <= 1

  const remainder = totalCount % pageSize
  let upperBound = parsedPageNumber * pageSize
  if (remainder && isLastPage) upperBound = totalCount
  const lowerBound = parsedPageNumber * pageSize - pageSize + 1
  const onPrevious = () => {
    if (pathname === '/fuel-transactions') {
      setPage(page <= 1 ? 1 : page - 1)
    } else {
      const newPage = parsedPageNumber <= 1 ? 1 : parsedPageNumber - 1
      query.page = `${newPage}`
      history.push({ search: stringify(query) })
    }
  }

  const onNext = () => {
    if (pathname === '/fuel-transactions') {
      setPage(page + 1)
    } else {
      const newPage = parsedPageNumber + 1
      query.page = `${newPage}`
      history.push({ search: stringify(query) })
    }
  }

  return (
    <Flex align='center'>
      <IconButton
        disabled={isFirstPage}
        onClick={onPrevious}
        aria-label='previous page'
        variant='ghost'
        rounded='md'
        icon={<ChevronRightIcon fontSize='lg' />}
      />
      {pathname === '/fuel-transactions' ? (
        <Text fontSize='xs' w='100px' textAlign='center'>
          {lowerBound as any}-{upperBound as any} {dictionary().OF} {totalCount as any}
        </Text>
      ) : (
        <Text fontSize='xs' w='100px' textAlign='center'>
          {lowerBound as any}-{upperBound as any} {dictionary().OF} {totalCount as any}
        </Text>
      )}
      <IconButton variant='ghost' rounded='md' icon={<ChevronLeftIcon fontSize='lg' />} disabled={isLastPage} onClick={onNext} aria-label='next page' />
    </Flex>
  )
}

export default Pagination
