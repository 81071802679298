import React, { useRef, useEffect } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, InputLeftElement, Select } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { parse } from 'query-string'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'

const EditStoreProduct: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
  } = useForm({
    mode: 'onChange',
  })

  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const product = useQuery(['store-product', id], () => fetch('GET', `/store-products/${id}`), {})
  const { isLoading, mutate } = useMutation((vars: any) => fetch('PATCH', `/store-products/${id}`, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push('/store-products')
    },
    onError,
  })
  useEffect(() => {
    setValue('price', product?.data?.price)
    setValue('en', product?.data?.name?.en)
    setValue('ar', product?.data?.name?.ar)
    setValue('stationId', product?.data?.stationId)
  }, [product?.data])
  const editStoreProduct = async () => {
    mutate({
      name: {
        en: getValues()?.en,
        ar: getValues()?.ar,
      },
      price: getValues()?.price,
      stationId: getValues()?.stationId,
    })
  }

  const query = parse(location.search, { parseBooleans: true })
  const stations = useQuery([query, 'stations'], () => fetch('GET', '/stations'))

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().EDIT_STORE_PRODUCT}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && editStoreProduct()} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(editStoreProduct as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl id={dictionary().PRODUCT_NAME_AR} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.ar}>
              <FormLabel>
                {dictionary().PRODUCT_NAME_AR} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('ar', {
                    required: dictionary().REQUIRED,
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.ar?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().PRODUCT_NAME_EN} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.en}>
              <FormLabel>
                {dictionary().PRODUCT_NAME_EN} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('en', {
                    required: dictionary().REQUIRED,
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.en?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().PRICE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.price}>
              <FormLabel>
                {dictionary().PRICE} <Reqiured />
              </FormLabel>
              <InputGroup>
                <InputLeftElement height='100%' pointerEvents='none'>
                  {dictionary().PRICING_CURRENCY}
                </InputLeftElement>
                <Input
                  type='number'
                  {...register('price', {
                    required: dictionary().REQUIRED,
                    valueAsNumber: true,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.price?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().STATION_NAME}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.stationId}
            >
              <FormLabel>
                {dictionary().STATION_NAME} <Reqiured />{' '}
              </FormLabel>
              <Select
                dir='ltr'
                textAlign='right'
                {...register('stationId', { valueAsNumber: true, required: dictionary().REQUIRED })}
                // placeholder={dictionary().STATION}
                defaultValue={getValues()?.stationId}
              >
                {stations?.data?.data?.map((item: any) => (
                  <option key={item?.id} value={item?.id}>
                    {' '}
                    {item?.name?.ar}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.stationId?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default EditStoreProduct
