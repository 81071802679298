import { CheckCircleIcon, CheckIcon, CloseIcon, WarningIcon } from '@chakra-ui/icons'
// eslint-disable-next-line import/named
import { Button, Flex, FormControl, FormErrorMessage, Icon, Input, InputGroup, Link, Select, Text, Tooltip } from '@chakra-ui/react'
import moment from 'moment-timezone'
import { stringify } from 'query-string'
// eslint-disable-next-line import/named
import { Functor, map, path } from 'ramda'
import { useState } from 'react'
import { BsExclamationLg, BsXCircleFill } from 'react-icons/bs'
import { MdOutlineDone } from 'react-icons/md'
import { useQuery } from 'react-query'
import { Link as ReactLink, useHistory, useLocation, useParams } from 'react-router-dom'
import { canEditEntity } from '../../../constants/editEntities/canEditEntity'
import { canEditCGStaff } from '../../../constants/editEntities/canEditCGStaff'
import dictionary from '../../../dictionary'
import fetch from '../../../helpers/fetch'
import financialNums from '../../../helpers/financialNums'
import formatAsCurrency from '../../../helpers/formatAsCurrency'
import { formatDecimal } from '../../../helpers/formatDecimal'
import formateDate from '../../../helpers/formateDate'
import getVersionStatus from '../../../helpers/getVersionStatus'
import { RowProps, TableHeader } from '../../../interfaces/tableProps'
import InvoicePayment from '../InvoicePayment'
import InvoiceDetails from '../Invoices/Details'
import actionsColumn from './actionsColumn'
import { modalCases } from './helpers'
import { TableDetails } from './TableDetails'
import { useStore } from '../../../store'

export const Row: React.FC<RowProps> = ({ header, row, handleIsDriverRemoved, renewalDate, handleIsBalancesChange, register, errors }) => {
  const { role } = useStore()
  const { push } = useHistory()
  const value = header.format ? header.format(path(header.key.split('.'), row), row) : path(header.key.split('.'), row)
  const urlQuery = (header.payload as Record<string, Record<string, string>>)?.search ?? {}
  const urlQueryValues = map((key) => path(key.split('.'), row), urlQuery as Functor<string>)
  const stringifiedQuery = stringify(urlQueryValues)
  const { account_id } = useParams<{ account_id: string }>()
  const [selected, setSelected] = useState(false)
  const [activate, setActivate] = useState(true)
  const { pathname } = useLocation()
  let corporateGroups: any = []
  pathname.includes('/corporates') ? (corporateGroups = useQuery(['corporate-group'], () => fetch('GET', `/corporate-group`), {})) : ''

  const handle = () => {
    setSelected(true)
    setActivate(true)
  }

  const getPaymentMethodName = (value: any) => {
    switch (value) {
      case 'CASH':
        return dictionary().CASH
      case 'TRANSFER':
        return dictionary().TRANSFER
      case 'VEHICLES_SUBSCRIPTION':
        return dictionary().VEHICLES_SUBSCRIPTION
      case 'CHEQUE':
        return dictionary().CHEQUE
      case 'BANK_DEPOSIT':
        return dictionary().BANK_DEPOSIT
      case 'BANK_TRANSFER':
        return dictionary().BANK_TRANSFER
      default:
        return value
    }
  }

  const getColor = (correction: number) => {
    let color = ''
    Math.abs(correction) > 1 ? (color = 'red.500') : (color = '')
    return color
  }

  const getIsRefunded = (isRefunded: any) => {
    if (isRefunded) {
      return (
        <Flex
          alignItems='center'
          gap={'5px'}
          width={'100px'}
          backgroundColor={'#D9F0E8'}
          borderRadius={'6px'}
          color={'#42B48B'}
          sx={{ padding: '4px 7px 4px 10px' }}
        >
          <img src='/assets/checked.svg' />
          <Text>{dictionary().RESTORED}</Text>
        </Flex>
      )
    }
    return <></>
  }
  const getDuplicateStatus = (value: any) => {
    let status = ''
    let minWidth = '70px'
    switch (value) {
      case 'detected':
        status = dictionary().DUPLICATE
        minWidth = '70px'
        break
      case 'suspected':
        status = dictionary().SUSPECT
        minWidth = '93px'
        break
      default:
        status = value
        break
    }
    return (
      <Flex
        alignItems='center'
        justifyContent='center'
        gap={'3px'}
        backgroundColor={'#E99F2733'}
        color={'#E99F27'}
        borderRadius={'6px'}
        sx={{ padding: '2px 7px 2px 10px' }}
        minWidth={minWidth}
      >
        <img src='/assets/pending.svg' />
        <span>{status}</span>
      </Flex>
    )
  }

  const getReadingStatus = (value: any, status: any, type?: string) => {
    if (value == null && (status === null || status === 'NULL')) {
      return <></>
    }
    let color = ''
    let label = ''
    if (status === 'VALID' || (type === 'pump_reading' && status === null && value !== null)) {
      color = 'green.400'
      label = dictionary().CONFIRMED
    } else if (status === 'NULL' || status === null) {
      color = 'orange.400'
      label = dictionary().PENDING
    } else if (status === 'INVALID' || status === 'NOT_READABLE') {
      color = 'red.500'
      label = dictionary().NOT_READABLE
    } else if (status === 'MISSING') {
      color = 'gray.500'
      label = dictionary().NO_IMG
    }

    return !['VARIANCE_REFUND', 'DUPLICATE_REFUND'].includes(row?.correction_status) ? (
      <Flex alignItems='center'>
        <Tooltip label={label} placement='top' fontSize='md'>
          <WarningIcon color={color} marginLeft='5px' />
        </Tooltip>
        {value === 'null' || value === null ? <>-</> : financialNums(value)}
      </Flex>
    ) : (
      <Flex alignItems='center'>{value === 'null' || value === null ? <>-</> : financialNums(value)}</Flex>
    )
  }

  const setPumpReadingValue = (row: any, value: any) => {
    let indicatorColor = ''
    let label = ''
    let pumpReading = ''
    let color = ''
    const status = row.pump_reading_error_status

    if (value === null && (status === null || status === 'NULL')) {
      indicatorColor = 'orange.400'
      label = dictionary().PENDING
      color = ''
      if (row?.ocr_response) {
        if (row?.ocr_response?.amount_detected) {
          pumpReading = formatAsCurrency(row?.ocr_response?.amount_detected?.amount)
        } else if (row?.ocr_response?.calculated_amount) {
          pumpReading = formatAsCurrency(row?.ocr_response?.calculated_amount)
        } else {
          pumpReading = dictionary().READING
        }
      } else {
        pumpReading = dictionary().IS_READING
      }
    } else if (status === 'MISSING') {
      indicatorColor = 'gray.500'
      label = dictionary().NO_IMG
      pumpReading = '-'
    } else if (+(value as any) === 0 || status === 'NOT_READABLE') {
      indicatorColor = 'red.500'
      pumpReading = '-'
      label = dictionary().NOT_READABLE
    } else {
      color = getColor(row.correction)
      indicatorColor = color ? 'gray.500' : 'green.400'
      pumpReading = financialNums(value as any)
      label = color ? dictionary().NEED_REVIEW_AGAIN : dictionary().CONFIRMED
    }

    return !['VARIANCE_REFUND', 'DUPLICATE_REFUND'].includes(row?.correction_status) ? (
      <Flex alignItems='center'>
        <Tooltip label={label} placement='top' fontSize='md'>
          <WarningIcon color={indicatorColor} marginLeft='5px' />
        </Tooltip>
        <Text color={color}>{pumpReading}</Text>
      </Flex>
    ) : (
      <Flex alignItems='center'>
        <Text color={color}>{pumpReading}</Text>
      </Flex>
    )
  }
  const setPotentialLossValue = (row: any) => {
    if (!row?.vehicle?.fuel_consumption_benchmark) return 0
    const benchmarkFuelConsumption = row?.vehicle?.fuel_consumption_benchmark
    const fuelConsumption = row?.fuel_consumption
    const distanceTraveled = row?.distance_traveled
    const price = row?.fuel_type?.price
    const value = (((fuelConsumption - benchmarkFuelConsumption) * distanceTraveled) / 100) * price
    return value
  }

  const setCorrectionValue = (value: any, row: any) => {
    let indicatorColor = ''
    let label = ''
    let correctionValue = ''
    let pumpReading = ''
    let color = ''
    const status = row.pump_reading_error_status

    if (row.pump_reading === null && (status === null || status === 'NULL')) {
      if (row?.ocr_response) {
        if (row?.ocr_response?.amount_detected) {
          pumpReading = row?.ocr_response?.amount_detected?.amount
        } else if (row?.ocr_response?.calculated_amount) {
          pumpReading = row?.ocr_response?.calculated_amount
        } else {
          pumpReading = row.pump_reading
        }
      } else {
        pumpReading = row.pump_reading
      }
    } else {
      pumpReading = row.pump_reading
    }
    if (status === 'MISSING') {
      indicatorColor = 'gray.500'
      label = dictionary().NO_IMG
      correctionValue = '-'
    } else if (status === 'NOT_READABLE') {
      indicatorColor = 'red.500'
      correctionValue = '-'
      label = dictionary().NOT_READABLE
    } else if (value === null) {
      indicatorColor = 'orange.400'
      label = dictionary().PENDING
      color = ''
      correctionValue = dictionary().IS_READING
    } else if (+(value as any) === 0 && row.amount - +pumpReading === 0) {
      color = ''
      indicatorColor = 'green.400'
      correctionValue = '0%'
      label = dictionary().CONFIRMED
    } else if (+(value as any) === 0) {
      color = 'red.500'
      indicatorColor = 'red.500'
      correctionValue = dictionary().UNREAD_YET
      label = dictionary().UNREAD_YET
    } else {
      color = getColor(value as any)
      indicatorColor = color ? 'gray.500' : 'green.400'
      correctionValue = `${Math.abs(value as number).toFixed(2)} %`
      label = color ? dictionary().NEED_REVIEW_AGAIN : dictionary().CONFIRMED
    }
    return (
      <Flex alignItems='center'>
        <Tooltip label={label} placement='top' fontSize='md'>
          <WarningIcon color={indicatorColor} marginLeft='5px' />
        </Tooltip>
        <Text color={color}>{correctionValue}</Text>
      </Flex>
    )
  }

  const getDistanceTraveledErrors = (value: any) => {
    if (['VARIANCE_REFUND', 'DUPLICATE_REFUND'].includes(row?.correction_status)) {
      return <>-</>
    }
    let distanceError = '-'
    if (value === 'INVALID_ODOMETER_READING') {
      distanceError = dictionary().INVALID_ODOMETER_READING
    } else if (value === 'LOWER_THAN_PREVIOUS_ODOMETER_READING') {
      distanceError = dictionary().INVALID_ODOMETER_READING
    } else if (value === 'SAME_PREVIOUS_ODOMETER') {
      distanceError = dictionary().SAME_PREVIOUS_ODOMETER
    } else if (value === 'DISTANCE_MORE_THAN_MAX') {
      distanceError = dictionary().DISTANCE_MORE_THAN_MAX
    } else if (value === 'DISTANCE_LESS_THAN_MIN') {
      distanceError = dictionary().DISTANCE_LESS_THAN_MIN
    } else if (value === 'AFTER_NEGATIVE') {
      distanceError = dictionary().AFTER_NEGATIVE
    } else if (value === 'NEGATIVE_DISTANCE') {
      distanceError = dictionary().NEGATIVE_DISTANCE
    } else if (value === 'FIRST_TRANSACTION') {
      distanceError = dictionary().FIRST_TRANSACTION
    }
    return <>{distanceError}</>
  }

  const getProfileType = (value: any) => {
    const upperCaseValue = value?.toUpperCase() as keyof typeof dictionary
    return upperCaseValue ? dictionary()[upperCaseValue] : '-'
  }

  const getPosStatus = (row: any) => {
    const { super_station, station } = row
    if (super_station) {
      return dictionary().ALL_STATIONS
    } else if (station) {
      return station?.name?.ar
    } else {
      return dictionary().NOT_ASSIGNED
    }
  }

  switch (header.type) {
    case 'ledger_transaction':
      if (account_id == row.credit_account) {
        if (row?.entry_type === 'WITHDRAWAL') {
          if (header.key === 'credit_balance') {
            return <>-</>
          } else if (header.key === 'debit_balance') {
            return <>{formatAsCurrency(row.amount)}</>
          } else if (header.key === 'balance') {
            return <>{formatAsCurrency(row.credit_balance)}</>
          }
        } else if (header.key === 'credit_balance') {
          return <>{formatAsCurrency(row.amount)}</>
        } else if (header.key === 'debit_balance') {
          return <>-</>
        } else if (header.key === 'balance') {
          return <>{formatAsCurrency(row.credit_balance)}</>
        }
      } else if (account_id == row.debit_account) {
        if (row?.entry_type === 'WITHDRAWAL') {
          if (header.key === 'credit_balance') {
            return <>{formatAsCurrency(row.amount)}</>
          } else if (header.key === 'debit_balance') {
            return <>-</>
          } else if (header.key === 'balance') {
            return <>{formatAsCurrency(row.debit_balance)}</>
          }
        } else if (header.key === 'debit_balance') {
          return <>{formatAsCurrency(row.amount)}</>
        } else if (header.key === 'credit_balance') {
          return <>-</>
        } else if (header.key === 'balance') {
          return <>{formatAsCurrency(row.debit_balance)}</>
        }
      }
    case 'text':
      if (header.key === 'max_fuel_liters' || header.key === 'liters' || header.key === 'fuel_consumption' || header.key === 'original_fuel_consumption') {
        return <>{financialNums(value as any)}</>
      } else if (header.key == 'settlement') {
        return <>{value === 'PRE_PAID' ? dictionary().PRE_PAID : dictionary().POST_PAID}</>
      } else if (header.key == 'appointment_status') {
        return <>{getPosStatus(row)}</>
      } else if (header.id == 'profile_type') {
        return getProfileType(value)
      } else if (header.id == 'version_status') {
        return getVersionStatus(value)
      } else if (header.key == 'distance_traveled_errors') {
        return getDistanceTraveledErrors(value)
      } else if (header.key === 'dimensions_concat') {
        return <> ( {value as any} ) م</>
      } else if (header.key === 'dimensions') {
        return row?.dimensions ? `(${row?.dimensions?.width} * ${row?.dimensions?.height} * ${row?.dimensions?.depth}) م` : '-'
      } else if (header.key === 'payment_method') {
        return <>{getPaymentMethodName(value as any)}</>
      } else if (header.id === 'paymentCorporateName') {
        return <>{row?.corporate_name || row?.corporate_group_name}</>
      } else if (header.key === 'is_fraud') {
        return (
          <>
            {value === null ? dictionary().IS_READING : value ? <CheckCircleIcon color='red.500' /> : <Icon as={BsXCircleFill} w={4} h={4} color='green.500' />}
          </>
        )
      } else if (header.key === 'location.longitude' || header.key === 'location.latitude') {
        return <>{value}°</>
      } else if (header.key === 'potential_loss') {
        const loss = setPotentialLossValue(row)
        return <>{isNaN(loss) ? '-' : loss.toFixed(2)}</>
      } else if (header.key == 'corporateGroupId') {
        const corporateGroup = corporateGroups?.data?.data?.find((item: any) => item.id === value)
        return corporateGroup ? corporateGroup.name : '-'
      } else if (header.key === 'max_weight' || header.key === 'weight') {
        return (
          <>
            {value}
            {dictionary().TON}
          </>
        )
      } else if (header.key === 'is_duplicate_status') {
        return getDuplicateStatus(value)
      } else if (header.key === 'status' || header.key === 'invoice.status') {
        const key = String(value).toLocaleLowerCase()
        if (key == 'confirmed') {
          return <> {dictionary().CONFIRMED}</>
        } else if (key == 'initial_confirmation') {
          return <> {dictionary().INITIAL_CONFIRMATION}</>
        } else if (key == 'initial_rejection') {
          return <> {dictionary().INITIAL_REJECTION}</>
        } else if (key == 'rejected') {
          return <> {dictionary().REJECTED}</>
        } else if (key == 'pending') {
          return <> {dictionary().PENDING}</>
        } else if (key == 'void' || key == 'canceled') {
          return <> {dictionary().CANCELED}</>
        } else if (key == 'unpaid' && row?.invoice?.amount != 0) {
          return <> {dictionary().UNPAID}</>
        } else if (key == 'paid' || row?.invoice?.amount === 0) {
          return <> {dictionary().PAID}</>
        } else if (key == 'patially_paid') {
          return <> {dictionary().PARTIALLY_PAID}</>
        } else if (key == 'external') {
          return <> {dictionary().EXTERNAL}</>
        } else {
          return <>{}</>
        }
      } else if (header.key === 'type') {
        if (value == 'TOLL') {
          return <> {dictionary().TOLL}</>
        } else if (value == 'FUEL') {
          return <> {dictionary().FUEL}</>
        } else if (value == 'STORE') {
          return <>{dictionary().STORE}</>
        } else {
          return <>{value}</>
        }
      } else if (header.id === 'pump_reading_diff') {
        return setPumpReadingValue(row, value)
      } else if (header.id === 'total') {
        return <>{formatAsCurrency(row.amount_due_per_vehicle * row.count)}</>
      } else if (header.key === 'Vehicle_driver_name') {
        return <>{row?.drivers?.length === 1 ? row?.drivers[0]?.name : row?.drivers?.length === 0 ? dictionary().NO_DRIVERS : dictionary().MULTIPLE}</>
      } else if (header.key === 'note') {
        return <>{row?.note?.length > 10 ? row?.note?.substring(0, 10) + '...' : value}</>
      } else if (header.key === 'comment') {
        return <>{row?.comment?.length > 10 ? row?.comment?.substring(0, 10) + '...' : value}</>
      } else if (header.key === 'vehicle_plate_number') {
        return (
          <>{row?.vehicles?.length === 1 ? row?.vehicles[0]?.number_plate : row?.vehicles?.length === 0 ? dictionary().NO_VEHICLES : dictionary().MULTIPLE}</>
        )
      } else if (header.key === 'vehicle_code') {
        return <>{row?.vehicles?.length === 1 ? row?.vehicles[0]?.code : row?.vehicles?.length === 0 ? dictionary().NO_VEHICLES : dictionary().MULTIPLE}</>
      } else if (header.key === 'odometer_reading') {
        return getReadingStatus(value, row.odometer_reading_status)
      } else if (header.key === 'pump_reading') {
        return getReadingStatus(value, row.pump_reading_error_status, 'pump_reading')
      } else if (header.key === 'distance_traveled' || header.key === 'original_distance_traveled') {
        return <>{financialNums(value as any)}</>
      } else if (header.key === 'correction_status') {
        switch (value) {
          case 'NORMAL':
            return <>{dictionary().NORMAL}</>
          case 'VARIANCE':
            return <>{dictionary().VARIANCE}</>
          case 'VARIANCE_REFUND':
            return <>{dictionary().VARIANCE_REFUND}</>
          case 'DUPLICATED':
            return <>{dictionary().DUPLICATED}</>
          case 'DUPLICATE_REFUND':
            return <>{dictionary().DUPLICATED_REFUND}</>
          default:
            return <>-</>
        }
      } else if (header.key === 'tips_amount') {
        return <>{formatDecimal(row?.tips_amount)}</>
      } else if (header.key === 'tips_refund') {
        return <>{formatDecimal(row?.tips_refund)}</>
      } else if (header.key === 'vat') {
        return <>{formatDecimal(row?.vat)}</>
      } else if (header.key === 'vat_tips_refund') {
        return <>{formatDecimal(row?.vat_tips_refund)}</>
      } else if (header.key === 'total_service_fee') {
        return <>{formatDecimal(row?.total_service_fee)}</>
      } else if (header.key === 'corporateStaffID' && !pathname.includes('corporate-groups')) {
        const editPath = `/corporates/staff/${row?.id}`
        if (canEditEntity(role ?? '')) {
          return (
            <>
              <Text
                sx={{ fontWeight: 600, cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => {
                  push(editPath)
                }}
              >
                {row?.id}
              </Text>
            </>
          )
        } else {
          return (
            <>
              <Text sx={{ fontWeight: 600 }}>{row?.id}</Text>
            </>
          )
        }
      } else if (header.key === 'corporateStaffID' && pathname.includes('corporate-groups')) {
        const editPath = `/corporate-groups/staff/${row?.id}`
        if (canEditCGStaff(role ?? '')) {
          return (
            <>
              <Text
                sx={{ fontWeight: 600, cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => {
                  push(editPath)
                }}
              >
                {row?.id}
              </Text>
            </>
          )
        } else {
          return (
            <>
              <Text sx={{ fontWeight: 600 }}>{row?.id}</Text>
            </>
          )
        }
      } else if (header.key === 'station_tips') {
        return <>{formatDecimal(row?.station_tips)}</>
      } else if (header.key === 'station_tips_refund') {
        return <>{formatDecimal(row?.station_tips_refund)}</>
      } else if (header.key === 'station_tips_vat') {
        return <>{formatDecimal(row?.station_tips_vat)}</>
      } else if (header.key === 'station_tips_vat_refund') {
        return <>{formatDecimal(row?.station_tips_vat_refund)}</>
      } else if (header.key === 'station_total_amount') {
        return <>{formatDecimal(row?.station_total_amount)}</>
      } else if (header.key === 'chargingProfileId') {
        return (
          <>
            {row?.chargingProfileId ? (
              <Text
                sx={{ fontWeight: 600, cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => {
                  window.open(`/charging-profiles/${row?.chargingProfileId}/${row?.corporateId}`)
                }}
              >
                {row?.chargingProfileId}
              </Text>
            ) : (
              <Text sx={{ fontWeight: 600, cursor: 'pointer' }}>-</Text>
            )}
          </>
        )
      } else {
        return <>{value || '-'}</>
      }
    case 'cash':
      return <>{formatAsCurrency(value as any)}</>
    case 'financialNumber':
      return <>{financialNums(value as any)}</>
    case 'input':
      if (header.id === 'amount') {
        return (
          <FormControl id={dictionary().CORPORATE} w={'100%'} isInvalid={!!errors[row.id + '']}>
            <Input
              placeholder='0'
              type='number'
              border={'1px'}
              bg='white'
              boxShadow='sm'
              {...register(row.id + '', {
                valueAsNumber: true,
                required: true && dictionary().REQUIRED,
                validate: (value: number) => value !== 0 || dictionary().CANNOT_BE_ZERO,
              })}
            />
            <FormErrorMessage>{errors[row.id + '']?.message}</FormErrorMessage>
          </FormControl>
        )
      } else if (header.id === 'paymentMethod') {
        return (
          <FormControl id={dictionary().PAYMENT_METHOD} w={'100%'} isInvalid={!!errors[row.id + 'method']}>
            <InputGroup>
              <Select
                dir='ltr'
                border={'1px'}
                bg='white'
                boxShadow='sm'
                defaultValue={'CASH'}
                placeholder={dictionary().PAYMENT_METHOD}
                textAlign='right'
                {...register(row.id + 'method', {
                  required: dictionary().REQUIRED,
                })}
              >
                <option key={1} value='CASH'>
                  {dictionary().CASH}
                </option>
                <option key={2} value='TRANSFER'>
                  {dictionary().TRANSFER_METHOD}
                </option>
              </Select>
            </InputGroup>
            <FormErrorMessage>{errors[row.id + 'method']?.message}</FormErrorMessage>
          </FormControl>
        )
      }
    case 'percentage':
      return <>{setCorrectionValue(value, row)}</>
    case 'boolean':
      if (header.key === 'offline_verification') {
        return value ? <> {dictionary().OFFLINE_PIN}</> : <>{dictionary().QR_CODE}</>
      } else if (header.key === 'is_demo') {
        return value ? <> {dictionary().IS_DEMO}</> : <>{dictionary().NOT_DEMO}</>
      } else if (header.key === 'isRefunded') {
        return getIsRefunded(value)
      } else if (header.key === 'is_advanced') {
        return value ? <> {dictionary().ADVANCED_USER}</> : <>{dictionary().NOT_ADVANCED_USER}</>
      } else if (header.key === 'is_invalid') {
        if (['VARIANCE_REFUND', 'DUPLICATE_REFUND'].includes(row?.correction_status)) {
          return <>-</>
        }
        return value ? (
          <> {dictionary().NOTVALID}</>
        ) : row.distance_traveled_errors === 'FIRST_TRANSACTION' ? (
          <>{dictionary().FIRST_TRANSACTION}</>
        ) : (
          <>{dictionary().VALID}</>
        )
      } else {
        return value ? <CheckIcon /> : <CloseIcon />
      }
    case 'link':
      return (
        <Link as={ReactLink} fontSize='.78em' to={((header.payload as Record<string, string>)?.to || '/').toString().replace(':key', value as string)}>
          {(header.payload as Record<string, string>)?.name}
        </Link>
      )
    case 'actions':
      return actionsColumn(header, row)
    case 'modal':
      return modalCases(header, row, value, handleIsDriverRemoved, handleIsBalancesChange)
    case 'details':
      return (
        <TableDetails
          name={(header.payload as Record<string, string>)?.name}
          to={((header.payload as Record<string, string>)?.to || '/')
            ?.toString()
            .replace(':key', value as string)
            .replace('search', stringifiedQuery)}
        />
      )
    case 'custom':
      const Comp = header.payload as React.FC<{ row: unknown; header: TableHeader; value: unknown }>
      return <Comp row={row} header={header} value={value} />
    case 'date':
      if (header?.id === 'Renewal_date') {
        const date = new Date(renewalDate as any)
        date.setFullYear(date.getFullYear() + 1)
        const momentDate = moment(date).tz('Africa/Cairo').format('DD/MM/YYYY')
        return <>{momentDate}</>
        // return <>{formatInTimeZone(date, 'Africa/Cairo', 'DD/MM/YYYY')}</>
        // return <>{format(date, 'DD/MM/YYYY')}</>
      }
      return <>{value ? formateDate(value as any) : '-'}</>
    case 'time':
      const time = moment(new Date(value as any))
        .tz('Africa/Cairo')
        .format('MM/dd/yyyyHH:mm:ss')
      return <>{(time as any)?.substring(10, 19).replace(/T/g, '   ')}</>
    case 'date_UTC':
      const date_UTC = moment.utc(new Date(value as any)).format('DD/MM/YYYY')
      return <>{date_UTC}</>
    case 'time_UTC':
      const time_UTC = moment.utc(value as any).format('hh:mm A')
      return <>{time_UTC}</>
    case 'dateTime':
      const dateTime = moment.utc(value as any).format('hh:mmA - DD/MM/yyyy')
      return <Text minWidth={'140px'}>{dateTime}</Text>
    case 'Cash_balance':
      const cashAcc = (value as any)?.find(({ account_type }: any) => account_type === 'CASH')

      return <>{(cashAcc as any)?.balance === undefined ? formatAsCurrency(0) : formatAsCurrency((cashAcc as any)?.balance)}</>
    case 'Consumed_balance':
      const consumedAcc = (value as any)?.find(({ account_type }: any) => account_type === 'CONSUMPTION')

      return <>{(consumedAcc as any)?.balance === undefined ? formatAsCurrency(0) : formatAsCurrency((consumedAcc as any)?.balance)}</>
    case 'Fuel_Balance':
      const fuelAcc = (value as any)?.find(({ account_type }: any) => account_type === 'FUEL')

      return <>{(fuelAcc as any)?.balance === undefined ? formatAsCurrency(0) : formatAsCurrency((fuelAcc as any)?.balance)}</>
    case 'Store_Balance':
      const storeAcc = (value as any)?.find(({ account_type }: any) => account_type === 'STORE')

      return <>{(storeAcc as any)?.balance === undefined ? formatAsCurrency(0) : formatAsCurrency((storeAcc as any)?.balance)}</>
    case 'Toll_Balance':
      const tollAcc = (value as any)?.find(({ account_type }: any) => account_type === 'TOLL')

      return <>{(tollAcc as any)?.balance === undefined ? formatAsCurrency(0) : formatAsCurrency((tollAcc as any)?.balance)}</>

    case 'stationId':
    case 'driverRedirect':
    case 'vehicleRedirect':
      return <>{value}</>

    case 'invoice_details':
      return (
        <>
          <Button onClick={handle} disabled={row.invoice.amount === 0}>
            {dictionary().VIEW_DETAILS}
          </Button>
          {selected === true ? (
            <>
              <InvoiceDetails id={value} activate={activate} setActivate={setActivate} />
            </>
          ) : (
            <></>
          )}
        </>
      )
    case 'invoice_payment':
      return row.invoice.status == 'PAID' || row.invoice.amount === 0 ? (
        <Button disabled={true}>{dictionary().PAY}</Button>
      ) : (
        <>
          <Button onClick={handle}>{dictionary().PAY}</Button>
          {selected === true ? (
            <>
              <InvoicePayment id={value} activate={activate} setActivate={setActivate} />
            </>
          ) : (
            <></>
          )}
        </>
      )
    case 'calculated_invoice':
      return <>{formatAsCurrency(row?.invoice?.amount - row?.invoice?.starting_balance - row?.paid_amount)}</>
    case 'total_paid_invoice':
      return <>{formatAsCurrency(row?.invoice?.starting_balance + row?.paid_amount)}</>

    default:
      return null
  }
}
