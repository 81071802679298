import { GridItem, Box, Stack, Grid, Text, Flex, Container, Hide, Tr, Th, Tfoot, Tag, HStack } from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { reverse } from 'ramda'
import { useState, useEffect, useCallback } from 'react'
import Table from '../../core/Table'
import Card from '../../core/Card'
import { TableHeader } from '../../../interfaces/tableProps'
import fetch from '../../../helpers/fetch'
import Header from '../Header'
import formatAsCurrency from '../../../helpers/formatAsCurrency'
import PaymentModel from '../PaymentModel'
import dictionary from '../../../dictionary'
import CsvDownloader from '../Download'

interface SubscriptionTableProps {
  headers: TableHeader[]
  restRoute: any
  title: string
}

const AdminSubscriptionTable: React.FC<SubscriptionTableProps> = ({ headers, restRoute, title }) => {
  const { data, isFetching, refetch } = useQuery(['AdminSubscriptionVehicles'], () => fetch('GET', restRoute))
  const options: any = { year: 'numeric', month: 'long' }
  const [isPaid, setIsPaid] = useState(false)
  const [selectedKey, setSelectedKey] = useState('')
  const [selectedDates, setSelectedDates] = useState<string[]>([])
  let keys = data ? Object.keys(data) : []
  let dates: string[] = []
  useEffect(() => {
    refetch()
  }, [isPaid])

  useEffect(() => {
    keys = data ? Object.keys(data) : []
    const SPId = reverse(keys)[0]
    dates = SPId ? Object.keys(data[SPId]?.data?.data) : []
    setSelectedDates(dates)
    setSelectedKey(SPId)
    handleSelectedTag()
  }, [data])

  const handleIsActive = (e: any, SPId: any) => {
    dates = Object.keys(data[SPId]?.data?.data)
    setSelectedDates(dates)
    setSelectedKey(SPId)
    handleSelectedTag(e)
  }

  // Handle Selected Tags
  const handleSelectedTag = (selectedTag?: any) => {
    const tagsElement = document.querySelectorAll(`.tags`)
    tagsElement.forEach((tag) => {
      tag.removeAttribute('style')
    })
    selectedTag?.target.setAttribute('style', 'color:white; background-color:#7165e3;')
    selectedTag?.target.setAttribute('test', 'test')
    !!!selectedTag && tagsElement[0]?.setAttribute('style', 'color:white; background-color:#7165e3;')
  }

  const handleIsSubscriptionPaid = useCallback(() => {
    setIsPaid(!isPaid)
  }, [isPaid])

  return (
    <Container maxW='100%' width='100%' h='100vh' padding={8} marginBottom={30}>
      <Hide below='769px'>
        <Header title='' />
      </Hide>
      {keys.length > 0 ? (
        <Grid gap={8} p={10} w='100%' h='100%'>
          <HStack>
            {reverse(keys)?.map((id, index) => {
              return (
                <Tag className='tags ' key={index} cursor='pointer' onClick={(e) => handleIsActive(e, id)} bg='gray.300' borderRadius='full'>
                  {data[id].name}
                </Tag>
              )
            })}
          </HStack>
          {selectedKey &&
            (selectedDates.length > 0 ? (
              <Grid gap={8} w='100%' h='100%'>
                {reverse(selectedDates)?.map((date, index) => {
                  return (
                    <GridItem w='100%' key={index}>
                      <Text m={3} fontSize='20px'>
                        {new Date(date).toLocaleDateString('ar-EG', options)}
                      </Text>
                      <Box w='100%'>
                        <Stack>
                          <GridItem>
                            <CsvDownloader
                              headers={headers}
                              query=''
                              PAGE_SIZE={''}
                              setPAGE_SIZE={''}
                              vehicleSubscriptionData={[...data[selectedKey]?.data?.data[date].data]}
                              renewalDate={date}
                              isUpload={false}
                            />
                            <Card h='100%' marginBottom={30}>
                              <Table
                                enableIdRedirect={false}
                                height={350}
                                headers={headers}
                                data={[...data[selectedKey]?.data?.data[date].data]}
                                renewalDate={date}
                                footer={
                                  <Tfoot style={{ borderTop: '4px double #a1a1a1' }}>
                                    <Tr>
                                      <Th>
                                        <div style={{ color: '#fff' }}>50</div>
                                      </Th>
                                      <Th>{dictionary().MONTH_TOTAL}</Th>
                                      <Th>
                                        <div style={{ color: '#fff' }}>50</div>
                                      </Th>
                                      <Th isNumeric={false}>{data[selectedKey]?.data?.data[date].vehicle_count}</Th>
                                      <Th isNumeric={false}>{formatAsCurrency(data[selectedKey]?.data?.data[date].claim_amount)}</Th>
                                      <Th>
                                        <PaymentModel
                                          data={data[selectedKey]?.data?.data[date]}
                                          date={date}
                                          paid={data[selectedKey]?.data?.data[date].paid}
                                          handleIsSubscriptionPaid={handleIsSubscriptionPaid}
                                          SPId={data[selectedKey]?.station_provider}
                                        />
                                      </Th>
                                    </Tr>
                                  </Tfoot>
                                }
                              />
                            </Card>
                          </GridItem>
                        </Stack>
                      </Box>
                    </GridItem>
                  )
                })}
              </Grid>
            ) : (
              <Flex w='100%' h='100%' align='center' justify='center'>
                <Text fontSize='2xl'>
                  {dictionary().NO_RESULTS_FOUND} {dictionary().IN} {title}
                </Text>
              </Flex>
            ))}
        </Grid>
      ) : !isFetching ? (
        <Flex w='100%' h='100%' align='center' justify='center'>
          <Text fontSize='2xl'>
            {dictionary().NO_RESULTS_FOUND} {dictionary().IN} {title}
          </Text>
        </Flex>
      ) : (
        <Flex w='100%' h='100%' align='center' justify='center'>
          <Text fontSize='2xl'>
            {dictionary().SEARCH_LOADING} {dictionary().IN} {title}
          </Text>
        </Flex>
      )}
    </Container>
  )
}

export default AdminSubscriptionTable
