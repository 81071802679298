import { HamburgerIcon } from '@chakra-ui/icons'
import { Box, Drawer, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex, Hide, Image, Show, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import LogoSrc from '../../assets/logo.svg'
import { ROLES } from '../../constants/ROLES'
import Logo from '../core/Logo'
import UserMenu from '../core/UserMenu'
import AccountManagerSideBar from '../sidebars/AccountManagerSideBar'
import AdminSideBar from '../sidebars/AdminSideBar'
import { useStore } from '../../store'

const Sidebar: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { role } = useStore()

  const getSideBar = (type: string) => {
    switch (role) {
      case ROLES.ACCOUNT_MANAGER:
        return <AccountManagerSideBar type={type} />
      default:
        return <AdminSideBar type={type} />
    }
  }

  return (
    <div>
      <Show below='768px'>
        <Flex
          position={'fixed'}
          zIndex={8}
          top={'45px'}
          width={'90%'}
          background={'#fff'}
          borderRadius={20}
          p={3}
          boxShadow={'rgb(112 144 176 / 18%) 14px 17px 40px 4px'}
          transform='translate(-50%,-50%);'
          height={'60px'}
          display={'flex'}
          left={'50%'}
        >
          <Box position={'absolute'} left={5} top={4}>
            <HamburgerIcon onClick={onOpen} fontSize={24} />
          </Box>
          <a href='/'>
            <Image src={LogoSrc} mr={2} />
          </a>
        </Flex>
        <Drawer
          id='rtl'
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          // finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <div>
              <a href='/'>
                <Logo boxSize='60%' />
              </a>
              <DrawerCloseButton onClick={onClose} />

              <Flex overflowY='scroll'>
                <Flex flexDir='column' mr={3} width={'100%'} maxH={300}>
                  {getSideBar('mobile')}
                </Flex>
                <UserMenu />
              </Flex>
            </div>
          </DrawerContent>
        </Drawer>
      </Show>
      <Hide below='769px'>
        <div>
          <Flex dir='rtl' id='blocSidebar' pos='sticky' bg='#fff' display={'none'} h='100vh' overflowY='scroll' flexDir='column' justifyContent='flex-start'>
            <Box mb={4} borderBottom={'1px solid #dbddea'} mt={'25px'}>
              <Logo boxSize='65%' />
            </Box>

            <Flex overflowY='scroll' maxH={400} flexDir='column' justifyContent='flex-start' mr={5} align={'start'}>
              {getSideBar('web')}
            </Flex>
            <UserMenu />
          </Flex>
        </div>
      </Hide>
    </div>
  )
}

export default Sidebar
