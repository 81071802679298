// import { parse } from 'query-string'
import { pathOr } from 'ramda'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Flex, GridItem, Spinner, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import Card from '../../components/core/Card'
import Table from '../../components/core/Table'

import formatAsCurrency from '../../helpers/formatAsCurrency'

const corporateDriversHeader = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'driverRedirect',
  },

  {
    id: 'name',
    key: 'name',
    name: dictionary().DRIVER_NAME,
    type: 'text',
  },
  {
    id: 'cash',
    key: 'ledger_accounts',
    name: dictionary().BALANCE,
    type: 'Fuel_Balance',
  },
]
const corporateVehiclesHeader = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'vehicleRedirect',
  },

  {
    id: 'numberPlate',
    key: 'number_plate',
    name: dictionary().NUMBER_PLATE,
    type: 'text',
  },
  {
    id: 'cash',
    key: 'ledger_accounts',
    name: dictionary().BALANCE,
    type: 'Fuel_Balance',
  },
]
interface BalanceProps {
  isBalancesChange: boolean
}

const CorporateStaff: React.FC<BalanceProps> = ({ isBalancesChange }) => {
  const { id } = useParams<{ id: string }>()
  const totalFuelBalance = useQuery(['rfr'], () => fetch('GET', `/corporates/${id}/total-fuel-balance`), {})
  // const corporateDrivers = useQuery(['rfsr'], () => fetch('GET', `/drivers?corporateId=${id}`))
  // const corporateVehicles = useQuery(['rfqr'], () => fetch('GET', `/vehicles?corporateId=${id}`))
  const netCorporateBalance = useQuery(['cashingKey', id], () => fetch('GET', `/corporates/${id}`), {})

  useEffect(() => {
    // corporateDrivers.refetch()
    // corporateVehicles.refetch()
    totalFuelBalance.refetch()
  }, [isBalancesChange])

  return (
    <>
      <GridItem colSpan={[12, 4, 4, 4]} w='100%'>
        <Card p={4}>
          <h2 style={{ display: 'block', textAlign: 'center', fontWeight: 'bold' }}>
            {' '}
            {netCorporateBalance?.isFetching ? (
              <Spinner />
            ) : (
              <>
                {dictionary().DRIVERS} ({formatAsCurrency(totalFuelBalance?.data?.driverBalance?.sum)})
              </>
            )}
          </h2>
          {/* {corporateDrivers.isLoading ? (
            <Flex w='100%' h='100%' align='center' justify='center'>
              <Spinner />
            </Flex>
          ) : corporateDrivers?.data?.data?.length > 0 ? (
            <Table raised enableIdRedirect={true} height={350} headers={corporateDriversHeader} data={pathOr([], ['data'], corporateDrivers?.data)} />
          ) : (
            <Flex w='100%' h='100%' align='center' justify='center'>
              <Text fontSize='20px'>{dictionary().NO_DRIVERS_FOR_THIS_CORPORATE}</Text>
            </Flex>
          )} */}
        </Card>
      </GridItem>
      <GridItem colSpan={[12, 4, 4, 4]} w='100%'>
        <Card p={4}>
          <h2 style={{ display: 'block', textAlign: 'center', fontWeight: 'bold' }}>
            {' '}
            {netCorporateBalance?.isFetching ? (
              <Spinner />
            ) : (
              <>
                {dictionary().VEHICLES} ({formatAsCurrency(totalFuelBalance?.data?.vehicleBalance?.sum)})
              </>
            )}
          </h2>
          {/* {corporateVehicles.isLoading ? (
            <Flex w='100%' h='100%' align='center' justify='center'>
              <Spinner />
            </Flex>
          ) : corporateVehicles?.data?.data?.length > 0 ? (
            <Table raised enableIdRedirect={true} height={350} headers={corporateVehiclesHeader} data={pathOr([], ['data'], corporateVehicles?.data)} />
          ) : (
            <Flex w='100%' h='100%' align='center' justify='center'>
              <Text fontSize='20px'>{dictionary().NO_VEHICLES_FOR_THIS_CORPORATE}</Text>
            </Flex>
          )} */}
        </Card>
      </GridItem>
    </>
  )
}

export default CorporateStaff
