import { useRef } from 'react'

import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from '@chakra-ui/react'

import dictionary from '../../dictionary'

interface Props {
  onCancel: () => void
  onClose: () => void
  isOpen: boolean
  message: string
}

const DepositConfigurationModel: React.FC<Props> = ({ onCancel, onClose, isOpen, message }) => {
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  return (
    <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onCancel} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader style={{ direction: 'rtl', display: 'flex', alignItems: 'center', gap: '10px' }}>
          <img src='/assets/alert.svg' style={{ marginTop: '10px' }} />
          <span>{dictionary().ALERT} </span>
        </ModalHeader>
        <ModalBody pb={6}>
          <Stack direction='column'>
            <Box dir='rtl'>
              <b>{message}</b>
            </Box>
          </Stack>
        </ModalBody>
        <ModalFooter dir='rtl'>
          <Button data-test={dictionary().CANCEL} onClick={onCancel}>
            {dictionary().CANCEL}
          </Button>
          <Button data-test={dictionary().CONFIRM_BUTTON} colorScheme='blue' mr={3} onClick={onClose}>
            {dictionary().CONFIRM_BUTTON}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DepositConfigurationModel
