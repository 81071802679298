import React from 'react'
import { parse } from 'query-string'
import { pathOr } from 'ramda'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { GridItem, Text, Box, Stack } from '@chakra-ui/react'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import Card from '../../components/core/Card'
import Table from '../core/Table'
import { getCorporateStaffHeaders } from '../../constants/headers/corporateStaffHeaders'
import { useStore } from '../../store'

interface Props {
  restRoute: string
}

const CorporateStaff: React.FC<Props> = ({ restRoute }) => {
  const { role } = useStore()
  const corporateStaffHeaders = getCorporateStaffHeaders(role)

  const query = parse(location.search, { parseBooleans: true })
  const { id } = useParams<{ id: string }>()
  const corporate_staff = useQuery([query], () => fetch('GET', restRoute + `${id}`), {
    keepPreviousData: false,
  })
  return (
    <>
      <Card h='100%' p={4}>
        <Text m={3} fontSize='20px'>
          {dictionary().STAFF}
        </Text>
        <GridItem colSpan={6}>
          <Box w='100%'>
            <Stack>
              <GridItem>
                <Card h='100%' data-test='staff-table'>
                  <Table raised enableIdRedirect={false} height={350} headers={corporateStaffHeaders} data={pathOr([], ['data'], corporate_staff?.data)} />
                </Card>
              </GridItem>
            </Stack>
          </Box>
        </GridItem>
      </Card>
    </>
  )
}

export default CorporateStaff
