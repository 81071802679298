import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  Select,
  Text,
} from '@chakra-ui/react'
import { endOfDay, startOfDay } from 'date-fns'
import moment from 'moment-timezone'
import { FC, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import filterIconSrc from '../../../assets/filter.svg'
import dictionary from '../../../dictionary'
import fetch from '../../../helpers/fetch'
import useQueryParams from '../../../hooks/useQueryParams '
import DateRange from '../../../pages/stats/DateRange'
import AutoCompleteInput from '../AutoCompleteInput'
import { CustomSelect } from '../CustomSelectInput'

type PaymentsFilterModelProps = any

const formatDate = (value: any) => {
  const momentDate = moment(new Date(value as any))
    .tz('Africa/Cairo')
    .format('YYYY-MM-DD')
  return momentDate
}

const PaymentsFilterModel: FC<PaymentsFilterModelProps> = ({ isOpen, onOpen, onClose }: any) => {
  const { add, remove } = useQueryParams()
  const statusType = [
    { label: dictionary().PENDING, value: 'PENDING' },
    { label: dictionary().INITIAL_CONFIRMATION, value: 'INITIAL_CONFIRMATION' },
    { label: dictionary().CONFIRMED, value: 'CONFIRMED' },
    // { label: dictionary().INITIAL_REJECTION, value: 'INITIAL_REJECTION' },
    { label: dictionary().REJECTED, value: 'REJECTED' },
  ]
  const [fromCreationDate, setFromCreationDate] = useState('')
  const [toCreationDate, setToCreationDate] = useState('')
  const [fromDepositDate, setFromDepositDate] = useState('')
  const [toDepositDate, setToDepositDate] = useState('')
  const [banks, setBanks] = useState([])
  const searchRef = useRef<HTMLButtonElement>(null)
  const {
    formState: { errors },
    control,
    register,
    watch,
    setValue,
    getValues,
  } = useForm({ mode: 'onChange' })

  const fieldValues = {
    corporate: watch('corporateId', null),
    status: watch('status', []),
    amount: watch('amount', ''),
    bank_Name: watch('bank_Name', ''),
  }

  const { mutate: getBanks } = useMutation(() => fetch('GET', `/banks`), {
    onSuccess: (data): void => {
      setBanks(data)
    },
  })

  useEffect(() => {
    isOpen && banks.length === 0 && getBanks()
  }, [isOpen])

  useEffect(() => {
    setValue('corporateId', null)
    setValue('status', [])
    setValue('from', '')
    setValue('to', '')
    setValue('fromDate', '')
    setValue('toDate', '')
    setValue('amount', '')
    setValue('bank_Name', '')

    setFromCreationDate('')
    setToCreationDate('')
    setFromDepositDate('')
    setToDepositDate('')
  }, [])

  const clearAll = () => {
    remove('corporateId')
    remove('amount')
    remove('status')
    remove('from')
    remove('fromDate')
    remove('to')
    remove('toDate')
    remove('bank_Name')

    setValue('corporateId', null)
    setFromCreationDate('')
    setToCreationDate('')
    setFromDepositDate('')
    setToDepositDate('')
    Object.keys(fieldValues).forEach((field) => setValue(field, []))

    onClose()
  }

  const submit = async () => {
    remove('corporateId')
    remove('amount')
    remove('status')
    remove('from')
    remove('fromDate')
    remove('to')
    remove('toDate')
    remove('bank_Name')

    getValues('corporateId')?.length > 0 && add('corporateId', getValues('corporateId')[0])
    getValues('status')?.length > 0 && add('status', getValues('status'))
    getValues('amount')?.length > 0 && add('amount', getValues('amount'))
    getValues('bank_Name')?.length > 0 && add('bank_Name', getValues('bank_Name'))
    fromCreationDate && add('from', startOfDay(new Date(fromCreationDate)).toISOString())
    toCreationDate && add('to', endOfDay(new Date(toCreationDate)).toISOString())
    fromDepositDate && add('fromDate', startOfDay(new Date(fromDepositDate)).toISOString())
    toDepositDate && add('toDate', endOfDay(new Date(toDepositDate)).toISOString())
    onClose()
  }

  const onChangeCreatedDate = (value: any) => {
    setFromCreationDate(value[0])
    setToCreationDate(value[1])
  }
  const onChangeDepositDate = (value: any) => {
    setFromDepositDate(value[0])
    setToDepositDate(value[1])
  }
  return (
    <>
      <Box position='relative' display='inline-block' sx={{ mr: '10px' }}>
        <Button onClick={onOpen} variant='outline' style={{ backgroundColor: '#7165E3' }} _focus={{ boxShadow: 'none' }}>
          <Image src={filterIconSrc} width={25} height={30} />
        </Button>
        {/* Red dot indicator */}
        {(Object.values(fieldValues).some((value) => value?.length > 0) || toDepositDate || toCreationDate || fromDepositDate || fromCreationDate) && (
          <Box position='absolute' top='-1' right='-1' width='15px' height='15px' borderRadius='50%' backgroundColor='red'></Box>
        )}
      </Box>
      <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={searchRef} onClose={submit} isOpen={isOpen} isCentered autoFocus colorScheme={'purple'}>
        <AlertDialogOverlay />
        <AlertDialogContent dir='rtl' maxW='900px'>
          <AlertDialogHeader>{dictionary().ADVANCED_FILTERATION}</AlertDialogHeader>
          <AlertDialogBody>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
              {' '}
              <FormControl id={dictionary().CREATED_AT} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>{dictionary().CREATED_AT} </FormLabel>
                <DateRange from={fromCreationDate} to={toCreationDate} onChange={onChangeCreatedDate} displayLabel={false} />
              </FormControl>
              <FormControl id={dictionary().DEPOSIT_DATE} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>{dictionary().DEPOSIT_DATE} </FormLabel>
                <DateRange from={fromDepositDate} to={toDepositDate} onChange={onChangeDepositDate} displayLabel={false} />
              </FormControl>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
              <FormControl id={dictionary().CHOOSE_CORPORATE} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().CHOOSE_CORPORATE}{' '}
                  {/* <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.corporate?.length || 0}
                  </Text> */}
                </FormLabel>
                <Controller
                  name={`corporateId`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      isInvalid={!!errors?.second_ref_id}
                      onChange={onChange}
                      value={value}
                      searchRoute='/corporates'
                      singleRoute='/corporates'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name}
                      boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                    />
                  )}
                />
              </FormControl>
              <FormControl id={dictionary().STATUS} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                  {dictionary().STATUS}{' '}
                  <Text
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '',
                      borderRadius: '50%',
                      backgroundColor: '#EDF2F7',
                      textAlign: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fieldValues.status?.length || 0}
                  </Text>
                </FormLabel>
                <Controller
                  name={`status`}
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect placeholder={'اختر الحالة'} options={statusType} value={value} onChange={onChange} />
                  )}
                />
              </FormControl>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
              {' '}
              <FormControl id={dictionary().AMOUNT} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>{dictionary().AMOUNT} </FormLabel>
                <InputGroup>
                  <Input
                    type='number'
                    {...register('amount', {
                      required: false,
                    })}
                  />
                </InputGroup>
              </FormControl>
              <FormControl id={dictionary().AMOUNT} w='100%' dir='rtl' marginTop={5}>
                <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>{dictionary().BANK_NAME} </FormLabel>
                <InputGroup>
                  <Select dir='ltr' textAlign='right' {...register('bank_Name', { required: false })} placeholder={dictionary().BANK_NAME}>
                    {banks.map((bank: any) => (
                      <option key={bank} value={bank}>
                        {bank}
                      </option>
                    ))}
                  </Select>
                </InputGroup>
              </FormControl>
            </Box>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant={'outline'} onClick={onClose}>
              {dictionary().CANCEL}
            </Button>
            <Button mr='3' style={{ backgroundColor: '#E0E0E0' }} variant={'solid'} onClick={() => clearAll()}>
              {dictionary().CLEAR_ALL}
            </Button>
            <Button ref={searchRef} mr='3' colorScheme='blue' variant={'solid'} onClick={() => submit()}>
              {dictionary().SEARCH}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default PaymentsFilterModel
