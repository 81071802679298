import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import Select from 'react-select'
import Required from '../../../components/core/reqiured'
import dictionary from '../../../dictionary'

type DropDownSelectControlProps = {
  errors: any
  register: any
  identifier: string
  label: string
  data: any
  first_key: string
  second_key?: string
  setValue: any
}

const DropDownSelectControl = ({ errors, register, identifier, setValue, label, data, first_key, second_key }: DropDownSelectControlProps) => {
  return (
    <FormControl id={dictionary().ROLE} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors[identifier]}>
      <FormLabel>
        {label} <Required />
      </FormLabel>
      <Select
        defaultValue={null}
        onChange={(value: any) => {
          setValue(identifier, value?.id)
        }}
        isSearchable
        options={data}
        placeholder={label}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#7165e3' : '#E2E8F0',
            height: '42px',
            borderRadius: '0.375rem',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#B8B2F1',
            primary: '#7165e3',
          },
        })}
      />
      <FormErrorMessage>{errors?.[identifier]?.message}</FormErrorMessage>
    </FormControl>
  )
}

export default DropDownSelectControl
