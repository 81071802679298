import axios from 'axios'

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const upload = async (ENDPOINT: any, files?: File[] | null, data?: any): Promise<string[]> => {
  const form = new FormData()
  if (!!files) {
    for (const file of files) {
      form.append('file_asset', file)
    }
  }
  try {
    const response = await axios.post(ENDPOINT, data ? data : form, { headers: { 'Content-Type': 'multipart/form-data' } })

    return response?.data
  } catch (error: any) {
    return error?.response
  } finally {
    ;(document?.getElementById('uploadform') as HTMLFormElement | undefined)?.reset()
  }
}

export default upload
