import { arEG } from 'date-fns/locale'

const Calendar = {
  sunday: 'الأحد',
  monday: 'الاثنين',
  tuesday: 'الثلاثاء',
  wednesday: 'الأربعاء',
  thursday: 'الخميس',
  friday: 'الجمعة',
  saturday: 'السبت',
  ok: 'تأكيد',
  today: 'اليوم',
  yesterday: 'أمس',
  hours: 'ساعات',
  minutes: 'دقائق',
  seconds: 'ثواني',
  /**
   * Format of the string is based on Unicode Technical Standard #35:
   * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
   **/
  formattedMonthPattern: 'MMMM yyyy',
  formattedDayPattern: 'dd MMMM yyyy',
  dateLocale: arEG,
}

export const locale = {
  Calendar,
  DateRangePicker: {
    ...Calendar,
  },
}
