import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'corporateName',
    key: 'corporate.name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'plate_number',
    key: 'vehicle.number_plate',
    name: dictionary().NUMBER_PLATE,
    type: 'text',
  },
  {
    id: 'product_type',
    key: 'product_type.name.ar',
    name: dictionary().PRODUCT_TYPE,
    type: 'text',
  },
  {
    id: 'trans_vehicle_type',
    key: 'vehicle.vehicle_type.name.ar',
    name: dictionary().VEHICLE_TYPE,
    type: 'text',
  },
  {
    id: 'station',
    key: 'station.name.ar',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
  {
    id: 'weight_fees',
    key: 'weight_fees',
    name: dictionary().WEIGHT_FEES,
    type: 'cash',
  },
  {
    id: 'toll_fees',
    key: 'toll_fees',
    name: dictionary().TOLL_AMOUNT,
    type: 'cash',
  },
  {
    id: 'fine_fees',
    key: 'fine_fees',
    name: dictionary().FINE_FEES,
    type: 'cash',
  },
  {
    id: 'trans_status',
    key: 'status',
    name: dictionary().STATUS,
    type: 'text',
  },
  {
    id: 'trans_date_created',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'trans_time_created',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
]

const Transactions: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().TOLL_TRANSACTIONS}
      to='/toll-transactions/new'
      tags={[
        { name: dictionary().ALL, filter: [{ name: '', value: '' }] },
        { name: dictionary().CONFIRMED, filter: [{ name: 'status', value: 'CONFIRMED' }] },
      ]}
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['toll-transaction']}
      countArrayPath={['count']}
      restRoute={'/toll-transactions'}
      cashingKey={CASHING_KEY.GET_TOLL_TRANSACTIONS}
      enableIdRedirect={false}
    />
  )
}

export default Transactions
