import OctaneHeader from '../components/core/OctaneTable/Header'
import { flatObject } from '../helpers/flatObject'
import { IFilterStore } from '../stores/filterStore'
import RowId from '../components/FuelTransactionTable/RowId'

const styles: { [key: string]: React.CSSProperties } = {
  id: {
    width: '50px',
    backgroundColor: '#ddd',
  },
  liters: {},
  odometer_reading: {},
  correction_status: {},
  fuel_type_name: {},
  station_name: {},
  station_staff_name: {},
  station_provider_name: {},
  corporate_name: {},
  vehicle_number_plate: {},
  vehicle_code: {},
  vehicle_type_name: {},
  driver_name: {},
  correction: {},
  indicator_message: {},
  is_refunded_transaction: {},
  hide_indicator_message: {},
  potential_loss: {},
  amount: {},
  formatted_amount: {},
  pump_reading_value: {},
  pump_reading: {},
  correction_value: {},
  status: {},
  status_value: {},
  is_invalid_reason: {},
  distance_traveled_errors_reason: {},
  comment: {},
  correction_status_reason: {},
  correction_reference_id: {},
  tips_amount: {},
  tips_refund: {},
  vat: {},
  vat_tips_refund: {},
  formatted_created_date: {},
  formatted_created_time: {},
  pump_image: {},
  odometer_image: {},
  station_tips: {},
  station_tips_refund: {},
  station_tips_vat: {},
  station_tips_vat_refund: {},
  station_total_amount: {},
}

const fuelTransactionHeaders: { [key: string]: React.ReactNode } = {
  id: <RowId />,
  driver_name: <OctaneHeader value='إسم السائق' style={styles.driver_name} />,
  corporate_name: <OctaneHeader value='اسم الشركة' style={styles.corporate_name} />,
  station_name: <OctaneHeader value='اسم المحطة' style={styles.station_name} />,
  vehicle_number_plate: <OctaneHeader value='رقم اللوحة' style={styles.vehicle_number_plate} />,
  vehicle_code: <OctaneHeader value='كود المركبة' style={styles.vehicle_code} />,
  vehicle_type_name: <OctaneHeader value='نوع المركبة' style={styles.vehicle_type_name} />,
  fuel_type_name: <OctaneHeader value='نوع الوقود' style={styles.fuel_type_name} />,
  liters: <OctaneHeader value='سعة المعاملة باللترات' style={styles.liters} />,
  odometer_reading: <OctaneHeader value='قراءة العداد (KM)' style={styles.odometer_reading} />,
  formatted_original_distance_traveled: <OctaneHeader value='المسافة الفعلية (كم)' style={styles.odometer_image} />,
  formatted_distance_traveled: <OctaneHeader value='المسافة المقطوعة المصححة (كم)' style={styles.odometer_image} />,
  original_fuel_consumption: <OctaneHeader value='استهلاك الوقود الفعلي (لتر/100كم)' style={styles.odometer_image} />,
  formatted_station_provider_fee: <OctaneHeader value='رسوم شبكات المحطات' style={styles.odometer_image} />,
  formatted_fuel_consumption: <OctaneHeader value='استهلاك الوقود المصحح (لتر/100كم)' style={styles.odometer_image} />,
  fuel_consumption_benchmark: <OctaneHeader value='معيار الاستهلاك' style={styles.odometer_image} />,
  potential_loss: <OctaneHeader value='خسارة محتملة' style={styles.potential_loss} />,
  amount: <OctaneHeader value='المبلغ' style={styles.amount} />,
  pump_reading_value: <OctaneHeader value='قراءة المضخة' style={styles.pump_reading_value} />,
  is_fraud: <OctaneHeader value='إحتيال' style={styles.is_fraud} />,
  fraud_reason: <OctaneHeader value='نوع الأحتيال' style={styles.is_fraud} />,
  correction_value: <OctaneHeader value='قيمة الزيادة' style={styles.correction_value} />,
  status_value: <OctaneHeader value='الحالة' style={styles.status_value} />,
  validity_status: <OctaneHeader value='الصلاحية' style={styles.odometer_image} />,
  distance_traveled_errors_reason: <OctaneHeader value='السبب' style={styles.distance_traveled_errors_reason} />,
  comment: <OctaneHeader value='التعليق' style={styles.comment} />,
  correction_status_reason: <OctaneHeader value='حالة الإختلاف' style={styles.correction_status_reason} />,
  correction_reference_id: <OctaneHeader value='معاملة الإرداد' style={styles.correction_reference_id} />,
  tips_amount: <OctaneHeader value='قيمة الإكرامية' style={styles.tips_amount} />,
  tips_refund: <OctaneHeader value='الإكرامية المستردة' style={styles.tips_refund} />,
  vat: <OctaneHeader value='قيمة الضريبة' style={styles.vat} />,
  vat_tips_refund: <OctaneHeader value='الضريبة المستردة' style={styles.vat_tips_refund} />,
  fraud_detection_fees: <OctaneHeader value='تكلفة كشف الإحتيال' style={styles.vat_tips_refund} />,
  fraud_detection_fees_vat: <OctaneHeader value='ضريبة تكلفة كشف الإحتيال' style={styles.vat_tips_refund} />,
  total_service_fee: <OctaneHeader value='مجموع تكلفة الخدمة' style={styles.odometer_image} />,
  station_tips: <OctaneHeader value='إكرامية المحطة' style={styles.station_tips} />,
  station_tips_refund: <OctaneHeader value='الإكرامية المستردة للمحطة' style={styles.station_tips_refund} />,
  station_tips_vat: <OctaneHeader value='ضريبة إكرامية المحطة' style={styles.station_tips_vat} />,
  station_tips_vat_refund: <OctaneHeader value='الضريبة المستردة لإكرامية للمحطة' style={styles.station_tips_vat_refund} />,
  station_total_amount: <OctaneHeader value='مجموع تكلفة الخدمة للمحطة' style={styles.station_total_amount} />,
  formatted_created_date: <OctaneHeader value='التاريخ' style={styles.formatted_created_date} />,
  formatted_created_time: <OctaneHeader value='الوقت' style={styles.formatted_created_time} />,
  // correction_status: <OctaneHeader value='حالة التصحيح' style={styles.correction_status} />,
  // station_staff_name: <OctaneHeader value='اسم الموظف' style={styles.station_staff_name} />,
  // station_provider_name: <OctaneHeader value='اسم شبكة المحطات' style={styles.station_provider_name} />,
  // correction: <OctaneHeader value='التصحيح' style={styles.correction} />,
  // indicator_message: <OctaneHeader value='indicator_message' style={styles.indicator_message} />,
  // is_refunded_transaction: <OctaneHeader value='حالة تعويض' style={styles.is_refunded_transaction} />,
  // hide_indicator_message: <OctaneHeader value='اخفاء' style={styles.hide_indicator_message} />,
  // formatted_amount: <OctaneHeader value='asas' style={styles.formatted_amount} />,
  // pump_reading: <OctaneHeader value='قراءة المضخة' style={styles.pump_reading} />,
  // status: <OctaneHeader value='الحالة' style={styles.status} />,
  // is_invalid_reason: <OctaneHeader value='سبب غير صحيح' style={styles.is_invalid_reason} />,

  pump_image: <OctaneHeader value='صورة مضخة البنزين' style={styles.pump_image} />,
  odometer_image: <OctaneHeader value='صورة العداد' style={styles.odometer_image} />,
  updateOdometerReadingModal: <OctaneHeader value='تعديل قراءة العداد (KM)' style={styles.odometer_image} />,
  // ConfirmTransactionModel: <OctaneHeader value='الإجراءات' style={styles.odometer_image} />,
}

export const getFuelTransactionHeaders = (filterStore: IFilterStore): { headersList: any; headers: any } => {
  if (filterStore.status === null) {
    fuelTransactionHeaders['ConfirmTransactionModel'] = <OctaneHeader value='الإجراءات' style={styles.odometer_image} />
  } else {
    delete fuelTransactionHeaders['ConfirmTransactionModel']
  }
  return { headersList: flatObject(fuelTransactionHeaders, 'keys'), headers: flatObject(fuelTransactionHeaders, 'values') }
}
