// import React from 'react'

// const CorporatesForGorporateGroup = () => {
//   return (
//     <div>CorporatesForGorporateGroup</div>
//   )
// }

// export default CorporatesForGorporateGroup
import React from 'react'
import { parse } from 'query-string'
import { pathOr } from 'ramda'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { GridItem, Text, Box, Stack } from '@chakra-ui/react'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import Card from '../../components/core/Card'
import Table from '../core/Table'

interface Props {
  restRoute: string
}

const corporateHeaders = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'name',
    key: 'name',
    name: dictionary().NAME,
    type: 'text',
  },
]

const CorporatesForGorporateGroup: React.FC<Props> = ({ restRoute }) => {
  const query = parse(location.search, { parseBooleans: true })
  const { id } = useParams<{ id: string }>()
  const { data } = useQuery([query], () => fetch('GET', restRoute + `/${id}`), {})
  return (
    <>
      {data?.corporates?.length > 0 && (
        <Card h='100%' p={4}>
          <Text m={3} fontSize='20px'>
            {dictionary().CORPORATES}
          </Text>
          <GridItem colSpan={6}>
            <Box w='100%'>
              <Stack>
                <GridItem>
                  <Card h='100%'>
                    <Table raised enableIdRedirect={false} height={350} headers={corporateHeaders} data={data?.corporates} />
                  </Card>
                </GridItem>
              </Stack>
            </Box>
          </GridItem>
        </Card>
      )}
    </>
  )
}

export default CorporatesForGorporateGroup
