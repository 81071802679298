import { WarningIcon } from '@chakra-ui/icons'
import { Flex, Tooltip, Text } from '@chakra-ui/react'
import { FuelTransaction } from '../types/FuelTransaction'
import dictionary from './../dictionary'
import formatAsCurrency from './formatAsCurrency'
import financialNums from './financialNums'

const getColor = (correction: number | null, row: FuelTransaction) => {
  let color = ''
  if (row?.correction_reference_id) {
    return ''
  }
  if ((correction === null || correction === undefined) && row?.status_value !== 'Confirmed') {
    return 'red.500'
  }
  Math.abs(+row?.correction_value) > 1 ? (color = 'red.500') : (color = '')
  return color
}

export const getPumpReadingValue = (row: FuelTransaction, value: any) => {
  let indicatorColor = ''
  let label = ''
  let pumpReading: any = ''
  let color = ''
  const status = row.pump_reading
  const correction_status_reason = row?.correction_status_reason || ''
  const correction_reference_id = row?.correction_reference_id

  if (value === null && (status === null || status === 'NULL' || status === 'جاري المراجعه')) {
    indicatorColor = 'orange.400'
    label = dictionary().PENDING
    color = ''
    pumpReading =
      !['VARIANCE_REFUND', 'DUPLICATE_REFUND', 'Variance Refund', 'Duplicate Refund'].includes(correction_status_reason) &&
      row?.status_value !== 'Pending' &&
      row?.status_value !== 'External' &&
      row?.validity_status !== 'غير صالح'
        ? row?.amount
        : status
  } else if ((status === '' || status === null) && value !== null) {
    indicatorColor = 'green.400'
    label = dictionary().CONFIRMED
    pumpReading = financialNums(value as any)
  } else if (status === 'MISSING') {
    indicatorColor = 'gray.500'
    label = dictionary().NO_IMG
    pumpReading = '-'
  } else if (+(value as any) === 0 || status === 'NOT_READABLE') {
    indicatorColor = 'red.500'
    pumpReading = '-'
    label = dictionary().NOT_READABLE
  } else {
    color = getColor(row.correction, row)
    indicatorColor = color !== '' ? 'gray.500' : 'green.400'
    pumpReading = financialNums(value as any)
    label = color !== '' ? dictionary().NEED_REVIEW_AGAIN : dictionary().CONFIRMED
  }

  return !['VARIANCE_REFUND', 'DUPLICATE_REFUND', 'Variance Refund', 'Duplicate Refund'].includes(correction_status_reason) && !correction_reference_id ? (
    <Flex alignItems='center'>
      <Tooltip label={label} placement='top' fontSize='md'>
        <WarningIcon color={indicatorColor} marginLeft='5px' />
      </Tooltip>
      <Text color={color}>{pumpReading}</Text>
    </Flex>
  ) : (
    <Flex alignItems='center'>
      <Text color={color}>{pumpReading}</Text>
    </Flex>
  )
}
