import { Grid, GridItem, Text, useDisclosure } from '@chakra-ui/react'
import { FC, Key, useState } from 'react'
import dictionary from '../../../dictionary'
import Card from '../Card'
import PreviewContractFile from './PreviewContractFile'
import PreviewFile from './PreviewFile'

interface Props {
  contractFiles: any
}

const ContractFiles: FC<Props> = ({ contractFiles }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedFileIndex, setSelectedFileIndex] = useState(0)

  const handleOpen = (index: number) => {
    onOpen()
    setSelectedFileIndex(index)
  }

  return (
    <Card p={5}>
      <Grid templateColumns='repeat(12, 1fr)' gap={4} pb={4}>
        <GridItem colSpan={[12]}>
          <Text fontSize='20px' fontWeight='700' mb={5} pb={5} pr={2} borderBottom='1px solid rgba(226, 232, 240, 1)'>
            {dictionary().CONTRACT}
          </Text>
        </GridItem>
      </Grid>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'center', flexWrap: 'wrap', gap: '10px', width: '100%' }}>
        {contractFiles?.map((file: any, index: number) => (
          <p onClick={() => handleOpen(index)} key={index}>
            <PreviewContractFile file={file} />
          </p>
        ))}
      </div>
      <PreviewFile
        selectedFileIndex={selectedFileIndex}
        setSelectedFileIndex={setSelectedFileIndex}
        contractFiles={contractFiles}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Card>
  )
}

export default ContractFiles
