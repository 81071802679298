import React from 'react'
import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'serial_number',
    key: 'serial_number',
    name: dictionary().THE_SERIAL_NUMBER,
    type: 'text',
  },
  {
    id: 'appointment_status',
    key: 'appointment_status',
    name: dictionary().APPOINTMENT_STATUS,
    type: 'text',
  },
  {
    id: 'version_number',
    key: 'version_number',
    name: dictionary().THE_VERSION_NUMBER,
    type: 'text',
  },
  {
    id: 'posActions',
    key: 'posActions',
    name: '',
    type: 'modal',
  },
]

const PosList: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().POS}
      tags={[]}
      hasSearch={false}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['pos-devices']}
      countArrayPath={['count']}
      restRoute={'/pos-devices'}
      cashingKey={CASHING_KEY.GET_POS_DEVICES}
      enableIdRedirect={false}
    />
  )
}

export default PosList
