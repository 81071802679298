import { Box } from '@chakra-ui/react'
import { FC } from 'react'
import { useStore } from '../../../store'
import AccountantReviewPaymentModel from './AccountantReviewPaymentModel'
import BackOfficeReviewPaymentModel from './BackOfficeReviewPaymentModel'

interface Props {
  data: any
  handleIsBalancesChange?: () => void
}

const PaymentReview: FC<Props> = ({ data, handleIsBalancesChange }) => {
  const { role } = useStore()

  const getReviewPaymentModel = (): JSX.Element => {
    switch (role) {
      case 'OPERATIONS':
      case 'MANAGER':
        return <BackOfficeReviewPaymentModel data={data} handleIsBalancesChange={handleIsBalancesChange} />
      case 'ACCOUNTANT':
        return <AccountantReviewPaymentModel data={data} handleIsBalancesChange={handleIsBalancesChange} />
      case 'SUPER':
        return (
          <Box display={'flex'} gap={3}>
            <BackOfficeReviewPaymentModel data={data} handleIsBalancesChange={handleIsBalancesChange} role={'SUPER'} />
            <AccountantReviewPaymentModel data={data} handleIsBalancesChange={handleIsBalancesChange} role={'SUPER'} />
          </Box>
        )
      default:
        return <></>
    }
  }
  return <>{getReviewPaymentModel()}</>
}

export default PaymentReview
