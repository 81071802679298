import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

interface UseQueryParamsReturn {
  add: (name: string, value: string | string[]) => void
  remove: (name: string) => void
  query: Record<string, string | string[]>
}

const useQueryParams = (): UseQueryParamsReturn => {
  const history = useHistory()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const paramsObj: Record<string, string | string[]> = {}
    params.forEach((value, key) => {
      if (paramsObj[key]) {
        const currentValue = paramsObj[key]
        if (Array.isArray(currentValue)) {
          currentValue.push(value)
        } else {
          paramsObj[key] = [currentValue, value]
        }
      } else {
        paramsObj[key] = value
      }
    })
  }, [])

  const add = (name: string, value: string | string[]) => {
    const newParams = new URLSearchParams(window.location.search)
    if (Array.isArray(value)) {
      // First, remove all instances of the key to prevent duplication
      newParams.delete(name)
      // Then, add each item in the array with indexed keys
      value.forEach((val, index) => {
        newParams.append(`${name}[${index}]`, val)
      })
    } else {
      newParams.set(name, value)
    }
    const newQueryString = newParams.toString()
    window.history.replaceState(null, '', '?' + newQueryString)

    history.push({ search: '?' + newQueryString })
  }

  const remove = (name: string) => {
    const newParams = new URLSearchParams(window.location.search)
    const keysToRemove: any = []

    // Iterate over all parameters to find matching indexed keys
    newParams.forEach((_, key) => {
      if (key.startsWith(name + '[') || key === name) {
        keysToRemove.push(key)
      }
    })

    // Delete all matching keys
    keysToRemove.forEach((key: any) => {
      newParams.delete(key)
    })

    const newQueryString = newParams.toString()
    window.history.replaceState(null, '', location.pathname + (newQueryString ? '?' + newQueryString : ''))

    history.push({ search: '?' + newQueryString })
  }

  const query = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const paramsObj: Record<string, string | string[]> = {}
    params.forEach((value, key) => {
      if (paramsObj[key]) {
        const currentValue = paramsObj[key]
        if (Array.isArray(currentValue)) {
          currentValue.push(value)
        } else {
          paramsObj[key] = [currentValue, value]
        }
      } else {
        paramsObj[key] = value
      }
    })
    return paramsObj
  }, [location.search])

  return { add, remove, query }
}

export default useQueryParams
