import React from 'react'
import { useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import AdminSubscriptionTable from '../../components/core/Table/AdminSubscriptionTable'

const headers = [
  {
    id: 'id',
    key: 'corporate_id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'corporate_name',
    key: 'corporate_name',
    name: dictionary().CORPORATE_NAME,
    type: 'text',
  },
  {
    id: 'amount_due_per_vehicle',
    key: 'amount_due_per_vehicle',
    name: dictionary().AMOUNT_DUE_PER_VEHICLE,
    type: 'text',
  },
  {
    id: 'count',
    key: 'count',
    name: dictionary().VEHICLE_COUNT,
    type: 'text',
  },
  {
    id: 'total',
    key: 'total',
    name: dictionary().TOTAL_AMOUNT,
    type: 'text',
  },
  {
    id: 'Renewal_date',
    key: 'Renewal_date',
    name: dictionary().RENEWAL_DATE,
    type: 'date',
  },
]

const DVehicleSubscription: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  return (
    <AdminSubscriptionTable
      headers={headers}
      restRoute={`/vehicle-subscriptions/breakdown-by-corporate?vehicleSubscriptionId=${id}`}
      title={dictionary().VEHICLE_SUBSCRIPTIONS}
    />
  )
}

export default DVehicleSubscription
