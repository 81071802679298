import React from 'react'
import { Center } from '@chakra-ui/react'

import Card from '../core/Card'

const AuthLayout: React.FC = ({ children }) => {
  return (
    <Center width='100vw' height='100vh' bg='#r'>
      <Card w={330} minH={350} p='8'>
        {children}
      </Card>
    </Center>
  )
}

export default AuthLayout
