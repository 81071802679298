type prepareDropDownDataProps = {
  data: any
  first_key: string
  second_key?: string
}

const prepareDropDownData = ({ data, first_key, second_key }: any) => {
  let newData = data.map((obj: any) => {
    return {
      ...obj,
      label: second_key ? obj?.[first_key]?.[second_key] : obj?.[first_key],
      value: obj?.id,
    }
  })

  return newData
}

export default prepareDropDownData
