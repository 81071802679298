import React, { useRef, useEffect } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, Select, Checkbox, RadioGroup, Stack, Radio } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'

const EditStation: React.FC = () => {
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/
  const phoneRegex = /^01[0125][0-9]{8}$/

  const { id } = useParams<{ id: string }>()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    control,
    watch,
  } = useForm({
    mode: 'onChange',
  })

  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const stationConfig = watch('station_config')

  const stations = useQuery(['fuel - type', id], () => fetch('GET', `/stations/${id}`), {})
  const stationProviders = useQuery(['station providers', id], () => fetch('GET', `/station-providers`), {})
  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('PATCH', `/stations`, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push(`/stations/${id}`)
    },
    onError,
  })

  useEffect(() => {
    setValue('id', stations?.data?.id)
    setValue('ar', stations?.data?.name?.ar)
    setValue('en', stations?.data?.name?.en)
    setValue('type', stations?.data?.type)
    setValue('contact_person.name', stations?.data?.contact_person.name)
    setValue('contact_person.phone', stations?.data?.contact_person?.phone)
    setValue('contact_person.email', stations?.data?.contact_person?.email)
    setValue('coordinates', `${stations?.data?.location?.coordinates[1]}, ${stations?.data?.location?.coordinates[0]}`)
    // setValue('has_printing', stations?.data?.has_printing)
    // setValue('is_demo', stations?.data?.is_demo)
    setValue('is_active', stations?.data?.is_active)
    // setValue('show_reports', stations?.data?.show_reports)
    // setValue('shift_starting', stations?.data?.shift_starting)
    // setValue('shifts_number', stations?.data?.shifts_number)
    setValue('use_liters', stations?.data?.use_liters)
    setValue('odometer_first', stations?.data?.odometer_first)
    setValue('show_for_drivers', stations?.data?.show_for_drivers)
    setValue('tips_percentage', stations?.data?.tips_percentage)
    setValue('station_config', stations?.data?.config?.live_ocr?.enabled)
    setValue('auto_skip', stations?.data?.config?.live_ocr?.auto_skip)
    setValue('live_ocr', stations?.data?.config?.live_ocr?.skip ? 'allow_skip' : 'no_skip')
  }, [stations?.data, stationProviders?.data])
  const editStationData = async () => {
    if (getValues()?.coordinates?.toString().replace(/\s+/g, '')?.split(',').length < 2) {
      alert(`شكل الإحداثيات لابد أن يكون هكذا : العرض,الطول
           مثال :  562,245
      `)
    }

    mutate([
      {
        id: getValues()?.id,
        name: { ar: getValues()?.ar.trim(), en: getValues()?.en.trim() },
        // has_printing: getValues()?.has_printing,
        type: getValues()?.type,
        // is_demo: getValues()?.is_demo,
        is_active: getValues()?.is_active,
        // show_reports: getValues()?.show_reports,
        contact_person: {
          name: getValues()?.contact_person?.name.trim(),
          phone: getValues()?.contact_person?.phone,
          email: getValues()?.contact_person?.email.trim(),
        },
        location: {
          latitude: Number(getValues()?.coordinates?.toString().replace(/\s+/g, '')?.split(',')[0].trim()),
          longitude: Number(getValues()?.coordinates?.toString().replace(/\s+/g, '')?.split(',')[1].trim()),
        },
        // shift_starting: getValues()?.shift_starting,
        // shifts_number: +getValues()?.shifts_number,
        use_liters: getValues()?.use_liters,
        odometer_first: getValues()?.odometer_first,
        show_for_drivers: getValues()?.show_for_drivers,
        tips_percentage: Number(getValues()?.tips_percentage),
        config: {
          live_ocr: {
            enabled: stationConfig,
            skip: getValues()?.live_ocr === 'allow_skip',
            auto_skip: getValues()?.auto_skip,
          },
        },
      },
    ])
  }

  useEffect(() => {
    if (!stationConfig) {
      setValue('live_ocr', null)
      setValue('auto_skip', null)
    }
  }, [stationConfig])

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header title={dictionary().EDIT_STATION} action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && editStationData()} />} />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(editStationData as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <Input
              hidden
              type='text'
              {...register('id', {
                required: dictionary().REQUIRED,
              })}
            />
            <FormControl id={dictionary().STATION_NAME_AR} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.ar}>
              <FormLabel>
                {dictionary().STATION_NAME_AR} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('ar', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.ar?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STATION_NAME_EN} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.en}>
              <FormLabel>
                {dictionary().STATION_NAME_EN} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('en', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.en?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STATIONS_TYPE} w='40%' minH='100px' isInvalid={!!errors.type} display='inline-block'>
              <FormLabel>
                {dictionary().STATIONS_TYPE} <Reqiured />
              </FormLabel>
              <Select
                dir='ltr'
                textAlign='right'
                {...register('type', {
                  required: dictionary().REQUIRED,
                })}
                placeholder={dictionary().STATIONS_TYPE}
              >
                <option key={1} value='TOLL'>
                  {' '}
                  {dictionary().TOLL}
                </option>
                <option key={2} value='FUEL'>
                  {' '}
                  {dictionary().FUEL}
                </option>
                <option key={3} value='STORE'>
                  {' '}
                  {dictionary().STORE}
                </option>
              </Select>
              <FormErrorMessage>{errors?.type?.message} </FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STATIONS_CONTACT_NAME} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.contact_person?.name}>
              <FormLabel>
                {dictionary().STATIONS_CONTACT_NAME} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('contact_person.name', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.contact_person?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STATIONS_CONTACT_NUMBER} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.contact_person?.phone}>
              <FormLabel>
                {dictionary().STATIONS_CONTACT_NUMBER} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='number'
                  {...register('contact_person.phone', {
                    required: dictionary().REQUIRED,
                    validate: (value) => (!phoneRegex.test(value) ? dictionary().INVALID_PHONE : undefined),
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.contact_person?.phone?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STATIONS_CONTACT_EMAIL} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.contact_person?.email}>
              <FormLabel>
                {dictionary().STATIONS_CONTACT_EMAIL} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('contact_person.email', {
                    required: dictionary().REQUIRED,
                    validate: (value) => (!emailRegex.test(value) ? dictionary().INVALID_EMAIL : undefined),
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.contact_person?.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STATION_PERCENTAGE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.tips_percentage}>
              <FormLabel>
                {dictionary().STATION_PERCENTAGE} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='number'
                  {...register('tips_percentage', {
                    required: dictionary().REQUIRED,
                    // pattern: {
                    //   value: /^\d+$/,
                    //   message: dictionary().THIS_INPUT_ACCEPTED_ONLY_INTEGER_NUMBERS,
                    // },
                    validate: (value) =>
                      value < 0 ? dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO : value > 100 ? dictionary().THIS_INPUT_CANT_BE_OVER_HUNDRED : undefined,
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.tips_percentage?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().COORDINATES}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.coordinates || error?.data?.message[1] === 'location.longitude must be a number conforming to the specified constraints'}
            >
              <FormLabel>
                {dictionary().COORDINATES}
                <Reqiured />
              </FormLabel>

              <InputGroup>
                <Input
                  type='text'
                  {...register('coordinates', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.length != 0 || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.coordinates?.message}</FormErrorMessage>
              <FormErrorMessage>
                {error?.data?.message[1] === 'location.longitude must be a number conforming to the specified constraints' && dictionary().INVALID_COORDINATES}
              </FormErrorMessage>
            </FormControl>
            <FormControl id={'Live OCR'} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <Controller
                control={control}
                name='station_config'
                defaultValue={false}
                render={({ field: { onChange } }) => (
                  <Checkbox data-test={'Live OCR'} isChecked={stationConfig} onChange={onChange} textTransform='capitalize' value='true'>
                    Live OCR
                  </Checkbox>
                )}
              />
            </FormControl>
            {stationConfig && (
              <>
                <FormControl w='40%' minH='100px' display='inline-block' isInvalid={!!errors.live_ocr}>
                  <FormLabel>
                    إعدادات Live OCR <Reqiured />
                  </FormLabel>
                  <Controller
                    name='live_ocr'
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <Stack direction='row'>
                          <Radio value='allow_skip' color='purple'>
                            {dictionary().ALLOW_SKIP}
                          </Radio>
                          <Radio value='no_skip' color='purple'>
                            {dictionary().NO_SKIP}
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                    rules={{
                      required: true && dictionary().REQUIRED,
                    }}
                  />
                  <FormErrorMessage>{errors.live_ocr?.message}</FormErrorMessage>
                </FormControl>
                <FormControl id={'auto_skip'} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
                  <Controller
                    control={control}
                    name='auto_skip'
                    defaultValue={false}
                    render={({ field: { onChange } }) => (
                      <Checkbox
                        data-test={dictionary().ALLOW_AUTO_SKIP}
                        isChecked={getValues().auto_skip}
                        onChange={onChange}
                        textTransform='capitalize'
                        value='true'
                      >
                        {dictionary().ALLOW_AUTO_SKIP} Live OCR
                      </Checkbox>
                    )}
                  />
                </FormControl>
              </>
            )}
            <FormControl id={dictionary().LITRES} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <Controller
                control={control}
                name='use_liters'
                defaultValue={false}
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().use_liters}>
                    {dictionary().USE_LITERS}
                  </Checkbox>
                )}
              />
            </FormControl>
            <FormControl id={dictionary().STATUS} w='40%' minH='100px' display='inline-block'>
              <FormLabel>{dictionary().STATUS}</FormLabel>
              <Controller
                control={control}
                name='is_active'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().is_active}>
                    {dictionary().IS_ACTIVE}
                  </Checkbox>
                )}
              />
            </FormControl>
            <FormControl id={dictionary().ODOMETER} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().ODOMETER}</FormLabel>
              <Controller
                control={control}
                name='odometer_first'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().odometer_first}>
                    {dictionary().ODOMETER_FIRST}
                  </Checkbox>
                )}
              />
            </FormControl>
            <FormControl id={dictionary().SHOW} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().SHOW}</FormLabel>
              <Controller
                control={control}
                name='show_for_drivers'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().show_for_drivers}>
                    {dictionary().SHOW_STATION}
                  </Checkbox>
                )}
              />
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default EditStation
