import React, { useRef } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, Checkbox, Select } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'

type AddCorporateVariables = {
  name: any
  shift_starting: any
}

const AddCorporateGroup: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    watch,
    control,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const useShiftValue = useRef({})
  useShiftValue.current = watch('use_shifts', '')

  const { isLoading, mutate } = useMutation((vars: any) => fetch('POST', '/corporate-group', { data: vars }), {
    onSuccess: (): void => {
      push('/corporate-groups')
      onSuccess()
    },
    onError,
  })

  const AddCorporateGroup = async (formData: AddCorporateVariables) => {
    !useShiftValue.current && (formData.shift_starting = '00:00')
    mutate([formData])
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().ADD_NEW_CORPORATE}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && AddCorporateGroup(getValues() as AddCorporateVariables)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(AddCorporateGroup as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl
              id='ADD_NEW_CORPORATE_GROUP_NAME'
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.name}
            >
              <FormLabel>
                <>
                  {' '}
                  {dictionary().CORPORATE_GROUP_NAME} <Reqiured />
                </>
              </FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().CORPORATE_GROUP_NAME}
                  type='text'
                  {...register('name', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().SHIFTS} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().SHIFTS}</FormLabel>
              <Controller
                control={control}
                name='use_shifts'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} textTransform='capitalize' value='true'>
                    {dictionary().USE_SHIFTS}
                  </Checkbox>
                )}
              />
            </FormControl>
            {useShiftValue.current && (
              <FormControl
                id={dictionary().SHIFT_START_TIME}
                w={['100%', '40%', '40%', '40%']}
                minH={['50px', '100px']}
                display='inline-block'
                isInvalid={!!errors.shift_starting}
              >
                <FormLabel>
                  <>
                    {dictionary().SHIFT_START_TIME}
                    <Reqiured />
                  </>
                </FormLabel>
                <InputGroup>
                  <Input
                    type='text'
                    defaultValue='00:00'
                    {...register('shift_starting', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                      pattern: {
                        value: /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/,
                        message: dictionary().STARTING_SHIFT_PATTERN,
                      },
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.shift_starting?.message}</FormErrorMessage>
              </FormControl>
            )}
            <FormControl id={dictionary().ALL_VEHICLES_ACCESS} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().DRIVERS}</FormLabel>
              <Controller
                control={control}
                name='all_vehicles_access'
                render={({ field: { onChange } }) => (
                  <Checkbox data-test={dictionary().ALL_VEHICLES_ACCESS} onChange={onChange} defaultValue='false' textTransform='capitalize' value='true'>
                    {dictionary().ALL_VEHICLES_ACCESS}
                  </Checkbox>
                )}
              />
            </FormControl>
            <FormControl id={dictionary().CONSUMPTION_MEASUREMENT} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().CONSUMPTION_MEASUREMENT}</FormLabel>
              <Select
                data-test={dictionary().CONSUMPTION_MEASUREMENT}
                dir='ltr'
                textAlign='right'
                {...register('consumption_measurement', {
                  required: false,
                })}
                defaultValue={'L_PER_HUNDRED_KM'}
                sx={{ paddingBottom: '5px ' }}
              >
                <option key={3} value='L_PER_HUNDRED_KM'>
                  {' '}
                  {dictionary().LITERS_PER_HUNDRED_KM}
                </option>
                <option key={1} value='L_PER_KM'>
                  {' '}
                  {dictionary().LITERS_PER_KM}
                </option>
                <option key={2} value='KM_PER_L'>
                  {' '}
                  {dictionary().KM_PER_LITERS}
                </option>
              </Select>
            </FormControl>
            <FormControl w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'></FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddCorporateGroup
