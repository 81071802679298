import { FormControl, FormErrorMessage, FormLabel, Input, Text } from '@chakra-ui/react'
import Required from '../../../components/core/reqiured'
import dictionary from '../../../dictionary'

type FuelAmountControlProps = {
  errors: any
  register: any
  error: any
}

const FuelAmountControl = ({ errors, register, error }: FuelAmountControlProps) => {
  return (
    <FormControl
      id={dictionary().FUEL_QUANTITY}
      w={['100%', '70%', '70%', '70%']}
      isInvalid={!!errors.amount || error?.data?.message === 'this amount exceed the vehicle capacity'}
    >
      <FormLabel>
        {dictionary().FUEL_QUANTITY} <Required />
      </FormLabel>
      <Input
        placeholder='0'
        type='number'
        {...register('amount', {
          valueAsNumber: true,
          required: true && dictionary().REQUIRED,
        })}
      />
      <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
      <Text color='red.500' dir='rtl' m={1}>
        {error?.data?.message === 'this amount exceed the vehicle capacity' && dictionary().EXCEED_VEHICLE_CAPACITY}
      </Text>
    </FormControl>
  )
}

export default FuelAmountControl
