import dictionary from '../../dictionary'

export const CHARGING_PROFILE_EXCEL_HEADERS = [
  {
    id: 'version_number',
    key: 'version_number',
    name: dictionary().VERSION_NUMBER,
    type: 'text',
  },
  {
    id: 'id',
    key: 'id',
    name: dictionary().CHARGING_PROFILE_ID,
    type: 'text',
  },

  {
    id: 'profile_type',
    key: 'type',
    name: dictionary().PROFILE_TYPE,
    type: 'text',
  },

  {
    id: 'version_status',
    key: 'status',
    name: dictionary().VERSION_STATUS,
    type: 'text',
  },
  {
    id: 'billing_period',
    key: 'billing_period',
    name: dictionary().BILLING_PERIOD,
    type: 'text',
  },
  {
    id: 'starts_at',
    key: 'starts_at',
    name: dictionary().FROM,
    type: 'date',
  },
  {
    id: 'expires_at',
    key: 'expires_at',
    name: dictionary().TO,
    type: 'date',
  },

  {
    id: 'corporateId',
    key: 'corporate.id',
    name: dictionary().CORPORATE_ID,
    type: 'text',
  },
  {
    id: 'corporate_group_name',
    key: 'corporate.corporate_group.name',
    name: dictionary().CORPORATE_GROUP_NAME,
    type: 'text',
  },
  {
    id: 'name',
    key: 'corporate.name',
    name: dictionary().CORPORATE_NAME,
    type: 'text',
  },
  {
    id: 'industry',
    key: 'corporate.industry',
    name: dictionary().INDUSTRY,
    type: 'text',
  },
  {
    id: 'tips_type',
    key: 'tips_type',
    name: dictionary().TIPS_TYPE,
    type: 'text',
  },
  {
    id: 'vehicle_subscription_fees',
    key: 'vehicle_subscription_fees',
    name: dictionary().SUBSCRIPTION_FEES,
    type: 'financialNumber',
  },
  {
    id: 'nfc_fees',
    key: 'nfc_fees',
    name: dictionary().NFC_FEES,
    type: 'financialNumber',
  },
  {
    id: 'installation_fees',
    key: 'installation_fees',
    name: dictionary().INSTALLATION_FEES,
    type: 'financialNumber',
  },
  {
    id: 'nfc_replacement_fees',
    key: 'nfc_replacement_fees',
    name: dictionary().NFC_REPLACEMENT_COST,
    type: 'financialNumber',
  },
  {
    id: 'internal_stations_fee_rate',
    key: 'internal_stations_fee_rate',
    name: dictionary().INTERNAL_STATION_FEES,
    type: 'financialNumber',
  },
  {
    id: 'fee_rate',
    key: 'fee_rate',
    name: dictionary().EXTERNAL_STATION_FEES,
    type: 'financialNumber',
  },
  {
    id: 'finance_rate',
    key: 'finance_rate',
    name: dictionary().FINANCE_FEE,
    type: 'financialNumber',
  },
  {
    id: 'tips_percentage',
    key: 'tips_percentage',
    name: dictionary().EXTERNAL_STATION_FEES_TIPS,
    type: 'financialNumber',
  },
  {
    id: 'fraud_detection_fees',
    key: 'fraud_detection_fees',
    name: dictionary().FRUAD_FEES,
    type: 'financialNumber',
  },
  {
    id: 'trial_duration_in_days',
    key: 'trial_duration_in_days',
    name: dictionary().TRIAL_DURATION,
    type: 'text',
  },
  {
    id: 'rejection_comment',
    key: 'rejection_comment',
    name: dictionary().REJECTION_REASON,
    type: 'text',
  },
  {
    id: 'creator',
    key: 'creator.name.en',
    name: dictionary().VERSION_CREATOR,
    type: 'text',
  },
  {
    id: 'reviewer',
    key: 'reviewer.name.en',
    name: dictionary().VERSION_REVIEWER,
    type: 'text',
  },
  {
    id: 'confirmer',
    key: 'confirmer.name.en',
    name: dictionary().VERSION_CONFIRMER,
    type: 'text',
  },
  {
    id: 'signer',
    key: 'signer.name.en',
    name: dictionary().SIGNED_OF_BY,
    type: 'text',
  },
  {
    id: 'rejector',
    key: 'rejector.name.en',
    name: dictionary().VERSION_REJECTOR,
    type: 'text',
  },
]
