import React, { useRef } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, Select, RadioGroup, Stack, Radio, useToast } from '@chakra-ui/react'

import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { parse } from 'query-string'
import { useHistory } from 'react-router-dom'
import { useStore } from '../../store'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import SaveButton from '../../components/core/SaveButton'
import Required from '../../components/core/reqiured'
import { USER_TYPE } from '../../constants'
import AutoCompleteControl from '../transactions/components/AutoCompleteControl'

type AddVehicleutationVariables = {
  corporateId: any
  number_plate: string
  chassis_number: string
  model: string
  brand: string
  color: string
  max_fuel_liters: number
  starting_balance_fuel: number
  replenish_amount: number
  code: string
  vehicle_type_id: number
  fuelTypeIds: [number]
  offline_verification: boolean | string
  pin_code?: number
}

const AddVehicle: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    control,
    watch,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const toast = useToast()
  const { userType } = useStore((state) => state)
  const { push } = useHistory()
  const { onSuccess } = useAlert()
  const stratingBalance = useRef({})
  stratingBalance.current = watch('starting_balance_fuel', 0)
  const query = parse(location.search, { parseBooleans: true })
  const vehiclesTypes = useQuery([query, 'vehicle-types'], () => fetch('GET', '/vehicle-types'))

  const { isLoading, mutate, error } = useMutation((vars: AddVehicleutationVariables) => fetch('POST', '/vehicles/create-vehicle', { data: vars }), {
    onSuccess: (): void => {
      push('/vehicles')

      onSuccess()
    },

    onError: (error: any) => {
      if (error?.data?.message === 'plate number already exists') {
        error.data.message = 'رقم اللوحة موجود مسبقا'
      }
      toast({
        containerStyle: {
          direction: 'rtl',
        },
        title: dictionary().ERROR,
        description: error?.data?.message,
        status: 'error',
        duration: 3000,
        position: 'top',
      })
    },
  })

  const addVehicle = async (formData: AddVehicleutationVariables) => {
    if (formData.offline_verification == 'true') {
      formData.offline_verification = true
    } else if (formData.offline_verification == 'false') {
      formData.offline_verification = false
    }
    userType === USER_TYPE.ADMIN && (formData.corporateId = +formData?.corporateId[0])
    formData.starting_balance_fuel = +formData?.starting_balance_fuel || 0
    formData.replenish_amount = +formData?.replenish_amount || 0
    !formData?.pin_code && delete formData?.pin_code
    mutate(formData)
  }

  return (
    <Container>
      <Flex ref={headerRef} flexDir='column' className='margin-150'>
        <Header
          title={dictionary().ADD_NEW_VEHICLE}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && addVehicle(getValues() as AddVehicleutationVariables)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(addVehicle as any)}>
          <Flex className='margin-100' justify='center' align='center' direction='column'>
            <FormControl id={dictionary().PIN} w={['100%', '70%', '70%', '70%']} display='block' isInvalid={!!errors?.pin_code}>
              <FormLabel mb='10px'>{dictionary().PIN}</FormLabel>
              <Input
                type='number'
                {...register('pin_code', {
                  required: false,
                })}
              />
              <FormErrorMessage>{errors?.pin_code?.message}</FormErrorMessage>
            </FormControl>
            {userType === USER_TYPE.ADMIN && (
              <AutoCompleteControl
                control={control}
                errors={errors}
                identifier='corporateId'
                label={dictionary().CORPORATE}
                route='/corporates'
                displayFormatter={(option: any) => option?.name}
              />
            )}
            <FormControl
              id={dictionary().NUMBER_PLATE}
              w={['100%', '70%', '70%', '70%']}
              isInvalid={
                !!errors.number_plate ||
                error?.data?.message === ' this plate number is already exist' ||
                error?.data?.message === ' plate number already exists'
              }
            >
              <FormLabel>
                {dictionary().NUMBER_PLATE} <Required />
              </FormLabel>
              <Input
                type='string'
                {...register('number_plate', {
                  required: dictionary().REQUIRED,
                  validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                })}
              />
              <FormErrorMessage>{errors?.number_plate?.message}</FormErrorMessage>
              <FormErrorMessage>
                {(error?.data?.message === ' this plate number is already exist' || error?.data?.message === ' plate number already exists') &&
                  dictionary().THIS_PLATE_NUMBER_IS_ALREADY_OCCURED}
              </FormErrorMessage>
            </FormControl>

            <FormControl id={dictionary().CHASSIS_NUMBER} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.chassis_number}>
              <FormLabel>{dictionary().CHASSIS_NUMBER}</FormLabel>
              <Input type='string' {...register('chassis_number', {})} />
              <FormErrorMessage>{errors.chassis_number?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().CAR_MODEL} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.model}>
              <FormLabel>{dictionary().CAR_MODEL}</FormLabel>
              <Input type='string' {...register('model', {})} />
              <FormErrorMessage>{errors.model?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().CAR_BRAND} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.brand}>
              <FormLabel>{dictionary().CAR_BRAND}</FormLabel>
              <Input type='string' {...register('brand', {})} />
              <FormErrorMessage>{errors.brand?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().CAR_COLOR} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.color}>
              <FormLabel>{dictionary().CAR_COLOR}</FormLabel>
              <Input type='string' {...register('color', {})} />
              <FormErrorMessage>{errors.color?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().MAX_FUEL_LITERS} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.max_fuel_liters}>
              <FormLabel>
                {dictionary().MAX_FUEL_LITERS} <Required />
              </FormLabel>
              <Input
                placeholder='0'
                type='number'
                {...register('max_fuel_liters', {
                  valueAsNumber: true,
                  required: true && dictionary().REQUIRED,
                  validate: (value) => (value <= 0 ? dictionary().THIS_INPUT_CANT_EQUAL_TO_ZERO : undefined),
                })}
              />
              <FormErrorMessage>{errors.max_fuel_liters?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().CONSUMPTION_BENCHMARK} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.fuel_consumption_benchmark}>
              <FormLabel>
                <>{dictionary().CONSUMPTION_BENCHMARK}</>
              </FormLabel>
              <Input
                type='text'
                {...register('fuel_consumption_benchmark', {
                  required: false,
                  valueAsNumber: true,
                  min: {
                    value: 0,
                    message: dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO,
                  },
                })}
              />
              <FormErrorMessage>{errors?.fuel_consumption_benchmark?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STARTING_BALANCE} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.starting_balance_fuel}>
              <FormLabel>{dictionary().STARTING_BALANCE}</FormLabel>
              <Input
                placeholder='0'
                type='number'
                {...register('starting_balance_fuel', {
                  valueAsNumber: true,
                  required: false,
                  min: {
                    value: 0,
                    message: dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO,
                  },
                })}
              />
              <FormErrorMessage>{errors.starting_balance_fuel?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().REPLENISH_AMOUNT} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.replenish_amount}>
              <FormLabel>{dictionary().REPLENISH_AMOUNT}</FormLabel>
              <Input
                placeholder='0'
                type='number'
                {...register('replenish_amount', {
                  required: false,
                  pattern: {
                    value: /^\d+$/,
                    message: dictionary().THIS_INPUT_ACCEPTED_ONLY_INTEGER_NUMBERS,
                  },
                  min: {
                    value: 0,
                    message: dictionary().NO_NEGATIVE_NUMBER,
                  },
                  validate: (value) => {
                    if (value > +stratingBalance.current) return dictionary().REPLENISH_AMOUNT_LESS_THAN_STARTING_BALANCE
                  },
                })}
              />
              <FormErrorMessage>{errors.replenish_amount?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id={dictionary().VEHICLE_TYPE} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.vehicle_type_id}>
              <FormLabel>{dictionary().VEHICLE_TYPE}</FormLabel>
              <Select dir='ltr' textAlign='right' {...register('vehicle_type_id', { valueAsNumber: true })} placeholder={dictionary().VEHICLE_TYPE}>
                {vehiclesTypes?.data?.data?.map((item: any) => (
                  <option key={item?.id} value={item?.id}>
                    {' '}
                    {item?.name?.ar}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.vehicle_type_id?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().FUEL_TYPES} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.fuelTypeIds}>
              <FormLabel>
                {dictionary().FUEL_TYPES} <Required />{' '}
              </FormLabel>
              <Controller
                name={`fuelTypeIds`}
                control={control}
                rules={{ required: dictionary().REQUIRED }}
                render={({ field: { onChange } }) => (
                  <AutoCompleteInput
                    onChange={onChange}
                    searchRoute='/fuel-types'
                    singleRoute='/fuel-types'
                    multiple={true}
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.name?.ar}
                    isInvalid={!!errors?.fuelTypeIds}
                  />
                )}
              />
              {errors.fuelTypeIds?.message && <p style={{ color: '#e53e3e', paddingTop: '5px' }}>{errors.fuelTypeIds?.message}</p>}
            </FormControl>
            <FormControl
              id={dictionary().CODE}
              w={['100%', '70%', '70%', '70%']}
              isInvalid={!!errors.code || error?.data?.message === 'this code is already exist'}
            >
              <FormLabel>
                {dictionary().CODE} <Required />
              </FormLabel>
              <Input
                type='string'
                {...register('code', {
                  required: true && dictionary().REQUIRED,
                  validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                })}
              />
              <FormErrorMessage>{errors?.code?.message}</FormErrorMessage>
              <FormErrorMessage>{error?.data?.message === 'this code is already exist' && dictionary().THIS_CODE_IS_ALREADY_OCCURED}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().DRIVER_VERIFICATION}
              w={['100%', '70%', '70%', '70%']}
              isInvalid={!!errors.code || error?.data?.message === 'this code is already exist'}
            >
              <FormControl id='offline_verification' isInvalid={!!errors.offline_verification}>
                <FormLabel>{dictionary().DRIVER_VERIFICATION}</FormLabel>
                <Controller
                  name='offline_verification'
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <Stack direction='row'>
                        <Radio value='true' color='purple'>
                          {dictionary().OFFLINE_PIN}
                        </Radio>
                        <Radio value='false' color='purple'>
                          {dictionary().QR_CODE}
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                  rules={{
                    // required: true && dictionary().REQUIRED,
                    required: false,
                  }}
                />
                {/* <FormErrorMessage>{errors.offline_verification?.message}</FormErrorMessage> */}
              </FormControl>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddVehicle
