import React from 'react'
import { Link as ReactLink, useRouteMatch } from 'react-router-dom'
import { Flex, Text, Icon, Link, VStack, Button, Box, HStack } from '@chakra-ui/react'
import { ChevronRightIcon, WarningIcon } from '@chakra-ui/icons'
import TranslateXOnHover from './TranslateXOnHover'

interface SubItem {
  title: string
  to: string
  icon: React.FC
  type: string
}

interface Props {
  selected?: string
  setSelected: (val: string) => void
  active?: boolean
  title: string
  to?: string
  subItems?: SubItem[]
  icon?: React.FC
  type: string
}

const NavSubItem: React.FC<SubItem> = ({ icon, title, to, type }) => {
  const match = useRouteMatch(to)

  return (
    <Box mt={'1px'} mb={'1px'} display={'flex'}>
      <TranslateXOnHover x={match ? '0px' : '5px'}>
        <Box ml={'10px'} mb={'1px'} display={'inline-block'}>
          <Icon viewBox='0 0 200 200' color='#2d3748' boxSize={'5px'}>
            <path fill='currentColor' d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0' />
          </Icon>
        </Box>
        <Flex mt={'1px'} mb={0} display={'inline-block'} ml={2} position={'relative'} top={1}>
          <Icon as={icon} fontSize='xl' color={'#8f9bba'} />
        </Flex>
        <Link
          data-test={`${type}_${title}`}
          className='sidebarItems'
          as={ReactLink}
          to={to}
          mt={2}
          color={'#8f9bba'}
          fontSize='14px'
          style={{
            ...(match && { transform: 'translateX(5px)', fontWeight: 'bolder', display: 'inline-block', color: '#2d3748' }),
          }}
        >
          <ChevronRightIcon /> {title}
        </Link>
      </TranslateXOnHover>
    </Box>
  )
}

const NavItem: React.FC<Props> = ({ selected, setSelected, type, active = false, icon, title, to = '', subItems = [] }) => {
  if (selected === title) {
    active = !active
  }

  return (
    <Flex direction='column' w='100%' height='100%' borderRadius={0} mt='1'>
      <Button
        className='sidebarItems'
        variant='link'
        as={subItems.length ? undefined : ReactLink}
        to={to}
        w='100%'
        p={'6px 8px'}
        onClick={() => {
          if (active) {
            setSelected('')
          } else {
            setSelected(title)
          }
        }}
        _focus={{ color: '#2d3748' }}
        _active={{ color: '#8f9bba' }}
        //   _hover={{ textDecor: subItems.length ? 'none' : 'none' , color:''}
        // }
        cursor={subItems.length ? 'pointer' : 'pointer'}
        // backgroundColor={active ? 'transparent' : 'undefined'}
        textColor={active ? '#2d3748' : '#8f9bba'}
        borderLeft={active ? '4px solid #7467dc' : 'undefined'}
        borderRadius={'3px'}
        data-test={`${type}_${title}`}
      >
        <Flex justify='flex-start' justify-content='center' w='100%' alignItems={'center'}>
          <Icon ml='18' as={icon} fontSize='xl' color={active ? '#7467dc' : '#8f9bba'} />
          <Text gap='15'>{title}</Text>
        </Flex>
      </Button>

      <VStack mr={5} mt={'4px'} align='flex-start'>
        {active ? subItems.map((item) => <NavSubItem type={type} key={item.to} title={item.title} icon={item.icon} to={item.to} />) : <></>}
      </VStack>
    </Flex>
  )
}

export default NavItem
