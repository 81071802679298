import axios, { AxiosResponse } from 'axios'
import { store } from '../store'

interface DownloadProgress {
  loaded: number
  total: number
}

export const handleDownloadFile = async (baseUrl: string, route: string, fileName: string, onProgress: (progress: DownloadProgress) => void) => {
  try {
    const { token } = store.getState() as any
    const url = `${baseUrl}${route}`

    const response: AxiosResponse<Blob> = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        const progress: DownloadProgress = {
          loaded: progressEvent.loaded,
          total: progressEvent.total,
        }
        onProgress(progress)
      },
    })

    const blob = new Blob([response.data], { type: 'text/csv' })
    const downloadUrl = window.URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', fileName)

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(downloadUrl)
  } catch (error) {
    console.error('Error downloading file:', error)
    // Handle error, show a message to the user, etc.
  }
}
