import React, { useEffect, useRef } from 'react'
import {
  Flex,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  ModalFooter,
  Button,
  Spinner,
  InputGroup,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'

interface Props {
  data?: any
}

const UpdateOdometerReadingModal: React.FC<Props> = ({ data }) => {
  const { push } = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isLoading, mutate } = useMutation((vars: any) => fetch('POST', `/fuel-transactions/${data.id}/odometer-reading`, { data: vars }), {
    onSuccess: (): void => {
      onClose()
      push('/fuel-transactions')
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    trigger,
    setValue,
  } = useForm({ mode: 'onChange' })

  const Save = async () => {
    mutate({
      odometer: `${getValues()?.odometer_reading}`,
      status: 'VALID',
    })
  }

  useEffect(() => {
    setValue('odometer_reading', data?.odometer_reading)
  }, [data])

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  return (
    <>
      <Button onClick={onOpen}>{dictionary().EDIT}</Button>

      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().UPDATE_ODOMETER_READING} </ModalHeader>
          <ModalBody pb={6}>
            <form dir='rtl' onSubmit={handleSubmit(Save as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                <FormControl id={dictionary().ODOMETER_READING} w='90%' minH='100px' display='inline-block' isInvalid={!!errors.odometer_reading}>
                  <FormLabel>{dictionary().ODOMETER_READING}</FormLabel>
                  <InputGroup size='md'>
                    <Input
                      pr='1rem'
                      {...register('odometer_reading', {
                        required: dictionary().REQUIRED,
                        min: {
                          value: 0,
                          message: dictionary().NO_NEGATIVE_NUMBER,
                        },
                      })}
                      type={'text'}
                    />
                  </InputGroup>
                  <FormErrorMessage>{errors.odometer_reading?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button onClick={async () => (await trigger()) && Save()} colorScheme='blue' mr={3}>
              {isLoading ? <Spinner /> : dictionary().SAVE}
            </Button>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UpdateOdometerReadingModal
