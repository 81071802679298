import React, { useRef } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import SaveButton from '../../components/core/SaveButton'
import Required from '../../components/core/reqiured'

const AddVehicleSubscription: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    control,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)

  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()

  const { isLoading, mutate } = useMutation((vars: any) => fetch('POST', '/Vehicle-subscriptions', { data: vars }), {
    onSuccess: (): void => {
      push('/vehicle-subscription')

      onSuccess()
    },

    onError,
  })

  const addVehicle = async () => {
    mutate([
      {
        name: getValues().name,
        fee_percentage: +getValues().fee_percentage,
        minimum_fee: +getValues().minimum_fee,
        station_provider_ids: getValues().station_provider_ids,
      },
    ])
  }
  return (
    <Container>
      <Flex ref={headerRef} flexDir='column' className='margin-150'>
        <Header title={dictionary().ADD_NEW_VEHICLE} action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && addVehicle()} />} />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(addVehicle as any)}>
          <Flex className='margin-100' justify='center' align='center' direction='column'>
            <FormControl id={dictionary().NAME} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.name}>
              <FormLabel>
                {dictionary().NAME} <Required />
              </FormLabel>
              <Input
                type='string'
                {...register('name', {
                  required: dictionary().REQUIRED,
                  validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                })}
              />
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id={dictionary().FEE_PERCENTAGE_RATE} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.fee_percentage}>
              <FormLabel>
                {dictionary().FEE_PERCENTAGE_RATE} <Required />
              </FormLabel>
              <Input
                placeholder='0'
                type='number'
                {...register('fee_percentage', {
                  required: true && dictionary().REQUIRED,
                  validate: (value) => (value < 0 ? dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO : undefined),
                })}
              />
              <FormErrorMessage>{errors.fee_percentage?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id={dictionary().MIMIMUM_FEE} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.minimum_fee}>
              <FormLabel>
                {dictionary().MIMIMUM_FEE} <Required />
              </FormLabel>
              <Input
                placeholder='0'
                type='number'
                {...register('minimum_fee', {
                  required: true && dictionary().REQUIRED,
                  validate: (value) => (value < 0 ? dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO : undefined),
                })}
              />
              <FormErrorMessage>{errors.minimum_fee?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id={dictionary().FUEL_TYPES} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.fuelTypeIds}>
              <FormLabel>
                {dictionary().STATION_PROVIDERS} <Required />{' '}
              </FormLabel>

              <Controller
                name={`station_provider_ids`}
                control={control}
                rules={{ required: true && dictionary().REQUIRED }}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    isInvalid={!!errors?.station_provider_ids}
                    onChange={onChange}
                    value={value}
                    searchRoute={`/station-providers`}
                    singleRoute={`/station-providers`}
                    valueFormatter={(option) => option?.id}
                    multiple={true}
                  />
                )}
              />
              {errors?.station_provider_ids?.message && <p style={{ color: '#e53e3e', paddingTop: '5px' }}>{errors?.station_provider_ids?.message}</p>}
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddVehicleSubscription
