import React from 'react'
import { useParams } from 'react-router-dom'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'invoice.id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'period_start',
    key: 'invoice.period_start',
    name: dictionary().PERIOD_START,
    type: 'date',
  },
  {
    id: 'period_end',
    key: 'invoice.period_end',
    name: dictionary().PERIOD_END,
    type: 'date',
  },
  {
    id: 'amount',
    key: 'invoice.amount',
    name: dictionary().TOTAL_INVOICE,
    type: 'cash',
  },
  {
    id: 'paid_amount',
    key: 'paid_amount',
    name: dictionary().AMOUNT_PAID,
    type: 'total_paid_invoice',
  },
  {
    id: 'starting_balance',
    key: 'starting_balance',
    name: dictionary().AMOUNT_REMAINING,
    type: 'calculated_invoice',
  },
  {
    id: 'status',
    key: 'invoice.status',
    name: dictionary().STATUS,
    type: 'text',
  },
  {
    id: 'pay',
    key: 'invoice.id',
    name: dictionary().PAY,
    type: 'invoice_payment',
  },
  {
    id: 'view',
    key: 'invoice.id',
    name: dictionary().VIEW_DETAILS,
    type: 'invoice_details',
  },
]

const Invoices: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  return (
    <>
      <ViewAll
        headers={headers}
        title={dictionary().INVOICES}
        to={`invoices/new`}
        tags={[{ name: dictionary().ALL, filter: [{ name: '', value: '' }] }]}
        hasSearch={true}
        sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
        dataArrayPath={['toll-station-prices']}
        countArrayPath={['count']}
        restRoute={`/invoices/corporate/${id}`}
        cashingKey={CASHING_KEY.INVOICES}
        enableIdRedirect={false}
      />
    </>
  )
}

export default Invoices
