import React from 'react'
import { useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'stationId',
  },
  {
    id: 'code',
    key: 'code',
    name: dictionary().STATIONS_CODE,
    type: 'text',
  },
  {
    id: 'name',
    key: 'name.ar',
    name: dictionary().STATIONS_NAME,
    type: 'text',
  },
  {
    id: 'address',
    key: 'address',
    name: dictionary().ADDRESS,
    type: 'text',
  },
  {
    id: 'city',
    key: 'city',
    name: dictionary().CITY,
    type: 'text',
  },
  {
    id: 'contact_person_name',
    key: 'contact_person.name',
    name: dictionary().STATIONS_CONTACT_NAME,
    type: 'text',
  },
  {
    id: 'contact_person_phone',
    key: 'contact_person.phone',
    name: dictionary().STATIONS_CONTACT_NUMBER,
    type: 'text',
  },
  {
    id: 'type',
    key: 'type',
    name: dictionary().STATIONS_TYPE,
    type: 'text',
  },
  {
    id: 'longitude',
    key: 'location.longitude',
    name: dictionary().LONGITUDE,
    type: 'text',
  },
  {
    id: 'latitude',
    key: 'location.latitude',
    name: dictionary().LATITUDE,
    type: 'text',
  },
  {
    id: 'stations_date_created',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'stations_time_created',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'st_provider_balance',
    key: 'ledger_accounts',
    name: dictionary().DRIVER_BALANCE,
    type: 'Cash_balance',
    isRight: true,
  },
]

export interface data {
  id: number
  stationProvider: any
}

const StationProviderStations: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  return (
    <ViewAll
      headers={headers}
      title={dictionary().STATION_PROVIDERS}
      to='/station-providers/new'
      tags={[{ name: dictionary().ALL, filter: [{ name: '', value: '' }] }]}
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['station-providers']}
      countArrayPath={['count']}
      restRoute={`/stations?stationProviderId=${id}`}
      cashingKey={CASHING_KEY.GET_STATION_PROVIDERS_STATIONS}
    />
  )
}

export default StationProviderStations
