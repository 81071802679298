const downloadImage = (imageUrl: string, fileName: string): void => {
  const image = new Image()
  image.crossOrigin = 'Anonymous' // This allows the canvas to use images from other domains

  image.onload = () => {
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height

    const ctx = canvas.getContext('2d')
    ctx?.drawImage(image, 0, 0)

    // Convert canvas content to a data URL representing the image
    const dataUrl = canvas.toDataURL('image/jpeg')

    // Create a temporary link element to trigger the download
    const element = document.createElement('a')
    element.href = dataUrl
    element.download = `${fileName}.jpg`
    element.click()

    // Clean up
    canvas.remove()
  }

  // Set the image source to trigger the onload event
  image.src = imageUrl
}

export default downloadImage
