import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'station_name',
    key: 'station.name.ar',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
  {
    id: 'vehicle_type',
    key: 'vehicle_type.name.ar',
    name: dictionary().VEHICLE_TYPE,
    type: 'text',
  },
  {
    id: 'product_type',
    key: 'product_type.name.ar',
    name: dictionary().PRODUCT_TYPE,
    type: 'text',
  },
  {
    id: 'rate',
    key: 'rate',
    name: dictionary().RATE,
    type: 'cash',
  },
  {
    id: 'pricing_date_created',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'pricing_time_created',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
]

const Pricing: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().PRICING}
      to='/pricing/new'
      tags={[{ name: dictionary().ALL, filter: [{ name: '', value: '' }] }]}
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['toll-station-prices']}
      countArrayPath={['count']}
      restRoute={'/toll-station-prices'}
      cashingKey={CASHING_KEY.GET_TOLL_PRICES}
      enableIdRedirect={false}
    />
  )
}

export default Pricing
