import { FormControl, FormErrorMessage, FormLabel, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import Required from '../../../components/core/reqiured'
import dictionary from '../../../dictionary'

type ExternalControlProps = {
  errors: any
  control: any
}

const ExternalControl = ({ errors, control }: ExternalControlProps) => {
  return (
    <FormControl id={dictionary().IS_EXTERNAL_FUEL_TRANSACTION} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.code}>
      <FormControl id='is_external' isInvalid={!!errors.is_external}>
        <FormLabel>
          {dictionary().IS_EXTERNAL_FUEL_TRANSACTION} <Required />
        </FormLabel>
        <Controller
          name='is_external'
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction='row'>
                <Radio value='true' color='purple'>
                  {dictionary().YES}
                </Radio>
                <Radio value='false' color='purple'>
                  {dictionary().NO}
                </Radio>
              </Stack>
            </RadioGroup>
          )}
          rules={{
            required: true && dictionary().REQUIRED,
          }}
        />
        <FormErrorMessage>{errors.is_external?.message}</FormErrorMessage>
      </FormControl>
    </FormControl>
  )
}

export default ExternalControl
