import { Redirect, Route, Switch } from 'react-router-dom'
import CorporateAccounts from '../../pages/accounting/corporates'
import StationsAccounts from '../../pages/accounting/stations'
import AccountingTransactions from '../../pages/accounting/transactions'
import AddCPayments from '../../pages/corporates/AddCPayments'
import CorporatePage from '../../pages/corporates/one'
import AddSPPayments from '../../pages/station-providers/AddSPPayments'
import Station from '../../pages/stations/one'
import FuelTransactions from '../../pages/transactions/fuel'
import { ChangePassword } from '../../pages/ChangePassword/ChangePassword'
import EInvoices from '../../pages/E-Invoices/E-Invoices'
export const FIN_OPS_ROUTES = (
  <Switch>
    <Route exact path='/fuel-transactions' render={() => <FuelTransactions />} />
    <Route exact path='/e-invoices' render={() => <EInvoices />} />
    <Route exact path='/corporates-deposits' render={() => <AddCPayments />} />
    <Route exact path='/station-providers-deposits' render={() => <AddSPPayments />} />
    <Route exact path='/corporates-accounts' render={() => <CorporateAccounts />} />
    <Route exact path='/corporates-accounts/:id' render={() => <CorporatePage />} />
    <Route exact path='/stations-accounts' render={() => <StationsAccounts />} />
    <Route exact path='/stations-accounts/:id' render={() => <Station />} />
    <Route exact path='/accounting-transactions' render={() => <AccountingTransactions />} />
    <Route exact path='/change-password' render={() => <ChangePassword />} />
    <Redirect exact from='/*' to='/fuel-transactions' />
  </Switch>
)
