import React, { useRef, useEffect, useState } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, RadioGroup, Stack, Radio, Checkbox, Select } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'
import { USER_TYPE } from '../../constants'
import { useStore } from '../../store'
import dictionary from '../../dictionary'

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/

type EditDriverVariables = {
  id: number
  username: string
  mobileNumber: number
  email: string
  code: any
  name: string
  starting_balance_fuel: number
  is_active: boolean | string
  offline_verification: boolean | string
  is_demo: boolean
  replenish_amount: number | string
  corporateId?: any
}

const EditDriver: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { userType } = useStore((state) => state)
  const userData = window.localStorage.getItem('activeRole')
  const { role } = useStore()

  const corporates = useQuery(['corporates'], () => fetch('GET', '/corporates'))
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    control,
    watch,
  } = useForm({
    mode: 'onChange',
  })

  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const [corporateDriver, setCorporateDriver] = useState<any>(null)
  const stratingBalance = useRef({})
  stratingBalance.current = watch('starting_balance_fuel', 0)

  const query = useQuery(['drivers', id], () => fetch('GET', `/drivers/${id}`), {})
  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('PATCH', `/drivers`, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push(`/drivers/${id}`)
    },
    onError,
  })
  useEffect(() => {
    setCorporateDriver(query?.data?.corporateId)
    setValue('username', query?.data?.username)
    setValue('mobileNumber', query?.data?.mobile_number)
    setValue('email', query?.data?.email)
    setValue('code', query?.data?.code)
    setValue('name', query?.data?.name)
    setValue('offline_verification', query?.data?.offline_verification + '')
    setValue('starting_balance_fuel', query?.data?.starting_balance_fuel)
    setValue('is_active', query?.data?.is_active)
    setValue('replenish_amount', query?.data?.replenish_amount)
  }, [query?.data, query?.data?.corporateId])
  useEffect(() => {
    if (corporateDriver) {
      setValue('corporateId', [corporateDriver])
    }
  }, [corporates?.data?.data, corporateDriver])

  const editDriver = async (formData: EditDriverVariables) => {
    formData.offline_verification = formData?.offline_verification === 'true' ? true : false
    formData.replenish_amount = +formData?.replenish_amount
    if (formData?.corporateId) {
      formData.corporateId = formData.corporateId?.[0]
    }
    !formData.code && delete formData.code
    formData.is_demo = false
    formData.id = Number(id)
    if (!['SUPER', 'MANAGER'].includes(role ?? '')) {
      delete formData?.corporateId
    }
    mutate(formData)
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().EDIT_DRIVER}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && editDriver(getValues() as EditDriverVariables)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(editDriver as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            {userType === USER_TYPE.ADMIN && (
              <FormControl
                isDisabled={!['SUPER', 'MANAGER'].includes(role ?? '')}
                id={dictionary().CORPORATE}
                w='40%'
                minH='100px'
                display='inline-block'
                isInvalid={!!errors.corporateId}
              >
                <FormLabel>
                  {dictionary().CORPORATE} <Reqiured />
                </FormLabel>
                <Controller
                  name='corporateId'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      onChange={onChange}
                      searchRoute='/corporates'
                      singleRoute='/corporates'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name}
                      isInvalid={!!errors?.corporateId}
                      value={value}
                      multiple={false}
                    />
                  )}
                  rules={{
                    required: true && dictionary().REQUIRED,
                  }}
                />
                <FormErrorMessage>{errors.corporateId?.message}</FormErrorMessage>
              </FormControl>
            )}
            <FormControl
              id={dictionary().DRIVER_USER_NAME}
              w='40%'
              minH='100px'
              display='inline-block'
              isInvalid={!!errors.username || error?.data?.message === 'user name or mobile number is already exist'}
            >
              <FormLabel>
                {dictionary().DRIVER_USER_NAME} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('username', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
              <FormErrorMessage>
                {error?.data?.message === `user name or mobile number is already exist` && dictionary().THIS_USERNAME_OR_PHONE_IS_ALREADY_TAKEN}
              </FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().DRIVER_NAME} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.name}>
              <FormLabel>
                {dictionary().DRIVER_NAME} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('name', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().PHONE}
              w='40%'
              minH='100px'
              display='inline-block'
              isInvalid={!!errors.mobileNumber || error?.data?.message === 'user name or mobile number is already exist'}
            >
              <FormLabel>
                {dictionary().PHONE} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('mobileNumber', {
                    required: dictionary().REQUIRED,
                    pattern: {
                      value: /^01[0125][0-9]{8}$/,
                      message: dictionary().INVALID_PHONE,
                    },
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.mobileNumber?.message}</FormErrorMessage>
              <FormErrorMessage>
                {error?.data?.message === `user name or mobile number is already exist` && dictionary().THIS_USERNAME_OR_PHONE_IS_ALREADY_TAKEN}
              </FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().EMAIL} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.email}>
              <FormLabel>
                {dictionary().EMAIL} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('email', {
                    required: dictionary().REQUIRED,
                    pattern: {
                      value: emailRegex,
                      message: dictionary().INVALID_EMAIL,
                    },
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().DRIVER_CODE}
              w='40%'
              minH='100px'
              display='inline-block'
              isInvalid={!!errors.code || error?.data?.message === `Given code already exists`}
            >
              <FormLabel>{dictionary().DRIVER_CODE}</FormLabel>
              <Input
                type='string'
                {...register('code', {
                  required: false,
                })}
              />
            </FormControl>
            <FormErrorMessage>{error?.data?.message === `Given code already exists` && dictionary().THIS_USERNAME_OR_PHONE_IS_ALREADY_TAKEN}</FormErrorMessage>
            <FormControl id={dictionary().STARTING_BALANCE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.starting_balance_fuel}>
              <FormLabel>
                {dictionary().STARTING_BALANCE} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('starting_balance_fuel', {
                    required: dictionary().REQUIRED,
                    valueAsNumber: true,
                    min: {
                      value: 0,
                      message: dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO,
                    },
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.starting_balance_fuel?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().REPLENISH_AMOUNT} w='40%' minH='100px' isInvalid={!!errors.replenish_amount}>
              <FormLabel>
                {dictionary().REPLENISH_AMOUNT} <Reqiured />
              </FormLabel>
              <Input
                placeholder='0'
                type='number'
                {...register('replenish_amount', {
                  required: true && dictionary().REQUIRED,
                  pattern: {
                    value: /^\d+$/,
                    message: dictionary().THIS_INPUT_ACCEPTED_ONLY_INTEGER_NUMBERS,
                  },
                  min: {
                    value: 0,
                    message: dictionary().NO_NEGATIVE_NUMBER,
                  },
                  validate: (value) => {
                    if (value > +stratingBalance.current) return dictionary().REPLENISH_AMOUNT_LESS_THAN_STARTING_BALANCE
                  },
                })}
              />
              <FormErrorMessage>{errors.replenish_amount?.message}</FormErrorMessage>
            </FormControl>
            <FormControl w='40%' minH='100px' display='inline-block' isInvalid={!!errors.offline_verification}>
              <FormLabel>
                {dictionary().DRIVER_VERIFICATION} <Reqiured />
              </FormLabel>
              <Controller
                name='offline_verification'
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction='row'>
                      <Radio value='true' color='purple'>
                        {dictionary().OFFLINE_PIN}
                      </Radio>
                      <Radio value='false' color='purple'>
                        {dictionary().QR_CODE}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
                rules={{
                  required: true && dictionary().REQUIRED,
                }}
              />
              <FormErrorMessage>{errors.offline_verification?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STATUS} w='40%' minH='100px' display='inline-block'>
              <FormLabel>{dictionary().STATUS}</FormLabel>
              <Controller
                control={control}
                name='is_active'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().is_active}>
                    {dictionary().IS_ACTIVE}
                  </Checkbox>
                )}
              />
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default EditDriver
