import { useRef } from 'react'

import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useDisclosure } from '@chakra-ui/react'

import { useLocation } from 'react-router-dom'
import dictionary from '../../dictionary'

interface Props {
  data?: any
  header?: any
}

const CommentModel: React.FC<Props> = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const { pathname } = useLocation()
  return (
    <>
      {(data?.comment || data?.corporate_comment) && <Button onClick={onOpen}>{dictionary().VIEW_IMAGE}</Button>}
      {[undefined, null, ''].includes(data?.comment) && <>-</>}
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().COMMENT} </ModalHeader>
          <ModalBody pb={6}>
            <Stack direction='column'>
              <Box dir='rtl'>
                <b>{dictionary().ADMIN_COMMENT}</b>
                <br />
                {data?.comment ? data?.comment : dictionary().NO_COMMENT}
              </Box>
              {pathname !== '/payments-details' && (
                <Box dir='rtl'>
                  <b>{dictionary().CORPORATE_COMMENT}</b>
                  <br />
                  {data?.corporate_comment?.comment ? data?.corporate_comment?.comment : dictionary().NO_COMMENT}
                </Box>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CommentModel
