import React, { useRef } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'

type AddInvoiceVariables = {
  corporateId: number
  period_end: string
  period_start: string
  // amount: number
}

const AddInvoice: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' })

  const { id } = useParams<{ id: string }>()
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const { isLoading, mutate } = useMutation((vars: any) => fetch('POST', '/invoices', { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push(`/corporates/${id}/invoices`)
    },
    onError,
  })
  const addInvoce = async (formData: AddInvoiceVariables) => {
    formData.corporateId = +formData?.corporateId
    mutate(formData)
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().ADD_NEW_FUEL}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && addInvoce(getValues() as AddInvoiceVariables)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(addInvoce as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <InputGroup>
              <Input type='number' {...register('corporateId', {})} value={id} hidden />
            </InputGroup>
            <FormControl
              id={dictionary().PERIOD_START}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.period_start}
            >
              <FormLabel>
                {dictionary().PERIOD_START} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='datetime-local'
                  {...register('period_start', {
                    required: dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.period_start?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id={dictionary().PERIOD_END}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.period_end}
            >
              <FormLabel>
                {dictionary().PERIOD_END} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='datetime-local'
                  {...register('period_end', {
                    required: dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.period_end?.message}</FormErrorMessage>
            </FormControl>

            {/* <FormControl id={dictionary().AMOUNT} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.amount}>
              <FormLabel>{dictionary().AMOUNT}</FormLabel>
              <InputGroup>
                <Input
                  type='number'
                  {...register('amount', {
                    required: false,
                    valueAsNumber: true,
                    min: {
                      value: 0,
                      message: dictionary().NO_NEGATIVE_NUMBER,
                    },
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.amount?.message}</FormErrorMessage>
            </FormControl> */}
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddInvoice
