import React, { useEffect, useRef, useState } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, Checkbox, RadioGroup, Stack, Radio, useDisclosure, Select } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Required from '../../components/core/reqiured'
import { useStore } from '../../store'
import DepositConfigurationModel from './DepositConfigurationModel'

type EditCorporateVariables = {
  name: any
  id: number
}

const EditCorporateGroup: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    control,
    watch,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const { userType, role } = useStore((state) => state)
  const useShiftValue = useRef({})
  useShiftValue.current = watch('use_shifts', '')
  const deposit_confirmation_type = watch('deposit_confirmation_type')
  const [isInitailRender, setIsInitailRender] = useState(true)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { mutate: getCorporateGroupData } = useMutation(['corporate-group', id], () => fetch('GET', `/corporate-group/${id}`), {
    onSuccess: (data) => {
      setValue('name', data?.name)
      setValue('use_shifts', data?.use_shifts)
      setValue('shift_starting', data?.shift_starting)
      setValue('deposit_confirmation_type', data?.deposit_confirmation_type)
      setValue('consumption_measurement', data?.consumption_measurement)
      setValue('all_vehicles_access', data?.all_vehicles_access)
    },
  })

  useEffect(() => {
    getCorporateGroupData()
  }, [])

  useEffect(() => {
    deposit_confirmation_type !== undefined && !isInitailRender && onOpen()
    deposit_confirmation_type && setIsInitailRender(false)
  }, [deposit_confirmation_type])

  const onCancelConfig = async () => {
    deposit_confirmation_type === 'FINAL_CONFIRMATION'
      ? await setValue('deposit_confirmation_type', 'INITIAL_CONFIRMATION')
      : await setValue('deposit_confirmation_type', 'FINAL_CONFIRMATION')
    onClose()
  }
  const { isLoading, mutate } = useMutation((vars: any) => fetch('PUT', '/corporate-group', { data: vars }), {
    onSuccess: (): void => {
      push(`/corporate-groups/${id}`)
      onSuccess()
    },
    onError,
  })

  const EditCorporateGroup = async (formData: EditCorporateVariables) => {
    formData.id = +id
    mutate([formData])
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().ADD_NEW_CORPORATE}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && EditCorporateGroup(getValues() as EditCorporateVariables)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(EditCorporateGroup as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl
              id='ADD_NEW_CORPORATE_GROUP_NAME'
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.name}
            >
              <FormLabel>
                <>
                  {' '}
                  {dictionary().CORPORATE_GROUP_NAME} <Required />
                </>
              </FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().CORPORATE_GROUP_NAME}
                  type='text'
                  {...register('name', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().SHIFTS} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().SHIFTS}</FormLabel>
              <Controller
                control={control}
                name='use_shifts'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().use_shifts}>
                    {dictionary().USE_SHIFTS}
                  </Checkbox>
                )}
              />
            </FormControl>
            {useShiftValue.current && (
              <FormControl
                id={dictionary().SHIFT_START_TIME}
                w={['100%', '40%', '40%', '40%']}
                minH={['50px', '100px']}
                display='inline-block'
                isInvalid={!!errors.shift_starting}
              >
                <FormLabel>
                  <>
                    {dictionary().SHIFT_START_TIME}
                    <Required />
                  </>
                </FormLabel>
                <InputGroup>
                  <Input
                    type='text'
                    defaultValue='00:00'
                    {...register('shift_starting', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                      pattern: {
                        value: /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/,
                        message: dictionary().STARTING_SHIFT_PATTERN,
                      },
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.shift_starting?.message}</FormErrorMessage>
              </FormControl>
            )}
            {userType === 'admin' && role === 'SUPER' && (
              <FormControl
                id='deposit_confirmation_type'
                w={['100%', '40%', '40%', '40%']}
                minH={['50px', '100px']}
                display='inline-block'
                isInvalid={!!errors.deposit_confirmation_type}
              >
                <FormLabel>
                  {dictionary().DEPOSIT_CONFIGURATION} <Required />
                </FormLabel>
                <Controller
                  name='deposit_confirmation_type'
                  control={control}
                  data-test={dictionary().DEPOSIT_CONFIGURATION}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <Stack direction='row' gap='15px'>
                        <Radio value='INITIAL_CONFIRMATION' color='purple'>
                          {dictionary().INITIAL_CONFIRMATION}
                        </Radio>
                        <Radio value='FINAL_CONFIRMATION' color='purple'>
                          {dictionary().FINAL_CONFIRMATION}
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                  rules={{
                    required: true && dictionary().REQUIRED,
                  }}
                />
                <FormErrorMessage>{errors.offline_verification?.message}</FormErrorMessage>
              </FormControl>
            )}
            <FormControl id={dictionary().CONSUMPTION_MEASUREMENT} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().CONSUMPTION_MEASUREMENT}</FormLabel>
              <Select
                data-test={dictionary().CONSUMPTION_MEASUREMENT}
                dir='ltr'
                textAlign='right'
                {...register('consumption_measurement', {
                  required: false,
                })}
                defaultValue={'L_PER_HUNDRED_KM'}
                sx={{ paddingBottom: '5px ' }}
              >
                <option key={3} value='L_PER_HUNDRED_KM'>
                  {' '}
                  {dictionary().LITERS_PER_HUNDRED_KM}
                </option>
                <option key={1} value='L_PER_KM'>
                  {' '}
                  {dictionary().LITERS_PER_KM}
                </option>
                <option key={2} value='KM_PER_L'>
                  {' '}
                  {dictionary().KM_PER_LITERS}
                </option>
              </Select>
            </FormControl>
            <FormControl id={dictionary().ALL_VEHICLES_ACCESS} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().DRIVERS}</FormLabel>
              <Controller
                control={control}
                name='all_vehicles_access'
                render={({ field: { onChange } }) => (
                  <Checkbox
                    onChange={onChange}
                    data-test={dictionary().ALL_VEHICLES_ACCESS}
                    defaultValue='false'
                    textTransform='capitalize'
                    value='true'
                    isChecked={getValues().all_vehicles_access}
                  >
                    {dictionary().ALL_VEHICLES_ACCESS}
                  </Checkbox>
                )}
              />
            </FormControl>
            <FormControl id='offline_verification' w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'></FormControl>
          </Flex>
        </form>
      </Card>
      <DepositConfigurationModel onCancel={onCancelConfig} onClose={onClose} isOpen={isOpen} />
    </Container>
  )
}

export default EditCorporateGroup
