export const INDUSTRY_TYPES = [
  'السكك الحديدية',
  'الحكومة',
  'السياحة',
  'النقل',
  'الخدمات اللوجستية',
  'سلع استهلاكية سريعة التحرك',
  'الإلكترونيات',
  'صناعي',
  'الاتصالات',
  'الصناعات الدوائية',
  'التوزيع',
  'التعليم',
  'النسيج',
  'التأمين',
  'البناء',
  'التأجير المالي',
  'الخدمات',
  'الطبي',
  'التصنيع',
  'التجارة',
]
