import React from 'react'

const Reqiured: React.FC = () => {
  return (
    <>
      <span style={{ color: '#a70000', fontSize: '20px', marginRight: '2px' }}>*</span>
    </>
  )
}
export default Reqiured
