import { useState } from 'react'

function useDebounce<T>(fn: (args: T) => void, time: number): (args: T) => void {
  const [timer, setTimer] = useState<NodeJS.Timer>()

  return (args: T) => {
    if (timer) {
      clearTimeout(timer)
      setTimer(undefined)
    }

    setTimer(setTimeout(() => fn(args), time))
  }
}

export default useDebounce
