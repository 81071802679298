import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import generatePDF from 'react-to-pdf'
import { Container } from 'rsuite'
import { DurationRadioOptions } from '../../components/core/ChargingProfileDetails/AddProfileType'

import VersionDetails from '../../components/core/ChargingProfileDetails/VersionDetails'
import VersionsList from '../../components/core/ChargingProfileDetails/VersionsList'
import Header from '../../components/core/Header'
import { VERSION_STATUS, VERSION_STATUS_TYPE } from '../../constants/VERSION_STATUS'
import { useExport } from '../../helpers/useExport'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useConfirmationModal from '../../hooks/useConfirmationModal'
import { CHARGING_PROFILE_EXCEL_HEADERS } from '../../constants/charging_profile/chargingProfleExcelHeaders'
import { CHARGING_PROFILE_CUSTOM_RENDERS } from '../../constants/charging_profile/chargingProfileCustomRenders'
import { useDownloadChargingProfileExcel } from '../../hooks/charging_profile/useDownloadChargingProfileExcel'
import { CHARGING_PROFILE_TYPE } from '../../constants/ChargingProfileType'
import useAlert from '../../helpers/useAlert'
import { RejectDialog } from './dialogs/RejectDialog'
import { ExtendDialog } from './dialogs/ExtendDialog'
import { useProfileMutations } from '../../hooks/charging_profile/useProfileMutations'
import { AdjustmentDialog } from './dialogs/AdjustmentDialog'
import { NotAllowedIcon } from '@chakra-ui/icons'

const ChargingProfilePage: React.FC = () => {
  const componentRef = useRef<any>(null)

  const {
    register,
    formState: { errors },
    getValues: getFormValues,
    watch,
    control,
    setValue,
  } = useForm({ mode: 'onChange' })

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: extendDialogOpen, onOpen: onExtendDialogOpen, onClose: onExtendDialogClose } = useDisclosure()
  const { isOpen: adjustmentDialogOpen, onOpen: onAdjustmentDialogOpen, onClose: onAdjustmentDialogClose } = useDisclosure()

  const { corporateId, profileId } = useParams<{ corporateId: string; profileId: string }>()

  const headerRef = useRef<HTMLDivElement>(null)

  const [versionData, setVersionData] = useState<any>([])
  const [selectedVersionId, setSelectedVersionId] = useState<any>(profileId)
  const [reject, setReject] = useState(false)
  const [getValues, setGetValues] = useState<any>()
  const [rejectReason, setRejectReason] = useState('')
  const [adjustmentComment, setAdjustmentComment] = useState('')
  const [askForAdjustment, setAskForAdjustment] = useState(false)

  const { isLoading, mutate } = useMutation(() => fetch('GET', `charging-profile/${selectedVersionId}`), {
    onSuccess: (data): void => {
      setVersionData(data)
    },
  })

  const { getExcelFile } = useDownloadChargingProfileExcel()
  const { changeVersionStatus, extendPeriod, stopVersion, deActivateProfile, needAdjustment, rejectChargingProfile } = useProfileMutations({
    selectedVersionId,
    getValues,
    getFormValues,
  })

  const getVersionStatus = () => {
    switch (versionData?.status) {
      case VERSION_STATUS.PENDING_ADJUSTMENTS:
        return dictionary().REVIEW
      case VERSION_STATUS.CREATED:
        return dictionary().REVIEW
      case VERSION_STATUS.REVIEW_NEEDED:
        return dictionary().REVIEW
      case VERSION_STATUS.REVIEWED:
        return dictionary().CONFIRM
      case VERSION_STATUS.CONFIRMED:
        return dictionary().SIGNE_OFF
      case VERSION_STATUS.SIGNED_OFF:
        return dictionary().INACTIVE
      case VERSION_STATUS.ACTIVE:
        return dictionary().ACTIVATED
      case VERSION_STATUS.EXPIRED:
        return dictionary().EXPIRED
      case VERSION_STATUS.REJECTED:
        return dictionary().REJECTED
      default:
        return <></>
    }
  }

  const changeStatus = () => {
    if (!reject && !askForAdjustment) {
      let status: string = VERSION_STATUS.CREATED

      switch (versionData?.status) {
        case VERSION_STATUS.PENDING_ADJUSTMENTS:
          status = VERSION_STATUS.REVIEW_NEEDED
          break
        case VERSION_STATUS.CREATED:
          status = VERSION_STATUS.REVIEWED
          break
        case VERSION_STATUS.REVIEW_NEEDED:
          status = VERSION_STATUS.REVIEWED
          break
        case VERSION_STATUS.REVIEWED:
          status = VERSION_STATUS.CONFIRMED
          break
        case VERSION_STATUS.CONFIRMED:
          status = VERSION_STATUS.SIGNED_OFF
          break
        default:
          break
      }
      changeVersionStatus(status as VERSION_STATUS_TYPE)
    } else if (askForAdjustment) {
      setAskForAdjustment(false)
      changeVersionStatus(VERSION_STATUS.REJECTED as VERSION_STATUS_TYPE)
      setAdjustmentComment('')
    } else if (reject && !askForAdjustment) {
      setReject(false)
      changeVersionStatus(VERSION_STATUS.REJECTED as VERSION_STATUS_TYPE)
      setRejectReason('')
    }
  }

  const { openModal, ConfirmationModal } = useConfirmationModal(changeStatus)
  const { openModal: OpenStopModal, ConfirmationModal: ConfirmationStopModal } = useConfirmationModal(stopVersion)
  const { openModal: OpenDeActivateModal, ConfirmationModal: DeActivateModal } = useConfirmationModal(deActivateProfile)

  useEffect(() => {
    mutate()
  }, [selectedVersionId])

  return (
    <Container>
      {/* {Reject Dialog} */}
      <RejectDialog
        changeStatus={changeStatus}
        rejectReason={rejectReason}
        setRejectReason={setRejectReason}
        setReject={setReject}
        onClose={onClose}
        rejectChargingProfile={rejectChargingProfile}
        isOpen={isOpen}
      />

      {/* {Adjustment Dialog} */}
      <AdjustmentDialog
        changeStatus={changeStatus}
        adjustmentComment={adjustmentComment}
        setAdjustmentComment={setAdjustmentComment}
        setAskForAdjustment={setAskForAdjustment}
        onAdjustmentDialogClose={onAdjustmentDialogClose}
        adjustmentDialogOpen={adjustmentDialogOpen}
        needAdjustment={needAdjustment}
      />

      {/* {Extend Dialog} */}
      <ExtendDialog
        control={control}
        errors={errors}
        extendPeriod={extendPeriod}
        register={register}
        setValue={setValue}
        watch={watch}
        setRejectReason={setRejectReason}
        extendDialogOpen={extendDialogOpen}
        onExtendDialogClose={onExtendDialogClose}
      />
      <ConfirmationModal title='هل أنت متأكد؟' body='هل أنت متأكد من أنك تريد القيام بهذه العملية؟' />
      <ConfirmationStopModal title='هل أنت متأكد؟' body='هل أنت متأكد من أنك تريد إيقاف هذا الإصدار؟' />
      <DeActivateModal title='هل أنت متأكد؟' body='هل أنت متأكد من أنك تريد إلغاء تنشيط سجل الشركة؟' />
      <Box style={{ minHeight: '100vh', marginBottom: '20px' }}>
        {/* @ts-ignore */}
        <Flex ref={headerRef} flexDir='column' className='margin-150' p={[5, 8]}>
          <Header
            isLoading={isLoading}
            title={dictionary().CHARGING_PROFILE_DETAILS + ' :'}
            action={
              !isLoading ? (
                <Flex gap={3}>
                  {![VERSION_STATUS.EXPIRED].includes(versionData?.status) && (
                    <Button
                      _hover={{ backgroundColor: 'rgba(205, 213, 223, 1)' }}
                      colorScheme='red'
                      variant='solid'
                      sx={{
                        height: '40px',
                        ml: '20px',
                        backgroundColor: 'rgba(205, 213, 223, 1)',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',
                      }}
                      onClick={() => OpenDeActivateModal('danger')}
                    >
                      <NotAllowedIcon style={{ width: '20px', height: '20px', color: 'rgba(43, 49, 60, 1)' }} />
                      <Text
                        style={{
                          fontFamily: 'Cairo',
                          fontSize: '14px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          textAlign: 'center',
                          color: 'rgba(43, 49, 60, 1)',
                        }}
                      >
                        إلغاء التنشيط
                      </Text>
                    </Button>
                  )}

                  <Button colorScheme='blue' sx={{ height: '40px' }} onClick={() => getExcelFile(corporateId)}>
                    <img src='/assets/download.svg' style={{ width: '20px', height: '20px', margin: '0px 5px' }} />
                    {/* // {dictionary().SAVE} */}
                    تحميل
                  </Button>
                </Flex>
              ) : (
                <></>
              )
            }
          />
        </Flex>
        <Grid templateColumns='repeat(12, 1fr)' gap={4} px={8}>
          <GridItem colSpan={[12, 12, 9, 10]}>
            {isLoading ? (
              <>
                <Skeleton height='200px' />
                <Skeleton height='200px' my={4} />
                <Skeleton height='250px' my={4} />
                <Skeleton height='100px' />
              </>
            ) : (
              <VersionDetails
                versionData={versionData}
                reference={componentRef}
                setGetValues={setGetValues}
                disableEditable={![VERSION_STATUS.PENDING_ADJUSTMENTS].includes(versionData?.status)}
                actions={{
                  onExtendDialogOpen,
                  OpenStopModal,
                  setAskForAdjustment,
                  onAdjustmentDialogOpen,
                  openModal,
                  getVersionStatus,
                  setReject,
                  onOpen,
                }}
              />
            )}
          </GridItem>
          <GridItem colSpan={[12, 12, 3, 2]}>
            <VersionsList corporateId={Number(corporateId)} selectedVersionId={selectedVersionId} setSelectedVersionId={setSelectedVersionId} />
          </GridItem>
        </Grid>
      </Box>
    </Container>
  )
}

export default ChargingProfilePage
