// import React from 'react'

// const AccessibleStationProviders = () => {
//   return (
//     <div>AccessibleStationProviders</div>
//   )
// }

// export default AccessibleStationProviders
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { GridItem, Text, Box, Stack, Flex, Spinner } from '@chakra-ui/react'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import Card from '../../components/core/Card'
import Table from '../core/Table'

const CorporateStationProvidersHeaders = [
  {
    id: 'id',
    key: 'stationProvider.id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'name',
    key: 'stationProvider.name.en',
    name: dictionary().NAME_EN,
    type: 'text',
  },
  {
    id: 'name',
    key: 'stationProvider.name.ar',
    name: dictionary().NAME_AR,
    type: 'text',
  },
]
const stationProvidersHeaders = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'name',
    key: 'name.en',
    name: dictionary().NAME_EN,
    type: 'text',
  },
  {
    id: 'name',
    key: 'name.ar',
    name: dictionary().NAME_AR,
    type: 'text',
  },
]
interface Props {
  isCorporateAccessSP?: boolean
}

const AccessibleStationProviders: React.FC<Props> = ({ isCorporateAccessSP }) => {
  const { id } = useParams<{ id: string }>()
  const stationProviders = useQuery(['all_station_providers'], () => fetch('GET', '/station-providers'))
  const { isLoading, data, refetch } = useQuery(['access_staion_provider'], () => fetch('GET', `/available-station-providers?corporateId=${id}`), {})
  useEffect(() => {
    refetch()
  }, [isCorporateAccessSP])

  return (
    <>
      <Card h='100%' p={4}>
        <Text m={3} fontSize='20px'>
          {dictionary().ACCESSABLE_STATION_PROVIDERS}
        </Text>
        {isLoading ? (
          <Flex w='100%' h='100%' align='center' justify='center'>
            <Spinner />
          </Flex>
        ) : data?.data?.length > 0 ? (
          <GridItem colSpan={6}>
            <Box w='100%'>
              <Stack>
                <GridItem>
                  <Card h='100%'>
                    <Table raised enableIdRedirect={false} height={350} headers={CorporateStationProvidersHeaders} data={data?.data} />
                  </Card>
                </GridItem>
              </Stack>
            </Box>
          </GridItem>
        ) : (
          <GridItem colSpan={6}>
            <Box w='100%'>
              <Stack>
                <GridItem>
                  <Card h='100%'>
                    <Table raised enableIdRedirect={false} height={350} headers={stationProvidersHeaders} data={stationProviders?.data?.data} />
                  </Card>
                </GridItem>
              </Stack>
            </Box>
          </GridItem>
        )}
      </Card>
    </>
  )
}

export default AccessibleStationProviders
