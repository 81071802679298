import React from 'react'
import { useParams } from 'react-router'
import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

let headers: any[] = []
const vehicleHeaders = [
  {
    id: 'fuel_transaction_date',
    key: 'created_at',
    name: dictionary().TRANSACTION_DATE,
    type: 'date',
  },
  {
    id: 'fuel_transaction_time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'liters',
    key: 'liters',
    name: dictionary().LITRES_AMOUNT,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().PRICE,
    type: 'cash',
  },
  {
    id: 'station_name',
    key: 'station.name.ar',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
]
const corporateHeaders = [
  {
    id: 'fuel_transaction_date',
    key: 'created_at',
    name: dictionary().TRANSACTION_DATE,
    type: 'date',
  },
  {
    id: 'fuel_transaction_time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'vehicle_id',
    key: 'vehicle.id',
    name: dictionary().VEHICLE_ID,
    type: 'text',
  },
  {
    id: 'liters',
    key: 'liters',
    name: dictionary().LITRES_AMOUNT,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().PRICE,
    type: 'cash',
  },
  {
    id: 'station_name',
    key: 'station.name.ar',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
]
const stationProviderHeaders = [
  {
    id: 'fuel_transaction_date',
    key: 'created_at',
    name: dictionary().TRANSACTION_DATE,
    type: 'date',
  },
  {
    id: 'fuel_transaction_time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'vehicle_id',
    key: 'vehicle.id',
    name: dictionary().VEHICLE_ID,
    type: 'text',
  },
  {
    id: 'corporate_name',
    key: 'corporate.name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'liters',
    key: 'liters',
    name: dictionary().LITRES_AMOUNT,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().PRICE,
    type: 'cash',
  },
  {
    id: 'station_name',
    key: 'station.name.ar',
    name: dictionary().STATION_NAME,
    type: 'text',
  },
]
const stationHeaders = [
  {
    id: 'fuel_transaction_date',
    key: 'created_at',
    name: dictionary().TRANSACTION_DATE,
    type: 'date',
  },
  {
    id: 'fuel_transaction_time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'vehicle_id',
    key: 'vehicle.id',
    name: dictionary().VEHICLE_ID,
    type: 'text',
  },
  {
    id: 'corporate_name',
    key: 'corporate.name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'liters',
    key: 'liters',
    name: dictionary().LITRES_AMOUNT,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().PRICE,
    type: 'cash',
  },
]
const FuelTransactionsByID: React.FC = () => {
  const { userType } = useParams<{ userType: string }>()
  if (userType === 'corporates') {
    headers = corporateHeaders
  } else if (userType === 'station-providers') {
    headers = stationProviderHeaders
  } else if (userType === 'stations') {
    headers = stationHeaders
  } else {
    headers = vehicleHeaders
  }
  return (
    <ViewAll
      headers={headers}
      title={dictionary().FUEL_TRANSACTIONS_DETAILS}
      to=''
      hasSearch={false}
      dataArrayPath={['/fuel-transactions']}
      countArrayPath={['count']}
      restRoute={'/fuel-transactions'}
      cashingKey={CASHING_KEY.GET_FUEL_TRANSACTIONS_FOR_ID}
    />
  )
}

export default FuelTransactionsByID
