import { Box, CircularProgress, FormControl, FormErrorMessage, FormHelperText, FormLabel, Text, Button, useToast } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'
import PasswordInput from '../../components/core/PasswordInput'
import redirectingIssueSrc from '../../assets/redirecting-Issue.svg'
import verifyingSrc from '../../assets/verifying.svg'

type NewPasswordProps = {}

const fieldStyle = {
  '& label': {
    color: 'black',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'yellow',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ddd',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#7F56D9',
    },
  },
}

interface FormTypes {
  password: string
  password_confirmed: string
}

const NewPassword: FC<NewPasswordProps> = () => {
  const corporate = window.localStorage.getItem('activeRole')
  const userData = corporate && JSON.parse(corporate)
  const [resetMailSent, setResetMailSent] = useState(false)
  const location = useLocation()
  const { push } = useHistory()
  const [email, setEmail] = useState<any>('')
  const [token, setToken] = useState<any>('')

  const [showPassword, setShowPassword] = useState(false)
  const [verified, setVerified] = useState(false)
  const [verificationErrors, setVerificationErrors] = useState(false)

  const toast = useToast()

  const {
    isLoading: verifyLoading,
    mutate: verifyToken,
    error: verifyError,
  } = useMutation(({ email, token }: { email: string; token: string }) => fetch('post', `/admin/verify-activation-token?email=${email}&token=${token}`), {
    onSuccess: (data: any): void => {
      setVerified(true)
    },
    onError: (error: any): void => {
      setVerificationErrors(true)
      setTimeout(() => {
        localStorage.clear()
        push('/admin/login')
      }, 1000)
    },
  })

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const email = searchParams.get('email')
    const token = searchParams.get('token')
    setEmail(email)
    setToken(token)

    if (email && token) {
      verifyToken({ email, token })
    }
  }, [location.search])

  const { go } = useHistory()

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<FormTypes>()

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('post', `/admin/new-password`, vars), {
    onSuccess: (data: any): void => {
      setResetMailSent(true)
      setTimeout(() => {
        localStorage.clear()
        push('/admin/login')
      }, 1000)
    },
    onError: (error: any): void => {
      toast({
        containerStyle: {
          direction: 'rtl',
        },
        title: dictionary().ERROR,
        description: error?.data?.message,
        status: 'error',
        duration: 3000,
        position: 'top',
      })
      console.error(error)
      setTimeout(() => {
        localStorage.clear()
        push('/admin/login')
      }, 1000)
    },
  })

  const sendResetPassword = async (formData: FormTypes) => {
    const searchParams = new URLSearchParams(location.search)
    const email = searchParams.get('email')
    const token = searchParams.get('token')

    mutate({
      data: {
        email,
        token,
        new_password: formData.password,
      },
    })
  }

  return (
    <div
      style={{
        backgroundColor: 'rgb(249 250 251)',
        height: '100%',
        width: '100%',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {verifyLoading && (
        <Box
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>
            <img src={verifyingSrc} width={200} height={200} style={{ marginBottom: '30px' }} />
          </div>

          <Text variant='h1' color={'#7165E3'} mb={10} fontSize={'1.5rem'}>
            جارِ التحقق
          </Text>

          <CircularProgress isIndeterminate color='#7165E3'></CircularProgress>
        </Box>
      )}

      {!verifyLoading && verificationErrors && (
        <Box
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>
            <img src={redirectingIssueSrc} width={200} height={200} style={{ marginBottom: '30px' }} />
          </div>
          <Text variant='h1' color={'#000'} mb={10} fontSize={'1.5rem'}>
            رابط التحقق غير صحيح، ...... جارِ إعادة التوجيه
          </Text>
          <CircularProgress isIndeterminate color='#E57373'></CircularProgress>
        </Box>
      )}

      {verified && (
        <form onSubmit={handleSubmit(sendResetPassword)}>
          <Box
            sx={{
              width: '28rem',
              backgroundColor: 'rgb(255 255 255)',
              borderRadius: '0.5rem',
              boxShadow: '0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)',
              padding: '2rem',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text fontSize='1.5rem' fontWeight={400} color='rgb(55 65 81)' lineHeight='2rem' fontFamily='Segoe UI' style={{ marginBottom: '0.5rem' }}>
              إنشاء كلمة مرور جديدة ل
            </Text>
            <Text fontSize='1.5rem' fontWeight={600} color='rgb(55 65 81)' lineHeight='2rem' fontFamily='Segoe UI' style={{ marginBottom: '1.5rem' }}>
              {email}
            </Text>

            <FormControl id='password' isInvalid={!!errors.password}>
              <FormLabel>{dictionary().PASSWORD}</FormLabel>
              <PasswordInput
                inputProps={{
                  ...register('password', {
                    required: 'required',
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z])(?!.*\s).{8,}$/,
                      message: dictionary().WEAK_PASSWORD,
                    },
                  }),
                  isInvalid: !!errors.password,
                  autoComplete: 'password',
                  id: 'password',
                  placeholder: 'أدخل كلمة مرور جديدة',
                  style: {
                    background: 'rgb(249 250 251)',
                    marginBottom: '1.5rem',
                  },
                }}
              />
              <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id='password_confirmed' isInvalid={!!errors.password_confirmed} mb={10}>
              <FormLabel>{dictionary().PASSWORD_CONFIRMATION}</FormLabel>
              <PasswordInput
                inputProps={{
                  ...register('password_confirmed', {
                    required: 'required',
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z])(?!.*\s).{8,}$/,
                      message: dictionary().WEAK_PASSWORD,
                    },
                    validate: (value) => {
                      if (getValues('password') !== value) {
                        return 'كلمات المرور غير متطابقة'
                      }
                    },
                  }),
                  isInvalid: !!errors.password_confirmed,
                  autoComplete: 'password_confirmed',
                  id: 'password_confirmed',
                  placeholder: 'أدخل كلمة مرور جديدة',
                  style: {
                    background: 'rgb(249 250 251)',
                    marginBottom: '1.5rem',
                  },
                }}
              />
              <FormErrorMessage>{errors?.password_confirmed?.message}</FormErrorMessage>
            </FormControl>

            <Button type='submit' disabled={resetMailSent} style={{ textTransform: 'none' }} colorScheme='blue' _hover={{ bg: 'blue.500' }}>
              {resetMailSent ? (
                <Text>
                  تم تعيين كلمة مرور جديدة بنجاح <CircularProgress style={{ color: '#fff', marginLeft: '20px' }} size={15} />
                </Text>
              ) : (
                <Text>تعيين كلمة المرور الجديدة</Text>
              )}
            </Button>
            {!!error ? (
              <Text color='red' style={{ marginTop: '30px' }}>
                {error?.data?.message?.[0]}
              </Text>
            ) : (
              <></>
            )}
          </Box>
        </form>
      )}
    </div>
  )
}

export default NewPassword
