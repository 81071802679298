import { CheckIcon } from '@chakra-ui/icons'
import { Button, Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { ReactElement, useEffect, useRef, useState } from 'react'

type CustomSelectProps = {
  placeholder: string
  options: any
  onChange: (value: any) => void
  value?: any
}

export const CustomSelect = ({ placeholder, options, onChange, value }: CustomSelectProps): ReactElement => {
  const [selectedValues, setSelectedValues] = useState<string[]>(value)

  const buttonRef = useRef<HTMLButtonElement>(null)
  const [buttonWidth, setButtonWidth] = useState(0)

  useEffect(() => {
    // Update the width of the MenuList based on the MenuButton's width
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth)
    }
  }, [])

  const handleSelect = (value: string): void => {
    const currentIndex = selectedValues.indexOf(value)
    const newSelectedValues = [...selectedValues]

    if (currentIndex === -1) {
      newSelectedValues.push(value)
    } else {
      newSelectedValues.splice(currentIndex, 1)
    }

    setSelectedValues(newSelectedValues)
    onChange(newSelectedValues)
  }

  const isSelected = (value: string): boolean => selectedValues.includes(value)

  const getDisplayValue = (): string => {
    if (!selectedValues.length) return placeholder
    const selectedOptions = options.filter((option: any) => selectedValues.includes(option.value))
    return selectedOptions.map((option: any) => option.label).join(' , ')
  }
  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={Button} ref={buttonRef} width='full' minWidth={buttonWidth + 'px'} sx={{ overflow: 'scroll' }}>
        {getDisplayValue()}
      </MenuButton>
      <MenuList minWidth={buttonWidth + 'px'}>
        {options.map((option: any) => (
          <MenuItem key={option.value} onClick={() => handleSelect(option.value)} _hover={{ bg: 'blue.100' }}>
            {isSelected(option.value) && <Icon as={CheckIcon} color='primary.500' ml={2} />}
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
