import { useRef } from 'react'

/**
 * Retrieves the values of various fields using the watch function.
 *
 * @param watch - The watch function provided by the form library.
 * @returns An object containing the values of the watched fields.
 */
const getWatchers = (watch: any) => {
  // Retrieve and store the value of the 'corporate_id' field
  const corporateId = useRef([])
  corporateId.current = watch('corporate_id', '')

  // Retrieve and store the value of the 'vehicle_id' field
  const vehicleId = useRef({})
  vehicleId.current = watch('vehicle_id', '')

  // Retrieve and store the value of the 'station_provider_id' field
  const stationProviderId = useRef([])
  stationProviderId.current = watch('station_provider_id', '')

  // Retrieve and store the value of the 'station_id' field
  const stationId = useRef([])
  stationId.current = watch('station_id', '')

  // Retrieve and store the value of the 'station_staff_id' field
  const stationStaffId = useRef({})
  stationStaffId.current = watch('station_staff_id', '')

  // Retrieve and store the value of the 'fuel_type_id' field
  const fuelTypeId = useRef([])
  fuelTypeId.current = watch('fuel_type_id', [])

  // Retrieve and store the value of the 'is_external' field
  const isExternal = useRef({})
  isExternal.current = watch('is_external', '')

  // Retrieve and store the value of the 'driver_id' field
  const driver_id = useRef({})
  driver_id.current = watch('driver_id', '')

  // Retrieve and store the value of the 'driver_id' field
  const comment = useRef('')
  comment.current = watch('comment', '')

  // Return an object containing the retrieved field values
  return {
    corporateId,
    vehicleId,
    stationProviderId,
    stationId,
    stationStaffId,
    fuelTypeId,
    isExternal,
    driver_id,
    comment,
  }
}

export default getWatchers
