import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'
import dictionary from '../../../dictionary'
import formatAsCurrency from '../../../helpers/formatAsCurrency'

interface Props {
  trigger: any
  handleRefund: () => void
  handleCancel: () => void
  isLoading: boolean
  data?: any
  width: string
  isDataFetched?: any
  setIsDataFetched?: any
}

const RefundConfirmationModel: React.FC<Props> = ({ trigger, handleRefund, handleCancel, isLoading, data, width, isDataFetched, setIsDataFetched }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)

  const handleOpen = async () => {
    setIsDataFetched(false)
    ;(await trigger()) && onOpen()
  }
  const handleClose = async () => {
    handleCancel()
    onClose()
  }
  const handleComfirmation = () => {
    handleRefund()
  }

  useEffect(() => {
    isDataFetched && onClose()
  }, [isDataFetched])

  return (
    <>
      <Button onClick={handleOpen} colorScheme='blue' variant={'solid'} marginTop={8} marginRight={5} w={width}>
        {dictionary().REFUND_IT}
      </Button>
      <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
        <AlertDialogOverlay />

        <AlertDialogContent dir='rtl'>
          <AlertDialogHeader>
            {dictionary().ARE_YOU_SURE_YOU_WANT_TO} {dictionary().REFUND_IT}
          </AlertDialogHeader>
          <AlertDialogBody>
            <b>
              {dictionary().AMOUNT} {formatAsCurrency(data?.total_amount)}
            </b>
            <br />
            {data?.total_correction !== undefined && (
              <b>
                {dictionary().CORRECTION_VALUE} {formatAsCurrency(data?.total_correction)}
              </b>
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleClose}>
              {dictionary().CANCEL}
            </Button>
            <Button mr='3' colorScheme='blue' disabled={isLoading} variant={'solid'} onClick={handleComfirmation}>
              {isLoading ? <Spinner /> : dictionary().CONFIRM_IT}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default RefundConfirmationModel
