import dictionary from '../../dictionary'
import formateDate from '../../helpers/formateDate'
import { VERSION_STATUS } from '../VERSION_STATUS'

export const CHARGING_PROFILE_CUSTOM_RENDERS = {
  type: (value: string): string => {
    const upperCaseValue = value?.toUpperCase() as keyof typeof dictionary
    return upperCaseValue ? dictionary()[upperCaseValue] : '-'
  },
  status: (value: string): string => {
    switch (value) {
      case VERSION_STATUS.CREATED:
        return dictionary().REVIEW
      case VERSION_STATUS.REVIEWED:
        return dictionary().CONFIRM
      case VERSION_STATUS.CONFIRMED:
        return dictionary().SIGNE_OFF
      case VERSION_STATUS.SIGNED_OFF:
        return dictionary().INACTIVE
      case VERSION_STATUS.ACTIVE:
        return dictionary().ACTIVE
      case VERSION_STATUS.EXPIRED:
        return dictionary().EXPIRED
      case VERSION_STATUS.REJECTED:
        return dictionary().REJECTED
      default:
        return '-'
    }
  },
  starts_at: (value: string): string => {
    return formateDate(value)
  },
  expires_at: (value: string): string => {
    return formateDate(value)
  },
  tips_type: (value: string): string => {
    return value.toLowerCase() === 'percentage' ? dictionary().PERCENTAGE : dictionary().PAISTRES
  },
  billing_period: (value: string): string => {
    return value === 'MONTHLY' ? dictionary().MONTHLY : value === 'WEEKLY' ? dictionary().WEEKLY : dictionary().BIWEEKLY
  },
}
