import React, { useState, useCallback } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button } from '@chakra-ui/react'

const useConfirmationModal = (onConfirmCallback: () => void) => {
  const [isOpen, setIsOpen] = useState(false)

  const [type, setType] = useState<string | null>(null)

  const openModal = useCallback((type = null) => {
    setType(type ?? null)
    setIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setType(null)
    setContent(null)
    setIsOpen(false)
  }, [])

  const onConfirm = useCallback(() => {
    onConfirmCallback()
    closeModal()
  }, [onConfirmCallback, closeModal])

  const [content, setContent] = useState<JSX.Element | null>(null)

  // Modal Component adjusted for RTL content
  const ConfirmationModal = ({ title = 'تأكيد الإجراء', body = 'هل أنت متأكد أنك تريد المتابعة؟' }) => (
    <Modal isOpen={isOpen} onClose={closeModal} closeOnOverlayClick={false} isCentered>
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
      <ModalContent dir='rtl'>
        <ModalHeader>{title}</ModalHeader>
        {/* Adjust the close button position for RTL */}
        <ModalCloseButton sx={{ display: 'none' }} />
        <ModalBody>{content ? content : body}</ModalBody>
        <ModalFooter>
          <Button colorScheme='gray' ml={3} onClick={closeModal}>
            إلغاء
          </Button>
          <Button colorScheme={type === 'danger' ? 'red' : 'blue'} onClick={onConfirm}>
            تأكيد
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )

  return { openModal, ConfirmationModal, setContent, closeModal }
}

export default useConfirmationModal
