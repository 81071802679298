import { ROLES } from './ROLES'

export const getMustSearch = (role: string): any => {
  switch (role) {
    case ROLES.ACCOUNT_MANAGER:
      return true
    default:
      return false
  }
}
