import { Flex, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, Select, useToast } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import Card from '../../components/core/Card'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Reqiured from '../../components/core/reqiured'
import SaveButton from '../../components/core/SaveButton'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'

const roles = [
  {
    id: 1,
    name: 'Super',
    value: 'SUPER',
  },
  {
    id: 2,
    name: 'Manager',
    value: 'MANAGER',
  },
  {
    id: 2,
    name: 'Account Manager',
    value: 'ACCOUNT_MANAGER',
  },
  {
    id: 3,
    name: 'Operations',
    value: 'OPERATIONS',
  },
  {
    id: 4,
    name: 'Analytics',
    value: 'ANALYTICS',
  },
  {
    id: 5,
    name: 'Accountant',
    value: 'ACCOUNTANT',
  },
  {
    id: 6,
    name: 'Data Entry',
    value: 'DATA_ENTRY',
  },
  {
    id: 7,
    name: 'Attendant',
    value: 'ATTENDANT',
  },
  {
    id: 8,
    name: 'Sales',
    value: 'SALES',
  },
  {
    id: 9,
    name: 'Sales Ops',
    value: 'SALES_OPS',
  },
  {
    id: 10,
    name: 'Fin Ops',
    value: 'FIN_OPS',
  },
]

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/

const AddAdmin: React.FC = () => {
  const toast = useToast()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess } = useAlert()
  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/admin', { data: vars }), {
    onSuccess: (): void => {
      push('/admins')
      onSuccess()
    },
    onError: (error: any) => {
      toast({
        containerStyle: {
          direction: 'rtl',
        },
        title: dictionary().ERROR,
        description: error?.data?.message,
        status: 'error',
        duration: 3000,
        position: 'top',
      })
    },
  })

  const addAdmin = async (formData: any) => {
    delete formData.confirmedPass
    formData.uses_two_factor = false
    mutate([{ ...formData, username: formData?.email, email: formData?.email.trim().toLowerCase() }])
  }

  return (
    <Container>
      <Flex ref={headerRef} flexDir='column' className='margin-150'>
        <Header title={dictionary().ADD_ADMIN} action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && addAdmin(getValues())} />} />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(addAdmin)}>
          <Flex className='margin-100' justify='center' align='center' direction='column'>
            <FormControl id={dictionary().NAME_AR} w='70%' display='inline-block' isInvalid={!!errors?.name?.ar}>
              <FormLabel>
                {dictionary().NAME_AR} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().NAME_AR}
                  type='text'
                  {...register('name.ar', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.ar?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().NAME_EN} w='70%' display='inline-block' isInvalid={!!errors?.name?.en}>
              <FormLabel>
                {dictionary().NAME_EN} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().NAME_EN}
                  type='text'
                  {...register('name.en', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.en?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id='email' w='70%' isInvalid={!!errors.email || (error as any)?.data?.message === 'this email is already exist'}>
              <FormLabel>
                {dictionary().EMAIL} <Reqiured />
              </FormLabel>
              <Input
                data-test={dictionary().EMAIL}
                type='email'
                {...register('email', {
                  required: dictionary().REQUIRED,
                  pattern: {
                    value: emailRegex,
                    message: dictionary().INVALID_EMAIL,
                  },
                })}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              <FormErrorMessage>
                {(error as any)?.data?.message === 'this username is already exist' && dictionary().THIS_USER_NAME_IS_ALREADY_TAKEN}
              </FormErrorMessage>
            </FormControl>
            {/* <PasswordAndConfirmation {...{ register, errors, watch, width: '70%' }} /> */}
            <FormControl id={dictionary().ROLE} w='70%' isInvalid={!!errors.role}>
              <FormLabel>
                {dictionary().ROLE} <Reqiured />
              </FormLabel>
              <Select
                data-test={dictionary().ROLE}
                dir='ltr'
                textAlign='right'
                {...register('role', { required: dictionary().REQUIRED })}
                placeholder={dictionary().ROLE}
              >
                {roles.map((item: any) => (
                  <option key={item.id} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.role?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddAdmin
