import { Icon, Th, Thead, Tr } from '@chakra-ui/react'
import { FaAngleDoubleDown, FaAngleDoubleUp } from 'react-icons/fa'
import { parse, stringify } from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { TableHeadProps, TableHeader } from '../../../interfaces/tableProps'

export const TableHead: React.FC<TableHeadProps> = ({ headers }) => {
  const { search, pathname } = useLocation()
  const { push } = useHistory()
  const query = parse(search, { parseNumbers: true, parseBooleans: true })
  const [isSortDesc, setIsSortDesc] = useState(true)
  const isDuplicatePath = pathname === '/duplicate-transactions'

  const isDuplicateTable = (header: TableHeader) => {
    const isDuplicate = header.key === 'original_transaction_id' && pathname === '/duplicate-transactions' && header.id === 'duplicate_transaction_id'
    return isDuplicate
  }

  const handleDesc = () => {
    query.sort_column = isDuplicatePath ? 'id' : 'fuel-transaction.id'
    query.sort_direction = 'DESC'
    push({ search: stringify(query) })
  }
  const handleAsc = () => {
    query.sort_column = isDuplicatePath ? 'id' : 'fuel-transaction.id'
    query.sort_direction = 'ASC'
    push({ search: stringify(query) })
  }

  useEffect(() => {
    query.sort_direction === 'DESC' ? setIsSortDesc(true) : setIsSortDesc(false)
  }, [query.sort_direction])

  return (
    <Thead>
      <Tr>
        {headers.map((header) => (
          <Th
            key={header.id ?? header.key}
            position='sticky'
            top='0'
            bg='gray.50'
            zIndex='9'
            isNumeric={header.isRight}
            style={{
              color: undefined,
              cursor: undefined,
              fontWeight: undefined,
              textDecoration: undefined,
              whiteSpace: 'nowrap', // Prevents text from wrapping
              width: 'auto', // Automatically adjusts width based on content
              maxWidth: '100%', // Ensures the cell grows only as much as needed
              paddingRight: '8px',
              paddingLeft: '8px',
              // border: '1px solid #e2e8f0',
              textAlign: 'center',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', textTransform: 'initial' }}>
              <>{header.name}</>
              {(header.id === 'id' || isDuplicateTable(header)) &&
                (pathname === '/fuel-transactions' || pathname === '/fuel-transactions-data-entry' || isDuplicateTable(header)) && (
                  <div style={{ cursor: 'pointer', marginTop: '6px' }}>
                    {isSortDesc ? (
                      <Icon as={FaAngleDoubleDown} boxSize={3} onClick={handleAsc} />
                    ) : (
                      <Icon as={FaAngleDoubleUp} boxSize={3} onClick={handleDesc} />
                    )}
                  </div>
                )}
            </div>
          </Th>
        ))}
      </Tr>
    </Thead>
  )
}
