import { NotAllowedIcon } from '@chakra-ui/icons'
import { Flex, Text } from '@chakra-ui/react'
import { BsExclamationLg } from 'react-icons/bs'
import { MdOutlineDone } from 'react-icons/md'
import dictionary from '../dictionary'

const getVersionStatus = (value: any) => {
  let color = '#E99F27'
  let backgroundColor = 'rgba(233, 159, 39, 0.2)'
  let label = ''
  let icon = <></>
  let width = '77px'
  switch (value) {
    case 'CREATED':
      color = 'rgba(233, 159, 39, 1)'
      backgroundColor = 'rgba(233, 159, 39, 0.2)'
      label = dictionary().REVIEW
      icon = <BsExclamationLg style={{ width: '12px', height: '12px' }} />
      width = '60px'
      break
    case 'PENDING_ADJUSTMENTS':
      color = 'rgba(43, 49, 60, 1)'
      backgroundColor = 'rgba(219, 219, 219, 1)'
      label = dictionary().PENDING_ADJUSTMENTS
      icon = <BsExclamationLg style={{ width: '12px', height: '12px' }} />
      width = '90px'
      break
    case 'REVIEW_NEEDED':
      color = 'rgba(233, 159, 39, 1)'
      backgroundColor = 'rgba(233, 159, 39, 0.2)'
      label = dictionary().REVIEW
      icon = <BsExclamationLg style={{ width: '12px', height: '12px' }} />
      width = '60px'
      break
    case 'REVIEWED':
      color = '#E9C511'
      backgroundColor = 'rgba(240, 203, 8, 0.2)'
      label = dictionary().CONFIRM
      icon = <BsExclamationLg style={{ width: '12px', height: '12px' }} />
      width = '77px'
      break
    case 'CONFIRMED':
      color = '#4C79BD'
      backgroundColor = 'rgba(76, 121, 189, 0.2)'
      label = dictionary().SIGNE_OFF
      icon = <BsExclamationLg style={{ width: '12px', height: '12px' }} />
      width = '77px'
      break
    case 'SIGNED_OFF':
      color = '#2B313C'
      backgroundColor = 'rgba(219, 219, 219, 1)'
      label = dictionary().INACTIVE
      icon = <NotAllowedIcon style={{ width: '12px', height: '12px' }} />
      width = '70px'
      break
    case 'ACTIVE':
      color = '#42B48B'
      backgroundColor = 'rgba(217, 240, 232, 1)'
      label = dictionary().ACTIVE
      icon = <MdOutlineDone style={{ width: '12px', height: '12px' }} />
      width = '77px'
      break
    case 'EXPIRED':
      color = '#2B313C'
      backgroundColor = 'rgba(219, 219, 219, 1)'
      label = dictionary().EXPIRED
      icon = <NotAllowedIcon style={{ width: '12px', height: '12px' }} />
      width = '77px'
      break
    case 'REJECTED':
      color = 'rgba(195, 69, 69, 1)'
      backgroundColor = 'rgba(234, 118, 103, 0.2)'
      label = dictionary().REJECTED
      icon = <NotAllowedIcon style={{ width: '12px', height: '12px' }} />
      width = '77px'
      break
    default:
      return <></>
  }
  return (
    <Flex
      alignItems='center'
      color={color}
      backgroundColor={backgroundColor}
      justifyContent='center'
      borderRadius='6px'
      padding='2px, 10px, 2px, 10px'
      gap='5px'
      minW={width}
      maxW={width}
    >
      {icon}
      <Text
        style={{
          fontFamily: 'Noto Kufi Arabic',
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '22.76px',
          letterSpacing: '0.03em',
          textAlign: 'left',
        }}
      >
        {value ? label : '-'}
      </Text>
    </Flex>
  )
}

export default getVersionStatus
