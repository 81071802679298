import React from 'react'
import { Link as ReactLink } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'

import dictionary from '../../dictionary'

interface Props {
  to: string
}

const AddNewButton: React.FC<Props> = ({ to }) => {
  return (
    <Button as={ReactLink} to={to} size='lg' leftIcon={<AddIcon />} background='#7165E3' color={'#fff'} h='48px'>
      {dictionary().ADD_NEW}
    </Button>
  )
}

export default AddNewButton
