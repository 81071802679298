import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'weight_cost',
    key: 'weight_cost',
    name: dictionary().WEIGHT_COST,
    type: 'cash',
  },
  {
    id: 'name',
    key: 'name.ar',
    name: dictionary().PRODUCT_NAME,
    type: 'text',
  },

  {
    id: 'product_type_date_created',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'product_type_time_created',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
]

const ProductTypes: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().PRODUCT_TYPES}
      to='/product-types/add'
      tags={[{ name: dictionary().ALL, filter: [{ name: '', value: '' }] }]}
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['product-types']}
      countArrayPath={['count']}
      restRoute={'/product-types'}
      cashingKey={CASHING_KEY.GET_PRODUCT_TYPES}
      enableIdRedirect={false}
    />
  )
}

export default ProductTypes
