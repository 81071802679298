import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import AutoCompleteInput from '../../../components/core/AutoCompleteInput'
import Required from '../../../components/core/reqiured'
import Table from '../../../components/core/Table'
import dictionary from '../../../dictionary'
import fetch from '../../../helpers/fetch'
import useAlert from '../../../helpers/useAlert'
import RefundConfirmationModel from './RefundConfirmationModel'

interface header {
  id: string
  key: string
  name: string
  type: string
}

interface Props {
  headers: header[]
  handleClose: () => void
}

const VarianceRefund: FC<Props> = ({ headers, handleClose }) => {
  const [transactionData, setTransactionData] = useState<any>({})
  const { onSuccess, onError } = useAlert()
  const { push } = useHistory()
  const [isRefundFetched, setIsRefundedFetched] = useState<boolean>(false)
  const {
    formState: { errors },
    getValues,
    trigger,
    control,
    watch,
    setValue,
    register,
  } = useForm({
    mode: 'onChange',
  })

  const transactionId = watch('transaction_id')

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', 'fuel-transactions/variance-correct?lang=ar', { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      setIsRefundedFetched(true)
      push('/fuel-transactions')
    },
    onError: () => {
      onError('')
      setIsRefundedFetched(true)
      //* handle error messages
    },
  })

  const handleRefundTransaction = () => {
    mutate({ correction: +getValues().correction, original_transaction: transactionId?.[0] })
  }

  useEffect(() => {
    if (transactionId !== undefined && transactionId?.length > 0) {
      fetch('GET', `/fuel-transactions/${transactionId}`).then((res) => setTransactionData(res))
    } else {
      setTransactionData({})
      setValue('correction', null)
      errors?.correction && (errors.correction = '' as any)
    }
  }, [transactionId])

  useEffect(() => {
    ;(error as any)?.data && ((error as any).data = null)
  }, [transactionId])

  return (
    <>
      <FormControl id={dictionary().TRANSACTION_NUMBER} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.transaction_id}>
        <FormLabel>
          {dictionary().TRANSACTION_NUMBER} <Required />
          <span style={{ color: '#a9a5a5', fontSize: '12px' }}>{dictionary().ENETER_THREE_DIGITS_TO_SEARCH}</span>
        </FormLabel>
        <Controller
          name='transaction_id'
          control={control}
          render={({ field: { onChange, value } }) => (
            <AutoCompleteInput
              onChange={onChange}
              searchRoute='/fuel-transactions/searchById'
              singleRoute='/fuel-transactions/searchById'
              query={{ status: 'CONFIRMED' }}
              valueFormatter={(option) => option?.id}
              displayFormatter={(option) => option?.id}
              isInvalid={!!errors?.transaction_id}
              value={value}
              pageSize={10}
              inputType='number'
              minInputLengthToSearch={3}
            />
          )}
          rules={{
            required: true && dictionary().REQUIRED,
          }}
        />
        <FormErrorMessage>{errors.transaction_id?.message}</FormErrorMessage>
      </FormControl>
      {Object.keys(transactionData).length > 0 && (
        <>
          <FormControl id={dictionary().VALUE_CORRECTION} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.correction}>
            <FormLabel>
              <>
                {dictionary().VALUE_CORRECTION}
                <Required />
              </>
            </FormLabel>

            <InputGroup>
              <Input
                type='number'
                {...register('correction', {
                  required: dictionary().REQUIRED,
                  min: {
                    value: 0.1,
                    message: dictionary().MORE_THAN_ZERO,
                  },
                  validate: (value) => value <= transactionData?.amount || dictionary().CANT_BE_MORE_THAN_THE_TRANSACTION_AMOUNT,
                })}
              />
            </InputGroup>

            <FormErrorMessage>{errors?.correction?.message}</FormErrorMessage>
          </FormControl>
          <Table raised enableIdRedirect={false} width={'70%'} headers={headers} data={[transactionData]} />
          {error && <b style={{ width: '70%', color: 'red' }}>{(error as any)?.data?.message}</b>}
          <RefundConfirmationModel
            trigger={trigger}
            handleRefund={handleRefundTransaction}
            handleCancel={handleClose}
            isLoading={isLoading}
            data={{ total_amount: getValues().correction }}
            width={'70%'}
            isDataFetched={isRefundFetched}
            setIsDataFetched={setIsRefundedFetched}
          />
        </>
      )}
    </>
  )
}

export default VarianceRefund
