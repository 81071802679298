import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'st_provider_name',
    key: 'name.ar',
    name: dictionary().STATION_PROVIDERS_NAME,
    type: 'text',
  },
  {
    id: 'st_provider_stations',
    key: 'station_count',
    name: dictionary().STATION_COUNT,
    type: 'text',
  },
  {
    id: 'fee_rate',
    key: 'fee_rate',
    name: dictionary().COMMISSION_FEE,
    type: 'text',
  },
  {
    id: 'fee_percentage_rate',
    key: 'fee_percentage_rate',
    name: dictionary().FEE_PERCENTAGE_RATE,
    type: 'text',
  },
  {
    id: 'st_provider_contact_person_name',
    key: 'contact_person.name',
    name: dictionary().STATIONS_CONTACT_NAME,
    type: 'text',
  },
  {
    id: 'st_provider_contact_person_phone',
    key: 'contact_person.phone',
    name: dictionary().STATIONS_CONTACT_NUMBER,
    type: 'text',
  },
  {
    id: 'st_provider_date',
    key: 'created_at',
    name: dictionary().CREATED_AT,
    type: 'date',
  },
  {
    id: 'st_provider_balance',
    key: 'ledger_accounts',
    name: dictionary().DRIVER_BALANCE,
    type: 'Cash_balance',
    isRight: true,
  },
]

const StationProviders: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().STATION_PROVIDERS}
      to='/station-providers/new'
      // tags={[
      //   { name: dictionary().ALL, filter: [{ name: '', value: '' }] },
      //   { name: dictionary().IS_ACTIVE, filter: [{ name: 'is_active', value: true }] },
      //   { name: dictionary().NOT_ACTIVE, filter: [{ name: 'is_active', value: false }] },
      // ]}
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['station-providers']}
      countArrayPath={['count']}
      restRoute={'/station-providers'}
      cashingKey={CASHING_KEY.GET_STATION_PROVIDERS}
    />
  )
}

export default StationProviders
