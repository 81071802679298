import React from 'react'
import { Flex, GridItem, Link } from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router-dom'
import { CASHING_KEY } from '../../../constants'
import { SummaryItem } from '../../../pages/stats/Summary'
import dictionary from '../../../dictionary'
import formatAsCurrency from '../../../helpers/formatAsCurrency'

interface BalanceScreenProps {
  cashingKey: string
  fuelSelected: string
  storeSelected: string
  tollSelected: string
  ledgerAccount: {
    cash_id: number
    fuel_id: number
    store_id: number
    toll_id: number
    name: string
    cash_balance: number
    fuel_balance: number
    store_balance: number
    toll_balance: number
  }
}

const BalanceScreen: React.FC<BalanceScreenProps> = ({ cashingKey, ledgerAccount, fuelSelected, storeSelected, tollSelected }) => {
  const { replace, go } = useHistory()
  const { userType, id } = useParams<{ userType: string; id: string; account_id: string }>()

  return (
    <>
      {cashingKey === CASHING_KEY.GET_LEDGER_TRANSACTIONS_FOR_ID && userType === 'corporates' ? (
        <GridItem colSpan={12}>
          <Flex mt='3em' direction='row' display={['block', 'flex', 'flex', 'flex']}>
            <SummaryItem
              sty={{ backgroundColor: '#D3D3D3', color: 'black' }}
              label={dictionary().BALANCE}
              value={formatAsCurrency(ledgerAccount?.cash_balance).toString()}
            />
          </Flex>
        </GridItem>
      ) : cashingKey === CASHING_KEY.GET_LEDGER_TRANSACTIONS_FOR_ID && (userType === 'drivers' || userType === 'vehicles') ? (
        <GridItem colSpan={12}>
          <Flex mt='3em' direction='row' display={['block', 'flex', 'flex', 'flex']} gap={4}>
            <Link
              onClick={() => {
                replace(`/${userType}/${id}/ledger-transactions/${(ledgerAccount?.fuel_id).toString()}`)
                go(0)
              }}
            >
              <SummaryItem
                key={dictionary().FUEL_BALANCE}
                sty={{ backgroundColor: fuelSelected, color: 'black' }}
                label={dictionary().FUEL_BALANCE}
                value={formatAsCurrency(ledgerAccount?.fuel_balance).toString()}
              />
            </Link>
            <Link
              onClick={() => {
                replace(`/${userType}/${id}/ledger-transactions/${ledgerAccount.store_id}`)
                go(0)
              }}
            >
              <SummaryItem
                key={dictionary().STORE_BALANCE}
                sty={{ backgroundColor: storeSelected, color: 'black' }}
                label={dictionary().STORE_BALANCE}
                value={formatAsCurrency(ledgerAccount?.store_balance).toString()}
              />
            </Link>
            <Link
              onClick={() => {
                replace(`/${userType}/${id}/ledger-transactions/${(ledgerAccount?.toll_id).toString()}`)
                go(0)
              }}
            >
              <SummaryItem
                key={dictionary().TOLL_BALANCE}
                sty={{ backgroundColor: tollSelected, color: 'black' }}
                label={dictionary().TOLL_BALANCE}
                value={formatAsCurrency(ledgerAccount?.toll_balance).toString()}
              />
            </Link>
          </Flex>
        </GridItem>
      ) : (
        <></>
      )}
    </>
  )
}

export default BalanceScreen
