import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Switch,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { parse, stringify } from 'query-string'
import { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import { ROLES } from '../../constants/ROLES'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import { useStore } from '../../store'

interface Props {
  data?: any
  handleDateUpdate?: () => void
}

const EditPos: React.FC<Props> = ({ data, handleDateUpdate }) => {
  const { role } = useStore()
  const isSuperAdmin = role === ROLES.SUPER_ADMIN
  const { super_station, station } = data
  const query = parse(location.search, { parseNumbers: true, parseBooleans: true })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    handleSubmit,
    register,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    watch,
    control,
  } = useForm({ mode: 'onChange' })
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const { push } = useHistory()
  const { onSuccess } = useAlert()
  const isAllStations = watch('super_station')
  const isAssign = watch('is_assign')

  useEffect(() => {
    if (!isAssign) {
      setValue('station_id', null)
      setValue('super_station', '')
    }
  }, [isAssign])

  useEffect(() => {
    isAllStations && setValue('station_id', null)
  }, [isAllStations])

  useEffect(() => {
    if (isOpen) {
      if (super_station) {
        setValue('is_assign', true)
        setValue('super_station', 'true')
      } else if (station) {
        setValue('is_assign', true)
        setValue('super_station', 'false')
        setValue('station_id', [station?.id])
      } else {
        setValue('is_assign', false)
      }
    }
  }, [isOpen])

  const { isLoading, mutate } = useMutation((vars: any) => fetch('PATCH', `/pos-devices/${data?.id}`, { data: vars }), {
    onSuccess: (): void => {
      handleDateUpdate && handleDateUpdate()
      onSuccess()
      onClose()
    },
  })
  const editPOS = async () => {
    const formData = {
      station_id: getValues('station_id')?.[0] || null,
      super_station: getValues('super_station') === 'true' ? true : false,
    }

    mutate(formData)
  }

  return (
    <>
      <Tooltip label='تعديل' placement='top'>
        <img src='/assets/ic_edit-24px.svg' data-test={dictionary().EDIT} onClick={onOpen} style={{ cursor: 'pointer', width: '20px', height: '20px' }} />
      </Tooltip>

      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} size='2xl' isCentered>
        <ModalOverlay />
        <ModalContent dir='rtl'>
          <ModalHeader style={{ direction: 'rtl', borderBottom: '1px solid #E2E8F0' }}>{dictionary().EDIT} </ModalHeader>
          <form onSubmit={handleSubmit(editPOS as any)}>
            <ModalBody pb={6}>
              <FormControl id={dictionary().POS_ID} display='flex' gap={5} w={['100%']}>
                <FormLabel fontWeight={'bold'} fontSize={'lg'}>
                  {dictionary().POS_ID} :
                </FormLabel>
                <Box fontWeight={'bold'} fontSize={'lg'} data-test={dictionary().POS_ID}>
                  {data?.id}
                </Box>
              </FormControl>
              <FormControl id={dictionary().THE_SERIAL_NUMBER} my={5} display='flex' gap={5} w={['100%']}>
                <FormLabel fontWeight={'bold'} fontSize={'lg'}>
                  {dictionary().THE_SERIAL_NUMBER} :
                </FormLabel>
                <Box fontWeight={'bold'} fontSize={'lg'} data-test={dictionary().THE_SERIAL_NUMBER}>
                  {data?.serial_number}
                </Box>
              </FormControl>
              <FormControl id='isAssign' w={['100%']} display='flex' gap={5} alignItems='center' isInvalid={!!errors.isAssign}>
                <FormLabel fontWeight={'bold'} fontSize={'lg'}>
                  {dictionary().APPOINTMENT_STATUS}
                </FormLabel>
                <Switch id='email-alerts' {...register('is_assign')} colorScheme='purple' data-test={dictionary().APPOINTMENT_STATUS} />
                <FormErrorMessage>{errors.isAssign?.message}</FormErrorMessage>
              </FormControl>

              {isAssign && isSuperAdmin && (
                <FormControl id='super_station' w={['100%']} my={10} isInvalid={!!errors.super_station}>
                  <Controller
                    name='super_station'
                    control={control}
                    data-test={dictionary().ALL_STATIONS}
                    render={({ field }) => (
                      <RadioGroup {...field} fontWeight={'bold'} fontSize={'lg'}>
                        <Stack direction='row'>
                          <Radio value='false' color='purple' defaultChecked ml={5}>
                            {dictionary().ONE_STATION}
                          </Radio>
                          <Radio value='true' color='purple'>
                            {dictionary().ALL_STATIONS}
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                    rules={{
                      required: true && dictionary().REQUIRED,
                    }}
                  />
                  <FormErrorMessage>{errors.super_station?.message}</FormErrorMessage>
                </FormControl>
              )}

              {(isAllStations === 'false' || (isAssign && !isSuperAdmin)) && (
                <FormControl id={dictionary().DRIVER_NAME} w='100%' dir='rtl' marginTop={5} isRequired isInvalid={!!errors?.station_id}>
                  <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }} fontWeight={'bold'} fontSize={'lg'}>
                    {dictionary().STATION}
                  </FormLabel>
                  <Controller
                    name={`station_id`}
                    rules={{
                      required: true && dictionary().REQUIRED,
                    }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        isInvalid={!!errors?.station_id}
                        onChange={onChange}
                        value={value}
                        dataTest={dictionary().STATION}
                        searchRoute={`/stations`}
                        singleRoute={`/stations`}
                        valueFormatter={(option) => option?.id}
                        displayFormatter={(option) => option?.name?.ar}
                      />
                    )}
                  />
                  <FormErrorMessage>{errors?.station_id?.message}</FormErrorMessage>
                </FormControl>
              )}
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>{dictionary().CANCEL}</Button>
              <Button data-test='edit-pos-btn' colorScheme='blue' mx={2} disabled={isLoading} onClick={async () => (await trigger()) && editPOS()}>
                {isLoading ? <Spinner /> : dictionary().EDIT}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default EditPos
