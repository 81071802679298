import { useRef } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useForm } from 'react-hook-form'
import {
  Modal,
  Spinner,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  FormErrorMessage,
  InputLeftElement,
  Text,
  Box,
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import useAlert from '../../helpers/useAlert'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'
import formatAsCurrency from '../../helpers/formatAsCurrency'
import Reqiured from './reqiured'

interface Props {
  data?: any
  handleIsBalancesChange?: () => void
}

const getAccountBalance = (item: any, type: string) => {
  const account = item?.filter((accountItem: any) => accountItem.account_type == type)
  return account ? account[0]?.balance : 0
}

const RechargeBalance: React.FC<Props> = ({ data, handleIsBalancesChange }) => {
  const { id } = data
  const corporateData = window.localStorage.getItem('activeRole')
  const corporateId = corporateData && JSON.parse(corporateData)?.corporateStaff?.corporate?.id

  const { pathname } = useLocation()
  const { onSuccess, onError } = useAlert()
  const { data: currentCorporateStaff, refetch } = useQuery(['current-corporate-staff'], () => fetch('GET', `/corporate-staff/current`), {})
  const { data: totalFuelBalance, refetch: totalFuelBalanceRefetch } = useQuery(
    ['corporate-total-fuel-balance'],
    () => fetch('GET', `/corporates/${corporateId}/total-fuel-balance`),
    {},
  )
  const { data: balanceSummary, refetch: balanceSummaryRefetch } = useQuery(
    ['corporate-balance-summary'],
    () => fetch('GET', `/corporates/balance-summary`),
    {},
  )
  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/corporates/transfer-balance', { data: vars }), {
    onSuccess: (): void => {
      handleIsBalancesChange && handleIsBalancesChange()
      onSuccess()
      onClose()
    },
    onError,
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
  } = useForm({ mode: 'onChange' })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  const getUnusedBalance = () => {
    let balance = 0
    const totalWallets =
      Number(totalFuelBalance?.driverBalance?.sum ? totalFuelBalance?.driverBalance?.sum : 0) +
      Number(totalFuelBalance?.vehicleBalance?.sum ? totalFuelBalance?.vehicleBalance?.sum : 0)
    const availableCorporateBalance =
      currentCorporateStaff?.corporateStaff?.corporate?.settlement === 'POST_PAID'
        ? +currentCorporateStaff?.corporateStaff?.corporate?.credit_limit - (balanceSummary?.unbilled + balanceSummary?.due)
        : balanceSummary?.available_balance
    balance = availableCorporateBalance - totalWallets
    return balance
  }

  const rechargeBalance = async (formData: any) => {
    formData.ref_id = id
    formData.ref_type = pathname.includes('drivers') ? 'DRIVER' : 'VEHICLE'
    formData.transfer_direction = 'DEBIT'
    formData.account_type = 'FUEL'
    formData.corporateId = corporateId
    mutate(formData)
  }
  const handleOnOpen = () => {
    onOpen()
    refetch()
    totalFuelBalanceRefetch()
    balanceSummaryRefetch()
    setValue('amount', null)
    errors && delete errors.amount
  }

  return (
    <>
      <Button mb={1} onClick={handleOnOpen}>
        {dictionary().REGHARGE_BALANCE}
      </Button>
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().REGHARGE_BALANCE} </ModalHeader>
          <ModalBody pb={6}>
            <form dir='rtl' onSubmit={handleSubmit(rechargeBalance as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                <FormLabel style={{ direction: 'rtl' }}>
                  <Box display='flex'>
                    <Text p={1} mt={1}>
                      {dictionary().UNUSED_BALANCE}{' '}
                    </Text>
                    <Text p={1} bg='blue.100' color='white' rounded={50}>
                      {formatAsCurrency(getUnusedBalance())}
                    </Text>
                  </Box>
                </FormLabel>
                <FormControl
                  dir='rtl'
                  id={dictionary().REQUIRED}
                  w={['100%', '70%', '70%', '70%']}
                  display='inline-block'
                  isInvalid={
                    !!errors.amount ||
                    error?.data?.message === 'balance is less than the transactions amount' ||
                    error?.data?.message === 'this ref not found or the balance not enough'
                  }
                >
                  <FormLabel style={{ direction: 'rtl' }}>
                    <>
                      {dictionary().AMOUNT} <Reqiured />
                    </>
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement height='100%' pointerEvents='none'>
                      {dictionary().PRICING_CURRENCY}
                    </InputLeftElement>
                    <Input
                      dir='ltr'
                      disabled={getUnusedBalance() < 0}
                      textAlign='right'
                      type='number'
                      {...register('amount', {
                        required: dictionary().REQUIRED,
                        valueAsNumber: true,
                        min: {
                          value: 0,
                          message: dictionary().NO_NEGATIVE_NUMBER,
                        },
                        validate: (value) => {
                          if (value > Math.abs(getUnusedBalance())) return dictionary().AMOUNT_MUST_BE_LESS_THAN_UNUSED_BALANCE
                        },
                      })}
                    />
                  </InputGroup>

                  <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
                  {
                    <FormErrorMessage>
                      {error?.data?.message === 'balance is less than the transactions amount' && dictionary().BALANCE_IS_LESS_THAN_TRANSACTION_AMOUNT}
                      {error?.data?.message === 'this ref not found or the balance not enough' && dictionary().ACC_TYPE_NOT_FOUND}
                    </FormErrorMessage>
                  }
                </FormControl>
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button onClick={async () => (await trigger()) && rechargeBalance(getValues() as any)} colorScheme='blue' mr={3}>
              {isLoading ? <Spinner /> : dictionary().SAVE}
            </Button>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default RechargeBalance
