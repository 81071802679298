import React from 'react'
import { useHistory } from 'react-router-dom'
import { MenuItem } from '@chakra-ui/react'
import Upload from '../../components/core/Upload'
import RefillBalancesModal from '../../pages/corporates/refillBalances'
import DeleteAction from './DeleteAction'
import TransferModal from './TransferModal'
import WithdrawModal from './WithdrawModal'
import AssignDriverToVehicleModal from './AssignDriverToVehicleModal'
import ResetPinCodeModal from './ResetPinCodeModal'
import ResetPassword from './ResetPasswordModal'
import UnbilledCorporateForStationProvider from './UnbilledCorporateForStationProvider'
import AccessibleStaionProvidersModel from './AccessibleStaionProvidersModel'
import TranseferBetweenEntitiesModal from './transeferBetweenEntitiesModal'

interface ActionProps {
  apiRoute?: string
  redirectRoute?: string
  action?: any
  redirect?: any
  id?: any
  handleIsBalancesChange?: () => void
  handleIsDriverAssigned?: () => void
  handleIsCorporateSPUnbilled?: () => void
  handleIsCorporateAccessSP?: () => void
  handleIsBalanseChanged?: () => void
  data?: any
}
const Actions: React.FC<ActionProps> = ({
  id,
  redirect,
  action,
  handleIsBalancesChange,
  handleIsDriverAssigned,
  handleIsCorporateSPUnbilled,
  handleIsCorporateAccessSP,
  handleIsBalanseChanged,
  data,
}) => {
  const { replace } = useHistory()
  return (
    <>
      {action.type === 'delete' ? (
        <DeleteAction
          onSuccess={() => replace(action.redirectRoute)}
          apiMethod={'DELETE'}
          apiRoute={action.apiRoute}
          variables={{ data: [{ id: parseInt(id) }] }}
        />
      ) : action.type === 'transeferModal' ? (
        <TransferModal action={action} handleIsBalanseChanged={handleIsBalanseChanged} />
      ) : action.type === 'transeferBetweenEntitiesModal' ? (
        <TranseferBetweenEntitiesModal action={action} handleIsBalanseChanged={handleIsBalanseChanged} />
      ) : action.type === 'withdrawModal' ? (
        <WithdrawModal action={action} handleIsBalanseChanged={handleIsBalanseChanged} />
      ) : action.type === 'AssignDriverToVehicleModal' ? (
        <AssignDriverToVehicleModal action={action} handleIsDriverAssigned={handleIsDriverAssigned} />
      ) : action.type === 'unbilledModel' ? (
        <UnbilledCorporateForStationProvider action={action} handleIsCorporateSPUnbilled={handleIsCorporateSPUnbilled} />
      ) : action.type === 'AccessibleStaionProvidersModel' ? (
        <AccessibleStaionProvidersModel action={action} handleIsCorporateAccessSP={handleIsCorporateAccessSP} />
      ) : action.type === 'upload' ? (
        <Upload action={action} handleIsBalancesChange={handleIsBalancesChange} />
      ) : action.type === 'resetDriverPassword' ? (
        <ResetPassword />
      ) : action.type === 'resetPinCode' ? (
        <ResetPinCodeModal action={action} driver={data} />
      ) : action.type === 'refillVehicles' || action.type === 'refillDrivers' ? (
        <RefillBalancesModal action={action} />
      ) : (
        <MenuItem data-test={action.title} onClick={() => redirect(action.action)}>
          {action.title}
        </MenuItem>
      )}
    </>
  )
}

export default Actions
