import React, { useRef } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, Select, InputLeftElement } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'

const AddTollStationPricing: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)

  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const vehichleTypesQuery = useQuery(['vehicles'], () => fetch('GET', `/vehicle-types`), {})
  const stationsQuery = useQuery(['stations'], () => fetch('GET', `/stations`), {})
  const productTypesQuery = useQuery(['product'], () => fetch('GET', `/product-types`), {})

  const { isLoading, mutate } = useMutation((vars: any) => fetch('POST', '/toll-station-prices', { data: vars }), {
    onSuccess: (): void => {
      push('/pricing')

      onSuccess()
    },
    onError,
  })
  const addTollStationPricing = async () => {
    mutate([
      {
        rate: getValues().rate,
        product_type_id: getValues().product_type_id,
        vehicle_type_id: getValues().vehicle_type_id,
        station_id: getValues().station_id,
      },
    ] as any)
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().ADD_NEW_PRICING}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && addTollStationPricing()} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(addTollStationPricing as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl id={dictionary().RATE} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.rate}>
              <FormLabel>
                {dictionary().RATE} <Reqiured />
              </FormLabel>
              <InputGroup>
                <InputLeftElement height='100%' pointerEvents='none'>
                  {dictionary().PRICING_CURRENCY}
                </InputLeftElement>
                <Input
                  type='number'
                  {...register('rate', {
                    required: dictionary().REQUIRED,
                    valueAsNumber: true,
                    validate: (value) => (value <= 0 ? dictionary().THIS_INPUT_CANT_EQUAL_TO_ZERO : undefined),
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.rate?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id={dictionary().VEHICLE_TYPE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.vehicle_type_id}
              display='inline-block'
            >
              <FormLabel>
                {dictionary().VEHICLE_TYPE} <Reqiured />
              </FormLabel>
              <Select
                dir='ltr'
                textAlign='right'
                {...register('vehicle_type_id', {
                  required: dictionary().REQUIRED,
                  valueAsNumber: true,
                })}
                placeholder={dictionary().VEHICLE_TYPE}
              >
                {vehichleTypesQuery?.data?.data.map((vehicle: any) => (
                  <option key={vehicle?.id} value={vehicle?.id}>
                    {vehicle?.name?.ar}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors?.vehicle_type_id?.message} </FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().STATION_NAME}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.station_id}
              display='inline-block'
            >
              <FormLabel>
                {dictionary().STATION_NAME} <Reqiured />
              </FormLabel>
              <Select
                dir='ltr'
                textAlign='right'
                {...register('station_id', {
                  required: dictionary().REQUIRED,
                  valueAsNumber: true,
                })}
                placeholder={dictionary().STATION_NAME}
              >
                {stationsQuery?.data?.data.map((station: any) => (
                  <option key={station?.id} value={station?.id}>
                    {station?.name?.ar}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors?.station_id?.message} </FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().PRODUCT_TYPE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.product_type_id}
              display='inline-block'
            >
              <FormLabel>
                {dictionary().PRODUCT_TYPE} <Reqiured />
              </FormLabel>
              <Select
                dir='ltr'
                textAlign='right'
                {...register('product_type_id', {
                  required: dictionary().REQUIRED,
                  valueAsNumber: true,
                })}
                placeholder={dictionary().VEHICLE_TYPE}
              >
                {productTypesQuery?.data?.data.map((productType: any) => (
                  <option key={productType?.name?.en} value={productType?.id}>
                    {productType?.name?.ar}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors?.product_type_id?.message} </FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddTollStationPricing
