import { useRef } from 'react'

import { Modal, Box, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button, useDisclosure } from '@chakra-ui/react'

import dictionary from '../../dictionary'

interface Props {
  data?: any
  header?: any
}

const NoteModel: React.FC<Props> = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  return (
    <>
      {data?.note ? <Button onClick={onOpen}>{dictionary().VIEW_IMAGE}</Button> : <>-</>}
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().COMMENT} </ModalHeader>
          <ModalBody pb={6}>
            <Box dir='rtl'>{data?.note ? data?.note : dictionary().NO_COMMENT}</Box>
          </ModalBody>
          <ModalFooter dir='rtl'>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default NoteModel
