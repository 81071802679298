import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { GridItem, Text, Box, Stack, Flex, Spinner } from '@chakra-ui/react'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import Card from '../../components/core/Card'
import Table from '../core/Table'

const CorporateStationProvidersHeaders = [
  {
    id: 'id',
    key: 'stationProvider.id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'name',
    key: 'stationProvider.name.en',
    name: dictionary().NAME_EN,
    type: 'text',
  },
  {
    id: 'name',
    key: 'stationProvider.name.ar',
    name: dictionary().NAME_AR,
    type: 'text',
  },
]
interface Props {
  isCorporateSPUnbilled?: boolean
}

const CorporateStationProviders: React.FC<Props> = ({ isCorporateSPUnbilled }) => {
  const { id } = useParams<{ id: string }>()
  const [data, setData] = useState({ data: [] })
  const { isLoading, mutate } = useMutation(['staion_provider'], () => fetch('GET', `/corporate-station-provider-charge-config?corporateId=${id}`), {
    onSuccess: (res) => setData(res),
  })
  useEffect(() => {
    mutate()
  }, [isCorporateSPUnbilled])

  useEffect(() => {
    mutate()
  }, [])

  return (
    <>
      <Card h='100%' p={4}>
        <Text m={3} fontSize='20px'>
          {dictionary().UNBILLED_STATION_PROVIDERS}
        </Text>
        {isLoading ? (
          <Flex w='100%' h='100%' align='center' justify='center'>
            <Spinner />
          </Flex>
        ) : data?.data?.length > 0 ? (
          <GridItem colSpan={6}>
            <Box w='100%'>
              <Stack>
                <GridItem>
                  <Card h='100%'>
                    <Table raised enableIdRedirect={false} height={350} headers={CorporateStationProvidersHeaders} data={data?.data} />
                  </Card>
                </GridItem>
              </Stack>
            </Box>
          </GridItem>
        ) : (
          <Flex w='100%' h='100%' justify='center'>
            <Text fontSize='20px'>{dictionary().NO_STATION_PROVIDERS_FOR_THIS_CORPORATE}</Text>
          </Flex>
        )}
      </Card>
    </>
  )
}

export default CorporateStationProviders
