import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, FormControl, Input, Spinner } from '@chakra-ui/react'
import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import useAlert from '../../helpers/useAlert'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'

interface Props {
  driverId: any
  handleIsDriverRemoved?: () => void
}

const UnassignDriver: React.FC<Props> = ({ driverId, handleIsDriverRemoved }) => {
  const { id } = useParams<{ id: string }>()
  const {
    formState: { errors },
    setValue,
    getValues,
    register,
    trigger,
  } = useForm({ mode: 'onChange' })
  const { onSuccess, onError } = useAlert()
  const { mutate, isLoading } = useMutation((vars: any) => fetch('POST', `/vehicles/unassign-driver`, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      handleIsDriverRemoved && handleIsDriverRemoved()
    },
    onError,
  })

  useEffect(() => {
    setValue('vehicleId', id)
    setValue('driverIds', [driverId])
  }, [])
  const unAssignDriverfun = async (formData: any) => {
    mutate(formData)
  }
  return (
    <>
      <FormControl hidden={true} id={dictionary().ID} w='40%' minH='100px' display='inline-block' isInvalid={!!errors?.vehicleId}>
        <Input
          type='number'
          {...register('vehicleId', {
            required: dictionary().REQUIRED,
            valueAsNumber: true,
          })}
        />
      </FormControl>
      <Button onClick={async () => (await trigger()) && unAssignDriverfun(getValues() as any)} color={'red'} h='10'>
        {isLoading ? <Spinner color={'#7165E3'} /> : dictionary().UNASSIGN}
      </Button>
    </>
  )
}

export default UnassignDriver
