import { Box, Table } from '@chakra-ui/react'
import { FuelTransaction, FuelTransactionResponse } from '../../../types/FuelTransaction'
import OctaneTableBody from './OctaneTableBody'
import OctaneTableHeader from './OctaneTableHeader'

export class OctaneTableBuilder {
  private data: FuelTransaction[]

  private total: number

  private columns: string[] | React.ReactNode[]

  private columnsObj: string[] | React.ReactNode[]

  private specialFields: any[]

  constructor(
    data: FuelTransactionResponse,
    headers: {
      headersList: string[] | React.ReactNode[]
      headers: string[] | React.ReactNode[]
    },
  ) {
    this.data = data?.data || []
    this.total = data?.total || 0
    this.columns = headers.headers
    this.columnsObj = headers.headersList
    this.specialFields = []
    return this
  }

  addColumn(header: React.ReactNode): OctaneTableBuilder {
    const newBuilder = new OctaneTableBuilder({ data: this.data, total: this.total }, { headers: this.columns, headersList: this.columnsObj })
    newBuilder.columns = [...this.columns, header]
    return newBuilder
  }

  addSpecialFields(key: string, headerFunction: (row: any) => React.ReactNode): OctaneTableBuilder {
    this.specialFields.push({ key, headerFunction })
    return this
  }

  build(): React.ReactNode {
    const raisedProps = { borderRadius: 'lg', boxShadow: '1px', borderWidth: '1px', bg: 'white' }
    return (
      <Box maxH={'75vh'} overflowY='auto' {...raisedProps}>
        <Table variant='striped' id='transactions-table'>
          <OctaneTableHeader headers={this.columns} />
          <OctaneTableBody headersList={this.columnsObj} data={this.data} specialFields={this.specialFields} />
        </Table>{' '}
      </Box>
    )
  }
}
