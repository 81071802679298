import fetch from '../../../helpers/fetch'
import { StateManagementVariables } from '../../../types/AddFuelTransactionVariables'
type updateisExternalParams = {
  isExternal: any
  stateManager: StateManagementVariables
  setStateManager: any
  setValue: any
}
const updateisExternal = ({ isExternal, stateManager, setStateManager, setValue }: updateisExternalParams) => {
  // Check if isExternal.current is a string
  if (typeof isExternal.current === 'string') {
    // Set isExternalSelected to true
    setStateManager({ ...stateManager, isExternalSelected: true })

    // Check if isExternal.current is 'true'
    if (isExternal.current === 'true') {
      // Reset values for selected inputs

      setValue('station_provider_id', [])
      setValue('station_id', [])
      setValue('station_staff_id', '')
      setValue('fuel_type_id', [])
      setValue('amount', '')
    } else {
      // Reset values for other inputs
      setValue('odometer_reading', '')
      setValue('driver_id', '')
      setValue('external_station_name', '')
    }
  } else {
    // Set isExternalSelected to false
    setStateManager({ ...stateManager, isExternalSelected: false })
  }
}

export default updateisExternal
