import React, { useEffect, useRef } from 'react'
import { Flex, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, Radio, RadioGroup, Select, Stack, useToast } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'
import PasswordAndConfirmation from '../../components/core/PasswordAndConfirmation'

const roles = [
  {
    id: 1,
    name: 'Super',
    value: 'SUPER',
  },
  {
    id: 2,
    name: 'Manager',
    value: 'MANAGER',
  },
  {
    id: 2,
    name: 'Account Manager',
    value: 'ACCOUNT_MANAGER',
  },
  {
    id: 3,
    name: 'Operations',
    value: 'OPERATIONS',
  },
  {
    id: 4,
    name: 'Analytics',
    value: 'ANALYTICS',
  },
  {
    id: 5,
    name: 'Accountant',
    value: 'ACCOUNTANT',
  },
  {
    id: 6,
    name: 'Data Entry',
    value: 'DATA_ENTRY',
  },
  {
    id: 7,
    name: 'Attendant',
    value: 'ATTENDANT',
  },
  {
    id: 8,
    name: 'Sales',
    value: 'SALES',
  },
  {
    id: 9,
    name: 'Sales Ops',
    value: 'SALES_OPS',
  },
  {
    id: 10,
    name: 'Fin Ops',
    value: 'FIN_OPS',
  },
]

const EditAdmin: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const toast = useToast()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    watch,
    setValue,
    control,
  } = useForm({ mode: 'onChange' })

  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess } = useAlert()

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('PATCH', '/admin', { data: vars }), {
    onSuccess: (): void => {
      push('/admins')
      onSuccess()
    },
    onError: (error: any) => {
      toast({
        containerStyle: {
          direction: 'rtl',
        },
        title: dictionary().ERROR,
        description: error?.data?.message,
        status: 'error',
        duration: 3000,
        position: 'top',
      })
    },
  })

  const query = useQuery(['drivers', id], () => fetch('GET', `/admin/${id}`), {})

  useEffect(() => {
    setValue('email', query?.data?.email || 'لا يوجد بريد إلكتروني مرتبط بهذا الحساب')
    setValue('username', query?.data?.username)
    setValue('name.ar', query?.data?.name.ar)
    setValue('name.en', query?.data?.name.en)
    setValue('role', query?.data?.role)
    setValue('active', query?.data?.active ? 'true' : 'false')
  }, [query?.data])

  const editAdmin = async (formData: any) => {
    delete formData.email
    formData.active = formData.active === 'true' ? true : false
    mutate([{ id: +id, ...formData }])
  }

  return (
    <Container>
      <Flex ref={headerRef} flexDir='column' className='margin-150'>
        <Header title={'تعديل مدير'} action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && editAdmin(getValues())} />} />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(editAdmin)}>
          <Flex className='margin-100' justify='center' align='center' direction='column'>
            <FormControl id={dictionary().EMAIL} w='70%' display='inline-block' isInvalid={!!errors?.email}>
              <FormLabel>
                {dictionary().EMAIL} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  disabled
                  sx={{ border: '1px solid #9E9E9E !important', borderWidth: '1px !important', backgroundColor: '#ddd' }}
                  {...register('email', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().NAME_AR} w='70%' display='inline-block' isInvalid={!!errors?.name?.ar}>
              <FormLabel>
                {dictionary().NAME_AR} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('name.ar', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.ar?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().NAME_EN} w='70%' display='inline-block' isInvalid={!!errors?.name?.en}>
              <FormLabel>
                {dictionary().NAME_EN} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  {...register('name.en', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.en?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id='username' w='70%' isInvalid={!!errors.username || (error as any)?.data?.message === 'this username is already exist'}>
              <FormLabel>
                {dictionary().USERNAME} <Reqiured />
              </FormLabel>
              <Input
                type='text'
                {...register('username', {
                  required: dictionary().REQUIRED,
                  validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                })}
              />
              <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
              <FormErrorMessage>
                {(error as any)?.data?.message === 'this username is already exist' && dictionary().THIS_USER_NAME_IS_ALREADY_TAKEN}
              </FormErrorMessage>
            </FormControl>
            {/* <PasswordAndConfirmation {...{ register, errors, watch, width: '70%' }} /> */}
            <FormControl id={dictionary().ROLE} w='70%' isInvalid={!!errors.role}>
              <FormLabel>
                {dictionary().ROLE} <Reqiured />
              </FormLabel>
              <Select dir='ltr' textAlign='right' {...register('role', { required: dictionary().REQUIRED })} placeholder={dictionary().ROLE}>
                {roles.map((item: any) => (
                  <option key={item.id} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.role?.message}</FormErrorMessage>
            </FormControl>

            <FormControl w='70%' minH='100px' display='inline-block' isInvalid={!!errors.active} mt={1}>
              <FormLabel>
                حالة التفعيل <Reqiured />
              </FormLabel>
              <Controller
                name='active'
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction='row'>
                      <Radio value='true' color='purple'>
                        {dictionary().IS_ACTIVE}
                      </Radio>
                      <Radio value='false' color='purple'>
                        {dictionary().NOT_ACTIVE}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
                rules={{
                  required: true && dictionary().REQUIRED,
                }}
              />
              <FormErrorMessage>{errors.active?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default EditAdmin
