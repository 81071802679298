import { FC } from 'react'
import { Th } from '@chakra-ui/react'

type OctaneHeaderProps = { value: string | number; style?: React.CSSProperties }

const OctaneHeader: FC<OctaneHeaderProps> = ({ value, style }) => {
  return (
    <Th
      position='sticky'
      top='0'
      bg='gray.50'
      zIndex='9'
      style={{ ...style, textAlign: 'center', direction: 'rtl', minWidth: 'max-content', width: 'max-content' }}
    >
      {value}
    </Th>
  )
}

export default OctaneHeader
