import { useMutation } from 'react-query'

import { VERSION_STATUS, VERSION_STATUS_TYPE } from '../../constants/VERSION_STATUS'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import { useHistory } from 'react-router-dom'

interface UseProfileMutationsProps {
  selectedVersionId: string
  getValues: any
  getFormValues: (key: string) => any
}

export function useProfileMutations({ selectedVersionId, getValues, getFormValues }: UseProfileMutationsProps) {
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()

  const { mutate: changeVersionStatus } = useMutation(
    (status: VERSION_STATUS_TYPE) =>
      fetch('PATCH', `charging-profile/${selectedVersionId}/move-to/${status}`, {
        data: getValues,
      }),
    {
      onSuccess: () => {
        // You might want to call a function to refetch profile data or show a success message
        onSuccess('تم تحديث حالة هذه النسخة بنجاح.')
        push('/charging-profiles')
      },
      onError: (error: any) => {
        // Handle errors, possibly showing a message to the user
        console.error('Error changing profile status:', error)
        onError('فشل في تحديث حالة هذه النسخة.')
      },
    },
  )

  const { mutate: extendPeriod } = useMutation(
    () => {
      const extend_days = getFormValues('trial_duration_in_days')
      return fetch('PATCH', `charging-profile/${selectedVersionId}/extend-trial`, {
        data: { extend_days },
      })
    },
    {
      onSuccess: () => {
        onSuccess('تم تمديد الفترة التجريبية بنجاح.')
        push('/charging-profiles')
      },
      onError: (error: any) => {
        console.error('Error extending trial period:', error)
        onError('فشل في تمديد الفترة التجريبية.')
      },
    },
  )

  const { mutate: stopVersion } = useMutation(() => fetch('PATCH', `charging-profile/${selectedVersionId}/stop-trial`), {
    onSuccess: () => {
      onSuccess('تم إيقاف التجربة بنجاح.')
      push('/charging-profiles')
    },
    onError: (error: any) => {
      console.error('Error stopping trial:', error)
      onError('فشل في إيقاف التجربة.')
    },
  })

  const { mutate: needAdjustment } = useMutation(
    (adjustmentComment: string) =>
      fetch('PATCH', `charging-profile/${selectedVersionId}/adjustment-needed`, {
        data: { comment: adjustmentComment },
      }),
    {
      onSuccess: () => {
        onSuccess('تم تحديث حالة هذه النسخة بنجاح.')
        push('/charging-profiles')
      },
      onError: (error: any) => {
        console.error('Error changing profile status:', error)
        onError('فشل في تحديث حالة هذه النسخة.')
      },
    },
  )

  const { mutate: rejectChargingProfile } = useMutation(
    (rejectReason: string) =>
      fetch('PATCH', `charging-profile/${selectedVersionId}/move-to/REJECTED`, {
        data: { rejection_comment: rejectReason },
      }),
    {
      onSuccess: () => {
        onSuccess('تم رفض  هذه النسخة بنجاح.')
        push('/charging-profiles')
      },
      onError: (error: any) => {
        console.error('Error rejecting profile status:', error)
        onError('فشل في رفض  هذه النسخة.')
      },
    },
  )

  const { mutate: deActivateProfile } = useMutation(() => fetch('DELETE', `charging-profile/${selectedVersionId}/deactivate`), {
    onSuccess: () => {
      onSuccess('تم إلغاء تفعيل هذه النسخة بنجاح.')
      push('/charging-profiles')
    },
    onError: (error: any) => {
      console.error('Error deactivating profile:', error)
      onError('فشل في إلغاء تفعيل هذه النسخة.')
    },
  })

  return { changeVersionStatus, extendPeriod, stopVersion, deActivateProfile, needAdjustment, rejectChargingProfile }
}
