import React from 'react'
import { useMutation } from 'react-query'
import { useStore } from '../store'
import { useHistory } from 'react-router-dom'
import fetch from '../helpers/fetch'

const useAuth = () => {
  const { setToken, userType, setUserType } = useStore((state) => state)

  const { push } = useHistory()
  const { isLoading, mutate } = useMutation(() => fetch('POST', `/admin/sign-out`), {
    onSuccess: (): void => {
      setToken(null)
      setToken(null)
      setUserType(null)
      localStorage.clear()
      push('/')
    },
    onError: (error: any): void => {
      console.log(error)
      alert('Something went wrong. Please try again later.')
    },
  })

  return {
    isLoading,
    signOut: () => {
      mutate()
    },
  }
}

export default useAuth
