import moment from 'moment'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const formateDate = (date: string) => {
  const momentDate = moment(new Date(date as any))
    .tz('Africa/Cairo')
    .format('DD/MM/YYYY')
  return momentDate
}

export default formateDate
