import React from 'react'
import { Button, Spinner, Text } from '@chakra-ui/react'

import dictionary from '../../dictionary'

const CancelButton: React.FC<{ label?: string; isLoading: boolean; variant?: string; height?: string; onClick: () => void }> = ({
  isLoading,
  onClick,
  variant,
  height,
  label,
}) => {
  return (
    <Button
      isLoading={isLoading}
      onClick={onClick}
      //   colorScheme='blue'
      variant={variant ?? 'outlined'}
      backgroundColor={'white'}
      w='70px'
      border={'rgba(208, 213, 221, 1) 1px solid'}
      fontSize='lg'
      letterSpacing='widest'
      h={height ?? '40px'}
    >
      <Text
        style={{
          fontFamily: 'Noto Kufi Arabic',
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '20px',
          textAlign: 'left',
        }}
      >
        {isLoading ? <Spinner /> : label ? label : dictionary().CANCEL}
      </Text>
    </Button>
  )
}

export default CancelButton
