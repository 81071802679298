import React from 'react'

import ViewOne from '../../components/layout/ViewOne'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const header = [
  {
    id: 'name',
    key: 'name.ar',
    name: dictionary().FUEL_NAME,
    type: 'text',
  },
  {
    id: 'price',
    key: 'price',
    name: dictionary().PRICE,
    type: 'cash',
  },
  {
    id: 'date',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
]
const actions = [
  {
    type: 'path',
    action: 'edit',
    title: dictionary().EDIT,
  },

  {
    type: 'delete',
    apiRoute: `fuel-types`,
    redirectRoute: '/fuel-types',
    title: dictionary().DELETE,
  },
]

const FuelPage: React.FC = () => {
  return (
    <ViewOne
      actions={actions}
      header={header}
      restRoute='/fuel-types'
      dataLocation={'data'}
      title={dictionary().FUEL_TYPES}
      cashingKey={CASHING_KEY.GET_SINGLE_FUEL}
    />
  )
}

export default FuelPage
