import React from 'react'
import { motion } from 'framer-motion'

interface TableLinkProps {
  x?: string
}

const TranslateXOnHover: React.FC<TableLinkProps> = ({ children, x }) => {
  return <motion.div whileHover={{ translateX: x ?? '5px' }}>{children}</motion.div>
}

export default TranslateXOnHover
