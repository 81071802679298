import { useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { Modal, Spinner, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button, useDisclosure, Flex, MenuItem } from '@chakra-ui/react'
import { useLocation, useParams } from 'react-router-dom'
import useAlert from '../../helpers/useAlert'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'
import PasswordAndConfirmation from './PasswordAndConfirmation'

interface Props {
  data?: any
}

const ResetPassword: React.FC<Props> = ({ data }) => {
  const [resetMailSent, setResetMailSent] = useState(false)

  const { onSuccess, onError } = useAlert()
  const { pathname } = useLocation()
  let route = ''
  let staffId = data?.id
  if (pathname.includes('station-providers')) {
    route = 'station-provider-staff'
  } else if (pathname.includes('corporates')) {
    route = 'corporate-staff'
  } else if (pathname.includes('corporate-group')) {
    route = 'corporate-group-staff'
  } else if (pathname.includes('stations')) {
    route = 'station-staff'
  } else if (pathname.includes('drivers')) {
    const { id } = useParams<{ id: string }>()
    staffId = id
    route = 'drivers'
  }

  const { isLoading, mutate } = useMutation((vars: any) => fetch('POST', `/${route}/${staffId}/reset-password`, { data: vars }), {
    onSuccess: (): void => {
      setResetMailSent(true)
      onSuccess()
      onClose()
    },
    onError: (err: any): void => {
      onError(err?.data?.message)
      setResetMailSent(false)
    },
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    watch,
    setValue,
  } = useForm({ mode: 'onChange' })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  const Reset = async () => {
    mutate({ password: getValues().password })
    setValue('password', '')
    setValue('confirmedPass', '')
  }

  const sendResetPassword = async () => {
    if (data?.email === null) {
      alert('لا يمكن إعادة تعيين كلمة المرور للحسابات التي لا يوجد لها بريد إلكتروني')
      return
    }
    mutate({ password: null })
    // setResetMailSent(true)
  }

  return (
    <>
      {pathname.includes('drivers') && <MenuItem onClick={onOpen}>{dictionary().RESET_PASSWORD}</MenuItem>}
      {(pathname.startsWith('/corporates/') || pathname.includes('corporate-groups')) && (
        <Button disabled={resetMailSent} variant={resetMailSent ? 'text' : 'contained'} color='secondary' onClick={() => sendResetPassword()}>
          {resetMailSent ? dictionary().RESET_PASS_EMAIL_IS_SENT : dictionary().RESET_PASS_EMAIL}
        </Button>
      )}
      {!pathname.includes('/corporates/') && !pathname.includes('drivers') && !pathname.includes('corporate-groups') && (
        <Button onClick={onOpen}>{dictionary().CHANGE}</Button>
      )}

      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().RESET_PASSWORD} </ModalHeader>
          <ModalBody pb={6}>
            <form dir='rtl' onSubmit={handleSubmit(Reset as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                <PasswordAndConfirmation register={register} errors={errors} watch={watch} width='90%' minHight='100px' />
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button onClick={async () => (await trigger()) && Reset()} colorScheme='blue' mr={3}>
              {isLoading ? <Spinner /> : dictionary().SAVE}
            </Button>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ResetPassword
