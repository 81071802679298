import React, { useEffect } from 'react'
import { Link as ReactLink, useLocation } from 'react-router-dom'
import { Center, Link, Text } from '@chakra-ui/react'

import Logo from '../../components/core/Logo'
import dictionary from '../../dictionary'
import { useStore } from '../../store'
import Form from './Form'

const LoginCorporate: React.FC = () => {
  const { pathname } = useLocation()
  const route = pathname.split('/')[1]
  const setUserType = useStore((state) => state.setUserType)

  useEffect(() => {
    setUserType('admin')
  }, [])

  return (
    <>
      <Center>
        <Logo boxSize='140px' />
      </Center>
      <Form />
    </>
  )
}

export default LoginCorporate
