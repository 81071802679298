import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react'
import React from 'react'
import { Input, Button } from '@chakra-ui/react'

export const AdjustmentDialog = ({
  adjustmentDialogOpen,
  onAdjustmentDialogClose,
  adjustmentComment,
  setAdjustmentComment,
  setAskForAdjustment,
  changeStatus,
  needAdjustment,
}: {
  adjustmentDialogOpen: boolean
  onAdjustmentDialogClose: () => void
  adjustmentComment: string
  setAdjustmentComment: (value: string) => void
  setAskForAdjustment: (value: any) => void
  changeStatus: () => void
  needAdjustment: (value: any) => void
}) => {
  const cancelRef = React.useRef<any>()

  return (
    <AlertDialog
      isOpen={adjustmentDialogOpen}
      leastDestructiveRef={cancelRef}
      onClose={() => {
        setAdjustmentComment('')
        onAdjustmentDialogClose()
      }}
      isCentered
    >
      <AlertDialogOverlay bg='blackAlpha.300' backdropFilter='blur(10px)'>
        <AlertDialogContent dir='rtl'>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            تأكيد الحاجة للتعديلات
          </AlertDialogHeader>

          <AlertDialogBody>
            <>
              <p>هل أنت متأكد من أنك تريد القيام بهذه العملية؟</p>
              <Input
                dir='rtl'
                type='text'
                id='rejectReason'
                placeholder='التعليق'
                mt={3}
                value={adjustmentComment}
                onChange={(e) => {
                  setAdjustmentComment(e.target.value)
                }}
              />
            </>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                setAdjustmentComment('')
                onAdjustmentDialogClose()
              }}
            >
              إلغاء
            </Button>
            <Button
              disabled={adjustmentComment === ''}
              sx={{ backgroundColor: '#FF9800', color: '#fff' }}
              _hover={{ backgroundColor: '#F57C00' }}
              onClick={() => {
                setAskForAdjustment(true)
                needAdjustment(adjustmentComment)
                onAdjustmentDialogClose()
              }}
              mr={3}
            >
              إرسال التعليق
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
