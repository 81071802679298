import React, { useEffect, useRef, useState } from 'react'
import {
  Flex,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  ModalFooter,
  Button,
  Spinner,
  InputGroup,
  Input,
  FormErrorMessage,
  Stack,
  Box,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { parse, stringify } from 'query-string'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'
import useAlert from '../../helpers/useAlert'
import ZoomImage from './ZoomImage'

interface Props {
  data?: any
  handleIsBalancesChange?: () => void
}

const UpdateOdometerReadingDataEntry: React.FC<Props> = ({ data, handleIsBalancesChange }) => {
  const [isSaveClicked, setIsSaveClicked] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [isNoImgClicked, setIsNoImgClicked] = useState(false)
  const { onSuccess } = useAlert()

  const query = parse(location.search, { parseNumbers: true, parseBooleans: true })
  const { push } = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { isLoading, mutate } = useMutation((vars: any) => fetch('POST', `/fuel-transactions/${data.id}/odometer-reading`, { data: vars }), {
    onSuccess: (): void => {
      handleIsBalancesChange && handleIsBalancesChange()
      onSuccess()
      onClose()
      push({ search: stringify(query) })
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    trigger,
    setValue,
  } = useForm({ mode: 'onChange' })

  const SaveNoImg = async () => {
    setIsNoImgClicked(true)
    mutate({
      odometer: `${data?.odometer_reading}`,
      status: 'MISSING',
    })
    isLoading && setIsNoImgClicked(false)
  }

  const Save = async () => {
    setIsClicked(true)
    mutate({
      odometer: `${data?.odometer_reading}`,
      status: 'INVALID',
    })
    isLoading && setIsClicked(false)
  }

  const Update = async () => {
    setIsSaveClicked(true)
    mutate({
      odometer: `${getValues()?.odometer_reading}`,
      status: 'VALID',
    })
    isLoading && setIsSaveClicked(false)
  }

  const handleOpen = () => {
    onOpen()
    setIsSaveClicked(false)
    setIsClicked(false)
    setIsNoImgClicked(false)
    errors?.odometer_reading && delete errors?.odometer_reading
  }

  useEffect(() => {
    data?.odometer_reading !== 'null' && setValue('odometer_reading', data?.odometer_reading)
  }, [data])

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  return (
    <>
      <Button onClick={handleOpen}>{dictionary().EDIT}</Button>

      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().UPDATE_ODOMETER_READING} </ModalHeader>
          <ModalBody pb={6}>
            <Stack direction='column'>
              <Box display='flex' flexDirection={'column'} alignItems='center'>
                {' '}
                <h2 style={{ display: 'block', textAlign: 'center', fontWeight: 'bold' }}>{dictionary().ODOMETER_IMG}</h2>
                {data?.images?.odometer ? <ZoomImage data={data} src={data?.images?.odometer} pump={false} /> : <h3>{dictionary().NO_IMG} </h3>}
              </Box>
            </Stack>

            <form dir='rtl' onSubmit={handleSubmit(Update as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                <FormControl id={dictionary().ODOMETER_READING} w='90%' minH='100px' display='inline-block' isInvalid={!!errors.odometer_reading}>
                  <FormLabel>{dictionary().ODOMETER_READING}</FormLabel>
                  <InputGroup size='md'>
                    <Input
                      pr='1rem'
                      {...register('odometer_reading', {
                        required: dictionary().REQUIRED,
                        min: {
                          value: 0,
                          message: dictionary().NO_NEGATIVE_NUMBER,
                        },
                        pattern: {
                          value: /^(?!.*[^\d.])\d*\.?\d+$/,
                          message: dictionary().THIS_INPUT_ACCEPTED_ONLY_NUMBERS,
                        },
                      })}
                      autoFocus
                      type='text'
                    />
                  </InputGroup>
                  <FormErrorMessage>{errors.odometer_reading?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>
              {dictionary().CANCEL}
            </Button>
            <Button onClick={async () => (await trigger()) && Update()} colorScheme='blue' mr={3}>
              {isSaveClicked ? <Spinner /> : dictionary().SAVE}
            </Button>
            <Button onClick={async () => SaveNoImg()} colorScheme='blue' mr={3}>
              {isNoImgClicked ? <Spinner /> : dictionary().NO_IMG}
            </Button>
            <Button onClick={async () => Save()} colorScheme='blue'>
              {isClicked ? <Spinner /> : dictionary().CANT_READ}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UpdateOdometerReadingDataEntry
