import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'code',
    key: 'code',
    name: dictionary().STATIONS_CODE,
    type: 'text',
  },
  {
    id: 'name',
    key: 'name.en',
    name: dictionary().STATIONS_NAME,
    type: 'text',
  },
  {
    id: 'address',
    key: 'address',
    name: dictionary().ADDRESS,
    type: 'text',
  },
  {
    id: 'city',
    key: 'city',
    name: dictionary().CITY,
    type: 'text',
  },
  {
    id: 'contact_person_name',
    key: 'contact_person.name',
    name: dictionary().STATIONS_CONTACT_NAME,
    type: 'text',
  },
  {
    id: 'contact_person_phone',
    key: 'contact_person.phone',
    name: dictionary().STATIONS_CONTACT_NUMBER,
    type: 'text',
  },
  {
    id: 'type',
    key: 'type',
    name: dictionary().STATIONS_TYPE,
    type: 'text',
  },
  {
    id: 'tips_percentage',
    key: 'tips_percentage',
    name: dictionary().STATION_PERCENTAGE,
    type: 'text',
  },
  {
    id: 'longitude',
    key: 'location.longitude',
    name: dictionary().LONGITUDE,
    type: 'text',
  },
  {
    id: 'latitude',
    key: 'location.latitude',
    name: dictionary().LATITUDE,
    type: 'text',
  },
  // {
  //   id: 'stations_shift_starting',
  //   key: 'shift_starting',
  //   name: dictionary().SHIFT_START_TIME,
  //   type: 'text',
  // },
  // {
  //   id: 'stations_shifts_number',
  //   key: 'shifts_number',
  //   name: dictionary().NUMBER_OF_SHIFTS_PER_DAY,
  //   type: 'text',
  // },
  {
    id: 'stations_date_created',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'stations_time_created',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'is_demo',
    key: 'is_demo',
    name: dictionary().TYPE,
    type: 'boolean',
  },
]

const Stations: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().STATIONS}
      to='/stations/new'
      // tags={[
      //   { name: dictionary().ALL, filter: [{ name: '', value: '' }] },
      //   { name: dictionary().IS_ACTIVE, filter: [{ name: 'is_active', value: true }] },
      //   { name: dictionary().NOT_ACTIVE, filter: [{ name: 'is_active', value: false }] },
      // ]}
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['stations']}
      countArrayPath={['count']}
      restRoute={'/stations'}
      cashingKey={CASHING_KEY.GET_STATIONS}
    />
  )
}

export default Stations
