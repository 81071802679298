import { parse } from 'query-string'
import { pathOr } from 'ramda'
import { useQuery } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'
import { GridItem, Flex, Box, Stack, Text } from '@chakra-ui/react'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import Card from '../../components/core/Card'
import Table from '../core/Table'

interface Props {
  restRoute: string
}

const stationStaffHeaders = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'vehicleRedirect',
  },

  {
    id: 'name',
    key: 'number_plate',
    name: dictionary().NUMBER_PLATE,
    type: 'text',
  },
  {
    id: 'model',
    key: 'model',
    name: dictionary().CAR_MODEL,
    type: 'text',
  },
]

const DriverAssignedVehicles: React.FC<Props> = ({ restRoute }) => {
  const query = parse(location.search, { parseBooleans: true })
  const { pathname } = useLocation()
  if (pathname.includes(`${restRoute}`)) {
    const { id } = useParams<{ id: string }>()

    const assignedVehicles = useQuery([query], () => fetch('GET', restRoute + `${id}`))

    return (
      <>
        <Card h='100%' p={4}>
          <Text m={3} fontSize='20px'>
            {dictionary().ASSIGNED_VEHICLES}
          </Text>
          <GridItem m='2' colSpan={6}>
            <Box w='100%'>
              <Stack>
                <GridItem>
                  {assignedVehicles && assignedVehicles?.data?.vehicles?.length > 0 ? (
                    <Table raised enableIdRedirect={true} height={200} headers={stationStaffHeaders} data={pathOr([], ['vehicles'], assignedVehicles?.data)} />
                  ) : !assignedVehicles.isFetching ? (
                    <Flex w='100%' h='100%' align='center' justify='center'>
                      <Text fontSize='2xl'>
                        {dictionary().NO_RESULTS_FOUND} {dictionary().IN} {dictionary().ASSIGNED_VEHICLES}
                      </Text>
                    </Flex>
                  ) : (
                    <Flex w='100%' h='100%' align='center' justify='center'>
                      <Text fontSize='2xl'>
                        {dictionary().SEARCH_LOADING} {dictionary().IN} {dictionary().ASSIGNED_VEHICLES}
                      </Text>
                    </Flex>
                  )}
                </GridItem>
              </Stack>
            </Box>
          </GridItem>
        </Card>
      </>
    )
  } else {
    return <></>
  }
}

export default DriverAssignedVehicles
