import React, { FC, useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { useParams, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  Modal,
  Spinner,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormLabel,
  Button,
  FormControl,
  Input,
  useDisclosure,
  MenuItem,
  Flex,
  InputGroup,
  FormErrorMessage,
  InputLeftElement,
} from '@chakra-ui/react'
import useAlert from '../../helpers/useAlert'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'

interface Props {
  action?: any
  handleIsBalanseChanged?: () => void
}

const TransferModal: FC<Props> = ({ action, handleIsBalanseChanged }) => {
  const [path, setPath] = useState('')
  const location = useLocation()
  useEffect(() => {
    const pathArray = location.pathname.split('/')
    if (pathArray[1] == 'stations') {
      setPath('STATION')
    }
    if (pathArray[1] == 'corporates') {
      setPath('CORPORATE')
    }
    if (pathArray[1] == 'station-providers') {
      setPath('STATION_PROVIDER')
    }
  }, [])
  const { id } = useParams<{ id: string }>()
  const { onSuccess, onError } = useAlert()
  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', `ledger-transactions/withdraw`, { data: vars }), {
    onSuccess: (): void => {
      handleIsBalanseChanged && handleIsBalanseChanged()
      onSuccess()
      onClose()
    },
    onError,
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
  } = useForm({ mode: 'onChange' })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const AddTransfer = async (formData: any) => {
    formData.entity_id = id
    formData.entity_type = path
    mutate([formData])
  }

  const handleOnOpen = () => {
    onOpen()
    setValue('amount', null)
  }

  return (
    <>
      <MenuItem onClick={handleOnOpen}>{action.title}</MenuItem>

      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().WITHDRAW} </ModalHeader>
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit(AddTransfer as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                <FormControl
                  id={dictionary().REQUIRED}
                  w='40%'
                  minH='100px'
                  display='inline-block'
                  isInvalid={
                    !!errors.amount ||
                    error?.data?.message === 'balance is less than the transactions amount' ||
                    error?.data?.message === 'this ref not found or the balance not enough'
                  }
                >
                  <FormLabel style={{ direction: 'rtl' }}>{dictionary().AMOUNT}</FormLabel>
                  <InputGroup>
                    <InputLeftElement height='100%' pointerEvents='none'>
                      {dictionary().PRICING_CURRENCY}
                    </InputLeftElement>
                    <Input
                      type='number'
                      {...register('amount', {
                        required: dictionary().REQUIRED,
                        valueAsNumber: true,
                      })}
                    />
                  </InputGroup>

                  <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
                  {
                    <FormErrorMessage>
                      {error?.data?.message === 'balance is less than the transactions amount' && dictionary().BALANCE_IS_LESS_THAN_TRANSACTION_AMOUNT}
                      {error?.data?.message === 'this ref not found or the balance not enough' && dictionary().ACC_TYPE_NOT_FOUND}
                    </FormErrorMessage>
                  }
                </FormControl>
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button onClick={async () => (await trigger()) && AddTransfer(getValues() as any)} colorScheme='blue' mr={3}>
              {isLoading ? <Spinner /> : dictionary().SAVE}
            </Button>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default TransferModal
