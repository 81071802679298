import { ReactNode, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  useDisclosure,
  Icon,
  InputGroup,
  MenuItem,
  GridItem,
  Flex,
  Divider,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { useForm, UseFormRegisterReturn } from 'react-hook-form'
import { FiFile } from 'react-icons/fi'
import { useQuery } from 'react-query'
import useAlert from '../../helpers/useAlert'

import dictionary from '../../dictionary'
import upload from '../../helpers/upload'
import fetch from '../../helpers/fetch'
import UploadFile from '../../helpers/UploadFile'
import CsvDownloader from './Download'

interface Props {
  action?: any
  handleIsBalancesChange?: () => void
}

type FileUploadProps = {
  register: UseFormRegisterReturn
  accept?: string
  multiple?: boolean
  children?: ReactNode
  getValues?: any
}

const Uploader: React.FC<Props> = ({ action, handleIsBalancesChange }) => {
  const { onSuccess } = useAlert()
  const [error, setError] = useState([]) as any
  const [isPick, setIsPick] = useState(true) as any
  const [status, setStatus] = useState('') as any
  const [isCheckData, setIsCheckData] = useState(false) as any
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { id } = useParams<{ id: string }>()
  const { push } = useHistory()
  const {
    handleSubmit,
    register,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' })

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  const SubmitUpload = async (formData: any) => {
    setIsCheckData(true)

    const res =
      action?.apiRoute === '/upload-csv/drivers-vehicles/validate/'
        ? await UploadFile(`${action?.apiRoute}${id}`, formData?.file_asset, +id).then((err) => {
            setIsCheckData(false)
            return err
          })
        : await upload(`${action?.apiRoute}${id}`, formData?.file_asset, null).then((err) => {
            setIsCheckData(false)
            return err
          })

    handleErrors(res)

    if ((res as any)?.message == 'success') {
      handleIsBalancesChange && handleIsBalancesChange()
      onSuccess()
      setError([])
      setValue('file_asset', '')
      push(`/corporates/${id}`)
      onClose()
    }
  }
  useEffect(() => {
    setIsPick(true)
  }, [errors.file_asset?.message])

  const close = () => {
    setError([])
    onClose()
    setValue('file_asset', '')
    setStatus('')
  }

  const handleOpen = () => {
    onOpen()
    setValue('file_asset', '')
    setError([])
    setIsPick(false)
  }

  const handleErrors = (res: any) => {
    if (action?.apiRoute === '/upload-csv/drivers-vehicles/validate/') {
      if (Array.isArray(res)) {
        if (res[res.length - 1] === 'validation') {
          const validationErrors: any[] = []
          res.forEach((error: any, index: any) => {
            index !== res.length - 1 && validationErrors.push(`Driver name ${error?.driver} : ${error?.constraints}`)
          })
          setError(validationErrors)
        } else if (res[res.length - 1] === 'errors') {
          const validationErrors: any[] = []
          res.forEach((error: any, index: any) => {
            if (index !== res.length - 1) {
              typeof error === 'string'
                ? validationErrors.push(error)
                : validationErrors.push(
                    error?.driver ? `Driver name ${error?.driver} : ${error?.constraints}` : `Vehicle Plate Number ${error?.vehicle} : ${error?.constraints}`,
                  )
            }
          })
          setError(validationErrors)
        } else if (res[0] === 'Success') {
          setError([])
          setStatus('')
          onSuccess()
          setValue('file_asset', '')
          handleIsBalancesChange && handleIsBalancesChange()
          push(`/corporates/${id}`)
          onClose()
        } else {
          setError([])
          setStatus('')
        }
      }
    } else {
      const messageText = Array.isArray((res as any)?.data?.message)
        ? (res as any)?.data?.message?.[0] !== undefined && Object.values((res as any)?.data?.message?.[0]).length > 0
          ? Object.values((res as any)?.data?.message?.[0])?.[0]
          : false
        : (res as any)?.data?.message
      setError(
        messageText
          ? [{ err: messageText }]
          : typeof (res as any)?.data?.message === 'string'
          ? [{ err: (res as any)?.data?.message }]
          : (res as any)?.data?.message,
      )
      setStatus((res as any)?.data?.statusCode || (res as any)?.message)
    }
  }

  const { data, isLoading } = useQuery(['download'], () => fetch('GET', `${action.apiHeader}`), {})
  return (
    <>
      <MenuItem onClick={handleOpen}>{action?.title}</MenuItem>

      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{action?.title} </ModalHeader>
          <ModalBody pb={6}>
            <GridItem style={{ direction: 'rtl' }} mb={1}>
              <Flex align='center' gap='15px'>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <CsvDownloader headers={data} query='' PAGE_SIZE={''} setPAGE_SIZE={''} apiHeader={action.apiHeader} isUpload={false} />
                )}
                <Divider orientation='vertical' height='42px' />
              </Flex>
            </GridItem>
            <form id='uploadform' onSubmit={handleSubmit(SubmitUpload as any)}>
              <FormControl isRequired>
                <Flex style={{ direction: 'rtl' }} justifyContent='end'>
                  <FileUpload multiple register={register('file_asset', { required: dictionary().REQUIRED })} getValues={getValues}>
                    <Button onClick={() => setError([])} leftIcon={<Icon as={FiFile} />}>
                      {dictionary().PICK_FILE}
                    </Button>
                  </FileUpload>
                </Flex>
                {errors.file_asset?.message && isPick && (
                  <Text style={{ direction: 'rtl' }} fontSize='md' m={2} color='red.400'>
                    {dictionary().REQUIRED}
                  </Text>
                )}
              </FormControl>
              {typeof error?.[0] !== 'string' ? (
                <h2 style={{ color: 'red' }} key={id}>
                  {error?.map((err: any, id: any) => (
                    <div key={id}>
                      {' '}
                      {Object.values(err).map((single: any, id) => (
                        <li key={id}>{single}</li>
                      ))}{' '}
                    </div>
                  ))}
                </h2>
              ) : (
                error?.length > 0 && (
                  <ul
                    style={
                      error?.length > 6
                        ? { color: 'red', padding: '8px', maxHeight: '220px', overflowY: 'scroll', marginTop: '15px' }
                        : { color: 'red', padding: '8px', maxHeight: '220px', marginTop: '15px' }
                    }
                  >
                    {error?.map((err: any, id: any) => (
                      <li style={{ padding: '5px 0px' }} key={id}>
                        {err}
                      </li>
                    ))}
                  </ul>
                )
              )}
            </form>
          </ModalBody>

          <ModalFooter>
            <Button disabled={!!isCheckData} onClick={async () => (await trigger()) && SubmitUpload(getValues() as any)} colorScheme='blue' mr={3}>
              {!!isCheckData ? <Spinner /> : dictionary().SAVE}
            </Button>
            <Button onClick={close}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const FileUpload = (props: FileUploadProps) => {
  const { register, children } = props
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { ref, ...rest } = register as { ref: (instance: HTMLInputElement | null) => void }

  const handleClick = () => inputRef.current?.click()

  return (
    <InputGroup style={{ direction: 'rtl' }} onClick={handleClick}>
      <input
        type={'file'}
        multiple={false}
        hidden
        {...rest}
        ref={(e) => {
          ref(e)
          inputRef.current = e
        }}
      />
      <>{children}</>
      <Text style={{ direction: 'rtl' }} fontSize='md' m={2}>
        {props.getValues()?.file_asset?.length === 1 && props.getValues()?.file_asset[0]?.name}
      </Text>
    </InputGroup>
  )
}

export default Uploader
