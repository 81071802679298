import React, { useState, useEffect } from 'react'
import { Flex, IconButton, Box, useDisclosure, Modal, ModalBody, ModalContent, ModalCloseButton, ModalHeader, ModalOverlay, Image } from '@chakra-ui/react'
import { ViewIcon, DeleteIcon } from '@chakra-ui/icons'
import dictionary from '../../dictionary'

interface Props {
  list?: string[]
  disabled?: boolean
  onRemove?: (index: number) => void
}

const ImageList: React.FC<Props> = ({ list = [], onRemove = () => {}, disabled = false }) => {
  const [innerList, setInnerList] = useState<string[]>([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedImage, setSelectedImage] = useState('')

  useEffect(() => {
    if (Array.isArray(list)) setInnerList(list)
  }, [list])

  const onItemRemove = (index: number) => {
    setInnerList([...innerList.filter((item, i) => index !== i)])
    onRemove(index)
  }

  return (
    <>
      <ImageModal isOpen={isOpen} onClose={onClose} src={selectedImage} />
      <Flex>
        {list.map((listItem, index) => (
          <Box key={index}>
            <ImageCard
              src={listItem}
              onRemove={() => onItemRemove(index)}
              onView={(src) => {
                setSelectedImage(src)
                onOpen()
              }}
              disabled={disabled}
            />
          </Box>
        ))}
      </Flex>
    </>
  )
}

interface ImageCardProps {
  src: string
  disabled?: boolean
  onRemove?: () => void
  onView: (src: string) => void
}

const ImageCard: React.FC<ImageCardProps> = ({ disabled, src, onRemove = () => {}, onView }) => {
  return (
    <Box borderRadius='8px' bg='transparent' border='1px solid gray' w='100px' h='100px' p='0.5' mr='2'>
      <Box
        borderRadius='8px'
        w='100%'
        h='100%'
        bgImg={`url(${src})`}
        bgSize='cover'
        bgPos='center'
        bgRepeat='no-repeat'
        sx={{ '&:hover': { '& > div': { display: 'flex', opacity: '0.7' } } }}
      >
        <Box
          borderRadius='8px'
          display='none'
          justifyContent='center'
          alignItems='center'
          w='100%'
          h='100%'
          opacity='0'
          bg='black'
          sx={{ transition: 'opacity 1s ease-out' }}
        >
          <IconButton aria-label='show' onClick={() => onView(src)} borderRadius='8px' size='sm' m='2'>
            <ViewIcon />
          </IconButton>
          {!disabled && (
            <IconButton aria-label='remove' onClick={onRemove} borderRadius='8px' size='sm' m='2'>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  )
}

interface ImageModalProps {
  isOpen: boolean
  onClose: () => void
  src: string
}

const ImageModal: React.FC<ImageModalProps> = ({ isOpen, onClose, src }) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{dictionary().IMAGE}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={src} alt='Uploaded Image' />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ImageList
