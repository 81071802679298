import axios from 'axios'

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const UploadFile = async (ENDPOINT: string, files: File[], id: number): Promise<any[]> => {
  const form = new FormData()
  if (!!files) {
    for (const file of files) {
      form.append('file_asset', file)
    }
  }
  try {
    const validationRes = await axios.post(ENDPOINT, form, { headers: { 'Content-Type': 'multipart/form-data' } })
    if (!validationRes?.data?.validation) {
      if (validationRes?.data?.errors?.length === 0) {
        const commitRes = await axios.post(`/upload-csv/drivers-vehicles/commit/${id}`, '')
        return ['Success']
      } else {
        return [...validationRes?.data?.errors, 'errors']
      }
    } else {
      return [...validationRes?.data?.validation, 'validation']
    }
  } catch (error: any) {
    return error?.response
  } finally {
    ;(document?.getElementById('uploadform') as HTMLFormElement | undefined)?.reset()
  }
}

export default UploadFile
