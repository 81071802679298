import React, { useRef } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputLeftElement } from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import Reqiured from '../../components/core/reqiured'

type AddProductTypeMutationVariables = {
  name_en: string
  name_ar: string
  icon: string
  density: number
  weight_cost: number
  vehicle_type_ids: [number]
  code: string
}

const AddProductType: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    control,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  React.useEffect(() => {}, [])

  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const { isLoading, mutate } = useMutation((vars: any) => fetch('POST', '/product-types', { data: vars }), {
    onSuccess: (): void => {
      push('/product-types')

      onSuccess()
    },

    onError,
  })

  const addProductType = async (formData: AddProductTypeMutationVariables) => {
    mutate([formData])
  }

  return (
    <Container>
      <Flex ref={headerRef} flexDir='column' className='margin-150'>
        <Header
          title={dictionary().ADD_NEW_PRODUCT_TYPE}
          action={
            <SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && addProductType(getValues() as AddProductTypeMutationVariables)} />
          }
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(addProductType as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl
              id={dictionary().PRODUCT_NAME_AR}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.name?.ar}
            >
              <FormLabel>
                {dictionary().PRODUCT_NAME_AR} <Reqiured />
              </FormLabel>
              <Input
                type='string'
                {...register('name.ar', {
                  required: dictionary().REQUIRED,
                })}
              />
              <FormErrorMessage>{errors?.name?.ar?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().PRODUCT_NAME_EN}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.name?.en}
            >
              <FormLabel>
                {dictionary().PRODUCT_NAME_EN} <Reqiured />
              </FormLabel>
              <Input
                type='string'
                {...register('name.en', {
                  required: dictionary().REQUIRED,
                })}
              />
              <FormErrorMessage>{errors?.name?.en?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().LOGO} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors?.icon}>
              <FormLabel>
                {dictionary().LOGO} <Reqiured />
              </FormLabel>
              <Input
                type='string'
                {...register('icon', {
                  required: dictionary().REQUIRED,
                })}
              />
              <FormErrorMessage>{errors?.icon?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().DENSITY}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.density}
            >
              <FormLabel>
                {dictionary().DENSITY} <Reqiured />
              </FormLabel>
              <InputLeftElement height='100%' pointerEvents='none' padding={3}>
                {dictionary().KG_CUBIC_METER}
              </InputLeftElement>
              <Input type='number' {...register('density', { required: dictionary().REQUIRED, valueAsNumber: true })} />
              <FormErrorMessage>{errors?.density?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().WEIGHT_COST}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.weight_cost}
            >
              <FormLabel>
                {dictionary().WEIGHT_COST} <Reqiured />
              </FormLabel>
              <InputLeftElement height='100%' pointerEvents='none' padding={3}>
                {dictionary().PRICING_CURRENCY}
              </InputLeftElement>
              <Input type='number' {...register('weight_cost', { required: dictionary().REQUIRED, valueAsNumber: true })} />
              <FormErrorMessage>{errors.weight_cost?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().VEHICLE_TYPES}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.vehicle_type_ids}
            >
              <FormLabel>{dictionary().VEHICLE_TYPES}</FormLabel>
              <Controller
                name={`vehicle_type_ids`}
                control={control}
                render={({ field: { onChange } }) => (
                  <AutoCompleteInput
                    onChange={onChange}
                    searchRoute='/vehicle-types'
                    singleRoute='/vehicle-types'
                    multiple={true}
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.name?.ar}
                    isInvalid={!!errors?.vehicle_type_ids}
                  />
                )}
              />
              <FormErrorMessage>{errors.vehicle_type_ids?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().CODE} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.code}>
              <FormLabel>
                {dictionary().CODE} <Reqiured />
              </FormLabel>
              <Input type='string' {...register('code', { required: dictionary().REQUIRED })} />
              <FormErrorMessage>{errors.code?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddProductType
