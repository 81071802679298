import React from 'react'
import ViewOne from '../../components/layout/ViewOne'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const header = [
  {
    id: 'cash_balance',
    key: 'ledger_accounts',
    name: dictionary().AVIALABLE_BALANCE,
    type: 'Cash_balance',
    isRight: true,
  },
  {
    id: 'name.ar',
    key: 'name.ar',
    name: dictionary().STATION_NAME_AR,
    type: 'text',
  },
  {
    id: 'name.en',
    key: 'name.en',
    name: dictionary().STATION_NAME_EN,
    type: 'text',
  },
  {
    id: 'contact_person.name',
    key: 'contact_person.name',
    name: dictionary().STATIONS_CONTACT_NAME,
    type: 'text',
  },
  {
    id: 'contact_person.phone',
    key: 'contact_person.phone',
    name: dictionary().STATIONS_CONTACT_NUMBER,
    type: 'text',
  },
  {
    id: 'station_count',
    key: 'station_count',
    name: dictionary().STATION_COUNT,
    type: 'text',
  },
  {
    id: 'fee_rate',
    key: 'fee_rate',
    name: dictionary().COMMISSION_FEE,
    type: 'text',
  },
  {
    id: 'fee_percentage_rate',
    key: 'fee_percentage_rate',
    name: dictionary().FEE_PERCENTAGE_RATE,
    type: 'text',
  },
  {
    id: 'show_balance',
    key: 'show_balance',
    name: dictionary().BALANCE,
    type: 'boolean',
  },
  {
    id: 'is_active',
    key: 'is_active',
    name: dictionary().STATUS,
    type: 'boolean',
  },
  {
    id: 'station_count',
    key: 'station_count',
    name: dictionary().STATIONS,
    type: 'table',
  },
]
const actions = [
  { type: 'path', action: 'edit', title: dictionary().EDIT },
  {
    type: 'path',
    action: 'add-new-staff',
    title: dictionary().ADD_NEW_STAFF,
  },
  {
    type: 'path',
    action: 'deposits',
    title: dictionary().WITHDRAW,
  },
]

const StationProvider: React.FC = () => {
  return (
    <>
      <ViewOne
        actions={actions}
        header={header}
        restRoute='/station-providers'
        dataLocation=''
        title={dictionary().STATION_PROVIDER}
        cashingKey={CASHING_KEY.GET_SINGLE_STATION_PROVIDER}
        userType='station-providers'
      />
    </>
  )
}

export default StationProvider
