import { ROLES } from '../ROLES'

export const getCorporateAddNew = (role: string): any => {
  switch (role) {
    case ROLES.ACCOUNT_MANAGER:
      return false
    default:
      return true
  }
}
