import React from 'react'

import ViewOne from '../../components/layout/ViewOne'
import { CASHING_KEY } from '../../constants'
import { getCorporateActions } from '../../constants/actions/corporate'
import dictionary from '../../dictionary'
import { store, useStore } from '../../store'

const header = [
  {
    id: 'corporate_total_balance',
    key: 'ledger_accounts',
    name: dictionary().CONSUMED_BALANCE,
    type: 'Consumed_balance',
    isRight: true,
  },
  {
    id: 'corporate_total_balance',
    key: 'ledger_accounts',
    name: dictionary().AMOUNT_PAID,
    type: 'Cash_balance',
    isRight: true,
  },
  {
    id: 'corporate_total_balance',
    key: 'ledger_accounts',
    name: dictionary().WALLET_BALANCE,
    type: 'usedBalance',
    isRight: true,
  },
  {
    id: 'corporate_total_balance',
    key: 'ledger_accounts',
    name: dictionary().UNUSED_BALANCE,
    type: 'unusedBalance',
    isRight: true,
  },
  {
    id: 'name',
    key: 'name',
    name: dictionary().NAME,
    type: 'text',
  },
  {
    id: 'corporateGroupId',
    key: 'corporateGroupId',
    name: dictionary().CORPORATES_GROUP,
    type: 'text',
  },
  {
    id: 'settlement_type',
    key: 'settlement',
    name: dictionary().SETTLEMENT_TYPE,
    type: 'text',
  },
  {
    id: 'billing_start_date',
    key: 'billing_start_date',
    name: dictionary().BILLING_TIME,
    type: 'date',
  },
  {
    id: 'credit_limit',
    key: 'credit_limit',
    name: dictionary().CREDIT_LIMIT,
    type: 'text',
  },
  {
    id: 'billing_period',
    key: 'billing_period',
    name: dictionary().BILLING_PERIOD,
    type: 'text',
  },
  {
    id: 'threshold',
    key: 'threshold',
    name: dictionary().THRESHOLD,
    type: 'text',
  },
  {
    id: 'fee_rate',
    key: 'fee_rate',
    name: dictionary().COMMISSION_FEE,
    type: 'text',
  },
  {
    id: 'finance_rate',
    key: 'finance_rate',
    name: dictionary().FINANCE_FEE,
    type: 'text',
  },
  {
    id: 'subscription_cost_per_vehicle',
    key: 'subscription_cost_per_vehicle',
    name: dictionary().SUBSCRIPTION_COST_PER_VEHICLE,
    type: 'text',
  },
  {
    id: 'fraud_detection_fees',
    key: 'fraud_detection_fees',
    name: dictionary().FRAUD_DETECTION_FEES,
    type: 'text',
  },
  {
    id: 'consumption_measurement',
    key: 'consumption_measurement',
    name: dictionary().CONSUMPTION_MEASUREMENT,
    type: 'text',
  },
  {
    id: 'replenish_type',
    key: 'replenish_type',
    name: dictionary().REPLENISH_TYPE,
    type: 'text',
  },
  {
    id: 'daily_replenish_time',
    key: 'daily_replenish_time',
    name: dictionary().DAILY_REPLENISH_TIME,
    type: 'text',
  },
  {
    id: 'date',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'config.pin_code_digits_number',
    key: 'config.pin_code_digits_number',
    name: dictionary().PIN_CODE_LENGTH,
    type: 'text',
  },
  {
    id: 'is_demo',
    key: 'is_demo',
    name: dictionary().TYPE,
    type: 'boolean',
  },
  {
    id: 'is_active',
    key: 'is_active',
    name: dictionary().STATUS,
    type: 'boolean',
  },
  {
    id: 'all_vehicles_access',
    key: 'all_vehicles_access',
    name: dictionary().DRIVERS,
    type: 'boolean',
  },
  {
    id: 'use_shifts',
    key: 'use_shifts',
    name: dictionary().SHIFTS,
    type: 'boolean',
  },
  {
    id: 'shift_starting',
    key: 'shift_starting',
    name: dictionary().SHIFT_START_TIME,
    type: 'text',
  },
]

const { role, userType } = store.getState()
if (role === 'SUPER' && userType === 'admin') {
  header.push({
    id: 'deposit_confirmation_type',
    key: 'deposit_confirmation_type',
    name: dictionary().DEPOSIT_CONFIGURATION,
    type: 'text',
  })
}

const CorporatePage: React.FC = () => {
  const actions = getCorporateActions(role ?? '')

  return (
    <ViewOne
      actions={actions}
      header={header}
      restRoute='/corporates'
      dataLocation={''}
      title={dictionary().CORPORATE}
      cashingKey={CASHING_KEY.GET_SINGLE_CORPORATE}
      userType='corporates'
    />
  )
}

export default CorporatePage
