import React from 'react'
import { Menu, MenuItem, useToast } from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import { Method } from 'axios'

import fetch from '../../helpers/fetch'
import { useConfirm } from '../../helpers/useConfirm'
import dictionary from '../../dictionary'

interface Props {
  title: string
  description?: string
  variables: any
  onSuccess?: () => void
  icon?: JSX.Element
  errMsg?: string
  actionName?: string
  color?: string
  confirmationText?: string
  apiMethod: Method
  apiRoute: string
}

const GenericAction: React.FC<Props> = ({
  variables,
  title,
  description,
  onSuccess = () => {},
  icon,
  errMsg = dictionary().FAILED,
  actionName = dictionary().DELETE,
  confirmationText,
  color,
  apiMethod,
  apiRoute,
}) => {
  const confirm = useConfirm()
  const toast = useToast()
  const onConfirm = async () => {
    try {
      await fetch(apiMethod, apiRoute, variables)
      onSuccess()
    } catch (e: any) {
      toast({
        title: errMsg,
        description: e.message,
        status: 'error',
        isClosable: true,
      })
    }
  }

  return (
    <MenuItem
      icon={icon ?? <DeleteIcon display='flex' alignItems='center' />}
      onClick={() =>
        confirm(onConfirm, {
          title,
          description,
          confirmationText: confirmationText ?? actionName,
          confirmationButtonProps: {
            colorScheme: color,
          },
        })
      }
      color={color ?? 'error.500'}
      display='flex'
      alignItems='center'
    >
      {actionName}
    </MenuItem>
  )
}

export default GenericAction
