import dictionary from '../../dictionary'
import { ROLES } from '../ROLES'

export const getCorporateGroupActions = (role: string | null): any => {
  switch (role) {
    case ROLES.ACCOUNT_MANAGER:
      break
    case ROLES.OPERATIONS:
    case ROLES.MANAGER:
    case ROLES.SUPER_ADMIN:
      return [
        {
          type: 'path',
          action: 'edit',
          title: dictionary().EDIT,
        },
        {
          type: 'path',
          action: 'add-new-staff',
          title: dictionary().ADD_NEW_STAFF,
        },
      ]
    default:
      return [
        {
          type: 'path',
          action: 'edit',
          title: dictionary().EDIT,
        },
        // {
        //   type: 'path',
        //   action: 'add-new-staff',
        //   title: dictionary().ADD_NEW_STAFF,
        // },
      ]
  }
}
