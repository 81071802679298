import { StateManagementVariables } from '../../../types/AddFuelTransactionVariables'

type updateDriverParams = {
  driver_id: any
  stateManager: StateManagementVariables
  setStateManager: any
  setValue: any
}
const updateDriver = ({ driver_id, stateManager, setStateManager, setValue }: updateDriverParams): void => {
  // Check if driver_id is a valid number
  if (typeof driver_id.current === 'number' && !Number.isNaN(driver_id.current)) {
    // Set isDriverSelected to true if driver_id is valid
    setStateManager({ ...stateManager, isDriverSelected: true })
  } else {
    // Set isDriverSelected to false if driver_id is not a valid number
    setStateManager({ ...stateManager, isDriverSelected: false })
    // Reset the value of external_station_name
    setValue('external_station_name', '')
  }
}

export default updateDriver
