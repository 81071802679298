import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Input, Button } from '@chakra-ui/react'

export const RejectDialog = ({
  isOpen,
  onClose,
  rejectReason,
  setRejectReason,
  setReject,
  changeStatus,
  rejectChargingProfile,
}: {
  isOpen: boolean
  onClose: () => void
  rejectReason: string
  setRejectReason: (value: string) => void
  setReject: (value: any) => void
  changeStatus: () => void
  rejectChargingProfile: (rejectReason: string) => void
}) => {
  const cancelRef = React.useRef<any>()

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={() => {
        setRejectReason('')
        onClose()
      }}
      isCentered
    >
      <AlertDialogOverlay bg='blackAlpha.300' backdropFilter='blur(10px)'>
        <AlertDialogContent dir='rtl'>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            تأكيد الرفض
          </AlertDialogHeader>

          <AlertDialogBody>
            <>
              <p>هل أنت متأكد من أنك تريد القيام بهذه العملية؟</p>
              <Input
                dir='rtl'
                type='text'
                id='rejectReason'
                placeholder='سبب الرفض'
                mt={3}
                value={rejectReason}
                onChange={(e) => {
                  setRejectReason(e.target.value)
                }}
              />
            </>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                setRejectReason('')
                onClose()
              }}
            >
              إلغاء
            </Button>
            <Button
              disabled={rejectReason === ''}
              colorScheme='red'
              onClick={() => {
                setReject(true)
                rejectChargingProfile(rejectReason)
                onClose()
              }}
              mr={3}
            >
              رفض
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
