import React, { useRef, useState } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, Text, Spinner, Button, InputGroup } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { parse } from 'query-string'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import Required from '../../components/core/reqiured'
import Table from '../../components/core/Table'
import ConfirmationModel from '../../components/core/ConfirmationModel'

type AddSPPaymentsVariables = {
  date: Date
  id: number
  entity_type: string
  amount: any
  payment_method: string
}

const stationHeaders = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'st_provider_name',
    key: 'name.ar',
    name: dictionary().NAME,
    type: 'text',
  },
  {
    id: 'st_provider_balance',
    key: 'ledger_accounts',
    name: dictionary().CURRENT_BALANCE,
    type: 'Cash_balance',
    isRight: true,
  },
  {
    id: 'paymentMethod',
    key: 'ledger_accounts',
    name: dictionary().PAYMENT_METHOD,
    type: 'input',
  },
  {
    id: 'amount',
    key: 'ledger_accounts',
    name: dictionary().AMOUNT,
    type: 'input',
  },
]

const AddSPPayments: React.FC = () => {
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const query = parse(location.search, { parseBooleans: true })

  const { onSuccess, onError } = useAlert()
  const [isCorporatesSelected, setIsCorporatesSelected] = useState(false)
  const [selectedCorporatesIds, setselectedCorporatesIds] = useState<any[]>([])
  const [selectedCorporates, setselectedCorporates] = useState<any[]>([])

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    trigger,
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
  })

  const {
    handleSubmit: finalHandleSubmit,
    register: finalRegister,
    formState: { errors: finalErrors },
    trigger: finalTrigger,
    getValues: finalGetValues,
  } = useForm({
    mode: 'onChange',
  })

  const corporates = useQuery([query, 'corporates'], () => fetch('GET', '/station-providers', { query: { page_size: 1000 } }))
  const { isLoading, mutate } = useMutation((vars: AddSPPaymentsVariables[]) => fetch('POST', '/ledger-transactions/withdraw', { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push('/station-providers')
    },
    onError,
  })

  const AddCorporates = async (formData: any) => {
    setselectedCorporatesIds(formData?.corporates_ids)
    const corporatesData: any[] = []
    corporates?.data?.data?.map((corporate: any) => {
      if (formData?.corporates_ids?.includes(corporate?.id)) {
        corporatesData.push(corporate)
      }
    })
    setselectedCorporates([...corporatesData])
    corporatesData.length > 0 && getValues()?.corporates_ids?.length > 0 && setIsCorporatesSelected(true)
  }
  const handlePrevious = () => {
    setIsCorporatesSelected(false)
    setValue('corporates_ids', selectedCorporatesIds)
  }

  const addPayments = async (formData: any) => {
    const paymentsData: any[] = []
    selectedCorporatesIds?.map((id: any) => {
      paymentsData?.push({
        entity_id: id,
        entity_type: 'STATION_PROVIDER',
        date: formData?.date,
        amount: formData[id + ''],
        payment_method: formData[id + 'method'],
      })
    })
    mutate(paymentsData)
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header title={dictionary().STATION_PROVIDERS} />
      </Flex>
      <Card p='8' marginTop={['20px', '0px', '20px']} style={{ minHeight: '350px' }}>
        {!isCorporatesSelected && (
          <form onSubmit={handleSubmit(addPayments as any)}>
            <Flex className='margin-100' justify='center' align='center' direction='column'>
              <Text fontSize={20}>{dictionary().STATION_PROVIDERS_DEPOSIT}</Text>
              <FormControl id={dictionary().CORPORATE} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.corporates_ids}>
                <FormLabel>
                  {dictionary().STATION_PROVIDERS} <Required />
                </FormLabel>
                <Controller
                  name='corporates_ids'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      onChange={onChange}
                      searchRoute='/station-providers'
                      singleRoute='/station-providers'
                      multiple={true}
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name?.ar}
                      isInvalid={!!errors?.corporates_ids}
                      value={value}
                    />
                  )}
                  rules={{
                    required: true && dictionary().REQUIRED,
                  }}
                />
                <FormErrorMessage>{errors.corporates_ids?.message}</FormErrorMessage>
              </FormControl>
              <Button marginTop={8} w={['100%', '70%', '70%', '70%']} onClick={async () => (await trigger()) && AddCorporates(getValues())}>
                {isCorporatesSelected ? <Spinner /> : dictionary().NEXT}
              </Button>
            </Flex>
          </form>
        )}
        {isCorporatesSelected && (
          <form onSubmit={finalHandleSubmit(AddCorporates as any)}>
            <Flex className='margin-100' justify='center' align='center' direction='column'>
              <Text fontSize={20}>{dictionary().STATION_PROVIDERS_DEPOSIT}</Text>
              <Flex justify='space-evenly' align='start' w={['100%', '70%', '70%', '70%']} direction='column'>
                <FormControl
                  id={dictionary().DATE}
                  marginBottom='5px'
                  w={['100%', '40%', '40%', '40%']}
                  minH={['50px', '100px']}
                  display='inline-block'
                  isInvalid={!!finalErrors?.date}
                >
                  <FormLabel>
                    {dictionary().DATE} <Required />{' '}
                  </FormLabel>
                  <InputGroup>
                    <Input
                      defaultValue={format(new Date(), 'yyyy-MM-dd')}
                      type='date'
                      {...finalRegister('date', {
                        required: true && dictionary().REQUIRED,
                      })}
                    />
                  </InputGroup>
                  <FormErrorMessage>{finalErrors?.date?.message}</FormErrorMessage>
                </FormControl>
                <Table
                  raised
                  enableIdRedirect={false}
                  height={450}
                  width={'100%'}
                  headers={stationHeaders}
                  data={selectedCorporates}
                  register={finalRegister}
                  errors={finalErrors}
                />
              </Flex>
              <Flex justify='space-evenly' align='center' direction='row' w={['100%', '70%', '70%', '70%']}>
                <Button marginTop={8} w={['50%', '70%']} marginLeft={5} onClick={handlePrevious}>
                  {dictionary().PREVIOUS}
                </Button>
                <ConfirmationModel
                  trigger={finalTrigger}
                  addPayments={addPayments}
                  getValues={finalGetValues}
                  isLoading={isLoading}
                  data={selectedCorporates}
                  width={['50%', '70%']}
                />
              </Flex>
            </Flex>
          </form>
        )}
      </Card>
    </Container>
  )
}

export default AddSPPayments
