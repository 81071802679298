import React from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { useStore } from '../../store'

interface Props {
  src: string
  pump?: boolean
  data?: any
}
interface IconProps {
  innerText: string
  Method: any
}

const IconButton: React.FC<IconProps> = ({ Method, innerText }) => {
  return (
    <button
      style={{
        padding: '1px 6px 3px 6px',
        margin: '3px',
        backgroundColor: 'rgba(255,255,255,0.5)',
        textAlign: 'center',
        borderRadius: '3px',
      }}
      onClick={() => Method()}
    >
      {innerText}
    </button>
  )
}

const ZoomImage: React.FC<Props> = ({ data, src, pump }) => {
  const { role } = useStore()
  const is_pump_cropped = data?.images?.is_pump_cropped || false

  return (
    <div style={{ cursor: 'zoom-in', borderRadius: '10px', border: '1px dashed rgba(133, 148, 177, 0.50)', padding: '4px' }}>
      <TransformWrapper>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <>
            <div
              style={{
                marginBottom: '2px',
                color: 'black',
                background: '#EEE4F1',
              }}
            >
              <IconButton Method={resetTransform} innerText='x' />
              <IconButton Method={zoomOut} innerText='-' />
              <IconButton Method={zoomIn} innerText='+' />
            </div>
            <TransformComponent>
              {(['SUPER', 'MANAGER'].includes(role ?? '') || !pump || (pump && !is_pump_cropped)) && (
                <img src={src} alt='Zoomable Image' style={{ height: '350px', width: '350px' }} />
              )}

              {!['SUPER', 'MANAGER'].includes(role ?? '') && pump && is_pump_cropped && (
                <div
                  style={{
                    overflow: 'hidden',
                    width: '295.5px',
                    height: '201.5px',
                    textAlign: 'center',
                    margin: '20px 125px',
                  }}
                >
                  <img
                    onDragStart={() => {
                      return false
                    }}
                    draggable={false}
                    src={src}
                    alt='IMG'
                    style={{
                      maxWidth: '402px',
                      maxHeight: '591px',
                      overflow: 'hidden',
                      transform: 'scale(1.1)',
                      marginBottom: '-305px',
                      marginLeft: '-30px',
                      pointerEvents: 'none',
                    }}
                  />
                </div>
              )}
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </div>
  )
}

export default ZoomImage
