import { FormControl, FormErrorMessage, FormLabel, Textarea } from '@chakra-ui/react'
import dictionary from '../../../dictionary'

type CommentControlProps = {
  errors: any
  register: any
}

const CommentControl = ({ errors, register }: CommentControlProps) => {
  return (
    <FormControl id={dictionary().COMMENT} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.comment}>
      <FormLabel>{dictionary().COMMENT}</FormLabel>
      <Textarea
        size='sm'
        height='150px'
        fontSize='md'
        p='0'
        {...register('comment', {
          required: false,
        })}
      />
      <FormErrorMessage>{errors.comment?.message}</FormErrorMessage>
    </FormControl>
  )
}

export default CommentControl
