import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const EInvoices = () => {
  const headers = [
    {
      id: 'invoice_number',
      key: 'invoice_number',
      name: dictionary().INVOICE_NUMBER_EN,
      type: 'text',
    },
    {
      id: 'invoice_date',
      key: 'invoice_date',
      name: dictionary().INVOICE_DATE,
      type: 'date',
    },
    {
      id: 'contact_name',
      key: 'contact_name',
      name: dictionary().CONTACT_NAME,
      type: 'text',
    },
    {
      id: 'payment_term',
      key: 'payment_term',
      name: dictionary().PAYMENT_TERM,
      type: 'text',
    },
    {
      id: 'invoice_type',
      key: 'invoice_type',
      name: dictionary().INVOICE_TYPE,
      type: 'text',
    },
    {
      id: 'movement_type',
      key: 'movement_type',
      name: dictionary().MOVEMENT_TYPE,
      type: 'text',
    },
    {
      id: 'reference_number',
      key: 'reference_number',
      name: dictionary().REFERENCE_NUMBER,
      type: 'text',
    },
    {
      id: 'item_name',
      key: 'item_name',
      name: dictionary().ITEM_NAME,
      type: 'text',
    },
    {
      id: 'description',
      key: 'description',
      name: dictionary().DESCRIPTION,
      type: 'text',
    },
    {
      id: 'quantity',
      key: 'quantity',
      name: dictionary().QUANTITY,
      type: 'text',
    },
    {
      id: 'price',
      key: 'price',
      name: dictionary().PRICE,
      type: 'text',
    },
    {
      id: 'discount_before_tax_type',
      key: 'discount_before_tax_type',
      name: dictionary().DISCOUNT_BEFORE_TAX_TYPE,
      type: 'text',
    },
    {
      id: 'discount_before_tax',
      key: 'discount_before_tax',
      name: dictionary().DISCOUNT_BEFORE_TAX,
      type: 'text',
    },
    {
      id: 'unit',
      key: 'unit',
      name: dictionary().UNIT,
      type: 'text',
    },
    {
      id: 'tax_group',
      key: 'tax_group',
      name: dictionary().TAX_GROUP,
      type: 'text',
    },
    {
      id: 'currency',
      key: 'currency',
      name: dictionary().CURRENCY,
      type: 'text',
    },
    {
      id: 'exchange_rate',
      key: 'exchange_rate',
      name: dictionary().EXCHANGE_RATE,
      type: 'text',
    },
    {
      id: 'chargingProfileId',
      key: 'chargingProfileId',
      name: dictionary().CHARGING_PROFILE_ID,
      type: 'text',
    },
  ]

  return (
    <ViewAll
      headers={headers}
      title={dictionary().E_INVOICES}
      hasSearchDate={true}
      dataArrayPath={['e-invoices']}
      countArrayPath={['count']}
      restRoute={'/corporates/e-invoices'}
      cashingKey={CASHING_KEY.E_INVOICES}
    />
  )
}

export default EInvoices
